<template>
  <article
    class="article"
    v-html="content"></article>
</template>


<script>
import { sanitize } from 'dompurify'
import '@/assets/doc.css'
import html from '@/docs/privacy.md'


export default {
  metaInfo: {
    title: "Privacy Policy",
  },
  setup () {
    const content = sanitize(html)

    return {
      content,
    }
  }
}
</script>

<style scoped>
.article {
  max-width: 40rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>