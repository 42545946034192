<template>
  <div class="posts">
    <mt-spinner
      v-show="busy && posts.length === 0"
      type="snake"
      class="spinner"></mt-spinner>

    <post-list-mobile
      v-if="isOnMobile"
      :posts="posts">
    </post-list-mobile>
    <post-list-pc
      v-else
      :posts="posts">
    </post-list-pc>

    <load-more-button
      v-show="posts.length > 0"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search(keyword)">
    </load-more-button>

    <pr-empty
      v-if="posts.length === 0 && !hasMore"
      :text="$t('noPostsFound')">
    </pr-empty>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { API_URL } from '@/config'
import PostListPc from '@/components/PostListPc.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useResponsive } from '@/composables/responsive'
import PostListMobile from '@/components/PostListMobile.vue'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      posts: [],
      hasMore: true,
      busy: false
    }
  },
  computed: {
    keyword () {
      return this.$route.query.q
    }
  },
  watch: {
    keyword (keyword, old) {
      this.page = 1
      this.hasMore = true
      this.posts = []

      this.search(keyword)
    }
  },
  mounted () {
    if (this.keyword && this.posts.length === 0) {
      this.search(this.keyword)
    }
  },
  methods: {
    search (keyword) {
      this.busy = true

      this.$http
        .get(`${API_URL}/posts/search`, {
          params: {
            limit: 5,
            page: this.page,
            keywords: keyword
          }
        })
        .then(({ ok, data }) => {
          this.busy = false

          if (ok) {
            const posts = data.result

            if (this.page === 1) {
              this.posts = posts
            } else if (posts.length > 0) {
              this.posts = [...this.posts, ...posts]
            }

            if (posts.length === 0) {
              this.hasMore = false
            }

            this.page = this.page + 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : (err.data ? err.data.message : err),
          })
        })
    }
  },
  components: {
    LoadMoreButton,
    PostListPc,
    PrEmpty,
    PostListMobile,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}
</style>
