<template>
  <div class="media">
    <img
      v-if="preview.type === MediaType.VIDEO"
      class="video-tag"
      src="@/assets/Video.png">
    <img
      v-if="preview.type === MediaType.IMAGE"
      class="cover"
      loading="lazy"
      :src="preview.data">
    <video
      v-else-if="preview.type === MediaType.VIDEO"
      class="cover"
      :src="preview.data">
    </video>
    <i
      v-else-if="preview.type === MediaType.FILE"
      class="pi pi-file icon-cover">
    </i>
    <span v-if="preview.type === MediaType.FILE || preview.type === MediaType.OBJ">{{ preview.filename }}</span>
    <div
      v-show="showBackdrop"
      class="backdrop"></div>

    <div class="overlay">
      <Button
        icon="pi pi-times"
        class="p-button-rounded p-button-danger p-button-sm"
        @click.stop="onremove">
      </Button>

      <span v-show="showFreeCover">{{ $t('freeCover') }}</span>
    </div>

    <progress-bar
      v-if="progress"
      :value="progress"
      :show-value="false">
    </progress-bar>
  </div>
</template>

<script>
//@ts-check
import { MediaType } from '@/utils/defines'
import Button from 'primevue/button'
import ProgressBar from 'primevue/progressbar'

export default {
  props: {
    preview: {
      type: Object,
      required: true
    },
    progress: {
      type: Number,
      default: 0
    },
    showFreeCover: {
      type: Boolean,
      default: false
    },
    showBackdrop: {
      type: Boolean,
      default: false
    },
  },
  setup (_, { emit }) {
    const onremove = function () {
      emit('close')
    }

    return {
      onremove,
      MediaType,
    }
  },
  components: {
    Button,
    ProgressBar
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.media {
  width: 7rem;
  height: 7rem;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .cover {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .icon-cover {
    width: 7rem;
    height: 7rem;
    font-size: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: @light-grey;
    top: 0;
    left: 0;
  }

  img.video-tag {
    width: 2em;
    height: 2em;
    z-index: 1;
  }

  .backdrop {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 2;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  .overlay > button {
    margin-right: 0.1rem;
    margin-top: 0.3rem;
  }

  .p-progressbar {
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0.8rem;
    position: absolute;
  }

  .overlay > span {
    background-color: @blue;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 54px;
    border-radius: 8px;
    position: absolute;
    left: calc(50% - 27px);
    bottom: 5px;
    color: white;
    font-weight: bolder;
  }
}
</style>