<template>
  <div
    class="searchbar-entry">
    <img
      class="search-icon"
      src="@/assets/icon_navi_Sear_icon@2x.png">
    <span>
      {{ placeholder }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    }
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.searchbar-entry {
  background-color: @border;
  height: 2em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:active {
    background-color: #ededed;
  }

  .search-icon {
    display: block;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
  }

  span {
    display: block;
    font-size: 16px;
    margin-left: 0.5em;
    color: @grey;
    font-weight: bolder;
  }
}
</style>