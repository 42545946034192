<template>
  <div class="container">
    <div class="form-container">
      <mt-field
        v-model="title"
        :label="$t('circleTitle')"
        :placeholder="$t('inputCircleTitle')">
      </mt-field>

      <mt-field
        v-model="description"
        :label="$t('circleDescription')"
        :placeholder="$t('inputCircleDescription')">
      </mt-field>

      <mt-field
        v-model="introduction"
        type="textarea"
        rows="4"
        :label="$t('circleIntroduction')"
        :placeholder="$t('inputCircleIntroduction')">
      </mt-field>

      <img
        v-if="coverUrl"
        loading="lazy"
        :src="coverUrl">
      <div
        v-else
        class="cover-placeholder">
        <span>{{ $t('clickBelowToUploadCircleCover') }}</span>
      </div>

      <pr-button
        :loading="uploadingCover"
        @click.native="fileInput.click()">
        {{ $t('uploadCover') }}
      </pr-button>

      <progress-bar
        v-show="uploadProgress > 0"
        :value="uploadProgress">
      </progress-bar>

      <hr>

      <mt-cell
        :label="$t('recurringPrice')">
        <prices-setter
          v-model="pricesData">
        </prices-setter>
      </mt-cell>

      <mt-cell
        :label="$t('complementPrice')">
        <prices-setter
          v-model="complementPricesData">
        </prices-setter>
      </mt-cell>

      <mt-cell
        v-if="forceComplement && salesType !== 'long-term'"
        :label="$t('upfrontPrice')">
        <prices-setter
          v-model="upfrontPricesData">
        </prices-setter>
      </mt-cell>

      <pr-button
        class="submit"
        type="primary"
        :loading="loading"
        :disabled="uploadingCover"
        @click.native="update">{{ $t('save') }}
      </pr-button>
    </div>

    <input
      ref="fileInput"
      hidden
      type="file"
      accept="image/*"
      @change="onFileInput">

    <Dialog
      :visible.sync="cropperShow"
      header="Image Resizer">
      <cropper
        v-if="cropperSrc"
        class="cropper"
        :src="cropperSrc"
        :stencil-props="{
          aspectRatio: 1/1,
          minAspectRatio: 1/1,
          maxAspectRatio: 1/1,
        }"
        @change="onCropperUpdated">
      </cropper>

      <template #footer>
        <div class="actions">
          <pr-button
            @click.native="closeCropper">{{ $t('cancel') }}</pr-button>
          <pr-button
            type="primary"
            @click.native="performCropping">Resize</pr-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
//@ts-check
import { useCircleEdit } from '@/composables/circle_edit'
import { useCoverUpload } from '@/composables/cover_upload'
import Dialog from 'primevue/dialog'
import { Cropper } from 'vue-advanced-cropper'
import PrButton from '@/components/PRButton.vue'
import ProgressBar from 'primevue/progressbar'
import PricesSetter from '@/components/PricesSetter.vue'

export default {
  setup () {
    const coverComposition = useCoverUpload()

    return {
      ...coverComposition,
      ...useCircleEdit({ coverUrl: coverComposition.coverUrl }),
    }
  },
  components: {
    PrButton,
    Dialog,
    Cropper,
    ProgressBar,
    PricesSetter,
  },
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.cropper {
  height: 40vh;
  max-width: 320px;
}

.p-progressbar {
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  
  @media @phone {
    width: 100%;
  }

  img {
    width: 100%;
    max-width: 20rem;
    margin-bottom: 1rem;
  }

  .submit {
    width: 100%;
    max-width: 20rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mint-field {
    width: 100% !important;
  }
}

.mint-cell {
  width: 100% !important;
}
</style>