//@ts-check
import { ref, onMounted } from "vue"
import { addRecord, getIssues } from "@/api/issue"
import { useToast } from "./toast"
import { useCurrentUser } from "./user_data"
import { until } from "@vueuse/core"

export const useDeliverIssues = function () {
  const { currentUserId } = useCurrentUser()
  const openedIssues = ref([])
  const { showToast } = useToast()

  const get = async function () {
    try {
      await until(() => currentUserId.value).toMatch(v => v !== null)
      const newIssues = await getIssues({
        assigneeId: currentUserId.value,
        status: ['open', 'assigner-rejected', 'working'],
      }, {
        page: 1,
        limit: 10,
      })
      openedIssues.value.push(...newIssues)
    } catch (err) {
      showToast(err)
    }
  }

  const addIssueRecord = async function (issueId, { content, status, artworkId }) {
    try {
      await addRecord(issueId, { content, status, artworkId })
    } catch (err) {
      showToast(`Failed to add commision record: ${err.message ? err.message : JSON.stringify(err)}, you can deliver it manually in the commsions page`)
    }
  }

  onMounted(get)

  return {
    addIssueRecord,
    openedIssues,
  }
}