<template>
  <div class="submit-container">
    <header>
      <pr-button
        type="primary"
        @click.native="submit">{{ buttonText }}</pr-button>
    </header>

    <div class="artworks">
      <post-list-item-selection
        v-for="(artwork, index) in artworks.filter(artwork => artwork)"
        :key="artwork._id"
        v-model="selectedList[index]"
        :post="artwork"
        class="item"></post-list-item-selection>
    </div>

    <load-more-button
      :has-more="hasMore"
      :busy="loading"
      :trigger="get"></load-more-button>
  </div>
</template>


<script>
import { useArtworkSubmit } from '@/composables/artwork_submit'
import PrButton from '@/components/PRButton.vue'
import PostListItemSelection from '@/components/PostListItemSelection.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'


export default {
  setup () {
    return {
      ...useArtworkSubmit()
    }
  },
  components: {
    PrButton,
    PostListItemSelection,
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.submit-container {
  padding-top: 4em;


  @media @desktop {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    button {
      width: 90%;

      @media @desktop {
        max-width: 1024px;
      }
    }
  }

  .item {
    margin-bottom: 0.5em;
  }
}
</style>