//@ts-check
import { useCurrentUser } from "@/composables/user_data"
import postapi, { getUserPostsTill, getUserPromotedPosts } from '@/api/post'
import { useArtworkStore } from "@/pinia/artwork"
import { ref, computed, onMounted } from "vue"
import { eventEmitter } from "@/utils"
import { events } from "@/utils/events"
import { useAuthenticator } from "@/pinia/authenticator"
import { useProfileStore } from "@/pinia/profile"
import { useToast } from "./toast"


const limit = 10

export const useUserMypage = function () {
  const buyPage = ref(1)
  const buyLoading = ref(false)
  const buyHasmore = ref(false)
  const myLoading = ref(false)
  const myHasmore = ref(false)
  const { currentUser } = useCurrentUser()
  const artworkStore = useArtworkStore()
  const selected = ref(0)
  const authenticator = useAuthenticator()
  const profileStore = useProfileStore()
  const { Toast } = useToast()
  
  let timestamp = new Date().getTime()


  const myPostIds = computed(() => profileStore.myPostIds)
  const buyPostIds = computed(() => profileStore.buyPostIds)


  const purchasedArtworks = computed(() => {
    return buyPostIds.value.map(postId => artworkStore.artworks[postId])
  })


  const myArtworks = computed(() => {
    return myPostIds.value.map(postId => artworkStore.artworks[postId])
  })


  const getBoughtPosts = async function () {
    try {
      buyLoading.value = true
      await authenticator.checkAuth()
      // @ts-ignore
      const posts = await postapi.getUserPurchased(currentUser.value._id, buyPage.value, limit)
      if (posts.length === 0) {
        buyHasmore.value = false
      } else {
        posts.forEach(post => artworkStore.cacheArtwork(post))
        profileStore.$patch({
          buyPostIds: [...buyPostIds.value, ...posts.map(post => post._id)]
        })
        buyPage.value += 1
        buyHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      buyLoading.value = false
    }
  }


  const getMyPosts = async function () {
    try {
      myLoading.value = true
      await authenticator.checkAuth()

      if (profileStore.myPostIds.length === 0) {
        // @ts-ignore
        const topPosts = await getUserPromotedPosts(currentUser.value._id)
        topPosts.forEach(post => artworkStore.cacheArtwork(post))
        profileStore.$patch({
          myPostIds: [...topPosts.map(post => post._id)]
        })
      }
      // @ts-ignore
      const posts = await getUserPostsTill(currentUser.value._id, timestamp, limit)
      if (posts.length === 0) {
        myHasmore.value = false
      } else {
        posts.forEach(post => artworkStore.cacheArtwork(post))
        profileStore.$patch({
          myPostIds: [...myPostIds.value, ...posts.map(post => post._id)]
        })
        timestamp = posts[posts.length - 1].createdAt
        myHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      myLoading.value = false
    }
  }


  onMounted(() => {
    if (myPostIds.value.length === 0) {
      getMyPosts()
    }

    if (buyPostIds.value.length === 0) {
      getBoughtPosts()
    }

    eventEmitter.on(events.ArtworkDeleted, (postId) => {
      const index = myPostIds.value.indexOf(postId)
      if (index !== -1) {
        profileStore.$patch({
          myPostIds: [...myPostIds.value.filter(iterPostId => iterPostId !== postId)]
        })
      }
    })
  })


  return {
    buyPostIds,
    buyPage,
    buyLoading,
    buyHasmore,
    myPostIds,
    myLoading,
    myHasmore,
    getBoughtPosts,
    getMyPosts,
    purchasedArtworks,
    myArtworks,
    selected,
  }
}