<template>
  <div
    v-if="circle"
    class="circle">
    <transition-image
      :src="circle.coverUrl"
      :alt="circle.title"
      :class-names="['cirlcle-img']">
    </transition-image>

    <div class="content">
      <h2>{{ circle.title }}</h2>
      <tags
        class="tags"
        :update="circle.status === 'updating' ? true: false"
        size="small"
        :tags="tags"
        :lighter="true"></tags>
      <p>{{ getPostTitle(circle.lastUpdatedPost) }}</p>
    </div>

    <div
      class="pricetag"
      :class="{ grey: circle.isMember }">
      {{ circle.isMember ? $t('joined') : priceText }}
    </div>
  </div>
</template>

<script>
import Tags from '@/components/Tags.vue'
import { useCirclePresent } from '@/composables/circle_present'
import { useCurrentCurrency } from '@/composables/currency'
import TransitionImage from '@/components/TransitionImage.vue'


export default {
  props: {
    circle: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { currency } = useCurrentCurrency()

    return {
      ...useCirclePresent(currency, {
        circle: props.circle
      })
    }
  },
  components: {
    Tags,
    TransitionImage,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";


.circle {
  margin-bottom: 8px;
  box-sizing: border-box;
  position: relative;
  height: 120px;
  padding: 5px;
  display: flex;
  width: 100%;

  .cirlcle-img {
    width: 90px;
    min-width: 90px;
    height: 110px;
    object-fit: cover;
    margin-right: 6px;
    border-radius: 4px;
  }

  .content {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 19px;
      font-weight: 600;
      color: @black;
      max-width: 66%;
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tags {
      margin-bottom: 0;
      height: auto;
      flex-grow: 1;
    }

    p {
      font-size: 14px;
      color: @light-grey;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 30px;
      width: 100%;
      border-radius: 4px;
      background-color: #f1f1f1;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 13px;
    }
  }

  .pricetag {
    text-align: center;
    position: absolute;
    top: 7px;
    right: 0;
    font-size: 12px;
    font-weight: bold;
    color: @yellow;
    border: 1px solid @yellow;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    height: 21px;

    &.grey {
      color: @grey;
      border-color: @grey;
    }
  }
}
</style>
