import Vue from 'vue'
import { API_URL } from '../config'
import { api } from './base'


export const getExploredUsers = async (page, limit) => {
  try {
    const res = await api.get('/users/promoted/list', {
      params: {
        page,
        limit,
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  getGirls (page = 1, limit = 10) {
    return new Promise((resolve, reject) => {
      Vue.http
      .get(`${API_URL}/girls`, {
        params: {
          page,
          limit
        }
      })
      .then(({ ok, data }) => {
        if (ok) {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        }
      })
      .catch(err => {
        if (err.data) {
          return reject(err.data.message)
        } else {
          return reject(err)
        }
      })
    })
  },

  getTopics (page = 1, limit = 10) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/topics`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getTopicPosts (topicId, page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/topics/${topicId}/posts`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getTopic (topicId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/topics/${topicId}/single`)
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
