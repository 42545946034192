<template>
  <div class="container">
    <div class="records">
      <div
        v-for="(record, index) in records"
        :key="index"
        class="record touchable"
        @click="handleRecord(index)"
      >
        <span class="description">{{ record.description }}</span>
        <span
          class="untrust"
          :class="{
            add: record.value >= 0,
            minus: record.value < 0
          }"
        >{{ record.value >= 0 ? '+' : '-' }}{{ Math.abs(record.value) }}</span>
        <span class="time">{{ new Date(record.createdAt).toLocaleString() }}</span>
      </div>

      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="fetch"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/user'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useToast } from '@/composables/toast'

let page = 1
const limit = 10

export default {
  metaInfo: {
    title: "不良度记录",
  },
  setup () {
    return {
      ...useToast(),
    }
  },
  components: {
    LoadMoreButton
  },
  data () {
    return {
      records: [],
      busy: false,
      hasMore: true
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    handleRecord (index) {
      const record = this.records[index]

      if (record.extras && record.extras.postId) {
        this.$router.push(`/posts/${record.extras.postId}`)
      }
    },
    fetch () {
      api
        .getUntrustRecords(page, limit)
        .then(records => {
          this.busy = false
          page += 1

          records.forEach(record => this.records.push(record))

          if (records.length < limit) {
            this.hasMore = false
          }
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.record {
  background: white;
  margin-bottom: 0.5em;
  padding: 1em;
  height: 3.5em;
  overflow: hidden;

  span {
    display: inline-block;
  }

  .time {
    width: 80%;
    color: #aaa;
  }

  .untrust {
    float: right;
    font-weight: bold;
    font-size: 1.3em;
    margin-top: 0.5em;
  }

  .add {
    color: rgb(255, 45, 85);
  }

  .minus {
    color: rgb(76, 217, 100);
  }
}
</style>
