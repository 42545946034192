<template>
  <div class="container">
    <mt-navbar
      v-if="authenticated"
      v-model="selected"
      class="segmented-control report-center-segmented-wrap"
    >
      <mt-tab-item
        :id="0"
        class="touchable"
        :class="{ hoverable : !isOnMobile }"
      >
        {{ $t('allReports') }}
      </mt-tab-item>
      <mt-tab-item
        :id="1"
        class="touchable"
        :class="{ hoverable : !isOnMobile }"
      >
        {{ $t('myReports') }}
      </mt-tab-item>
      <mt-tab-item
        v-if="currentUser.authed"
        :id="2"
        class="touchable"
        :class="{ hoverable : !isOnMobile }"
      >
        {{ $t('reportsToMe') }}
      </mt-tab-item>
    </mt-navbar>

    <mt-tab-container v-model="selected">
      <mt-tab-container-item :id="0">
        <all />
      </mt-tab-container-item>

      <mt-tab-container-item
        v-if="authenticated"
        :id="1"
      >
        <mine />
      </mt-tab-container-item>

      <mt-tab-container-item
        v-if="authenticated"
        :id="2"
      >
        <accusation />
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import All from '@/components/ReportCenterAll.vue'
import Mine from '@/components/ReportCenterMine.vue'
import Accusation from '@/components/ReportCenterAccusation.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'


export default {
  metaInfo: {
    title: "举报中心",
  },
  setup () {
    return {
      ...useResponsive(),
      ...useCurrentUser()
    }
  },
  data () {
    return {
      selected: 0,
    }
  },
  components: {
    All,
    Mine,
    Accusation
  }
}
</script>

<style lang="less" scoped>
.container {
  margin-top: 10px;

  .report-center-segmented-wrap {
    width: 96%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

@media (min-width: 800px) {
  .container {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
