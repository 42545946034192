<template>
  <div class="model-renderer">
    <canvas
      ref="canvasDomRef"
      @touchstart="onTouchStart"
      @touchmove.prevent="void 0"
      @touchend="pressed = false"
      @mousedown="pressed = true"
      @mousemove.prevent="void 0"
      @mouseup="pressed = false">
    </canvas>

    <div class="info">
      <div class="header">
        <div
          class="dot"
          :style="{ backgroundColor: dotColor }">
        </div>
        <p v-if="mode === 'webrtc'">ice connection: {{ iceConnectionState }}</p>
        <p>network connection: {{ connectionState }}</p>
      </div>
      <div class="logs">
        <fading-paragraph
          v-for="(log, $index) in logs"
          :key="$index">{{ log }}</fading-paragraph>
      </div>
    </div>

    <div class="operations">
      <pr-button
        size="small"
        @mousedown.native="scaleUpPressed = true"
        @mouseup.native="scaleUpPressed = false"
        @touchstart.native="scaleUpPressed = true"
        @touchend.native="scaleUpPressed = false">
        <i class="pi pi-search-plus"></i>
      </pr-button>

      <pr-button
        size="small"
        @mousedown.native="scaleDownPressed = true"
        @mouseup.native="scaleDownPressed = false"
        @touchstart.native="scaleDownPressed = true"
        @touchend.native="scaleDownPressed = false">
        <i class="pi pi-search-minus"></i>
      </pr-button>
    </div>
  </div>
</template>

<script>
//@ts-check
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { until, useMouse } from '@vueuse/core'
import FadingParagraph from './FadingParagraph.vue'
import { useModelStreamRTC } from '@/composables/model_stream_rtc'
import { useModelStreamWS } from '@/composables/model_stream_ws'
import PrButton from './PRButton.vue'


export default {
  props: {
    mediaId: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const canvasDomRef = ref(null)
    const pressed = ref(false)
    const scaleUpPressed = ref(false)
    const scaleDownPressed = ref(false)
    const { x, y } = useMouse()
    const dotColor = ref('grey')
    const logs = ref([])
    const mode = ref('websocket')
    const { connectionState: rtcConnectionState, iceConnectionState, connectRTC, channel } = useModelStreamRTC(props.mediaId, canvasDomRef, logs)
    const { connectionState: wsConnectionState, connectWS, ws } = useModelStreamWS(props.mediaId, canvasDomRef, logs)

    let prevX = 0
    let prevY = 0
    let intervalId = null
    let scaleIntervalId = null

    const connectionState = computed(() => {
      if (mode.value === 'webrtc') {
        return rtcConnectionState.value
      } else if (mode.value === 'websocket') {
        return wsConnectionState.value
      }

      return 'closed'
    })

    watch(connectionState, () => {
      if (connectionState.value === 'closed') {
        dotColor.value = 'grey'
      } else if (connectionState.value === 'connected') {
        dotColor.value = 'green'
      } else if (connectionState.value === 'connecting') {
        dotColor.value = 'yellow'
      } else if (connectionState.value === 'failed') {
        dotColor.value = 'red'
      }
    })

    watch(pressed, () => {
      if (pressed.value) {
        intervalId = setInterval(() => {
          onPointerMove()
        }, 1 / 24 * 1000)
      } else {
        clearInterval(intervalId)
        prevX = 0
        prevY = 0
      }
    })

    watch([scaleDownPressed, scaleUpPressed], () => {
      const delta = scaleUpPressed.value ? 100 : -100
      if (scaleDownPressed.value || scaleUpPressed.value) {
        scaleIntervalId = setInterval(() => {
          onPinch({ offset: [delta] })
        }, 1 / 24 * 1000)
      } else {
        clearInterval(scaleIntervalId)
      }
    })

    const onPinch = async ({ offset }) => {
      if (connectionState.value !== 'connected') {
        return
      }

      const scale = offset[0] * 0.01
      if (mode.value === 'webrtc') {
        channel.value.send(JSON.stringify({ orbitStretch: -scale }))
      } else if (mode.value === 'websocket') {
        ws.value.send(JSON.stringify({ orbitStretch: -scale }))
      }
    }

    const onTouchStart = function (event) {
      if (event.touches.length === 1) {
        pressed.value = true
      }
    }

    const onPointerMove = function () {
      if (mode.value === 'webrtc' && !channel.value) {
        return
      }

      if (mode.value === 'websocket' && !ws.value) {
        return
      }

      const rotationCommand = {}

      if (prevX !== 0) {
        rotationCommand.rotateY = (x.value - prevX) * 3.3
      }

      if (prevY !== 0) {
        rotationCommand.rotateX = (y.value - prevY) * 3.3
      }

      if (rotationCommand.rotateY && rotationCommand.rotateX) {
        if (mode.value === 'webrtc') {
          if (channel.value.readyState === 'open') {
            channel.value.send(JSON.stringify(rotationCommand))
          }
        } else if (mode.value === 'websocket') {
          if (ws.value.readyState === WebSocket.OPEN) {
            ws.value.send(JSON.stringify(rotationCommand))            
          }
        }
      }

      prevX = x.value
      prevY = y.value
    }

    onMounted(async () => {
      if (mode.value === 'webrtc') {
        connectRTC()
      } else if (mode.value === 'websocket') {
        connectWS()
      }
    })

    onUnmounted(() => {
      if (channel.value) {
        channel.value.close()
      }

      if (intervalId) {
        clearInterval(intervalId)
      }
    })

    return {
      onPointerMove,
      canvasDomRef,
      pressed,
      dotColor,
      logs,
      connectionState,
      iceConnectionState,
      mode,
      onTouchStart,
      scaleUpPressed,
      scaleDownPressed,
    }
  },
  components: {
    FadingParagraph,
    PrButton
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";


.model-renderer {
  position: relative;
}

.info {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  text-align: left;
  font-size: 0.5rem;
  pointer-events: none;
}

.header {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  gap: 1rem;
}

.logs {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
}

.dot {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
}

canvas {
  width: 100%;
  aspect-ratio: 1 / 1;
  border: 0.1rem solid @light-grey;
  border-radius: 1rem;
}

canvas:hover {
  cursor: grab;
}

.operations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}

.operations > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: @light-grey;
  cursor: pointer;
}
</style>