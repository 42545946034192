//@ts-check
import { computed, ref } from "vue"
import { useCirclePresent } from "./circle_present"
import { whenever } from "@vueuse/core"
import { updateCircle } from "@/api/circle"
import { useToast } from "./toast"
import { useCurrentCurrency } from "./currency"
import { useRoute, useRouter } from "vue-router/composables"
import { useI18n } from "vue-i18n-composable"


export const useCircleEdit = ({ coverUrl }) => {
  const { currency } = useCurrentCurrency()
  const circleId = useRoute().params.circleId
  const { circle } = useCirclePresent(currency, { fromNetwork: true })
  const title = ref('')
  const description = ref('')
  const introduction = ref('')
  const loading = ref(false)
  const router = useRouter()
  const { Toast } = useToast()
  const { t } = useI18n()
  const salesType = ref(null)
  const forceComplement = ref(false)
  const defaultCurrency = ref('JPY')
  const pricesData = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    }
  })
  const upfrontPricesData = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    }
  })
  const complementPricesData = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    }
  })

  const prices = computed(() => {
    return pricesData.value.prices
  })

  const upfrontPrices = computed(() => {
    return upfrontPricesData.value.prices
  })

  const complementPrices = computed(() => {
    return complementPricesData.value.prices
  })

  whenever(circle, () => {
    title.value = circle.value.title
    description.value = circle.value.description
    introduction.value = circle.value.introduction
    coverUrl.value = circle.value.coverUrl
    salesType.value = circle.value.salesType
    forceComplement.value = circle.value.forceComplement
    defaultCurrency.value = circle.value.defaultCurrency

    for (const currency of Object.keys(circle.value.prices)) {
      if (currency === 'JPY') {
        pricesData.value.prices[currency] = circle.value.prices[currency]
      } else {
        pricesData.value.prices[currency] = Number((circle.value.prices[currency] / 100).toFixed(2))
      }

      if (currency === defaultCurrency.value) {
        pricesData.value.defaultCurrency = currency
        if (currency === 'JPY') {
          pricesData.value.price = circle.value.prices[currency]
        } else {
          pricesData.value.price = Number((circle.value.prices[currency] / 100).toFixed(2))
        }
      }
    }

    for (const currency of Object.keys(circle.value.upfrontPrices)) {
      if (currency === 'JPY') {
        upfrontPricesData.value.prices[currency] = circle.value.upfrontPrices[currency]
      } else {
        upfrontPricesData.value.prices[currency] = Number((circle.value.upfrontPrices[currency] / 100).toFixed(2))
      }

      if (currency === defaultCurrency.value) {
        if (currency === 'JPY') {
          upfrontPricesData.value.price = circle.value.upfrontPrices[currency]
        } else {
          upfrontPricesData.value.price = Number((circle.value.upfrontPrices[currency] / 100).toFixed(2))
        }
        upfrontPricesData.value.defaultCurrency = currency
      }
    }

    for (const currency of Object.keys(circle.value.complementPrices)) {
      if (currency === 'JPY') {
        complementPricesData.value.prices[currency] = circle.value.complementPrices[currency]
      } else {
        complementPricesData.value.prices[currency] = Number((circle.value.complementPrices[currency] / 100).toFixed(2))
      }

      if (currency === defaultCurrency.value) {
        complementPricesData.value.defaultCurrency = currency
        if (currency === 'JPY') {
          complementPricesData.value.price = circle.value.complementPrices[currency]
        } else {
          complementPricesData.value.price = Number((circle.value.complementPrices[currency] / 100).toFixed(2))
        }
      }
    }
  })

  const update = async function () {
    try {
      const submitPrices = {}

      const iterPrices = []
      if (pricesData.value.prices[defaultCurrency.value] > 0) {
        iterPrices.push(prices)
      }

      if (complementPricesData.value.prices[defaultCurrency.value] > 0) {
        iterPrices.push(complementPrices)
      }

      if (upfrontPricesData.value.prices[defaultCurrency.value] > 0) {
        iterPrices.push(upfrontPrices)
      }

      for (const [index, pricesRef] of iterPrices.entries()) {
        for (const currency of Object.keys(pricesRef.value)) {
          const value = currency === 'JPY' ? pricesRef.value[currency] : Math.round(pricesRef.value[currency] * 100)
          if (index === 0) {
            if (!submitPrices.prices) {
              submitPrices.prices = {}
            }
            submitPrices.prices[currency] = value
          } else if (index === 1) {
            if (!submitPrices.upfrontPrices) {
              submitPrices.upfrontPrices = {}
            }
            submitPrices.upfrontPrices[currency] = value
          } else if (index === 2) {
            if (!submitPrices.complementPrices) {
              submitPrices.complementPrices = {}
            }
            submitPrices.complementPrices[currency] = value
          }
        }
      }

      loading.value = true
      await updateCircle(circleId, {
        title: title.value,
        description: description.value,
        introduction: introduction.value,
        coverUrl: coverUrl.value,
        defaultCurrency: pricesData.value.defaultCurrency,
        ...submitPrices,
      })
      Toast(t('success'), 'ok')
      router.push(`/circles/${circleId}`)
    } catch (err) {
      Toast(err.message)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    title,
    description,
    introduction,
    update,
    coverUrl,
    pricesData,
    upfrontPricesData,
    complementPricesData,
    defaultCurrency,
    forceComplement,
    salesType,
  }
}