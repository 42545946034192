<template>
  <div class="layout-box">
    <div class="mod-media">
      <div class="media-main">
        <img
          loading="lazy"
          :src="titleimg">
      </div>
    </div>
    <div class="box-col">
      <div class="txt-cut title">
        {{ post.title }}<span>  (金币收益{{ goldnum }}%)</span>
      </div>
      <div class="txt-cut content">
        {{ post.content }}
      </div>
      <div class="picture">
        <img src="@/assets/icon_web_h5_ImageNumber_grey.png">
        <span>{{ post.pictureCount }}</span>
        <img
          class="imgleft"
          src="@/assets/icon_web_h5_VideoNumber_grey.png"
        >
        <span>{{ post.videoCount }}</span>

        <span class="yellow">{{ post.price }}金币</span>
      </div>
    </div>
  </div>
</template>

<script>
//import { isOnMobile } from '../util'

export default {
  components: {
   
  },
  props: {
    post: {
      type: Object,
      default () {
        return {}
      }
    },
    gold: {
      type: Number,
      default () {
        return 0
      }
    },
  },
  data () {
    return {
      
    }
  },
  computed: {
    titleimg () {
      let imgs = this.post.pictures
      let imglength = this.post.pictures.length
      let postimg = 0
      let imgurl = ''
      for (let i = 0; i < imglength; i++) {
        if (imgs[i].canBrowse) {
          postimg = i
          break
        }
      }
      if (postimg) {
        imgurl = imgs[postimg].url
      } else {
        imgurl = imgs[postimg].thum
      }
      return imgurl
    },
    goldnum () {
      return this.gold * 100 
    }
  },
  mounted () {
    //console.info(this.post)
  },
  methods: {
  
  }
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.layout-box {
  display: flex;
  vertical-align: baseline;

  .mod-media {
    position: relative;
    display: block;
    text-align: center;
    margin: .5rem 0 .5rem 0rem;

    .media-main {
      position: relative;

      img {
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: top;
        object-fit: cover;
      }
    }
  }
  .box-col {
    padding: .5rem 0rem .5em .6em;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex: 1;
    width: 100%;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;

    .title {
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #333333;


      span {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: justify;
        color: #9b9b9b;
      }
    }
    .content {
      line-height: 1.7;
    }
    .picture {
      position: relative;
      line-height: 1em;

      img {
        width: 13px;
        height: 13px;
        object-fit: cover;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.93;
        letter-spacing: normal;
        text-align: left;
        color: #cfcfcf;
        margin-left: 3px;
        position: relative;
        top: -1px;
      }
      .imgleft {
        margin-left: 12px;
      }

      .yellow {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #ffda55;
        position: absolute;
        right: 0px;
        top: 1px;
      }
    }
  }
} 

.txt-cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
}
</style>
