<template>
  <pr-popup
    v-model="visible"
    class="channel-collector"
    @hide="visible = false">
    <template #header>
      <span>{{ $t('collectInto') }}</span>
    </template>

    <div class="channels">
      <div
        v-for="(channel, index) in channels"
        :key="channel._id"
        class="channel touchable hoverable"
        @click="selectedIndex = index">
        <span>{{ channel.title }}</span>
        <img
          v-if="selectedIndex === index"
          src="@/assets/ic-page-switch-selected@2x.png">
        <img
          v-else
          src="@/assets/ic-page-switch-normal@2x.png">
      </div>
    </div>

    <template #footer>
      <pr-button
        style="width: 100%;"
        type="primary"
        :disabled="selectedIndex === -1"
        :loading="loading"
        @click.native="confirm">
        {{ $t('collect') }}
      </pr-button>
    </template>
  </pr-popup>
</template>


<script>
import { onMounted, ref, computed, watch } from 'vue'
import { useApplication } from '@/pinia/application'
import { collectInto } from '@/api/channel'
import PrButton from '@/components/PRButton.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useResponsive } from '@/composables/responsive'
import { useI18n } from 'vue-i18n-composable'
import { useChannelStore } from '@/pinia/channel'
import { useToast } from '@/composables/toast'
import PrPopup from '@/components/PRPopup.vue'


export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input'],
  setup (props, { emit }) {
    const visible = ref(props.value)
    const selectedIndex = ref(-1)
    const uistates = useApplication()
    const { authenticated } = useCurrentUser()
    const { t } = useI18n()
    const channelStore = useChannelStore()
    const { Toast } = useToast()
    const loading = ref(false)

    const channels = computed(() => {
      return channelStore.manageableChannels
    })

    watch(props, () => {
      visible.value = props.value
    })

    watch(visible, () => {
      emit('input', visible.value)
    })

    watch(authenticated, () => {
      if (authenticated.value && channels.value.length === 0) {
        channelStore.fetchManageableChannels()
      }
    })


    const confirm = async function () {
      try {
        loading.value = true
        await collectInto(uistates.channelCollectorPostId, channels.value[selectedIndex.value]._id)
        Toast({ message: t('success') }, 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }

    
    onMounted(() => {
      if (channels.value.length === 0 && authenticated.value) {
        channelStore.fetchManageableChannels()
      }
    })


    return {
      visible,
      confirm,
      channels,
      selectedIndex,
      loading,
      ...useResponsive(),
    }
  },
  components: {
    PrButton,
    PrPopup,
  }
}
</script>

<style>
.channel-collector .p-dialog {
  width: 30rem;
}

.channel-collector.p-sidebar {
  height: 40rem;
}

.channel-collector .p-sidebar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";


.channels {
  flex: 1;
  min-height: 20rem;
  overflow-y: scroll;

  .channel {
    display: flex;
    align-items: center;
    height: 3em;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: @border;
    
    span {
      color: @black;
      font-size: 15px;
      font-weight: bold;
    }

    img {
      width: 1.5em;
      height: 1.5em;
      margin-left: auto;
    }
  }
}
</style>