<template>
  <pr-popup
    v-model="visible"
    class="sns-dialog">
    <template #header>
      {{ $t('share') }}
    </template>

    <div class="content">
      <share-network
        network="twitter"
        :url="url"
        :title="title"
        class="hoverable touchable">
        <icon name="twitter"></icon>
      </share-network>

      <share-network
        network="facebook"
        :url="url"
        :title="title"
        class="hoverable touchable">
        <icon name="facebook"></icon>
      </share-network>
    </div>
  </pr-popup>
</template>


<script>
import { useResponsive } from '@/composables/responsive'
import { useApplication } from '@/pinia/application'
import { computed, ref, watch } from 'vue'
import Icon from 'vue-icon/lib/vue-feather.esm'
import PrPopup from '@/components/PRPopup.vue'


export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const uistates = useApplication()
    const visible = ref(props.value)
    const url = computed(() => uistates.socialSharingUrl)
    const title = computed(() => uistates.socialSharingTitle)
    const imageUrl = computed(() => uistates.socialSharingImageUrl)

    watch(props, () => {
      visible.value = props.value
    })

    watch(visible, () => {
      emit('input', visible.value)
    })

    return {
      visible,
      url,
      title,
      imageUrl,
      ...useResponsive()
    }
  },
  components: {
    Icon,
    PrPopup,
  }
}
</script>

<style>
.sns-dialog .p-dialog {
  width: 20em;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";
.sns-dialog {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 2em;
  }

  @media @phone {
    width: 100%;
  }
}
</style>