<template>
  <component :is="isOnMobile ? ChannelMobile : ChannelDesktop">
  </component>
</template>

<script>
import ChannelMobile from '@/components/ChannelDetail.vue'
import ChannelDesktop from '@/components/ChannelDesktop.vue'
import { useResponsive } from '@/composables/responsive'

export default {
  setup () {
    return {
      ...useResponsive(),
      ChannelMobile,
      ChannelDesktop,
    }
  },
}
</script>