<template>
  <div
    v-if="post"
    class="wrap-detail">
    <div
      v-if="postNotFound || deleted"
      class="postDisappear">
      <img src="@/assets/notFound.gif">
      <p>Artwork Not Found</p>
    </div>
    <pr-empty
      v-else-if="post.status === 'handling'"
      :text="$t('stillProcessing')">
    </pr-empty>
    <div
      v-else-if="!outofservice"
      class="container">
      <div
        v-show="isVisitorMode"
        class="visitor-hint">
        <div class="title">
          {{ $t('inVisitorMode') }}
          <pr-button
            size="small"
            @click.native="$router.push({
              path: `/posts/${post._id}`,
              replace: true,
            })">
            {{ $t('back') }}
          </pr-button>
        </div>
        <div class="explain">
          {{ $t('visitorModeExplain') }}
        </div>
      </div>

      <mt-navbar
        v-if="!postNotFound"
        v-model="selected"
        class="segmented-control">
        <mt-tab-item
          :id="0"
          class="touchable"
          :class="{ hoverable: !isOnMobile }">
          {{ $t('postDetail') }}
        </mt-tab-item>
        <mt-tab-item
          :id="1"
          class="touchable"
          :class="{ hoverable: !isOnMobile }">
          {{ $t('comment') }}{{ post.commentCount > 0 ? `(${post.commentCount})` : '' }}
        </mt-tab-item>
      </mt-navbar>

      <mt-tab-container
        v-if="post.user"
        v-model="selected">
        <mt-tab-container-item :id="0">
          <h1 class="h1-title">
            {{ localeTitle }}
          </h1>
          <div
            v-if="post.user.status === 0"
            class="content">
            <div
              v-show="circleAdText"
              class="circle-ad common-touchable"
              :class="{ hoverable: !isOnMobile }"
              @click="goToCircles">
              {{ circleAdText }}
              <img src="@/assets/Get_into_Channel@3x.png">
            </div>

            <artwork-photo-content
              v-if="post._id"
              :post="post"></artwork-photo-content>

            <router-link
              v-if="post.user"
              class="outline touchable hoverable"
              :to="`/users/${post.user._id}`">
              {{ $t('moreOfHer', { username: post.user.nickname }) }}
            </router-link>
          </div>


          <div
            v-if="!post.user.authed"
            class="unauthorized">
            Author not verified
          </div>

          <banned
            v-else-if="post.user.status !== 0"
            :user="post.user"></banned>
        </mt-tab-container-item> 

        <mt-tab-container-item :id="1">
          <comment v-show="post._id"></comment>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>
    <out-of-service-cover v-else></out-of-service-cover>
  </div>
</template>

<script>
import ArtworkPhotoContent from '@/components/ArtworkPhotoContent.vue'
import Comment from '@/components/Comment.vue'
import Banned from '@/components/Banned.vue'
import OutOfServiceCover from '@/components/OutOfServiceCover.vue'
import { ref, onMounted } from 'vue'
import { useResponsive } from '@/composables/responsive'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { useRoute } from 'vue-router/composables'
import { useArtworkPurchase } from '@/composables/artwork_purchase'
import { useArtworkCircle } from '@/composables/artwork_circle'
import { useCurrentUser } from '@/composables/user_data'
import PrEmpty from '@/components/PREmpty.vue'
import mixpanel from 'mixpanel-browser'
import PrButton from '@/components/PRButton.vue'


export default {
  metaInfo () {
    return this.artworkMetaInfo
  },
  setup () {
    const selected = ref(0)
    const { currency } = useCurrentCurrency()
    const postId = useRoute().params.postId

    onMounted(() => {
      mixpanel.track('View Artwork', {
        type: 'photo',
        artworkId: postId
      })
    })

    return {
      selected,
      ...useResponsive(),
      ...useArtworkPresent(postId, currency),
      ...useArtworkPurchase(postId),
      ...useCurrentUser(),
      ...useArtworkCircle(postId),
    }
  },
  methods: {
    more () {
      this.$router.push(`/users/${this.post.user._id}`)
    },
    goToCircles () {
      this.$router.push(`/circles/${this.post.groupIds[0]}`)
    }
  },
  components: {
    ArtworkPhotoContent,
    Comment,
    Banned,
    OutOfServiceCover,
    PrEmpty,
    PrButton,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.postDisappear {
  text-align: center;
  padding-top: 100px;
  img {
    width: 120px;
    height: 120px;

  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
  }
}


.h1-title {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-top: 20px;
  
}
@media (max-width: 800px) { 
  .h1-title {
    font-size: 17px;
    margin-left: 10px;
  }
}


@media (min-width: 800px) {
  .container {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (max-width: 800px) {
  .container {
    width: 100%;
    height: auto;
    overflow-x: hidden;

    .segmented-control {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}


.mint-tab-container {
  overflow: visible;
}

.circle-ad {
  margin-top: 16px;
  width: 100%;
  height: 29px;
  line-height: 29px;
  background-color: @border;
  color: @minor;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
  box-sizing: border-box;

  img {
    width: 14px;
    height: 14px;
    float: right;
    margin-top: 7.5px;
  }
}

.content {
  margin-bottom: 26px;
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 800px) { 
  .content {
    margin-left: 0px;
    margin-right: 0px;
  }
  
}

.unauthorized {
  text-align: center;
  padding-top: 2em;
}

a {
  display: inline-block;
}

.visitor-hint {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.9rem;
  background-color: @yellow;
  color: white;
  font-weight: bolder;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.visitor-hint div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
