<template>
  <div class="user-container">
    <user-avatar
      class="user-avatar"
      :user="user"></user-avatar>
    <div class="content">
      <h5>
        {{ user.nickname }}
        <circle-badge
          v-if="user.tag && user.tag.visible"
          :member-tag="user.tag.memberTag"
          @click.native.stop="$router.push(`/user/${user.tag.authorId}/circles`)"></circle-badge>
      </h5>
      <p>{{ user.motto }}</p>
    </div>
    <span
      v-if="showAisatuCode"
      class="chat-token">
      {{ user.mVipCode }}
    </span>
    <pr-button
      v-else-if="showFollowButton"
      :type="user.followed ? 'default' : 'primary'"
      :flat="user.followed"
      class="hoverable"
      @click.prevent.stop.native="user.followed ? unfollow(user._id, user) : follow(user._id, user)">
      {{ user.followed ? $t('followed') : $t('follow') }}
    </pr-button>
  </div>
</template>

<script>
import UserAvatar from './UserAvatar.vue'
import PrButton from './PRButton.vue'
import CircleBadge from './CircleBadge.vue'
import { useUserFollow } from '@/composables/user_follow'


export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    showAisatuCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFollowButton: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  setup () {
    return {
      ...useUserFollow()
    }
  },
  components: {
    UserAvatar,
    PrButton,
    CircleBadge
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.user-container {
  border-radius: 4px;
  background-color: white;
  border: solid 1.8px #f1f1f1;
  position: relative;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 11px;
  box-sizing: border-box;
  
  .user-avatar {
    max-width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
    display: inline-block;
    flex: 1;
  }

  .content {
    flex: 1;
    display: inline-block;
    margin-left: 6px;
    display: flex;
    flex-direction: column;

    h5 {
      display: inline-block;
      vertical-align: top;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      display: inline-block;
      font-size: 13px;
      padding-right: 0px;
      color: #a1a1a1;
      white-space:nowrap; 
      text-overflow:ellipsis; 
      overflow:hidden;
      width: 180px;
      margin-left: 0.5em;
    }
  }

  button {
    position: absolute;
    top: 18px;
    right: 8px;
    height: 25px;
    border-radius: 2px;
    font-size: 12px;

    &.primary {
      border : 0px;
    }
    &.primary:hover {
      background : #ff5579;
    }
  }

  .chat-token {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @black;
    font-weight: bolder;
  }
}
</style>
