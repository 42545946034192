//@ts-check
import { onMounted, ref } from 'vue'
import { getCircleKinds } from '@/api/circle'
import { useToast } from './toast'


export const useCircleKind = function () {
  const circleKinds = ref([])
  const { Toast } = useToast()

  onMounted(async () => {
    try {
      circleKinds.value = await getCircleKinds()
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  })

  return {
    circleKinds,
  }
}