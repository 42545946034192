<template>
  <article
    class="article"
    v-html="content">
  </article>
</template>


<script>
import { parse } from 'marked'
import { sanitize } from 'dompurify'
import '@/assets/doc.css'
import md from '@/docs/disclosure.md'


export default {
  metaInfo: {
    title: "Commerce Disclosure",
  },
  setup () {
    const content = sanitize(parse(md))

    return {
      content,
    }
  }
}
</script>

<style scoped>
.article {
  max-width: 20em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>