var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transition-image",class:_vm.classNames.join(' '),style:({
    width: _vm.styles.width,
    height: _vm.styles.height,
    pointerEvents: _vm.styles.pointerEvents,
    aspectRatio: _vm.styles.aspectRatio,
    borderRadius: _vm.styles.borderRadius,
  })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loaded),expression:"!loaded"}],ref:"loadingPlaceholder",style:({
      objectFit: _vm.styles.objectFit,
      aspectRatio: _vm.styles.aspectRatio,
      pointerEvents: _vm.styles.pointerEvents,
    }),attrs:{"src":require("@/assets/ic_img_loading_guider.jpg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.thumbnail && !_vm.displayFullImage),expression:"thumbnail && !displayFullImage"}],ref:"thumbnailDom",style:({
      objectFit: _vm.styles.objectFit,
      aspectRatio: _vm.styles.aspectRatio,
      pointerEvents: _vm.styles.pointerEvents,
    }),attrs:{"crossorigin":"anonymous","src":_vm.thumbnail,"alt":_vm.alt}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.src),expression:"src"}],ref:"fullImageDom",style:({
      ..._vm.innerStyle,
      objectFit: _vm.styles.objectFit,
      aspectRatio: _vm.styles.aspectRatio,
      pointerEvents: _vm.styles.pointerEvents,
    }),attrs:{"crossorigin":"anonymous","src":_vm.src,"alt":_vm.alt,"loading":_vm.thumbnail ? 'eager' : 'lazy'},on:{"error":_vm.handleFullImageError}})])
}
var staticRenderFns = []

export { render, staticRenderFns }