<template>
  <div
    v-if="authenticated"
    class="container">
    <mt-cell :title="$t('enablePushNotification')">
      <input-switch v-model="enablePushNotification"></input-switch>
    </mt-cell>

    <mt-cell :title="$t('authorFollowMe')">
      <input-switch v-model="authorFollow" />
    </mt-cell>

    <mt-cell :title="$t('authorNewPost')">
      <input-switch v-model="authorPost" />
    </mt-cell>

    <mt-cell :title="$t('commentNew')">
      <input-switch v-model="comment" />
    </mt-cell>

    <mt-cell :title="$t('authorSubmitMyChannel')">
      <input-switch v-model="postContribute" />
    </mt-cell>
  </div>
</template>

<script>
import api from '@/api/user'
import { mapActions } from 'pinia'
import { useCurrentUser } from '@/composables/user_data'
import { useUserStore } from '@/pinia/user'
import { useToast } from '@/composables/toast'
import { t } from '@/translations'
import InputSwitch from 'primevue/inputswitch'
import { useOneSignal } from '@/composables/onesignal'
import { onMounted, watch, ref } from 'vue'


export default {
  metaInfo: {
    title: t('notifySetting'),
  },
  setup () {
    const { optIn, optOut, isOptedIn } = useOneSignal()

    const enablePushNotification = ref(false)

    watch(enablePushNotification, async (value) => {
      if (value) {
        await optIn()
        if (!await isOptedIn()) {
          enablePushNotification.value = false
        }
      } else {
        optOut()
      }
    })

    onMounted(async () => {
      enablePushNotification.value = await isOptedIn()
    })

    return {
      enablePushNotification,
      ...useCurrentUser(),
      ...useToast(),
    }
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    authorPost: {
      get () {
        return this.currentUser.notifyFlags.authorPost
      },
      set (value) {
        console.log(value)
        this.updateReceiveAuthorNewPost(value)
      }
    },
    authorFollow: {
      get () {
        return this.currentUser.notifyFlags.authorFollow
      },
      set (value) {
        this.updateReceiveAuthorFollow(value)
      }
    },
    comment: {
      get () {
        return this.currentUser.notifyFlags.comment
      },
      set (value) {
        this.updateReceiveComment(value)
      }
    },
    postContribute: {
      get () {
        return this.currentUser.notifyFlags.postContribute
      },
      set (value) {
        this.updatePostContribute(value)
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, [
      'patchSelf'
    ]),
    updateReceiveAuthorNewPost (authorPost) {
      api
        .updateNotificationSettings({ authorPost })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
    updateReceiveAuthorFollow (authorFollow) {
      api
        .updateNotificationSettings({ authorFollow })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
    updateReceiveComment (comment) {
      api
        .updateNotificationSettings({ comment })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
    updatePostContribute (postContribute) {
      api
        .updateNotificationSettings({ postContribute })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    }
  },
  components: {
    InputSwitch,
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 2em;
}
</style>
