import Vue from 'vue'
import { API_URL } from '../config'

export default {
  getAvailableTopics (page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/topics/canContribute/all`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  contributeToTopic (topicId, postId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/topics/${topicId}/contribute`, {
          postId
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve()
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
