//@ts-check
import { api } from './base.js'
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import { cdn } from '@/config.js'


export const getUploadUrl = async function (fileName, fileType, fileSize, lastModified) {
  try {
    const res = await api.get('/s3/uploadPublicUrl', {
      params: {
        fileName,
        fileType,
        fileSize,
        lastModified,
        method: 'put',
      }
    })

    return res.data.result.url
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const upload = async function (url, file, callback) {
  try {
    if (file.size >= 1 * 1024 * 1024) {
      const blob = await imageCompression(file, { maxSizeMB: 1 })
      file = new File([blob], file.name)
    }
    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress ({ loaded, total }) {
        callback(loaded / total)
      }
    })

    const finalUrl = new URL(cdn.global)
    finalUrl.pathname = 'files'
    finalUrl.searchParams.append('path', new URL(url).pathname)

    return finalUrl.toString()
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getPrivateUploadUrl = async function (fileName, fileType, fileSize, lastModified) {
  try {
    const res = await api.get('/s3/uploadUrl', {
      params: {
        fileName,
        fileType,
        fileSize,
        lastModified,
        method: 'put',
      }
    })

    return res.data.result.url
  } catch (err) {
    console.error(err)
    if (err.body) {
      throw new Error(err.body.message)
    }

    throw err
  }
}

export const uploadPrivate = async function (url, file, callback) {
  try {
    await api.put(url, file, {
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress ({ loaded, total }) {
        callback(loaded, total)
      }
    })

    return new URL(url).pathname
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const startMultipartUpload = async function (fileName, fileType, fileSize, lastModified) {
  try {
    const res = await api.post('/s3/startMultipartUpload', {
      fileName, fileType, fileSize, lastModified
    })

    return {
      key: res.data.result.key,
      uploadId: res.data.result.uploadId
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getMultipartUploadUrl = async function (key, partNumber, uploadId, fileType) {
  try {
    const res = await api.get('/s3/multipartUploadUrl', {
      params: {
        key, partNumber, uploadId, fileType
      }
    })

    return res.data.result.url
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const uploadPart = async function (url, chunk, partNumber, callback) {
  try {
    const res = await axios.put(url, chunk, {
      headers: {
        'Content-Type': 'binary/octet-stream'
      },
      onUploadProgress ({ loaded, total }) {
        callback(loaded, total)
      }
    })

    return {
      ETag: res.headers.etag,
      PartNumber: partNumber,
    }
  } catch (err) {
    if (err.response && err.response.status === 404 || err.code === 'ERR_NETWORK' || err.response.status === 400 || err.code === 'ERR_BAD_REQUEST') {
      console.log('error occured in part', partNumber)
      if (err.response) {
        console.error(err.response.data)        
      } else {
        console.error(err)
      }
      console.log('retrying')
      return uploadPart(url, chunk, partNumber, callback)
    }
    console.error(err)
    throw err
  }
}


export const completeMultipartUpload = async function (parts, uploadId, key, fileType) {
  try {
    // parts:  { PartNumber: number, Etag: string }[]
    await api.post('/s3/completeMultipartUpload', {
      parts, uploadId, key, fileType,
    })
  } catch (err) {
    console.error(err)
    throw err
  }
}