import { render, staticRenderFns } from "./PRFooter.vue?vue&type=template&id=46c0c882&scoped=true"
import script from "./PRFooter.vue?vue&type=script&lang=js"
export * from "./PRFooter.vue?vue&type=script&lang=js"
import style0 from "./PRFooter.vue?vue&type=style&index=0&id=46c0c882&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c0c882",
  null
  
)

export default component.exports