//@ts-check
import { getOneIssue } from "@/api/issue"
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router/composables"
import { useToast } from "./toast"
import { useI18n } from "vue-i18n-composable"
import { format } from 'timeago.js'
import { useCurrentUser } from "./user_data"

export const useIssuePresent = function () {
  const route = useRoute()
  const issueId = route.params.issueId
  const issue = ref(null)
  const loading = ref(false)
  const { showToast } = useToast()
  const { t } = useI18n()
  const { currentUserId } = useCurrentUser()


  const involved = computed(() => {
    if (!issue.value) {
      return false
    }

    return issue.value.assigner._id === currentUserId.value
      || issue.value.assignee._id === currentUserId.value
  })


  const displayRecords = computed(() => {
    if (!issue.value) {
      return []
    }

    const records = []

    for (const [index, record] of issue.value.records.entries()) {
      const prevRecord = issue.value.records[index - 1]

      if (record.status && (!prevRecord || (prevRecord && prevRecord.status !== record.status))) {
        let i18nKey = null

        if (record.status === 'open') {
          i18nKey = 'issueOpened'
        } else if (record.status === 'assigner-rejected') {
          i18nKey = 'issueAssignerRejected'
        } else if (record.status === 'working') {
          i18nKey = 'issueWorking'
        } else if (record.status === 'reviewing') {
          i18nKey = 'issueSubmitted'
        } else if (record.status === 'assignee-rejected') {
          i18nKey = 'issueAssigneeRejected'
        } else if (record.status === 'resolved') {
          i18nKey = 'issueResolved'
        } else if (record.status === 'closed') {
          i18nKey = 'issueClosed'
        }

        records.push({
          ...record,
          content: t(i18nKey, { assigner: issue.value.assigner.nickname }),
          createdAt: format(record.createdAt, navigator.language),
          statusChanged: true,
        })
      }

      records.push({
        ...record,
        createdAt: format(record.createdAt, navigator.language),
        statusChanged: false,
      })
    }

    return records.filter(record => record.statusChanged || record.content || record.artwork)
  })

  const get = async function () {
    try {
      loading.value = true
      console.log('get issue')
      issue.value = await getOneIssue(issueId)
      console.log('got issue')
    } catch (err) {
      console.error(err)
      showToast(err)
    } finally {
      loading.value = false
    }
  }

  onMounted(() => {
    get()
  })

  return {
    loading,
    issue,
    displayRecords,
    getIssue: get,
    involved,
  }
}