<template>
  <div class="container">
    <div class="content">
      <div
        v-for="(media, index) in medias"
        :key="index">
        <img
          v-if="media.type === MediaType.IMAGE"
          class="product"
          :src="media.thum"
          @click="view(index)">
        <img
          v-if="media.type === MediaType.VIDEO"
          class="product"
          loading="lazy"
          :src="media.thum"
          @click="view(index)">
        <img
          v-if="media.type === MediaType.VIDEO"
          class="video"
          src="@/assets/video_tag.png">
        <img
          v-show="media.showcheck && !media.ischeck"
          class="edit"
          src="@/assets/Select_Off.png">
        <img
          v-show="media.showcheck && media.ischeck"
          class="edit"
          src="@/assets/Select_On.png">
      </div>
      <div
        v-show="medias.length > 0"
        class="hoverable touchable load"
        @click.prevent="more">
        <span>{{ $t('viewMore') }}</span>
      </div>
    </div>

    <div class="bottom">
      <span
        v-show="!editprocess && medias.length > 0"
        class="hoverable touchable"
        @click="edit(true)">{{ $t('edit') }}</span>
      <span
        v-show="editprocess"
        class="red hoverable touchable"
        @click="remove">
        {{ $t('delete') }}
        ({{ dealPicture }})
      </span>
      <span
        v-show="editprocess"
        class="cancel hoverable touchable"
        @click="edit(false)">{{ $t('cancel') }}</span>
    </div>
  </div>
</template>


<script>
//@ts-check
import { useCurrentUser } from '@/composables/user_data'
import { MediaType } from '@/utils/defines'
import { useCollectionStore } from '@/pinia/star'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'


export default {
  metaInfo: {
    title: 'My Stars'
  },
  setup () {
    const collectionStore = useCollectionStore()
    const { authenticated } = useCurrentUser()
    const route = useRoute()

    const dealPicture = computed(() => collectionStore.selectedMediasCount)

    const medias = computed(() => {
      return collectionStore.medias
    })

    const editprocess = computed(() => {
      return collectionStore.editing
    })

    watch(authenticated, () => {
      if (authenticated.value) {
        collectionStore.getMedias(route.params.ownerId)
      }
    })

    onMounted(() => {
      if (authenticated.value && collectionStore.medias.length === 0) {
        collectionStore.getMedias(route.params.ownerId)
      }
    })

    onUnmounted(() => {
      collectionStore.$patch({ medias: [], hasMore: true })
    })

    return {
      editprocess,
      authenticated,
      MediaType,
      dealPicture,
      medias,
      edit: collectionStore.edit,
      more: collectionStore.more,
      remove: collectionStore.remove,
      view: collectionStore.view,
    }
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.content {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.2em;
  padding: 0.2em;

  div {
    aspect-ratio: 1 / 1;
    position: relative;
    display: flex;

    .video {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 28%;
      top: 28%;
    }

    .edit {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }

  .load {
    border: solid 1px @blue;
    display: flex;
    justify-content:center;
    align-items:center;
    box-sizing: border-box;
    position: relative;

    span {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: @blue;

    }
  }
}

.bottom {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: @warm-grey;
  margin-top: 20px;
  margin-bottom: 60px;
  

  span {
    width: 120px;
    height: 30px;
    border-radius: 15px;
    background-color: @main;
    display: inline-block;
    line-height: 30px;
    color: @minor;
    background-color: @border;

    &.cancel {
      border: solid 1px @grey;
    }

    &.red {
      background-color: @main;
      color: white;
      margin-right: 6px;
    }
  }
}

.container {
  height: 100vh;
  height: 100dvh;
  background: white;
  box-sizing: border-box;
}

a, img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  display: inline-block;
  line-height: 5em;
  text-align: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

img:nth-child(even) {
  margin-left: 1%;
  margin-right: 1%;
}

@media (min-width: 800px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    min-width: 345px;
    max-width: 512px;
  }
}



</style>
