<template>
  <div class="placement-container">
    <div class="records">
      <div
        v-for="(record, index) in placement.records"
        :key="index"
        class="record"
      >
        <div @click="clickPlace(record ? record.user._id : undefined, placement._id, index)">
          <div
            v-if="record && anonymous"
            class="circle circlered touchable"
          >
            <img
              v-if="record.user._id === user._id"
              :src="user.avatarURL"
              :alt="record.user.nickname"
              class="creatoruserprofile"
            >
            <img
              v-else
              src="../assets/ic-page-user-defult-headimage@2x.png"
              class="creatoruserprofile"
            >
          </div>
          <div
            v-if="record && !anonymous"
            class="circle circlered touchable"
          >
            <img
              class="creatoruserprofile"
              :src="record.user.avatarURL"
              :alt="record.user.nickname"
            >
          </div>
          <div
            v-if="!record"
            class="circle touchable"
          >
            <img
              class="creatoruser"
              src="../assets/ic-page-creator-user-without-order.png"
            >
          </div>
        </div>
      </div>  
    </div>

    <div class="textwrap">
      <mt-field label="推广开始时间">
        <span class="starttime">{{ new Date(placement.startTimestamp).toLocaleDateString() }} {{ new Date(placement.startTimestamp).getHours() }}:{{ new Date(placement.startTimestamp).getMinutes() }}</span>
      </mt-field>
      <mt-field label="推广结束时间">
        <span class="starttime">{{ new Date(placement.endTimestamp).toLocaleDateString() }} {{ new Date(placement.endTimestamp).getHours() }}:{{ new Date(placement.endTimestamp).getMinutes() }}</span>
      </mt-field>
      <mt-field
        v-if="bookable"
        label="本次预约截止时间"
      >
        <count-down-label :end-timestamp="placement.startTimestamp" />
      </mt-field>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '../api/placement'
import CountDownLabel from '@/components/CountDownLabel.vue'
import { useCurrentUser } from '@/composables/user_data'

const coin = require('../assets/coin@2x.png')


export default {
  props: {
    placement: {
      required: true,
      type: Object,
    },
    bookable: {
      type: Boolean,
      default: true
    },
    anonymous: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      ...useCurrentUser()
    }
  },
  data () {
    return {
      popmsg: false,
      indexOffsetMax: 0
    }
  },
  mounted () {
  },
  methods: {
    clickPlace (userId, placementId, index) {
      this.popmsg = true
      if (!this.bookable) {
        if (!this.anonymous) {
          const placement = this.placement
          const record = placement.records[index]

          if (record) {
            this.$toast({
              message: `${record.user.nickname}已花费 ${record.price} 金币预约此推广位`,
              position: 'bottom'
            })
          }
        }

        return
      }

      const placement = this.placement
      const booked = placement.records.filter(record => {
        if (record) {
          return record.user._id === this.user._id
        } else {
          return false
        }
      }).length > 0

      if (this.user._id === userId) {
        const record = placement.records[index]

        this.$toast({
          message: `您已花费 ${record.price} 金币预约此推广位`,
          position: 'bottom'
        })
      } else if (booked) {

      } else {
        this.book(placementId, index)
      }
    },
    book (placementId, index) { 
      const placement = this.placement
      const record = placement.records[index]

      let recordslength = this.placement.records.length
      let recordstr = ''
      for (let i = 1; i < recordslength - index; i++) {
        recordstr += '<span index="'+i+'" class="wrapspan">'+i+'位</span>'
      }
      let str = '<p class="description">选择一个您可接受的出价范围，在此基础上可以追加您可以接受的顺延位数，我们会在这个区间选择最低价来为您选择最靠前的推广位置，多出的部分将会退回。</p>' +
                '<div class="coinwrap-placement">' +
                  '<img class="coin-placement-low" src="'+coin+'"/>' +
                  '<input type="number" class="lowprice" placeholder="最低价"/><span class="pricescope">至</span>' +
                  '<img class="coin-placement" src="'+coin+'"/>' +
                  '<input type="number" class="highprice" placeholder="最高价"/>' +
                '</div>' +
                '<div id="contentquery" class="pop-content">' +
                  '<p>顺延位数</p>' +
                  '<div class="wrapplacement">' +
                    '<span index="0" class="wrapspan spanactive">不顺延</span>' + 
                      recordstr + 
                  '</div>'+
                '</div>'

      
      const message = str
      
      setTimeout(()=>{
        document.querySelectorAll('.wrapspan')[0].style.cssText = "border:solid 1px #ff5579;color: #ff5579;"
        for(let j = 1; j<recordslength - index; j++) {
          document.querySelectorAll('.wrapspan')[j].style.cssText = "border:solid 1px #cfcfcf;color: #9b9b9b;"
        }
        document.querySelector('.highprice').value = ""
        document.querySelector('.lowprice').value = ""
        this.setstatus()
      },10)
    
      this.$messagebox
        .confirm(message, '预约该推广位')
        .then(() => {
          let highprice = document.querySelector('.highprice').value
          let lowprice = document.querySelector('.lowprice').value
        
          if (highprice == '') {
            throw new Error('最高价不能为空')
          }
          if (lowprice == '') {
            throw new Error('最低价不能为空')
          }
          if ( parseInt(lowprice) >= parseInt(highprice) ) {
            throw new Error('最低价不能高于最高价')
          }
          //console.info(placementId+ '-' + highprice+ '-' + index+ '-' + this.indexOffsetMax+ '-' + lowprice) 
          return api.book(placementId, index, parseInt(highprice),parseInt(this.indexOffsetMax),parseInt(lowprice))
        })
        .then(placement => {
          this.$indicator.close()
          this.$toast({
            message: '预约成功',
            position: 'bottom'
          })
        })
        .catch(err => {
          this.$indicator.close()
          if (err === 'cancel') {
            return
          }
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
      
      
    },
    cancel (placementId, index) {
      const record = this.placement.records[index]

      return this.$messagebox
        .confirm(`您已花费 ${record.price} 金币预约了<b>另一个</b>推广位，要取消预约吗？`)
        .then(() => {
          this.$indicator.open()
          return api.cancel(placementId)
        })
        .then(() => {
          this.$indicator.close()
          this.$toast({
            message: '取消成功',
            position: 'bottom'
          })

          Vue.set(this.placement.records, index, undefined)

          return Promise.resolve(true)
        })
        .catch(err => {
          this.$indicator.close()

          if (err === 'cancel') {
            return
          }

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    },
    setstatus () {
      var wrapspanarr = document.querySelectorAll('.wrapspan')
      var wrapspanlength = wrapspanarr.length
      var that = this
      for (let i = 0; i<wrapspanlength; i++) {
        wrapspanarr[i].onclick = function(){
          let index = this.getAttribute('index')
          for (let j = 0; j<wrapspanlength; j++) {
            if(index == j) {
              wrapspanarr[index].style.cssText = "border:solid 1px #ff5579;color: #ff5579;"
            } else {
              wrapspanarr[j].style.cssText = "border:solid 1px #cfcfcf;color: #9b9b9b;"
            }
            that.indexOffsetMax = index
            
          }
          
        }
      }
    }
  },
  components: {
    CountDownLabel,
  }
}
</script>

<style lang="less">
@import '@/assets/base.less';
@import '@/assets/placement.css';


.placement-container {
  width: 100%;
  margin: 0em auto 2em;
  background: white;
  padding: 12px 1em;
  box-sizing: border-box;
  border: 1px solid @border;
  .records {
    margin-bottom: 1em;
  }
  .record {
    width: 23%;
    height: 70px;
    line-height: 70px;
    margin-left: 5px;
    display: inline-block;
    text-align: center;

    > div {
      display: inline-block;
      height: 70px;
      overflow: hidden;
    }

    img {
      object-fit: cover;
      width: 57px;
      height: 57px;
      position: relative;
    }

    .circle {
      display: inline-block;
      vertical-align: middle;
      border: 1.5px solid #d0d0d0;
      border-radius: 27px;
      height: 54px;
      line-height: 54px;
      width: 54px;
      background: #f6f6f6;

      .creatoruser {
        width: 24px;
        height: 24px;
        position: relative;
        top: 6px;
      }
      .creatoruserprofile {
        object-fit: cover;
        width: 53.8px;
        height: 53.8px;
        position: relative;
        top: 0.5px;
        border-radius: 50%;
      }
    }
    .circlered {
      border: 1.5px solid @main;
    }
  }

  .textwrap {

    .mint-field .mint-cell-title {
      width: 130px !important;
    }
    .mint-cell{
      color: #333333;

      .starttime {
        color: @grey;
      }
    }
    .mint-cell-wrapper {
      border-top: 1px solid @border;
      border-bottom: 0px;
    }

  }

}
</style>
