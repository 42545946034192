<template>
  <div class="container">
    <mt-header
      v-if="!isInPeroApp && !fromWeixin"
      fixed
      :title="$t('paymentResult')"
    >
      <a slot="left">
        <pr-button
          v-if="!isOnMobile"
          class="hoverable touchable"
          icon="back"
          @click.native="dismiss"
        >{{ $t('back') }}</pr-button>
        <pr-button
          v-else
          icon="back"
          @click.native="dismiss"
        >{{ $t('back') }}</pr-button>
      </a>
    </mt-header>

   
    <div
      v-show="!loading"
      class="content"
    >
      <img
        v-show="ok || alipay"
        src="@/assets/success.png"
        alt=""
      >
      <img
        v-show="!ok && !alipay"
        src="@/assets/failed.png"
        alt=""
      >
      <h1 v-if="!alipay">
        {{ $t('payment') }}{{ ok ? $t('success') : $t('fail') }}
      </h1>
      <h1 v-if="alipay">
        支付成功
      </h1>

      <p v-if="ok && fromWeixin || alipay && fromWeixin">
        请返回微信并点击「完成支付」
      </p>
      <p v-else-if="fromWeixin">
        请返回微信并点击「未完成支付」
      </p>
    </div>
  </div>
</template>

<script>
import { isOnMobile, isInPeroApp } from '@/utils'
import api from '@/api/user'
import PrButton from '@/components/PRButton.vue'

export default {
  metaInfo: {
    title: "支付结果",
  },
  data () {
    return {
      isOnMobile: isOnMobile(),
      alipay: false,
      loading: true
    }
  },
  computed: {
    ok () {
      if (this.$route.query.result === 'success' || this.$route.query.is_success === 'T') {
        return true
      } else {
        return false
      }
    },
    isInPeroApp () {
      return isInPeroApp()
    },
    fromWeixin () {
      return this.$route.query.fromwx === 'yes' 
    }
  },
  mounted () {
    // if (this.ok && this.$route.query.out_trade_no) {
    //   this.$router.push(`/alipay?chargeNo=${this.$route.query.out_trade_no}`)
    // }
    setTimeout(() => {
      if (this.$route.query.out_trade_no) {
        this.getChargeStatus(this.$route.query.out_trade_no) 
      } else {
        this.$indicator.close() 
        this.loading = false 
      }
    }, 1000)
    this.$indicator.open()
    this.loading = true

    

    setTimeout(() => {
      if (this.ok && isInPeroApp()) {
        window.close()
      }
    }, 500)
  },
  methods: {
    dismiss () {
      let path = '/i/work'

      if (this.isOnMobile) {
        path = '/i/wallet'
      }

      const query = {}

      if (this.ok) {
        query.action = 'refresh'
      }
      this.$router.push({ path, query })
    },
    //获取订单状态
    getChargeStatus (chargeId) {
      api
        .getChargeStatus(chargeId)
        .then((data)=>{

          if (data.result.status === 'paid') {
            this.alipay = true
            this.$indicator.close() 
            this.loading = false  
          } else {
            this.$indicator.close()  
            this.loading = false  
          }
        })
        .catch(err => {
          this.$indicator.close()
          this.loading = false 
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    }
  },
  components: {
    PrButton
  }
}
</script>

<style scoped>
img {
  padding-top: 6em;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: block;
}

h1 {
  padding-top: 20px;
  font-weight: 500;
  text-align: center;
}

p {
  margin-top: 1em;
  text-align: center;
}
</style>
