<template>
  <div class="container">
    <article class="article">
      <p>{{ circle.description }}</p>
      <p
        v-for="(content, index) in introduction"
        :key="index">
        {{ content }}
      </p>
    </article>

    <article class="article">
      <h1>Perohub Circle Terms of Service</h1>
      <p>This article is in additon to <router-link to="/terms">Terms Of Service</router-link>, please read it first</p>
      <p>All prices are set by the artist</p>
      <h2>Subscription</h2>
      <p>- Currently Perohub doesn't charge you every month, instead, we push monthly orders to you, you decide to continue to pay or not.</p>
      <p>- Your access to the circle expires at first day each month</p>
      <p>- Perohub supports both Youtube like subscription mode and Fantia like subscription mode, artist can choose one, or customize</p>
      <h2>YouTube Like Mode</h2>
      <p>You can access to all contents as long as your subscription is active</p>
      <p>Once your subscription expires you lose access to all contents</p>
      <p>Each month you pay the same price</p>
      <h2>Fantia Like Mode</h2>
      <p>You need to pay for the contents of each month</p>
      <p>Your access to the contens you paied for will never expire</p>
      <h2>Refund Policies</h2>
      <p>You can get refund only when:</p>
      <ul>
        <li>The circle is banned due to violation of community guidelines</li>
        <li>You are blocked by the artist thus lose access to all the contents of the artist and you applied for refund</li>
      </ul>
      <p>You can cancel subscription anytime, you will not receive pushed orders anymore, but no refund for the money you've already paid</p>
      <p>If your account is banned due to violation of community guidelines(eg. redistributing contents without permission from the artist), you will not be refunded</p>
    </article>
  </div>
</template>

<script>
import { getCircle } from '@/api/circle'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'


export default {
  beforeRouteEnter (to, from, next) {
    document.title = '应援团介绍'
    next()
  },
  setup () {
    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      circle: {},
      load: false,
      introduction: []
    }
  },
  mounted () {
    this.getCircle()
  },
  methods: {
    getCircle () {
      this.$indicator.open()

      getCircle(this.$route.params.circleId)
        .then(circle => {
          this.$indicator.close()
          this.circle = circle
          this.introduction = circle.introduction.split('\n')
          this.load = true
        })
        .catch(err => {
          this.$indicator.close()

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  text-align: left;
}

</style>
