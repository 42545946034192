//@ts-check
import { api } from "./base"


export const getChatWishingUsers = async function (userId, page, limit = 5) {
  try {
    const res = await api.get(`/users/${userId}/requesters`, {
      params: {
        page,
        limit
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const searchChatWishingUsers = async function (userId, keyword, limit = 5) {
  try {
    const res = await api.get(`/users/${userId}/requesters/search`, {
      params: {
        limit,
        keyword
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}