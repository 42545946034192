<template>
  <div
    class="comment-cell-container"
    @mouseover="mouseover"
    @mouseleave="mouseleave">
    <div
      class="user touchable hoverable"
      @click="$router.push(`/users/${comment.user._id}`)">
      <div class="userimage">
        <img :src="comment.user.avatarURL">
        <img
          v-if="comment.user.officialBadge"
          class="badge"
          :src="comment.user.officialBadge.iconUrl">
      </div>      
    </div>
    <div class="content">
      <h5>
        {{ comment.user.nickname }} 
        <circle-badge
          v-if="comment.user.tag && comment.user.tag.memberTag != null"
          :member-tag="comment.user.tag.memberTag"
          @click.native="$router.push(`/user/${comment.user.tag.authorId}/circles`)"></circle-badge>
      </h5>
      <p
        v-linkified:options="{ className: 'commentcell-linkified' }"
        v-html="sanitize(comment.content)"></p>
      <span
        v-if="comment.repliesCount > 0 && !isReply"
        @click="showReplies">
        {{ $t('repliesTotal', { total: comment.repliesCount }) }}
        <img
          src="@/assets/icon_page_nextpage_grey.png"
          alt="">
      </span>
    </div>
    <div
      class="right">
      <ins>{{ getHumanReadableTimeString(comment.createdAt) }}</ins>
      <div class="actions">
        <a
          v-show="showDeleteButton && shouldShowDeleteButton"
          @click.prevent.stop="remove">
          {{ $t('delete') }}
        </a>
        <a
          v-show="showReplyButton && !isReply"
          @click.prevent.stop="showReplies">
          {{ $t('reply') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'timeago.js'
import CircleBadge from '@/components/CircleBadge.vue'
import { sanitize } from 'dompurify'
import { computed, ref } from 'vue'
import { useCurrentUser } from '@/composables/user_data'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { deleteComment } from '@/api/comment'
import { useArtworkStore } from '@/pinia/artwork'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'


export default {
  props: {
    comment: {
      type: Object,
      required: true
    },
    isReply: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { isOnMobile } = useResponsive()
    const showReplyButton = ref(isOnMobile.value)
    const showDeleteButton = ref(isOnMobile.value)
    const moreMenuVisible = ref(false)
    const { currentUser, authenticated } = useCurrentUser()
    const { showToast } = useToast()
    const artworkStore = useArtworkStore()

    const artwork = computed(() => {
      return artworkStore.artworks[props.comment.postId]
    })

    const shouldShowDeleteButton = computed(() => {
      if (!authenticated) {
        return false
      }

      if (!artwork.value) {
        return false
      }

      if (artwork.value.user._id === currentUser.value._id) {
        return true
      }

      if (props.comment.userId === currentUser.value._id) {
        return true
      }

      return false
    })


    const mouseleave = function () {
      if (isOnMobile.value) {
        return
      }
      showReplyButton.value = false
      showDeleteButton.value = false
    }

    const mouseover = function () {
      if (isOnMobile.value) {
        return
      }
      showReplyButton.value = true
      showDeleteButton.value = shouldShowDeleteButton.value
    }

    const remove = async function () {
      try {
        await deleteComment(props.comment._id)
        showToast('delete ok', 'ok')
        eventEmitter.emit(events.CommentDeleted, props.comment._id)
      } catch (err) {
        showToast(err.message)
      }
    }


    return {
      showReplyButton,
      sanitize,
      moreMenuVisible,
      showDeleteButton,
      mouseleave,
      mouseover,
      remove,
      shouldShowDeleteButton,
    }
  },
  methods: {
    getHumanReadableTimeString (createdAt) {
      return format(createdAt, navigator.language.replace('-', '_'))
    },
    showReplies () {
      this.$parent.showReplies(this.comment)
    },
  },
  components: {
    CircleBadge,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";


.comment-cell-container {
  display: flex;
  background: white;
  margin-bottom: 20px;
  padding: 0px 10px;
  position: relative;

  .user {
    max-width: 50px;
    display: inline-block;
    text-align: center;
    flex: 1;
    

    .userimage {
      position: relative;
      
      img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
      }

      .badge {
        position: absolute;
        right: 6px;
        width: 12px;
        height: 12px;
        bottom: 6px;
      }
    }
    
  }

  .content {
    flex: 1;
    font-size: 14px;
    display: flex;
    flex-direction: column;

    h5 {
      margin-bottom: 4px;
      font-size: 12px;
      color: @black;
    }

    span {
      color: @light-grey;
      height: 21px;
      line-height: 21px;
      width: 90%;
      display: inline-block;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      img {
        margin-bottom: -2px;
        height: 15px;
      }
    }

    .commentcell-linkified {
      color: @blue;
      cursor: pointer;
      opacity: 0.65;
      -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
      float: none;
    }
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  a {
    display: block;
    color: #333333;
    font-size: 0.9rem;

    &:hover {
      color: @main;
      cursor: pointer;
    }
  }

  ins {
    color: #cfcfcf;
    font-size: 10px;
  }
}

.actions {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  flex: 1;
}
</style>
