//@ts-check
import { useAuthenticator } from "@/pinia/authenticator"
import { computed } from "vue"



export const useCurrentUser = function () {
  const authenticator = useAuthenticator()

  const currentUser = computed(() => authenticator.currentUser)
  const authenticated = computed(() => authenticator.authenticated)
  const currentUserId = computed(() => {
    if (!currentUser.value) {
      return null
    }

    return currentUser.value._id
  })
  const currentUserAuthed = computed(() => {
    if (currentUser.value) {
      return currentUser.value.authed
    }

    return false
  })

  return {
    currentUserAuthed,
    currentUser,
    authenticated,
    currentUserId,
  }
}