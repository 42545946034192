//@ts-check
import { defineStore } from "pinia"
import { ref } from 'vue'
import { getAllCircles, getMyCircles, getUserCircles } from '@/api/circle'
// @ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'
import { useToast } from "@/composables/toast"
import { eventEmitter } from "@/utils"
import { events } from "@/utils/events"


export const useCircleStore = defineStore('circle', () => {
  const manageableCircles = ref([])
  const myCircles = ref([])
  const allCircles = ref([])
  const { Toast } = useToast()

  const _fetchManageableCircles = async function (userId) {
    try {
      const circles = await getUserCircles(userId)
      manageableCircles.value = circles
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const fetchManageableCircles = reusePendingPromise(_fetchManageableCircles)

  const fetchMyCircles = async function (page, limit) {
    try {
      const circles = await getMyCircles(page, limit)
      if (page === 1) {
        myCircles.value = circles
      } else {
        myCircles.value.push(...circles)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const fetchAllCircles = async function (page, limit) {
    try {
      const circles = await getAllCircles(page, limit)
      if (page === 1) {
        allCircles.value = circles
      } else {
        allCircles.value.push(...circles)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  eventEmitter.on(events.LogoutCompleted, () => {
    manageableCircles.value = []
    myCircles.value = []
  })

  
  return {
    manageableCircles,
    myCircles,
    allCircles,
    fetchManageableCircles,
    fetchMyCircles,
    fetchAllCircles,
  }
})