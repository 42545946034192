<template>
  <div class="container">
    <div class="group">
      <mt-cell
        :title="$t('notifications')"
        is-link
        class="hoverable touchable"
        @click.native="$router.push('/i/settings/notification')">
      </mt-cell>
      <mt-cell
        v-show="isOnMobile"
        :title="$t('downloads')"
        is-link
        class="hoverable touchable"
        @click.native="$router.push('/i/downloads')">
      </mt-cell>
      <mt-cell
        :title="$t('blacklist')"
        is-link
        class="hoverable touchable"
        @click.native="$router.push('/i/settings/blacklist')">
      </mt-cell>
      <mt-cell
        :title="$t('clearCache')"
        class="hoverable touchable"
        @click.native="clearCache">
      </mt-cell>
      <mt-cell
        v-if="isOnMobile"
        :title="$t('about')"
        is-link
        class="hoverable"
        @click.native="$router.push('/about-us')">
      </mt-cell>
    </div>

    <div class="group">
      <div class="cell">
        <span class="title">{{ $t('siteLang') }}</span>

        <div class="content">
          <Dropdown
            v-model="$i18n.locale"
            option-label="name"
            option-value="value"
            :options="[
              { name: 'English', value: 'en' },
              { name: '中文', value: 'zh' },
              { name: '日本語', value: 'ja' },
            ]">
          </Dropdown>
        </div>
      </div>
      <Divider />
    </div>

    <div
      v-if="isOnMobile"
      class="group">
      <mt-cell
        :title="$t('logout')"
        class="touchable"
        @click.native="logout">
      </mt-cell>
    </div>
  </div>
</template>


<script>
import { useResponsive } from '@/composables/responsive'
import { useI18n } from 'vue-i18n-composable'
import { useToast } from '@/composables/toast'
import { useAuthenticator } from '@/pinia/authenticator'
import Dropdown from 'primevue/dropdown'
import Divider from 'primevue/divider'


const storeKey = 'medias'

export default {
  setup () {
    const { t } = useI18n()
    const { Toast } = useToast()
    const authenticator = useAuthenticator()

    const clearCache = async function () {
      try {
        const cacheNames = await caches.keys()
        if (cacheNames.includes(storeKey)) {
          await caches.delete(storeKey)          
        }
        Toast(t('success'), 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        }) 
      }
    }

    return {
      clearCache,
      ...useResponsive(),
      logout: authenticator.logout
    }
  },
  components: {
    Dropdown,
    Divider,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.cell {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;


  .content {
    margin-left: auto;
  }
}

.p-dropdown {
  min-width: 14rem;

  @media @phone {
    min-width: 10rem;
  }
}

.group {
  margin-bottom: 4rem;
}
</style>