<template>
  <div
    id="app"
    :class="{ 'mobile-app': isOnMobile, 'pc-app': !isOnMobile }"
    :style="containerStyle">

    <nav-bar
      v-show="navVisible"></nav-bar>

    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        :key="$route.fullPath"></router-view>
    </keep-alive>

    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.fullPath"></router-view>

    <login></login>

    <tab-bar v-if="isOnMobile" />

    <pr-footer
      v-else
      v-show="footerVisible"
      class="footer"></pr-footer>

    <channel-collector
      v-model="showChannelCollector"></channel-collector>

    <circle-collector
      v-model="showCircleCollector"></circle-collector>

    <social-sharing
      v-model="showSocialSharing"></social-sharing>

    <payment-processor-picker
      v-model="showPaymentPicker">
    </payment-processor-picker>

    <media-browser></media-browser>

    <toast :position="isOnMobile ? 'bottom-center' : 'top-right'"></toast>

    <toast
      position="bottom-center"
      group="withButtons">
      <template #message="slotProps">
        <div style="display: flex; flex-direction: column; width: 100%;">
          <div class="text-center">
            <p>{{ slotProps.message.detail }}</p>
          </div>
          <div style="display: flex; width: 100%; gap: 1rem;">
            <div
              v-if="slotProps.message.onReject"
              style="flex: 1">
              <Button
                class="p-button-secondary"
                style="width: 100%;"
                :label="slotProps.message.rejectLabel || 'No'"
                @click="slotProps.message.onReject">
              </Button>
            </div>
            <div
              v-if="slotProps.message.onConfirm"
              style="flex: 1">
              <Button
                class="p-button-success"
                style="width: 100%;"
                :label="slotProps.message.confirmLabel || 'Yes'"
                @click="slotProps.message.onConfirm"></Button>
            </div>
          </div>
        </div>
      </template>
    </toast>

    <scroll-top :threshold="500"></scroll-top>

    <confirm-dialog></confirm-dialog>

    <r18-cover
      v-if="showR18Cover"
      v-show="showR18Cover"
      @dismiss="showR18Cover = false">
    </r18-cover>
  </div>
</template>


<script>
import Login from '@/components/Login.vue'
import TabBar from '@/components/TabBar.vue'
import NavBar from '@/components/NavBar.vue'
import PrFooter from '@/components/PRFooter.vue'
import { useResponsive } from '@/composables/responsive'
import ChannelCollector from '@/components/ChannelCollector.vue'
import CircleCollector from '@/components/CircleCollector.vue'
import SocialSharing from '@/components/SocialSharing.vue'
import PaymentProcessorPicker from '@/components/PaymentProcessorPicker.vue'
import MediaBrowser from './components/MediaBrowser.vue'
import R18Cover from './components/R18Cover.vue'
import { mapActions } from 'pinia'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'
import Button from 'primevue/button/Button'
import { useToast } from '@/composables/toast'

import 'mint-ui/lib/style.css'
import 'typo.css'
import '@/assets/base.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'

import { useApplication } from '@/pinia/application'
import { computed, onMounted } from 'vue'
import { useChannelStore } from '@/pinia/channel'
import { useAuthenticator } from '@/pinia/authenticator'
import ScrollTop from 'primevue/scrolltop'
import { useR18CoverStore } from './pinia/r18cover'


export default {
  metaInfo: {
    title: "Artworks Marketplace | Perohub",
  },
  setup () {
    const uistates = useApplication()
    const channelStore = useChannelStore()
    const r18coverStore = useR18CoverStore()
    const { Toast } = useToast()

    const showChannelCollector = computed({
      get () {
        return uistates.showChannelCollector
      },
      set (value) {
        uistates.showChannelCollector = value
      }
    })


    const showCircleCollector = computed({
      get () {
        return uistates.showCircleCollector
      },
      set (value) {
        uistates.showCircleCollector = value
      }
    })

    const showSocialSharing = computed({
      get () {
        return uistates.socialSharingVisible
      },
      set (value) {
        uistates.socialSharingVisible = value
      }
    })


    const sourceChannel = computed(() => {
      return channelStore.sourceChannel
    })

    const showPaymentPicker = computed({
      get () {
        return uistates.paymentVisible
      },
      set (value) {
        uistates.paymentVisible = value
      }
    })

    const showR18Cover = computed({
      get () {
        return r18coverStore.r18coverVisible
      },
      set (value) {
        r18coverStore.$patch({ r18coverVisible: value })
      }
    })

    return {
      ...useResponsive(),
      showChannelCollector,
      showCircleCollector,
      showSocialSharing,
      channelStore,
      sourceChannel,
      showPaymentPicker,
      showR18Cover,
      ...useToast(),
    }
  },
  data () {
    return {
      transitionName: 'slide-left',
      chatposttion: false,
      top: 0,
      chatnow: {}
    }
  },
  computed: {
    footerVisible () {
      if (this.isOnMobile) {
        return false
      }
      //return this.$route.matched.filter(({ path }) => path.indexOf('/stars') !== -1).length === 0 &&
      // this.$route.matched.filter(({ path }) => path.indexOf('/christmas') !== -1).length === 0 &&
        //this.$route.matched.filter(({ path }) => path.indexOf('/comicpost') !== -1).length === 0

      return this.$route.matched.filter(({ path }) => path.indexOf('/comics') !== -1).length === 0
    },
    navVisible () {
      return this.$route.matched.filter(({ path }) => path.indexOf('/comics') !== -1).length === 0
    },
    containerStyle () {
      if (this.isOnMobile) {
        return {}
      }

      const height = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight
      return {
        minHeight: `${height}px`,
        paddingBottom: this.footerVisible ? '201px' : '0'
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if ((to.path === '/' && from.path === '/i') || (to.path === '/i' && from.path === '/')) {
        return this.transitionName = ''
      }

      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length

      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
  methods: {
    ...mapActions(useAuthenticator, ['checkAuth']),
    async setChannelNumberIfNeeded () {
      const channelNumber = this.$route.query.cid
      await this.channelStore.setSourceChannelNumber(channelNumber)

      if (this.sourceChannel) {
        const message =  this.$t('fromChannel', {
          title: this.sourceChannel.title,
          owner: this.sourceChannel.owner.nickname
        })

        this.showToast({
          message,
        }, 'info')
      }
    },
  },
  async created () {
    await this.setChannelNumberIfNeeded()
    await this.checkAuth()
   
    this.bodyEl = document.body
    this.top = 0
  },
  components: {
    Login,
    TabBar,
    NavBar,
    PrFooter,
    ChannelCollector,
    CircleCollector,
    SocialSharing,
    PaymentProcessorPicker,
    MediaBrowser,
    Toast,
    ScrollTop,
    ConfirmDialog,
    Button,
    R18Cover,
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

body {
  .scrollbar(@main, transparent);
}
</style>

<style lang="less" scoped>
.mobile-app {
  height: 100vh;
  height: 100dvh; // fix ios safari dosen't fucking respect 100vh
  max-width: 736px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.pc-app {
  background: white;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.view {
  position: absolute;
  transition: all .5s cubic-bezier(.55, 0, .1, 1);
}

.footer {
  position: absolute;
  bottom: 0;
}

.clickthrough {
  pointer-events: none;
}

.chat {
  width: 365px;
  height: 680px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 4;

  .navigationchat {
    position: absolute;
    bottom: 0px;
    right: 20px;
    pointer-events: auto;
    width: 60px;
    height: 60px;
  }
  .tip {
    min-width: 30px;
    padding-left: 2px;
    padding-right: 2px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
    border-radius: 12px;
    background-color: #55caff;
    border: solid 1px #55caff;
    position: absolute;
    bottom: 39px;
    right: 14px;
  }

  .chatwrap {
    width: 345px;
    height: 560px;
    background: white;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
  }
  .chatwrapphone {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e7e7e7;
    padding-left: 10px;
    padding-left: 10px;
    box-sizing: border-box;
  }
}

.chatphone {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0px;
  right: 0px;
  top: -10px;
  left: 0px;
}

.chatbottom {
  position: absolute;
  bottom: 270px;
}

/* vue-router transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}

.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.icon {
  margin-top: 12px;
  color: white;
}

.p-scrolltop {
  bottom: 5rem;
}
</style>
