<template>
  <div
    v-if="circle"
    ref="div"
    class="container">
    <header
      ref="head"
      :style="{ backgroundImage: `url('${circle.coverUrl}')` }">
      <div class="actions">
        <more-menu
          v-model="moreMenuVisible"
          :items="menuItems">
          <icon
            name="more-horizontal"
            @click.native="moreMenuVisible = true"></icon>
        </more-menu>
      </div>
      <div class="content">
        <h1>{{ circle.title }}</h1>
        <tags
          class="tags"
          :update="circle.status === 'updating' ? true: false"
          :tags="tags"
          size="small"
          :lighter="true"></tags>
        <router-link :to="`/circles/${circle._id}/readme`">
          <p class="p-color touchable hoverable">
            <span>{{ circle.description }}</span>
            <img
              loading="lazy"
              src="@/assets/icon_page_more_white_shadow.png"
              alt="introduction">
          </p>
        </router-link>
      </div>
    </header>

    <div class="wrap">
      <router-link :to="`/users/${circle.author._id}`">
        <div class="middle hoverable">
          <img
            loading="lazy"
            :src="circle.author.avatarURL">
          <div style="margin-left: 0.5em;">
            <span class="black">{{ circle.author.nickname }}
              <router-link
                v-if="circle.author.tag && circle.author.tag.memberTag !== null"
                :to="`/user/${circle.author.tag.authorId}/circles`">
                <circle-badge
                  v-if="circle.author.tag && circle.author.tag.visible"
                  :member-tag="circle.author.tag.memberTag"></circle-badge>
              </router-link>
            </span>
            <span>{{ ellipseString(circle.author.motto) }}</span>
          </div>
          <span
            v-if="!circle.isMember"
            class="right">{{ priceText }}</span> 
          <span
            v-else
            class="right grey">{{ $t('joined') }}</span>
        </div>
      </router-link>

      <div v-if="circle.displayType === 'album' || circle.displayType === 'article'">
        <mt-navbar
          v-model="selected"
          class="segmented-control">
          <mt-tab-item :id="0">
            {{ $t('overview') }}
          </mt-tab-item>
          <mt-tab-item :id="1">
            {{ $t('works') }}
          </mt-tab-item>
        </mt-navbar>
        <mt-tab-container v-model="selected">
          <mt-tab-container-item :id="1">
            <post-list-mobile
              :posts="posts"></post-list-mobile>
            <load-more-button
              :busy="loading"
              :has-more="postHasmore"
              :trigger="loadMorePosts"></load-more-button>
          </mt-tab-container-item>
          <mt-tab-container-item :id="0">
            <section
              v-for="chapter in chapters"
              :key="chapter._id">
              <header>
                {{ chapter.groupTitle }}
              </header>
              <div class="posts">
                <router-link
                  v-for="post in chapter.posts.filter(post => post.type === 'album' || post.type === 'article')"
                  :key="post._id"
                  :to="`/posts/${post._id}`">
                  <div class="post touchable hoverable">
                    {{ getPostTitle(post) }}
                  </div>
                </router-link>
                <router-link
                  v-for="post in chapter.posts.filter(post => post.type === 'comics')"
                  :key="post._id"
                  :to="`/comics/${post._id}`">
                  <div class="post touchable hoverable">
                    {{ getPostTitle(post) }}
                  </div>
                </router-link>
              </div>
            </section>
            <load-more-button
              :has-more="chapterHasmore"
              :busy="loading"
              :trigger="getChapters"></load-more-button>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>


      <!--漫画应援团-->
      <div
        v-else-if="circle.displayType === 'comics'"
        class="comic">
        <p>
          <span>{{ $t('allChapters', { count: circle.postsCount }) }}</span>
          <span
            class="normal hoverable"
            :class="{'grey-span': sort === 'asc'}"
            @click="onSortUpdated('asc')">
            <img
              v-if="sort === 'asc'"
              src="@/assets/ic-comic-clockwise-selected@2x.png">
            <img
              v-else
              src="@/assets/ic-comic-clockwise@2x.png">
            {{ $t('normalOrder') }}
          </span>
          <span
            class="reverse hoverable"
            :class="{'grey-span': sort === 'des'}"
            @click="onSortUpdated('des')">
            <img
              v-if="sort === 'des'"
              src="@/assets/ic-comic-anticlockwise@2x.png">
            <img
              v-else
              src="@/assets/comic-ic-comic-anticlockwise-selected@2x.png">
            {{ $t('reversedOrder') }}
          </span>   
        </p>
        <div>
          <router-link
            v-for="post in posts"
            :key="post._id"
            :to="{ name: 'comic', params: { postId: post._id }}">
            <span class="hoverable">{{ post.title }}</span> 
          </router-link>
        </div>
        <load-more-button
          :has-more="postHasmore"
          :busy="loading"
          :trigger="loadMorePosts"></load-more-button>
      </div>
      <!--漫画应援团-->
    </div>

    <circle-join-picker
      v-model="showPicker"
      :circle="circle"></circle-join-picker>

    <footer
      v-show="!circle.isMember"
      @click="authenticated ? showPicker = true : showLogin()">
      <p>
        {{ $t('joinThisCircle') }}
        {{ priceText }}
      </p>
    </footer>
  </div>
</template>

<script>
//@ts-check
import Tags from '@/components/Tags.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import CircleJoinPicker from '@/components/CircleJoinPicker.vue'
import PostListMobile from '@/components/PostListMobile.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import { useResponsive } from '@/composables/responsive'
import { useCirclePresent } from '@/composables/circle_present'
import { useCurrentUser } from '@/composables/user_data'
import { useFormattedString } from '@/composables/formatted_string'
import { useCurrentCurrency } from '@/composables/currency'
import MoreMenu from '@/components/MoreMenu.vue'
import Icon from 'vue-icon/lib/vue-feather.esm'
import { useCircleMoreMenu } from '@/composables/circle_moremenu'
import { useApplication } from '@/pinia/application'
import { ref } from 'vue'


export default {
  metaInfo () {
    return this.circleMetaInfo
  },
  setup () {
    const showPicker = ref(false)
    const { currency } = useCurrentCurrency()
    const circlePresentComposition = useCirclePresent(currency, {
      fromNetwork: true,
    })
    const application = useApplication()
    const selected = ref(0)

    return {
      showLogin: application.showLogin,
      currency,
      ...circlePresentComposition,
      ...useCurrentUser(),
      ...useFormattedString(),
      ...useResponsive(),
      ...useCircleMoreMenu(circlePresentComposition.circle, showPicker),
      showPicker,
      selected,
    }
  },
  components: {
    Tags,
    LoadMoreButton,
    CircleJoinPicker,
    CircleBadge,
    PostListMobile,
    MoreMenu,
    Icon,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.p-color {
  color: #ffffff;
}

.btnthread {
  width: 100%;
  height: 43px;
  margin-top: 10px;
  margin-bottom: 6px;
  background: rgb(255,102,134);
  color: white;
  font-weight: normal;
}

.container {
  position: relative;
  padding-bottom: 60px;

  > header {
    height: 320px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .actions {
      position: absolute;
      top: 0;
      width: 100%;
      height: 3em;
      background: transparent;
      display: flex;
      justify-items: center;

      .icon {
        margin-left: 1em;
        color: white;
        height: 100%;
      }
    }

    .content {
      color: white;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px 10px;
      box-sizing: border-box;

      h1 {
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.34);
        font-size: 24px;
        font-weight: normal;
      }

      .tags {
        margin-bottom: 0;
      }

      p {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.34);
        font-size: 14px;

        span {
          display: inline-block;
          vertical-align: middle;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        img {
          float: right;
          display: block;
          height: 16px;
          width: 16px;
          margin-top: 4px;
        }
      }
    }

    button {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 60px;
      height: 30px;
      font-size: 14px;
      background: rgba(0, 0, 0, 0);
      color: white;
      border: 1px solid white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
    }
  }

  .middle {
    padding: 8px 10px;
    position: relative;
    border: 1.5px solid @border;
    margin-top: 10px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      vertical-align: -1px;
    }
    div {
      display: inline-block;

      span {
        display: block;
        font-size: 14px;
        color: @grey;
      }
      .black {
        color: @black;
      }
    }
    .right {
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 12px;
      color: @yellow;
      border: 1px solid @yellow;
      padding: 0px 10px;
      border-radius: 10px;
    }
    .grey {
      color: @grey;
      border: 1px solid @grey;
    }
  }

  section {
    padding: 10px 0px;
    margin-bottom: 12px;

    header {
      font-size: 14px;
      height: 24px;
      font-weight: 500;
      color: @light-grey;
      border-bottom: 1px solid @border;
    }

    .posts {
      margin-top: 16px;

      a {

        .post {
          display: inline-block;
          width: 49%;
          height: 30px;
          line-height: 30px;
          background: @border;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: @light-grey;
          padding: 0 6px;
          box-sizing: border-box;
          margin-bottom: 6px;
          vertical-align: top;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          
        }

        &:nth-child(even) {
          margin-left: 2%;
        }

      }

      

      span {
        text-align: center;
        display: block;
        color: @blue;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  > footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: @blue;
    color: white;
    height: 45px;
    line-height: 45px;
    text-align: center;
    z-index: 5;
    font-weight: bold;
  }
}
.mcontain {
  width: 32%;
  padding-top: 16px;
  min-width: 520px;
  margin: 0 auto;
  padding-bottom: 16px;

  .mimg {
    height: 360px;
    width: 100%;
  }
  .post {
    margin-left: 0px;
    width:30%;
    margin-right:10px;
  }
  >section .posts .post {
    width: 30%;
  }
  > section .posts .post:nth-child(2n) {
    margin-left: 0;
  }
  .footpc {
    width: 92%;
    min-width: 490px;
    z-index: 6;
    margin-left: 3px;
    position: static;
  }


}

.comic {
  p {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #cfcfcf;
    margin-top: 10px;

    span {

      img {
        width: 14px;
        height: 14px;
        position: relative;
        top: 2px;
      }
    }

    .reverse {
      float: right;
      margin-right: 16px;


    }
    .normal {
      float: right;

    }
    .grey-span {
      color: #9b9b9b;
    }
  }

  div {
    margin-top: 10px;

    span {
      display: inline-block;
      width: 48.2%;
      margin-right: 1.6%;
      height: 30px;
      border-radius: 4px;
      background-color: #f1f1f1;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 30px;
      color: #9b9b9b;
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }
}
.wrap {
  padding: 10px 10px 0;
  box-sizing: border-box;
}
</style>
