<template>
  <component
    :is="StarsMobile"
    v-if="isOnMobile"></component>
  <component
    :is="Stars"
    v-else></component>
</template>


<script>
import { useResponsive } from '@/composables/responsive'
import StarsMobile from '@/components/StarsMobile.vue'
import Stars from '@/components/Stars.vue'


export default {
  metaInfo: {
    title: 'My Stars | Perohub',
  },
  setup () {
    return {
      ...useResponsive(),
      StarsMobile,
      Stars,
    }
  },
}
</script>


<style lang="less" scoped>
</style>
