<template>
  <div class="review-container">
    <div
      v-for="artwork in artworks"
      :key="artwork._id"
      class="artwork">
      <div class="artist">
        {{ $t('submittedBy') }}
        <router-link :to="`/users/${artwork.user._id}`">@{{ artwork.user.nickname }}</router-link>
        <span>{{ format(artwork.createdAt, locale) }}</span>
      </div>

      <div class="medias">
        <div
          v-for="media in artwork.pictures"
          :key="media._id"
          class="media">
          <img
            :src="media.thum">
          <div
            v-if="media.type === MediaType.VIDEO"
            class="backdrop"></div>
          <img
            v-if="media.type === MediaType.VIDEO"
            class="video-tag"
            src="@/assets/Video.png">
        </div>
      </div>

      <p>{{ artwork.title }}</p>

      <hr>

      <div class="buttons">
        <div
          class="button green touchable"
          @click="approve(artwork._id)">
          <img src="@/assets/Audit_Pass.png">
          {{ $t('approve') }}
        </div>

        <div
          class="button touchable hoverable"
          @click="reject(artwork._id)">
          <img src="@/assets/Audit_Refuse.png">
          {{ $t('reject') }}
        </div>
      </div>
    </div>

    <load-more-button
      :has-more="hasMore"
      :busy="loading"
      :trigger="get"></load-more-button>
  </div>
</template>


<script>
//@ts-check
import { useChannelReview } from '@/composables/channel_review'
import { format } from 'timeago.js'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { MediaType } from '@/utils/defines'


export default {
  setup () {
    return {
      format,
      ...useChannelReview(),
      MediaType,
    }
  },
  components: {
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.review-container {
  width: 100%;

  @media @desktop {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.artwork {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;

  .artist {
    display: flex;
    align-items: baseline;
    color: @grey;
    font-size: 15px;
    margin-bottom: 0.5em;

    a {
      color: @blue;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      margin-left: auto;
      font-size: 10px;
    }
  }

  p {
    min-height: 1em;
    color: @black;
    font-size: 18px;
    font-weight: bolder;
  }

  .medias {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    padding-bottom: 0.5em;

    .scrollbar(@warm-grey, transparent);

    .media {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
      width: 4em;
      height: 4em;
      overflow: hidden;
      flex-shrink: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 4em;
        height: 4em;

        &.video-tag {
          position: initial;
          height: 3em;
          width: 3em;
          z-index: 1;
        }
      }

      .backdrop {
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }


  hr {
    border-color: @border;
    margin-bottom: 0.5em;
  }


  .buttons {
    display: flex;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: auto;
      flex: 1;
      margin: 0.2em;
      background-color: @border;
      border-radius: 4px;

      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      &.green {
        color: white;
        background-color: @green;
      }

      &:hover {
        filter: brightness(85%);
        cursor: pointer;
      }
    }
  }
}
</style>