<template>
  <div style="width: 100%; height: 100%;">
    <img
      v-show="false"
      ref="imageRef"
      :src="src">
  </div>
</template>


<script>
//@ts-check
import { onMounted, ref, watch, computed, onBeforeUnmount } from 'vue'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'


export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    }
  },
  setup (props) {
    const imageRef = ref(null)
    let viewer = null

    const currentUrl = computed(() => props.src)

    watch(imageRef, () => {
      if (imageRef.value) {
        load()
      }
    })

    watch(currentUrl, () => {
      if (viewer) {
        setTimeout(() => {
          viewer.update()
        }, 10)
      }
    })

    const load = () => {
      if (!viewer) {
        viewer = new Viewer(imageRef.value, {
          inline: true,
          button: false,
          toolbar: false,
          title: false,
          backdrop: false,
          transition: true,
        })
      }
    }

    onMounted(() => {
      if (imageRef.value) {
        load()
      }
    })

    onBeforeUnmount(() => {
      if (viewer) {
        viewer.hidden()
      }
    })

    return {
      imageRef
    }
  }
}
</script>

<style>
.viewer-navbar {
  display: none;
}
</style>