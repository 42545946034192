//@ts-check
import { ref } from "vue"
import { useToast } from "./toast"
import { getUploadUrl, upload } from '@/api/s3'

const getImageDimension = async function (file) {
  const image = new Image()

  return new Promise((resolve, reject) => {
    image.onload = function () {
      const width = image.width
      const height = image.height

      resolve({ width, height })

      URL.revokeObjectURL(image.src)
      image.onload = null
      image.src = ''
    }

    image.onerror = reject
    image.src = URL.createObjectURL(file)
  })
}


const dataURItoBlob = function (dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = decodeURI(dataURI.split(',')[1])
  }
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], {type:mimeString})
}

export const useCoverUpload = () => {
  const coverUrl = ref(null)
  const uploadProgress = ref(0)
  const fileInput = ref(null)
  const uploadingMeta = {
    name: '',
    size: 0,
    lastModified: 0,
    type: '',
  }
  const cropperShow = ref(false)
  const cropperSrc = ref('')
  const { Toast } = useToast()
  const uploadingCover = ref(false)
  let canvasRef = null


  const openCropper = function (file) {
    try {
      uploadingMeta.name = file.name
      uploadingMeta.size = file.size
      uploadingMeta.lastModified = file.lastModified
      uploadingMeta.type = file.type

      const fileReader = new FileReader()
      fileReader.onload = function () {
        cropperSrc.value = fileReader.result.toString()
        cropperShow.value = true
      }
      fileReader.readAsDataURL(file)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const onFileInput = async function (event) {
    try {
      const file = event.target.files[0]
      if (!file) {
        throw new Error('file not read')
      }
      if (!file.type.startsWith('image/')) {
        throw new Error('not an image')
      }
      const { width, height } = await getImageDimension(file)
      if (width !== height) {
        return openCropper(file)
      }

      uploadingMeta.name = file.name
      uploadingMeta.size = file.size
      uploadingMeta.lastModified = file.lastModified
      uploadingMeta.type = file.type
      await uploadFile(file)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      event.target.value = ''
    }
  }

  const uploadFile = async function (file) {
    try {
      coverUrl.value = ''
      if (!uploadingMeta.name || !uploadingMeta.type || !uploadingMeta.size || !uploadingMeta.lastModified) {
        throw new Error('lack uploading file meta')
      }
      const url = await getUploadUrl(uploadingMeta.name, uploadingMeta.type, uploadingMeta.size, uploadingMeta.lastModified)
      uploadingCover.value = true
      coverUrl.value = await upload(url, file, (percentage) => {
        uploadProgress.value = Number((percentage * 100).toFixed(2))
      })
      uploadingCover.value = false
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      uploadingCover.value = false
    }
  }

  const closeCropper = function () {
    cropperShow.value = false
    canvasRef = null

    uploadingMeta.name = ''
    uploadingMeta.size = 0
    uploadingMeta.lastModified = 0
    uploadingMeta.type = ''
  }

  const onCropperUpdated = function ({ canvas }) {
    if (canvasRef === canvas) {
      return
    }

    canvasRef = canvas
  }

  const performCropping = async function () {
    try {
      if (!canvasRef) {
        throw new Error('please operate the stencil')
      }

      const data = canvasRef.toDataURL()
      const file = dataURItoBlob(data)
      cropperShow.value = false
      await uploadFile(file)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  return {
    coverUrl,
    uploadProgress,
    fileInput,
    onFileInput,
    cropperShow,
    cropperSrc,
    openCropper,
    onCropperUpdated,
    performCropping,
    closeCropper,
    uploadingCover,
  }
}