import { api } from "./base"



export const getOrders = async function (page = 1, limit = 5) {
  try {
    const res = await api.get('/orders', {
      params: {
        page,
        limit
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const payOrder = async function (orderId, { processor, returnUrl, subProcessor }) {
  try {
    const res = await api.post(`/orders/${orderId}/pay`, {
      processor,
      returnUrl,
      subProcessor,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}