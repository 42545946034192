//@ts-check
import { defineStore } from "pinia"
import channelapi, { fetchMyChannels, fetchPosts, fetchSubscribedChannels, getChannelAlgoPosts } from "@/api/channel"
import Vue, { computed, ref } from 'vue'
import { useArtworkStore } from "@/pinia/artwork"
import { eventEmitter } from "@/utils"
import { events } from "@/utils/events"
// @ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'
import { useAuthenticator } from "./authenticator"
import { useToast } from "@/composables/toast"
import { useR18CoverStore } from "./r18cover"

const limit = 10

export const useChannelStore = defineStore('channel', () => {
  const manageableChannels = ref([])
  const channels = ref({}) // [channelId: channel]
  const subscribedChannels = ref([])
  const sourceChannelId = ref(null)
  const artworkStore = useArtworkStore()
  const authenticator = useAuthenticator()
  const r18coverStore = useR18CoverStore()
  const { Toast } = useToast()


  const sourceChannel = computed(() => {
    if (!sourceChannelId.value) {
      return null
    }

    return channels.value[sourceChannelId.value]
  })


  const fetchManageableChannels = async function () {
    try {
      manageableChannels.value = await fetchMyChannels()
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const _fetchChannel = async function ({ channelId, channelNumber }) {
    try {
      if (channelId) {
        const channel = channels.value[channelId]
        if (channel) {
          return
        }
      } else if (!channelNumber) {
        return
      }

      await authenticator.checkAuth()
      const channel = channelId ? await channelapi.fetchOne(channelId)
                                : await channelapi.fetchOneByNumber(channelNumber)

      const page = 1
      const [ users, topPosts ] = await Promise.all([
        channelapi.fetchUsers(channel._id, page),
        channelapi.fetchPromotedPosts(channel._id),
      ])

      topPosts.forEach(post => artworkStore.cacheArtwork(post))

      channel.users = users
      channel.latestPosts = [...topPosts]
      channel.bestPosts = []
      channel.hotPosts = []
      channel.algoPosts = []
      channel.latestPage = 1
      channel.hotPage = 1
      channel.bestPage = 1
      channel.algoPage = 1


      Vue.set(channels.value, channel._id, channel)

      const [ bestPosts, hotPosts, latestPosts, algoPosts ] = await Promise.all([
        channelapi.fetchPosts(channel._id, page, limit),
        channelapi.getChannelPostHot(page, limit, channel._id),
        channelapi.getChannelPostTime(page, limit, channel._id),
        getChannelAlgoPosts(channel._id, limit),
      ])

      if (bestPosts.length > 0) {
        channels.value[channel._id].bestPage += 1
      }
      if (hotPosts.length > 0) {
        channels.value[channel._id].hotPage += 1
      }
      if (latestPosts.length > 0) {
        channels.value[channel._id].latestPage += 1
      }
      if (algoPosts.length > 0) {
        channels.value[channel._id].algoPage += 1
      }

      channel.bestPosts = [...bestPosts]
      channel.hotPosts = [...hotPosts]
      // remove duplicated posts in [...topPosts, ...latestPosts]
      channel.latestPosts = Array.from(new Map([...topPosts, ...latestPosts].map(post => [post._id, post])).values())
      channel.algoPosts = [...algoPosts]

      latestPosts.forEach(post => artworkStore.cacheArtwork(post))
      hotPosts.forEach(post => artworkStore.cacheArtwork(post))
      bestPosts.forEach(post => artworkStore.cacheArtwork(post))
      algoPosts.forEach(post => artworkStore.cacheArtwork(post))

      return channel
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const fetchChannel = reusePendingPromise(_fetchChannel)

  const fetchSubscriptions = async function () {
    try {
      subscribedChannels.value = await fetchSubscribedChannels()
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const fetchChannelBestPosts = async function (channelId) {
    try {
      const posts = await fetchPosts(channelId, channels.value[channelId].bestPage)
      if (posts.length === 0) {
        return
      }
      const alreadyPostsIds = channels.value[channelId].bestPosts.map(post => post._id)
      const arr = [...channels.value[channelId].bestPosts]

      for (const post of posts.values()) {
        if (!alreadyPostsIds.includes(post._id)) {
          arr.push(post)
          alreadyPostsIds.push(post._id)
        }
      }

      channels.value[channelId].bestPosts = [...arr]
      channels.value[channelId].bestPage += 1
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const fetchChannelHotPosts = async function (channelId) {
    try {
      const posts = await channelapi.getChannelPostHot(channels.value[channelId].hotPage, limit, channelId)
      if (posts.length === 0) {
        return
      }
      const alreadyPostsIds = channels.value[channelId].hotPosts.map(post => post._id)
      const arr = [...channels.value[channelId].hotPosts]

      for (const post of posts.values()) {
        if (!alreadyPostsIds.includes(post._id)) {
          arr.push(post)
          alreadyPostsIds.push(post._id)
        }
      }

      channels.value[channelId].hotPosts = [...arr]
      channels.value[channelId].hotPage += 1
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const fetchChannelLatestPosts = async function (channelId) {
    try {
      let latestLimit = limit
      const channel = channels.value[channelId]
      if (channel.latestPage >= 0 && channel.latestPage <= 1) {
        latestLimit = 10
      }
      const posts = await channelapi.getChannelPostTime(channel.latestPage, latestLimit, channelId)
      if (posts.length === 0) {
        return
      }
      const alreadyPostsIds = channel.latestPosts.map(post => post._id)
      const arr = [...channel.latestPosts]

      for (const post of posts.values()) {
        if (!alreadyPostsIds.includes(post._id)) {
          arr.push(post)
          alreadyPostsIds.push(post._id)

          if (post.isR18) {
            r18coverStore.showR18Cover()
          }
        }
      }
      channel.latestPosts = [...arr]
      channel.latestPage += 1
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const fetchChannelAlgoPosts = async function (channelId) {
    try {
      const posts = await getChannelAlgoPosts(channelId, limit)
      if (posts.length === 0) {
        return
      }
      const alreadyPostsIds = channels.value[channelId].algoPosts.map(post => post._id)
      const arr = [...channels.value[channelId].algoPosts]

      for (const post of posts.values()) {
        if (!alreadyPostsIds.includes(post._id)) {
          arr.push(post)
          alreadyPostsIds.push(post._id)
        }
      }

      channels.value[channelId].algoPosts = [...arr]
      channels.value[channelId].algoPage += 1
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const setSourceChannelNumber = async function (channelNumber) {
    try {
      const channel = await fetchChannel({ channelNumber })
      if (channel) {
        sourceChannelId.value = channel._id        
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const removeArtwork = function (artworks, artworkIdToRemove) {
    for (const [index, artwork] of artworks.entries()) {
      if (artwork._id === artworkIdToRemove) {
        artworks.splice(index, 1)
      }
    }
  }

  const setAdded = function (channelId, isAdded) {
    if (channels.value[channelId]) {
      channels.value[channelId].isAdded = isAdded
    }
  }


  eventEmitter.on(events.ArtworkRemovedFromChannel, (artworkId, sourceChannelId) => {
    for (const channelId of Object.keys(channels.value)) {
      if (channelId === sourceChannelId) {
        const channel = channels.value[channelId]
        removeArtwork(channel.bestPosts, artworkId)
        removeArtwork(channel.hotPosts, artworkId)
        removeArtwork(channel.latestPosts, artworkId)
        return
      }
    }
  })

  eventEmitter.on(events.LogoutCompleted, () => {
    fetchSubscriptions()
  })

  eventEmitter.on(events.LoginCompleted, () => {
    fetchSubscriptions()
  })


  return {
    manageableChannels,
    fetchChannel,
    fetchSubscriptions,
    subscribedChannels,
    fetchChannelBestPosts,
    fetchChannelHotPosts,
    fetchChannelLatestPosts,
    fetchManageableChannels,
    fetchChannelAlgoPosts,
    channels,
    setSourceChannelNumber,
    sourceChannel,
    sourceChannelId,
    setAdded,
  }
})