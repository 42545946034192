//@ts-check
import { onMounted, ref, watch } from "vue"
import { createCircleGrant, cancelCircleGrant, getCircleGrants, getMonthNumbers } from "@/api/circle"
import { useRoute } from "vue-router/composables"
import { useToast } from "./toast"


export const useCircleGrant = () => {
  const circleRights = ref([])
  const circleId = useRoute().params.circleId
  const { Toast } = useToast()
  const allMonthNumbers = ref([])
  const monthNumbers = ref([])
  const popupVisible = ref(false)
  const loading = ref(false)
  const selectedUserId = ref(null)

  watch(popupVisible, () => {
    if (!popupVisible.value) {
      monthNumbers.value = []
      selectedUserId.value = null
    }
  })

  const get = async function () {
    try {
      circleRights.value = await getCircleGrants(circleId)
    } catch (err) {
      Toast(err.message)
    }
  }

  const remove = async function (circleRightId, monthNumbersToRemove) {
    try {
      loading.value = true
      await cancelCircleGrant(circleId, circleRightId, monthNumbersToRemove)
      await get()
    } catch (err) {
      Toast(err.message)
    } finally {
      loading.value = false
    }
  }

  const create = async function () {
    try {
      loading.value = true
      await createCircleGrant(circleId, selectedUserId.value, monthNumbers.value)
      await get()
      popupVisible.value = false
    } catch (err) {
      Toast(err.message)
    } finally {
      loading.value = false
    }
  }

  const initMonthNumbers = async function () {
    try {
      allMonthNumbers.value = await getMonthNumbers(circleId)
    } catch (err) {
      Toast(err.message)
    }
  }


  const getMonthNumberTitle = function (monthNumber) {
    const year = Math.floor(monthNumber / 12)
    const month = monthNumber % 12
    return `${year}-${month + 1}`
  }

  const toggleMonthNumber = function (monthNumber) {
    if (monthNumbers.value.includes(monthNumber)) {
      monthNumbers.value = monthNumbers.value.filter(v => v !== monthNumber)
    } else {
      monthNumbers.value.push(monthNumber)
    }
  }

  onMounted(() => {
    get()
    initMonthNumbers()
  })

  return {
    create,
    remove,
    circleRights,
    monthNumbers,
    popupVisible,
    getMonthNumberTitle,
    toggleMonthNumber,
    allMonthNumbers,
    loading,
    selectedUserId,
  }
}