<template lang="html">
  <div class="container">
    <p>在最新版 app 中，在「频道详情」中点击右上角的「...」可以分享频道。</p>
    <img src="@/assets/img@2x.png">
    <p>在分享链接中产生的收入，依然遵循分成规则。</p>
    <p>从频道中分享作品，产生的收入，依然遵循分成规则。</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "频道分享",
  },
}
</script>

<style scoped>
.container {
  padding: 0.6em;
}

p {
  margin-bottom: 0.6em;
}

img {
  width: 180px;
  height: 180px;
}
</style>
