<template>
  <div
    v-if="!isOnMobile"
    class="container">
    <ul class="left">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: index === selected }"
        @click="select(index)">
        {{ tab }}
      </li>
    </ul>

    <div class="right">
      <keep-alive>
        <search-result-posts v-if="selected === 0"></search-result-posts>
      </keep-alive>

      <keep-alive>
        <search-result-users v-if="selected === 1"></search-result-users>
      </keep-alive>

      <keep-alive>
        <search-result-channels v-if="selected === 2"></search-result-channels>
      </keep-alive>
    </div>
  </div>

  <div
    v-else
    class="mobile-container">
    <input
      ref="inputRef"
      v-model="q"
      type="text"
      :placeholder="$t('search')"
      @keyup.enter="doSearch">

    <mt-navbar
      v-model="selected"
      :fixed="true">
      <mt-tab-item
        v-for="(tab, index) in tabs"
        :id="index"
        :key="index">{{ tab }}</mt-tab-item>
    </mt-navbar>
    
    <mt-tab-container
      v-model="selected">
      <mt-tab-container-item
        :id="0"
        :key="0">
        <search-result-posts ref="searchPostsRef"></search-result-posts>
      </mt-tab-container-item>
      <mt-tab-container-item
        :id="1"
        :key="1">
        <search-result-users ref="searchUsersRef"></search-result-users>
      </mt-tab-container-item>
      <mt-tab-container-item
        :id="2"
        :key="2">
        <search-result-channels ref="searchChannelsRef"></search-result-channels>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import SearchResultChannels from '@/components/SearchResultChannels.vue'
import SearchResultPosts from '@/components/SearchResultPosts.vue'
import SearchResultUsers from '@/components/SearchResultUsers.vue'
import { useResponsive } from '@/composables/responsive'
import { t } from '@/translations'
import { addParamsToRoute } from '@/utils'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { useRoute } from 'vue-router/composables'


export default {
  metaInfo: {
    title: t('search'),
  },
  setup () {
    const selected = ref(0)
    const q = ref('')
    const { t } = useI18n()
    const tabs = [t('works'), t('users'), t('channels')]
    const route = useRoute()
    const searchPostsRef = ref(null)
    const searchUsersRef = ref(null)
    const searchChannelsRef = ref(null)
    const inputRef = ref(null)
    const isFocus = ref(false)


    watch([inputRef, isFocus], () => {
      if (isFocus.value && inputRef.value) {
        inputRef.value.focus()
      }
    })


    watch(selected, () => {
      if (isFocus.value && inputRef.value) {
        inputRef.value.focus()
      }
    })


    watch(q, () => {
      if (searchUsersRef.value) {
        searchUsersRef.value.page = 1        
      }
      if (searchChannelsRef.value) {
        searchChannelsRef.value.page = 1        
      }
      if (searchPostsRef.value) {
        searchPostsRef.value.page = 1        
      }
    })


    const select = function (value) {
      selected.value = value
      addParamsToRoute(route, {
        type: selected.value,
        q: q.value,
      })
    }

    const doSearch = function () {
      console.log('dosearch')
      addParamsToRoute(route, {
        q: q.value,
        type: selected.value,
      })
      if (searchUsersRef.value) {
        searchUsersRef.value.search(q.value)        
      }
      if (searchChannelsRef.value) {
        searchChannelsRef.value.search(q.value)        
      }
      if (searchPostsRef.value) {
        searchPostsRef.value.search(q.value)        
      }
    }


    onMounted(() => {
      if (typeof route.query.type !== 'undefined') {
        selected.value = Number(route.query.type)
        q.value = route.query.q
      }

      if (!q.value) {
        isFocus.value = true        
      }
    })


    return {
      selected,
      q,
      tabs,
      select,
      searchPostsRef,
      searchUsersRef,
      searchChannelsRef,
      doSearch,
      isFocus,
      inputRef,
      ...useResponsive()
    }
  },
  components: {
    SearchResultUsers,
    SearchResultPosts,
    SearchResultChannels
  },
}
</script>


<style lang="less" scoped>
.container {
  text-align: center;
  //margin-right: 8%;
  //margin-top: 12px;
  width: 1000px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  .left {
    display: inline-block;
    width: 120px;
    margin-right: 40px;
    vertical-align: top;

    li {
      display: block;
      height: 30px;
      width: 120px;
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 10px;

      &.active {
        color: #9b9b9b;
        border-radius: 15px;
        background-color: #f1f1f1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .right {
    display: inline-block;
    width: 816px;
    text-align: left;
  }
}
</style>


<style lang="less" scoped>
@import "@/assets/base.less";

.mobile-container {
  input {
    border: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3em;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    caret-color: @main;
    font-size: 17px;
    z-index: 2;
  }

  .mint-navbar {
    top: 51px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
  }

  a.mint-tab-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    color: @minor;
    text-align: center;
    border-radius: 15px;
    margin-right: 10px;
    padding: 0 10px;
    min-width: 80px;
    margin: 8px 0;
  }

  .mint-navbar .mint-tab-item.is-selected {
    background: @border;
    border: 1px solid @border;
    color: @minor;
  }

  .mint-tab-item-label {
    line-height: 29px;
    font-weight: 500;
    font-size: 14px;
    color: @minor;
  }

  .mint-tab-container-item {
    margin-top: calc(51px + 46px);
  }
}
</style>