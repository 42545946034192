<template>
  <div v-if="post">
    <div
      class="item-container item-container-common touchable block-hoverable"
      :class="{ gray: post.fake}"
      @click="$router.push(`/posts/${post._id}`)">
      <div class="item-header">
        <user-avatar
          class="item-avatar item-avatar-common"
          :class="{'item-avatar-pc-postlistitem': !isOnMobile}" 
          :user="post.user"
          :disabled="true"
          @click.stop.native="$router.push(`/users/${post.user._id}`)"></user-avatar>
        <h5
          class="item-nickname">
          {{ nickname }}
          <circle-badge
            v-if="post.user && post.user.tag && post.user.tag.visible"
            :member-tag="post.user.tag.memberTag"
            @click.native.stop="$router.push(`/user/${post.user.tag.authorId}/circles`)"></circle-badge>
        </h5>
        <span
          class="user-avatar-span">
          {{ createdAt }}&nbsp;&nbsp;
          {{ price === $t('free') ? $t('free') : priceText }}
        </span>
      </div>

      <img
        v-if="post.fake"
        class="fake-badge"
        src="@/assets/fake_original.png"
        alt="fake">

      <div
        class="title-common">
        <h2>
          {{ outofservice ? $t('outofservice', { countryCode }) : localeTitle }}
        </h2>
        <div class="item-indicators-container">
          <a
            v-if="post.likes > post.starCount || post.starCount === 0"
            class="indicator hoverable"
            @click.prevent.stop="like">
            <img
              v-if="!post.liked"
              src="@/assets/like.png">
            <img
              v-else
              src="@/assets/like_click.png">
            <span>{{ post.likes }}</span>
          </a>
          <a
            v-else
            class="indicator hoverable"
            @click.prevent.stop="intentStar">
            <img
              v-if="!post.starred"
              src="@/assets/star.png">
            <img
              v-else
              src="@/assets/star_click.png">
            <span>{{ post.hideStars ? '' : post.starCount }}</span>
          </a>
        </div>
      </div>

      <more-menu
        v-model="moreMenuVisible"
        :items="menuItems">
        <span
          class="more touchable hoverable more-common"
          @click.prevent.stop="moreMenuVisible = !moreMenuVisible"
          @mouseover="moreMenuVisible = true"
          @mouseleave="moreMenuVisible = false">
          <img src="@/assets/More_icon.png">
        </span>
      </more-menu>

      <post-list-item-overview v-if="!outofservice && post.pictures.filter(media => media.type === MediaType.IMAGE || media.type === MediaType.VIDEO || media.type === MediaType.OBJ).length > 0">
      </post-list-item-overview>

      <div
        v-if="!outofservice && firstPreviewTextMedia"
        style="padding: 0.25rem 0.8rem; color: #333;"
        class="markdown"
        v-html="firstPreviewTextMedia.textContent.slice(0, 500)">
      </div>

      <tags
        class="tag-over tag-common"
        :tags="post.tags"></tags>
    </div>
    <!--report-->
    <mt-popup
      v-if="reportShow"
      v-show="reportShow"
      :position="isOnMobile ? 'right' : 'middle'">
      <report
        :pics-component="post"
        :post-id-component="post._id"
        :user-id-component="post.user._id"
        :url="true"
        :nickname-component="post.user.nickname"
        @close="reportShow = false"
      />
    </mt-popup>
    <!--report-->

    <media-saver
      v-if="collectorShow"
      v-model="collectorShow"
      :post="post"
      @completed="onStarCompleted"></media-saver>
  </div>
</template>

<script>
//@ts-check
import Tags from '@/components/Tags.vue'
import PostListItemOverview from '@/components/PostListItemOverview.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import Report from '@/pages/Report.vue'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { usePostMoreMenu } from '@/composables/artwork_moremenu'
import { useResponsive } from '@/composables/responsive'
import { useArtworkActions } from '@/composables/artwork_action'
import MediaSaver from '@/components/MediaSaver.vue'
import MoreMenu from '@/components/MoreMenu.vue'
import { MediaType } from '@/utils/defines'
import { useCurrentCountry } from '@/composables/country'


export default {
  props: {
    post: {
      type: Object,
      required: true
    },
  },
  setup (props) {
    const { currency } = useCurrentCurrency()
    return {
      ...useCurrentCountry(),
      ...useArtworkPresent(props.post._id, currency, props.post),
      ...usePostMoreMenu(props.post),
      ...useResponsive(),
      ...useArtworkActions(props.post._id),
      MediaType,
    }
  },
  data () {
    return {
      moreMenuVisible: false,
    }
  },
  components: {
    PostListItemOverview,
    Tags,
    UserAvatar,
    CircleBadge,
    Report,
    MediaSaver,
    MoreMenu,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.item-container {
  width: 100%;
  margin-top: 1em;
  background: white;
  position: relative;
  border: solid 0.1rem @border;

  a {
    display: inline-block;
    font-size: 0.85em;

    &:first-child {
      margin-left: 5px;
    }
  }

  .more {
    position: absolute;
    right: 0.8rem;
    top: 15px;

    img {
      width: 20px;
    }
  }

  .tag-over {
    margin-top: 5px;
    margin-bottom: 0.4em;
  }

  .tag-common {
    margin-bottom: 0px;
  }
}

.title-common {
  position: relative;
  display: flex;
  align-items: flex-start;

  h2 {
    font-size: 17px;
    color: @minor;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    display: -webkit-box;          /* Required for the line-clamp to work */
    -webkit-line-clamp: 2;         /* Number of lines you want to display */
    -webkit-box-orient: vertical;  /* Required for the line-clamp to work */
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
  }

  a {
    font-size: 0.6rem;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 0.8rem;
    }
  }
}


.item-header {
  text-align: left;

  span {
    position: absolute;
    left: 6.1em;
    top: 3.4em;
    font-size: 12px;
    color: @grey;
  }
  .user-avatar-span {
    left: 5.9em;
  }

  .item-avatar {
    width: 50px;
    height: 50px;
    margin-left: 0.85em;
    margin-top: 0.85em;
    border-radius: 25px;
  }

  .item-avatar-pc-postlistitem {
    margin-left: 0em;
  }

  .item-avatar-common {
    margin-left: 0.4em;
  }

  .item-nickname {
    position: absolute;
    left: 4.6em;
    top: 1.3em;
    font-size: 15px;
    color: @black;
    font-weight: 500;
  }
}


.item-indicators-container {
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0.8rem;

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      margin-right: 0.6em;
    }

    span {
      font-size: 12px;
      color: @grey;
    }
  }
}

.gray {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.fake-badge {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 120px;
  z-index: 10;
}

a {
  span {
    color: #B4B4B4;
    vertical-align: middle;
    margin-left: -3px;
  }

  img {
    width: 32px;
    vertical-align: middle;
  }
}


.item-container-common {
  margin-top: 0px;
  padding-bottom: 0.4em;
}
</style>
