<template>
  <div class="profile-container">
    <user-info :user="currentUser"></user-info>

    <mt-navbar
      v-if="currentUser.authed || allowPirate"
      v-model="selected"
      class="segmented-control">
      <mt-tab-item
        :id="0"
        class="touchable hoverable">
        {{ $t('myworks') }}
      </mt-tab-item>
      <mt-tab-item
        :id="1"
        class="touchable hoverable">
        {{ $t('purchased') }}
      </mt-tab-item>
    </mt-navbar>
    <mt-tab-container
      v-if="currentUser.authed || allowPirate"
      v-model="selected">
      <mt-tab-container-item :id="0">
        <div style="display: flex; gap: 1rem;">
          <pr-button
            v-if="!currentUser.authed"
            type="primary"
            class="wide"
            @click.native="contactTwitter">{{ $t('applyVerify') }}</pr-button>
          <pr-button
            type="primary"
            class="wide"
            @click.native="$router.push('/i/publisher/publish')">{{ $t('publish') }}</pr-button>
        </div>
        <post-list-mobile
          v-if="isOnMobile"
          :posts="myArtworks"></post-list-mobile>
        <post-list-pc
          v-else
          :posts="myArtworks"></post-list-pc>
        <load-more-button
          :busy="myLoading"
          :has-more="myHasmore"
          :trigger="getMyPosts"></load-more-button>
      </mt-tab-container-item>

      <mt-tab-container-item :id="1">
        <post-list-mobile
          v-if="isOnMobile"
          :posts="purchasedArtworks"></post-list-mobile>
        <post-list-pc
          v-else
          :posts="purchasedArtworks"></post-list-pc>
        <load-more-button
          :busy="buyLoading"
          :has-more="buyHasmore"
          :trigger="getBoughtPosts"></load-more-button>
      </mt-tab-container-item>
    </mt-tab-container>

    <div
      v-else
      style="padding-top: 1rem;">
      <pr-button
        type="primary"
        class="wide"
        @click.native="contactTwitter">{{ $t('applyVerify') }}</pr-button>
      <h2 style="margin: 1rem 0">{{ $t('purchased') }}</h2>
      <post-list-mobile
        v-if="isOnMobile"
        :posts="purchasedArtworks"></post-list-mobile>
      <post-list-pc
        v-else
        :posts="purchasedArtworks"></post-list-pc>
      <load-more-button
        :busy="buyLoading"
        :has-more="buyHasmore"
        :trigger="getBoughtPosts"></load-more-button>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/components/UserInfo.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostListPc from '@/components/PostListPc.vue'
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import PostListMobile from '@/components/PostListMobile.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useUserMypage } from '@/composables/user_mypage'
import { t } from '@/translations'
import { allowPirate } from '@/config'


export default {
  metaInfo: {
    title: t('mypage'),
  },
  setup () {
    const contactTwitter = function () {
      window.open('https://twitter.com/perohubcom')
    }

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      ...useUserMypage(),
      allowPirate,
      contactTwitter,
    } 
  },
  components: {
    UserInfo,
    LoadMoreButton,
    PostListPc,
    PrButton,
    PostListMobile,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.profile-container {
  .wide {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1em;
  }

  .segmented-control {
    margin-left: 1em;
    margin-right: 1em;
  }

  .mint-tab-container-item {
    padding-top: 1em;
  }
}
</style>
