//@ts-check
import { useAuthenticator } from "@/pinia/authenticator"
import { ref } from "vue"

export const useLoginPassword = () => {
  const authenticator = useAuthenticator()
  const email = ref('')
  const password = ref('')
  const loginLoading = ref(false)

  const loginPassword = async () => {
    try {
      loginLoading.value = true
      await authenticator.loginPassword({ email: email.value, password: password.value })
    } catch (error) {
      console.error(error)
    } finally {
      loginLoading.value = false
    }
  }

  return {
    email,
    password,
    loginPassword,
    loginLoading,
  }
}