<template>
  <div
    class="container">
    <mt-navbar
      v-model="selected"
      class="segmented-control">
      <mt-tab-item
        :id="0"
        class="touchable"
        :class="{ hoverable: !isOnMobile }">
        {{ $t('overview') }}
      </mt-tab-item>
      <mt-tab-item
        :id="1"
        class="touchable"
        :class="{ hoverable: !isOnMobile }">
        {{ $t('detail') }}
      </mt-tab-item>
      <mt-tab-item
        :id="2"
        class="touchable"
        :class="{ hoverable: !isOnMobile }">
        {{ $t('payout') }}
      </mt-tab-item>
    </mt-navbar>

    <mt-tab-container v-model="selected">
      <mt-tab-container-item :id="0">
        <chart></chart>
      </mt-tab-container-item>

      <mt-tab-container-item :id="1">
        <sheet></sheet>
      </mt-tab-container-item>

      <mt-tab-container-item
        v-if="currentUser.authed"
        :id="2">
        <withdraw></withdraw>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import Chart from '@/components/Bill.Chart.vue'
import Sheet from '@/components/Bill.Sheet.vue'
import Withdraw from '@/components/Bill.Withdraw.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useResponsive } from '@/composables/responsive'


export default {
  metaInfo: {
    title: "Bills",
  },
  setup () {
    return {
      ...useCurrentUser(),
      ...useResponsive(),
    }
  },
  data () {
    return {
      selected: 0,
    }
  },
  components: {
    Chart,
    Sheet,
    Withdraw
  },
}
</script>
