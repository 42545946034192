//@ts-check
import { computed, ref } from "vue"
import postapi from "@/api/post"
// @ts-ignore
import { Indicator } from 'mint-ui'
import { useArtworkStore } from "@/pinia/artwork"
import { APP_URL, cdn } from "@/config"
import { useArtworkPurchase } from "@/composables/artwork_purchase"
import { useApplication } from "@/pinia/application"
import { useCurrentUser } from "@/composables/user_data"
import { useToast } from "./toast"
import localforage from "localforage"
import { ACCESSTOKEN_KEY } from "@/pinia/authenticator"
import { useArtworkCircle } from "./artwork_circle"
import { useRouter } from "vue-router/composables"
import { useConfirm } from "./confirm"
import { createZipTask } from "@/api/ziptask"
import { useI18n } from "vue-i18n-composable"
import { getCDNHost } from "@/utils"
import { MediaType } from "@/utils/defines"


export const useArtworkActions = function (postId) {
  const collectorShow = ref(false)
  const artworkStore = useArtworkStore()
  const { buyPost } = useArtworkPurchase(postId)
  const { openSocialSharing, showLogin } = useApplication()
  const { authenticated } = useCurrentUser()
  const application = useApplication()
  const { Toast, showToastWithButtons } = useToast()
  const { circle } = useArtworkCircle(postId)
  const router = useRouter()
  const { confirm } = useConfirm()
  const { t } = useI18n()


  const post = computed(() => {
    return artworkStore.artworks[postId]
  })


  const videoIncluded = computed(() => {
    if (!post.value) {
      return false
    }

    return post.value.pictures.filter(picture => picture.type === MediaType.VIDEO).length > 0
  })

  const browsableMedias = computed(() => {
    if (!post.value) {
      return []
    }

    return post.value.pictures.filter(media => {
      if (media.type === MediaType.FILE) {
        return false
      }

      return true
    })
  })


  const intentStar = function () {
    if (!post.value) {
      return
    }

    if (!authenticated.value) {
      return showLogin()
    }

    if (post.value.price > 0) {
      if (post.value.requiresCircleRight) {
        if (!circle.value.isMember && !post.value.paid) {
          return router.push(`/circles/${circle.value._id}`)
        }
      } else if (!post.value.paid) {
        return unlock()
      }
    }

    collectorShow.value = true
  }


  const like = async function () {
    try {
      if (!authenticated.value) {
        return showLogin()
      }

      if (post.value.liked) {
        return
      }

      Indicator.open()
      await postapi.like(postId)
      artworkStore.like(postId)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      Indicator.close()
    }
  }


  const share = async function () {
    try {
      const url = `${APP_URL}/posts/${postId}`
      openSocialSharing(post.value.title, url)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const unlock = function () {
    if (circle.value) {
      if (post.value.requiresCircleRight) {
        if (!circle.value.isMember && !post.value.paid) {
          return buyPost({ postId })
        } else {
          return router.push(`/circles/${circle.value._id}`)
        }
      } else {
        return confirm({
          message: t('unlockCirclePrompt', {
            title: circle.value.title,
          }),
          accept () {
            buyPost({ postId })
          },
          reject () {
            router.push(`/circles/${circle.value._id}`)
          },
          rejectLabel: t('unlockCirclePromptAccept'),
          acceptLabel: t('unlockCirclePromptReject'),
        })
      }
    }

    return buyPost({ postId })
  }


  const view = function (index) {
    if (post.value.fake) {
      return
    }

    const media = post.value.pictures[index]

    if (circle.value) {
      if (post.value.requiresCircleRight) {
        if (circle.value.isMember || post.value.paid) {
          return application.openBrowser(browsableMedias.value, index, post.value._id)
        } else {
          return router.push(`/circles/${circle.value._id}`)
        }
      } else {
        if (post.value.paid || post.value.price === 0) {
          if (media.type === MediaType.VIDEO) {
            return false
          }

          return application.openBrowser(browsableMedias.value, index, post.value._id)
        }

        return confirm({
          message: t('unlockCirclePrompt', {
            title: circle.value.title,
          }),
          accept () {
            buyPost({ postId })
          },
          reject () {
            router.push(`/circles/${circle.value._id}`)
          },
          rejectLabel: t('unlockCirclePromptAccept'),
          acceptLabel: t('unlockCirclePromptReject'),
        })
      }
    }

    if (post.value.price !== 0) {
      if (!post.value.paid && videoIncluded.value) {
        return buyPost({ postId })
      }

      if (!post.value.paid && !post.value.pictures[index].canBrowse) {
        return buyPost({ postId })
      }
    }

    if (media.type === MediaType.VIDEO) {
      return false
    }

    if (media.type === MediaType.OBJ) {
      return router.push(`/posts/${postId}`)
    }

    application.openBrowser(browsableMedias, index, post.value._id)
  }


  const downloadZip = async function () {
    try {
      if (!authenticated.value) {
        return Toast('please login', 'info')
      }
  
      if (post.value.prices['USD'] !== 0 && !post.value.paid) {
        return buyPost({ postId })
      }
  
      Indicator.open()
      await createZipTask(postId)
      Indicator.close()
      const close = showToastWithButtons({
        message: t('downloadCreated'),
        confirmLabel: t('goToDownloads'),
        rejectLabel: t('cancel'),
        onReject () {
          close()
        },
        onConfirm () {
          router.push('/i/downloads')
        },
        type: 'ok',
      })
    } catch (err) {
      Toast(err.message)
    } finally {
      Indicator.close()
    }
  }


  const downloadRawFile = async function (mediaId) {
    if (post.value.price !== 0 && !post.value.paid) {
      return buyPost({ postId })
    }

    const url = new URL(getCDNHost())
    url.pathname = 'raw'
    const token = await localforage.getItem(ACCESSTOKEN_KEY)
    url.searchParams.append('token', token)
    url.searchParams.append('media_id', mediaId)
    const newWindow = window.open(url.toString())
    if (!newWindow) {
      location.href = url.toString()
    }
  }


  const onStarCompleted = function () {
    artworkStore.star(postId)
  }


  return {
    intentStar,
    like,
    collectorShow,
    share,
    view,
    onStarCompleted,
    downloadZip,
    downloadRawFile,
    unlock,
  }
}