<template>
  <div class="container">
    <report-list :reports="reports" />

    <load-more-button
      :busy="busy"
      :has-more="hasMore"
      :trigger="fetch"
    />
  </div>
</template>

<script>
import ReportList from '@/components/ReportList.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import api from '@/api/report'
import { useToast } from '@/composables/toast'


const limit = 10

export default {
  setup () {
    return {
      ...useToast(),
    }
  },
  components: {
    ReportList,
    LoadMoreButton
  },
  data () {
    return {
      hasMore: true,
      busy: false,
      reports: [],
      page: 1
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.busy = true
      api
        .getAll(this.page, limit)
        .then(reports => {
          this.busy = false
          this.page += 1

          reports.forEach(report => this.reports.push(report))

          if (reports.length === 0) {
            this.hasMore = false
          }
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
