<template>
  <div class="article">
    <h1>{{ $t('createOnPerohub') }}</h1>
    <h2>
      {{ $t('promoteLineOne') }}
    </h2>

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Features</th>
            <th>Perohub</th>
            <th>Fanbox</th>
            <th>Fantia</th>
            <th>Patreon</th>
            <th>Gumroad</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('globalPaymentsSupport') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
          </tr>
          <tr>
            <td>{{ $t('displayOnWebsite') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td>{{ $t('getPaidMonthly') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td>{{ $t('sellSingleItems') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
          </tr>
          <tr>
            <td>{{ $t('getPaidForPast') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">{{ $t('alipaySupport') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">{{ $t('watermarkSupport') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">{{ $t('realtimePayout') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">{{ $t('usdtPayout') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
          <tr>
            <td>{{ $t('directSupport') }}</td>
            <td><img src="@/assets/Select_On.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
            <td><img src="@/assets/Select_Off.png"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="action-container">
      <pr-button
        v-if="authenticated"
        type="primary"
        @click.native="$router.push('/i/publisher/verify')">
        {{ $t('createOnPerohub') }}
      </pr-button>
      <pr-button
        v-else
        type="primary"
        @click.native="showLogin">
        {{ $t('login') }}
      </pr-button>
    </div>
  </div>
</template>

<script>
//@ts-check
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n-composable'
import PrButton from '@/components/PRButton.vue'
import { useApplication } from '@/pinia/application'


export default {
  setup() {
    const { t } = useI18n()
    const { showLogin } = useApplication()
    const { authenticated } = useCurrentUser()

    useHead({
      title: t('createOnPerohub')
    })

    return {
      authenticated,
      showLogin,
    }
  },
  components: {
    PrButton
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.article {
  width: 960px;
  margin: 0 auto;

  @media @phone {
    width: 100%;
  }
}

.table-container {
  overflow-x: auto;
  margin: 20px 0;
}

table {
  border-collapse: collapse;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  font-size: 0.9rem;
  color: @black;
  width: auto;
  table-layout: fixed;

  tbody tr td:first-child {
    text-align: left;
  }
}

tbody tr td:first-child,
thead tr th:first-child,
thead tr td:first-child {
  text-align: left;
  width: 25rem;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  width: 8rem;

  img {
    width: 1.5rem;
    margin-top: 0.5rem;
  }
}

thead {
  background-color: @listview-background;
  color: @black;

  th {
    text-align: center;
    font-weight: bold;
  }
}

tr:nth-child(even) {
  background-color: @listview-background;
}

.action-container {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
}
</style>