<template>
  <div class="banned">
    <img src="@/assets/earth.png">
    <p>{{ $t('banMessage', { username: user.nickname, reason: user.bannedReason }) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.banned {
  margin-top: 6em;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: @black;

  img {
    width: 180px;
  }

  p {
    margin-top: 2em;
  }
}
</style>
