//@ts-check
import { api } from './base'


export const isGiftCodeValid = async function (giftcode, {
  artworkId
} = { artworkId: undefined }) {
  try {
    const res = await api.get('/giftcodes', {
      params: {
        giftcode,
        artworkId
      }
    })

    return res.data.result.ok
  } catch (err) {
    console.error(err)
    throw err
  }
}