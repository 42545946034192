<template>
  <LineChart
    :data="chartData"
    :options="{ responsive: true }"
  />
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)


const getGradientColor = function (context) {
  const chart = context.chart
  const { ctx, chartArea } = chart
  if (!chartArea) {
    return
  }

  const gradient = ctx.createLinearGradient(0, 0, 0, 450)
  gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)')
  gradient.addColorStop(.5, 'rgba(255, 0, 0, 0.25)')
  gradient.addColorStop(1, 'rgba(145, 67, 204, 0.46)')

  return gradient
}


export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
    }
  },
  mounted () {
    const { labels, datasets } = this.data

    this.chartData = {
      labels,
      datasets: [{
        label: datasets[0].label,
        data: datasets[0].data,
        backgroundColor: getGradientColor,
        borderColor: getGradientColor,
      }]
    }
  },
  components: {
    LineChart,
  }
}
</script>
