<template>
  <div class="container">
    <p class="title">
      {{ topic.title }}
    </p>

    <div
      v-if="!isOnMobile"
      class="common">
      <post-list-pc :posts="posts"></post-list-pc>
      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="getPosts"></load-more-button>
    </div> 
    

    <!--<header v-if="topic.coverUrl && !isOnMobile">
      <img :src="topic.coverUrl" :alt="topic.title">
    </header>-->

    <div
      v-if="isOnMobile"
      class="posts">
      <post-list-mobile :posts="posts"></post-list-mobile>
      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="getPosts"></load-more-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/explore'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostListPc from '@/components/PostListPc.vue'
import PostListMobile from '@/components/PostListMobile.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'


export default {
  metaInfo () {
    return {
      title: `${this.topic.title} | Perohub`,
      meta: [
        { name: 'description', content: `Perohub,pero,应援团,福利姬,二次元福利,原创作品社区|${this.topic.description} | Perohub` },
        { name: 'keywords', content: `Perohub,pero,应援团,福利姬,二次元福利,原创作品社区|${this.topic.title} | Perohub` }
      ]
    }
  },
  setup () {
    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.matched.length > 0 && to.matched[0].path !== '/posts/:postId') {
      this.clear()
    }

    next()
  },
  data () {
    return {
      posts: [],
      topic: {},
      hasMore: true,
      busy: false,
      page: 1,
      topicId: '',
    }
  },
  activated () {
    if (this.topicId !== this.$route.params.topicId) {
      this.topicId = this.$route.params.topicId

      api
        .getTopic(this.$route.params.topicId)
        .then(topic => {
          this.topic = topic
          //document.title = topic.title
        })

      this.getPosts()
    }
  },
  methods: {
    getPosts () {
      this.busy = true

      api
        .getTopicPosts(this.$route.params.topicId, this.page, 10)
        .then(posts => {
          this.busy = false

          if (this.page === 1) {
            this.posts = posts
          } else {
            this.posts = [...this.posts, post]
          }

          if (posts.length === 0) {
            this.hasMore = false
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    clear () {
      this.posts = []
      this.topic = {}
      this.hasMore = true
      this.busy = false
      this.page = 1
      this.topicId = ''
    }
  },
  components: {
    LoadMoreButton,
    PostListPc,
    PostListMobile,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  margin-top: 28px;
  margin-left: 10px;
  margin-right: 10px;

  header {
    img {
      display: block;
      width: 100%;
      border-radius: 4px;
      border: solid 1px @border;
      object-fit: cover;
    }
  }
}

.title {
  height: 21px;
  font-family: PingFang-SC;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-bottom: 10px;
  padding-left: 4px;
}

@media (min-width: 800px) { 
  .container {
    width: 816px;
    margin-left: auto;
    margin-right: auto;

    
    .common {
      width: 32%;
      min-width: 810px;
      margin: 0px auto;
      text-align: left;
    }
  }
}
</style>
