<template>
  <div
    class="report-wrap"
    :class="{'report-wrap-web' : url}">
    <mt-header
      v-if="url"
      fixed>
      <a slot="left">
        <pr-button
          class="back-report"
          icon="back"
          :class="{ hoverablebtn: !isOnMobile }"
          @click.native="disappear"></pr-button>
      </a>
    </mt-header>
    <p
      v-if="nickname"
      :class="{'p-padding': url}"
      class="author">
      Report
      <span
        :class="{ hoverablebtn: !isOnMobile }"
        @click="$router.push(`/users/${userId}`)">
        @{{ nickname }}
      </span>
      's Artwork
    </p>
    <div
      v-show="false"
      :class="{ hoverablebtn: !isOnMobile }"
      class="yellow touchable"
      @click="showcontent">
      淫秽色情
      <img
        v-if="!yellowshow"
        src="@/assets/ic-page-cell-unfold@2x.png">
      <img
        v-else
        src="@/assets/ic-page-cell-fold@2x.png">
    </div>
    <div v-show="false && yellowshow">
      <mt-radio
        v-model="reason"
        class="choice"
        :options="pornOptions"></mt-radio>

      <content-selector
        v-if="!query"
        v-show="becauseOfPorn"
        :pics="pics"
        :paid="paid"
        :post-id="postIdComponent"
        @valuechange="selectionChanged"></content-selector>
      <content-selector
        v-if="query"
        v-show="becauseOfPorn"
        :pics="pics"
        :paid="paid"
        :post-id="postIdComponent"
        @valuechange="selectionChanged"></content-selector>
    </div>
    <div
      :class="{ hoverablebtn: !isOnMobile }"
      class="yellow touchable"
      @click.stop="showhurt">
      Piracy
      <img
        v-if="!hurtshow"
        src="@/assets/ic-page-cell-unfold@2x.png">
      <img
        v-else
        src="@/assets/ic-page-cell-fold@2x.png">
    </div>

    <div v-show="hurtshow">
      <mt-radio
        v-model="reason"
        class="choice choicemargin"
        title=""
        :options="otherOptions"></mt-radio>
      <p class="power-p">
        Source
      </p>
      <textarea
        v-model="piracy"
        class="choice textarea last"
        rows="3"
        label="Piracy"
        type="textarea"
        placeholder="source link"
        @keypress="textareaKeyPress"
        @focus="focusPricy"></textarea>
    </div>

    <div
      :class="{ hoverablebtn: !isOnMobile }"
      class="yellow touchable"
      @click.stop="showotherreason">
      Other Reasons
      <img
        v-if="!otherreasonshow"
        src="@/assets/ic-page-cell-unfold@2x.png">
      <img
        v-else
        src="@/assets/ic-page-cell-fold@2x.png">
    </div>
    <div v-show="otherreasonshow">
      <textarea
        ref="otherReason"
        v-model="otherReason"
        class="choice textarea last"
        rows="3"
        type="textarea"
        placeholder="Please input other reasons"
        @keypress="textareaKeyPress"></textarea>
    </div>

    <pr-button
      v-show="showButton"
      :loading="reporting"
      :class="{ hoverablebtn: !isOnMobile }"
      @click.native.stop="report">
      {{ $t('report') }}
    </pr-button>
    <p
      class="isyellow hoverable touchable"
      @click="$router.push(`/rules?chapter=Censorship`)">
      《Perohub {{ $t('communityGuideline') }}》
    </p>
    <p
      v-show="!showButton"
      class="report-p">
      {{ $t('report') }} {{ $t('success') }}
    </p>
  </div>
</template>

<script>
import ContentSelector from '@/components/ContentSelector.vue'
import api from '@/api/post'
import reportapi from '@/api/report'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import PrButton from '@/components/PRButton.vue'


export default {
  props: {
    postIdComponent: {
      type: String,
      required: true,
    },
    nicknameComponent: {
      type: String,
      required: true,
    },
    userIdComponent: {
      type: String,
      required: true,
    },
    picsComponent: {
      type: Object,
      required: true,
    },
    url: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close'],
  setup () {
    const { Toast } = useToast()
    const reporting = ref(false)

    const reportPost = async function ({ postId, reason, contentIds }) {
      try {
        if (!postId) {
          return Toast({
            message: 'missing params',
          })
        }

        if (!reason) {
          return Toast({
            message: 'no reason',
          })
        }
        reporting.value = true
        await reportapi.send(postId, reason, contentIds)
        Toast('report success', 'ok')
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      } finally {
        reporting.value = false
      }
    }

    return {
      reporting,
      reportPost,
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      postId: '',
      reason: '',
      otherReason: '',
      piracy: '',
      contentIds: [],
      pornOptions: [
        '露点',
        '露毛露晕',
        '以上两者的半透明遮挡',
        '对性爱过程的描述，如声音、自慰动作与对下体进行刺激的动作',
        '性爱玩具与人接触，如按摩棒乳夹',
        '低俗内容，如放尿、姨妈与内分泌物',
        '张开双腿特写下体',
        '令人不适的未成年人内容',
        '揉胸视频',
        '非艺术性大面积裸露'
      ],
      otherOptions: ['Sneak shots violating others\' portrait rights', 'Unauthorized reposting'],
      showButton: true,
      yellowshow: false,
      hurtshow: false,
      nickname: '',
      userId: '',
      otherreasonshow: false,
      pics: [],
      //postpics: [],
      paid: false,
      query: false,
      free: true
    }
  },
  computed: {
    becauseOfPorn () {
      return this.pornOptions.filter(option => option === this.reason).length > 0
    },
    postpics () {
      let postpic = []

      this.picsComponent.pictures.forEach(picture => {
        picture.selected = false
        postpic.push(picture)
      })
      return postpic

    },
  },
  watch: {
    otherReason (value) {
      this.reason = value
    },
    piracy (value) {
      this.reason = `侵权内容：${value}`
    },
    query (value) {
      if (value) {
        api
          .getOne(this.postId)
          .then(post => {
            this.paid = post.paid
            if (post.price > 0) {
              this.free = false
            } else {
              this.free = true
            }
            post.pictures.forEach((pic)=>{
              pic.selected = false
            })
            this.pics = post.pictures

          })
          .catch(err => {
            console.error(err)
          })
      }
    },
  },
  methods: {
    focusPricy () {
      if (this.piracy == '') {
        this.piracy = 'https://'
      }
    },
    textareaKeyPress (evt) {
      if (this.query) {
        if (!this.free && !this.paid) {
          evt.preventDefault()
          evt.stopPropagation()
          this.showToast({
            message: '请购买以后在填写举报原因',
          }, 'info')
          //this.otherReason = ''
          return false
        } else {
          return true
        }
      } else {
        if (!this.free && !this.paid) {
          evt.preventDefault()
          evt.stopPropagation()
          this.showToast({
            message: '请购买以后在填写举报原因',
          }, 'info')
          //this.otherReason = ''
          return false
        } else {
          return true
        }
      }

    },
    disappear () {
      //this.$parent.report()
      if (this.url) {
        if (this.$parent.$parent) {
          this.$emit('close')
        }
      }
    },
    showcontent () {
      this.yellowshow = !this.yellowshow
    },
    showhurt () {
      this.hurtshow = !this.hurtshow
    },
    showotherreason () {
      this.otherreasonshow = !this.otherreasonshow
    },
    report () {
      const postId = this.postId
      let reason = this.reason
      if (this.piracy !='') {
        var reg=/(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
        if(!reg.test(this.piracy)){ 
          this.showToast({
            message: '侵权内容是个网址哦',
          }, 'warn')

          return

        }

        
        reason += ' 侵权内容： ' + this.piracy
      }

      
     
      const contentIds = this.contentIds
     
      if (this.becauseOfPorn && contentIds.length === 0) {
        return this.showToast({
          message: '请选择证据',
        }, 'info')
      }

      this
        .reportPost({ postId, reason, contentIds })
        .then(success => {
          if (success === true) {
            this.showButton = false
            if (this.url) {
              setTimeout(()=>{
                this.$parent.$parent.report()
              }, 1000)
            }
          }
        })
    },

    selectionChanged (contentIds) {
      this.contentIds = contentIds
    },
    cloneObj (obj){
      var str, newobj = obj.constructor === Array ? [] : {};
      if(typeof obj !== 'object'){
          return;
      } else if(window.JSON){
          str = JSON.stringify(obj), //系列化对象
          newobj = JSON.parse(str); //还原
      } else {
          for(var i in obj){
              newobj[i] = typeof obj[i] === 'object' ?
              cloneObj(obj[i]) : obj[i];
          }
      }
      return newobj;
    }


  },
  mounted () {

    //document.title = '举报'

    // 手机 app 的 postId 是通过 query 传进来的
    if (this.$route.query.postId) {
      this.postId = this.$route.query.postId
      this.query = true
      document.title = '举报'
    }
    //console.info(this.$route.query.)

    if (this.$route.params.postId) {
      this.query = true
      this.postId = this.$route.params.postId
    }

    if (this.$route.query.nickname) {
      this.nickname = this.$route.query.nickname
    }

    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId
    }

    if (this.url) {
      this.postId = this.picsComponent._id
      this.nickname = this.picsComponent.user.nickname
      this.userId = this.picsComponent.user._id
      this.paid = this.picsComponent.paid
      let pica = this.cloneObj(this.picsComponent.pictures)
      pica.forEach((pic)=>{
        pic.selected = false
      })
      this.pics = pica
    }


  },
  components: {
    ContentSelector,
    PrButton,
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";
.mint-popup:has(.report-wrap) {
  z-index: 10;

  @media @phone {
    height: 100%;
    width: 100%;
  }
}

.power-p {
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  padding-left: 4px;
  margin-bottom: 4px;
}
.report-wrap {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  z-index: 10;
  margin-bottom: 10px;
  box-sizing: border-box;

  textarea::-webkit-input-placeholder {
    color: #cfcfcf;
  }
  .hoverablebtn:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  .mint-header.is-fixed {
    top: 0px;
  }
  .mint-header .mint-button {
    text-align: left;
  }
  .mint-cell-wrapper {
    border-bottom: 1px solid @border;
    padding: 0px;
    font-weight: 500;
   }

  .mint-radiolist-label {
      padding: 0px;
  }
  .mint-radiolist-label:hover {
    cursor: pointer;
    opacity: 0.65;
  }
  .mint-radio-core {
    position: absolute;
    right: 0px;
    background-image: url("@/assets/ic-page-switch-normal@2x.png");
    background-size:24px 24px;
    background-position: center center;
    border: 0px;
  }
  .mint-radio-input:checked + .mint-radio-core {
    background-image: url("@/assets/ic-page-switch-selected@2x.png");
    background-size:24px 24px;
    background-color: transparent;
  }
  .mint-radio-input:checked + .mint-radio-core::after {
    background-color: transparent;
  }
  .mint-radiolist-title {
    display: none;
  }
  .mint-radiolist-title :hover {
    cursor: pointer;
    opacity: 0.65;
  }
  .textarea .mint-cell-wrapper {
    display: block;
    font-weight: bold;
    font-size: 14px;
    padding-left: 6px;
  }

  .textarea .mint-field-core {
    font-size: 17px;
    resize: none;
  }
  .mint-field .mint-cell-title {
    width: 180px !important;
  }
  .mint-radiolist-label {
    padding-right: 20px;
    padding-left: 6px;
  }
  .mint-radio-label {
    margin-left: 0px;
  }
  .law {
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    color: @grey;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: relative;
      vertical-align: middle;
    }
  }
  .yellow {
    position: relative;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid @border;
    font-size: 14px;
    color: @minor;
    margin: 0 16px;
    font-weight: bold;


    img {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 12px;
      right: 0px;
    }
  }

  .author {
    width: 100%;
    background: @border;
    text-align: left;
    height: 29px;
    font-size: 14px;
    color: @minor;
    line-height: 29px;
    padding-left: 16px;
    box-sizing: border-box;

    span {
      color: @blue;
    }
  }
  .p-padding {
    margin-top: 49px;
  }

  .choice {
    margin-bottom: 20px;
    padding: 0 16px;
  }
  .choicemargin {
    margin-bottom: 0px;
  }
  .last {
    margin-bottom: 0px;
    min-width: 288px;
    margin-left: 12px;
    padding: 0px;
    padding-left: 6px;
    border: 0px;
    outline: none;
    resize: none;
    width: 90%;
    box-sizing: border-box;
  }


  .report-p {
    text-align: center;
    margin-top: 16px;
  }

  button {
    background: @main;
    color: white;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}
.isyellow {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #55caff;
  margin-top: 5px;
  margin-bottom: 30px;
}



@media (min-width: 800px) {
  .report-wrap {
    width: 512px;
    margin: 0 auto;

    .back-report {
      border: 0px;
      background: #ffffff;
    }
  }
  .report-wrap-web {
    width: 360px;
    z-index: 10;
    height: 570px;
    border: 1px solid #e7e7e7;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

}
</style>
