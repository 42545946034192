//@ts-check
import Vue from 'vue'
import { API_URL, APK_URL } from '@/config'
// @ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'
import { api } from './base'


const _fetchPosts = async (channelId, page, limit = 5) => {
  try {
    const res = await api.get(`/channels/${channelId}/mixedPosts`, {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchPosts = reusePendingPromise(_fetchPosts)


export const collectInto = async function (postId, channelId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/collect`, {
      postId,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const createChannel = async function ({ title, description, needVerify, royaltyRate, titleLocales, descriptionLocales }) {
  try {
    const res = await api.post('/channels/create', {
      title,
      description,
      needVerify,
      royaltyRate,
      titleLocales,
      descriptionLocales,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateChannel = async function (channelId, doc) {
  try {
    const res = await api.post(`/channels/${channelId}/update`, doc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const contributeArtwork = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/submit`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getReviewingPosts = async function (channelId, page, limit) {
  try {
    const res = await api.get(`/channels/${channelId}/reviewPosts`, {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const approveReviewingPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/pass`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const rejectReviewingPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/reject`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const removeChannelPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/remove`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const pinChannelPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/promote`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const unpinchannelPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/unPromote`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

const _fetchMyChannels = async function () {
  try {
    const res = await api.get('/channels/admin/channels')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchMyChannels = reusePendingPromise(_fetchMyChannels)


const _fetchSubscribedChannels = async function () {
  try {
    const res = await api.get('/channels/user/channels')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchSubscribedChannels = reusePendingPromise(_fetchSubscribedChannels)


export const _getChannelAlgoPosts = async function (channelId, limit = 5) {
  try {
    const res = await api.get(`/channels/${channelId}/algoPosts`, {
      params: {
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getChannelAlgoPosts = reusePendingPromise(_getChannelAlgoPosts)

export default {
  fetchWeixinSubscriptions () {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/wechat/channels`)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchOne (channelId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${channelId}/channel`)
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            
            return resolve(data.result)
          } else if (data.code === 204) {
            return reject("无数据")
          } else {
            return reject(data.message)
          } 
        })
        .catch(err => {
          if (err.data) {
            
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchOneByNumber (number) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${number}/info`)
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchUsers (channelId, page, limit = 10) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${channelId}/girls`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchPromotedPosts (channelId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${channelId}/topPosts`)
        .then(({ ok, data }) => {
          if (ok && data.code == 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchPosts (channelId, page, limit = 5) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${channelId}/mixedPosts`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  updateSubscriptions (channelIds) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .post(`${API_URL}/channels/user/updateSubscribe`, {
          channelIds
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve()
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else if (err.message) {
            return reject(err.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  applyFeatured (channelId, postId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .post(`${API_URL}/channels/${channelId}/promoteApply`, {
          postId
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve()
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else if (err.message) {
            return reject(err.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchApplicableChannels (page = 1, limit = 10) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/applicable/channels`)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result.filter(channel => channel._id === '5873d400e57b02001cfb8399' || channel._id === '5873d50be57b02001cfb839a'))
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else if (err.message) {
            return reject(err.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  generateApk (channelIds) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .post(`${APK_URL}/partners/dynamicApk/generate`, {
            channelIds
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data)
          } else {
            return reject(data)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchApkstatus () {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${APK_URL}/partners/dynamicApk/status`)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    }) 
  },

  fetchApkid (partnerId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${APK_URL}/partners/${partnerId}/detail`)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },

  fetchallchannels () {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/bookable/channels?page=1`,)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },

  collectRequest (page, limit = 10) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/collectRequest/posts`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },

  updateStatus (channelId, postId, status) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .post(`${API_URL}/channels/${channelId}/post/updateStatus`, {
            postId,
            status
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },

  //获取频道帖子，按时间排序
  getChannelPostTime (page, limit = 5, channelId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${channelId}/timePosts`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },

  //获取频道帖子，按热度排序
  getChannelPostHot (page, limit = 5, channelId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/${channelId}/heatPosts`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },

  //获取推荐的应援团
  getPromotedChannel (page, limit = 5) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Vue.http
        .get(`${API_URL}/channels/promoted/channels?page=${page}&limit=${limit}`)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)

          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
      }) 
  },
}
