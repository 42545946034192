import { ref, watch } from 'vue'


export const useChannelPortalBar = function (refSelectedChannel) {
  const portalBarShow = ref(false)


  watch(refSelectedChannel, () => {
    if (!refSelectedChannel.value) {
      portalBarShow.value = false
      window.removeEventListener('scroll', handler)
    } else {
      window.addEventListener('scroll', handler)
    }
  })


  const handler = () => {
    portalBarShow.value = window.scrollY > 114
  }

  return {
    portalBarShow,
  }
}