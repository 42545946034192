<template>
  <div
    v-if="memberTag && memberTag.style"
    class="circle-badge">
    <span
      v-if="memberTag.style === 'block'"
      :style="{'background': memberTag.color}"
      class="block">{{ memberTag.title }}</span>
    <span
      v-else
      :style="{'border-color': memberTag.color, 'color' :memberTag.color}"
      class="outline">{{ memberTag.title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    memberTag: {
      type: Object, 
      required: true,
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.circle-badge {
  display: inline-block;
  margin-left: 0px;
  
  span {
    border-radius: 4px;
    font-size: 12px;
    display: inline;
    height: 20px;
    line-height: 20px;
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
  }
  .block {
    background: black;
    color: @white;
  }
  .outline {
    border: 1px solid red;
    color: red
  }
}
.circle-badge:hover {
  cursor: pointer;
  text-decoration: none;
}


</style>
