<template>
  <div class="password-container">
    <span class="p-float-label p-input-icon-left">
      <i class="pi pi-envelope"></i>
      <input-text
        id="password"
        v-model="password"
        type="password">
      </input-text>
      <label for="password">{{ $t('password') }}</label>
    </span>

    <pr-button
      :loading="saving"
      type="primary"
      @click.native="save">
      {{ $t('save') }}
    </pr-button>
  </div>
</template>


<script>
//@ts-check
import { update } from '@/api/user'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'
import { createHash } from 'crypto-browserify'
import InputText from 'primevue/inputtext'
import { ref } from 'vue'
import PrButton from '@/components/PRButton.vue'

export default {
  setup () {
    const password = ref('')
    const saving = ref(false)
    const { Toast} = useToast()
    const { currentUserId } = useCurrentUser()

    const save = async () => {
      try {
        saving.value = true
        const hashedPassword = createHash('md5').update(password.value).digest('hex')
        await update(currentUserId.value, { password: hashedPassword })
        Toast({
          message: 'Password updated',
        }, 'ok')
      } catch (err) {
        console.error(err)
        Toast(err)
      } finally {
        saving.value = false
      }
    }

    return {
      password,
      save,
      saving,
    }
  },
  components: {
    InputText,
    PrButton,
  }
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.password-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media @desktop {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
  }

  button {
    width: 100%;
  }
}
</style>