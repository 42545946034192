var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.resolutions.length > 0)?_c('vue-plyr',{ref:"vuePlyrRef",attrs:{"options":{
    quality: {
      default: Number(_vm.defaultResolution),
      options: _vm.resolutions.map(resolution => {
        return Number(resolution)
      }),
      forced: true,
      onChange: _vm.setResolution,
    },
  }}},[_c('video',{ref:"videoRef",attrs:{"crossorigin":"true","playsinline":"","poster":_vm.media.thum},on:{"play":_vm.onplay}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }