<template>
  <div
    v-if="notification && notification._id"
    class="notification-container"
    @click="act">
    <div class="avatar-container">
      <user-avatar
        :disabled="true"
        :user="notification.producer"></user-avatar>
    </div>

    <div class="content-container">
      <h3>{{ notification.producer.nickname }}</h3>
      <span>{{ $d(notification.createdAt, 'dateonly') }}</span>
      <h2>{{ notification.title }}</h2>
      <blockquote>
        {{ notification.content }}
      </blockquote>
      <hr>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router/composables'
import UserAvatar from '@/components/UserAvatar.vue'
import { useToast } from '@/composables/toast'
import { useNotificationStore } from '@/pinia/notification'


const CMD = {
  'NONE': '200',
  'GO_POST_COMMENTS': '201',
  'GO_REPLY_COMMENTS': '202',
  'GO_CHANNEL_DETAIL': '203',
  'GO_WEB_PAGE': '204',
  'GO_USER_MAIN_PAGE': '205'
}


export default {
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  setup (props) {
    const router = useRouter()
    const { Toast } = useToast()
    const notificationStore = useNotificationStore()

    const act = async function () {
      try {
        if (props.notification.action === CMD.NONE) {
          return
        } else if (props.notification.action === CMD.GO_WEB_PAGE) {
          const url = new URL(props.notification.actionOptions.url)
          router.push(url.pathname)
        } else if (props.notification.action === CMD.GO_POST_COMMENTS) {
          const { postId, commentId, postUserId } = props.notification.actionOptions
          router.push(`/posts/${postId}`)
        } else if (props.notification.action === CMD.GO_REPLY_COMMENTS) {
          const { postId, commentId, postUserId } = props.notification.actionOptions
          router.push(`/posts/${postId}`)
        } else if (props.notification.action === CMD.GO_CHANNEL_DETAIL) {
          const channelId = props.notification.actionOptions.channelId
          router.push(`/channels/${channelId}`)
        } else if (props.notification.action === CMD.GO_USER_MAIN_PAGE) {
          const userId = props.notification.actionOptions.userId
          router.push(`/users/${userId}`)
        }

        await notificationStore.markAsRead(props.notification._id)
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    return {
      act,
    }
  },
  components: {
    UserAvatar,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.notification-container {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  padding: 0.5em;
  box-sizing: border-box;

  .avatar-container {
    margin-right: 1em;

    > div {
      width: 3rem;
      height: 3rem;
    }
  }

  .content-container {
    width: auto;
    flex-grow: 1;

    hr {
      border-color: @border;
      margin: 0;
    }
  }

  h2, h3 {
    color: @black;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
  }

  h2 {
    font-size: 15px;
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  span {
    color: @grey;
    font-size: 12px;
  }

  blockquote {
    background-color: @border;
    color: @warm-grey;
    padding: 0.4em;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 4px;
  }
}
</style>