//@ts-check
import { onMounted, ref, watch } from "vue"
import { useCurrentCountry } from "./country"


// Generated by gpt4
const eurozoneCountries = ['AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'];


export const useCurrentCurrency = function () {
  const { countryCode } = useCurrentCountry()
  const currency = ref('USD')

  watch(countryCode, () => {
    setCurrency()
  })

  const setCurrency = () => {
    let _currency = 'USD'

    if (countryCode.value === 'CN' || countryCode.value === 'HK' || countryCode.value === 'MO') {
      _currency = 'CNY'
    } else if (countryCode.value === 'JP') {
      _currency = 'JPY'
    } else if (eurozoneCountries.includes(countryCode.value)) {
      _currency = 'EUR'
    } else {
      _currency = 'USD'
    }

    currency.value = _currency
  }

  onMounted(setCurrency)

  return {
    currency,
  }
}