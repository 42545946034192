<template>
  <sidebar
    :visible.sync="visible"
    position="full">

    <div
      class="media-browser"
      @click="showPreviews = !showPreviews">
      <div
        v-if="medias[index]"
        class="canvas-container">
        <image-viewer
          v-if="medias[index].type === MediaType.IMAGE"
          :id="medias[index]._id"
          :src="medias[index].url"></image-viewer>
        <div
          v-else-if="medias[index].type === MediaType.VIDEO"
          class="video-container">
          <video-player
            :media="medias[index]"
            :artwork-id="medias[index].postId"
            :paid="paid"></video-player>
        </div>
      </div>

      <div
        v-if="medias.length > 0"
        v-show="showPreviews"
        class="item-previews">
        <div
          v-for="(media, $index) in medias"
          :key="media._id"
          class="item-preview hoverable touchable"
          :class="{ 'selected': $index === index }"
          @click.prevent.stop="index = $index">
          <img
            v-if="media.type === MediaType.VIDEO"
            style="position: absolute;"
            src="@/assets/video_tag.png">
          <img
            :src="media.thum">
        </div>
      </div>
    </div>
  </sidebar>
</template>

<script>
//@ts-check
import { useApplication } from '@/pinia/application'
import { computed, ref, watch } from 'vue'
import Sidebar from 'primevue/sidebar'
import ImageViewer from '@/components/ImageViewer.vue'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { useArtworkStore } from '@/pinia/artwork'
import { MediaType } from '@/utils/defines'


export default {
  setup () {
    const application = useApplication()
    const index = ref(0)
    const showPreviews = ref(true)
    const showVideo = ref(false)
    const artwortStore = useArtworkStore()

    const visible = computed({
      get () {
        return application.browserVisible
      },
      set (value) {
        if (!value) {
          application.closeBrowser()
        }
      }
    })

    const paid = computed(() => {
      if (application.browserArtworkId) {
        return artwortStore.artworks[application.browserArtworkId].paid
      }

      // adapt for stars page
      return true
    })

    const medias = computed(() => application.browserMedias.filter(media => media.type === MediaType.IMAGE || media.type === MediaType.VIDEO))

    watch(visible, () => {
      if (visible.value) {
        index.value = application.browserIndex
        if (application.browserArtworkId) {
          eventEmitter.emit(events.ViewerEntered, application.browserArtworkId)
        }
      } else {
        if (application.browserArtworkId) {
          eventEmitter.emit(events.ViewerQuitOnIndex, application.browserArtworkId, index.value)            
        }
        // if we browserArtworkId to null in closeBrowser, this watcher will not execute forever
        application.$patch({ browserArtworkId: null })
      }
    })

    watch(index, () => {
      if (medias.value[index.value]) {
        const media = medias.value[index.value]
        if (media.type === MediaType.VIDEO) {
          showVideo.value = true
        } else {
          showVideo.value = false
        }
      }
    })

    return {
      visible,
      medias,
      index,
      showPreviews,
      showVideo,
      paid,
      MediaType,
    }
  },
  components: {
    Sidebar,
    ImageViewer,
    VideoPlayer,
  }
}
</script>


<style scoped>
.p-sidebar {
  background-color: #212121 !important;
}

.p-sidebar .p-sidebar-content {
  padding: 0 !important;
}

.media-browser {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.item-previews {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 200rem;
  display: flex;
  gap: 0.5rem;
  align-self: flex-end;
  padding-top: 0.5rem;
}

.item-previews > div, .item-previews img {
  width: 3rem;
  height: 3rem;
}

.item-previews img {
  object-fit: cover;
}

.item-preview {
  position: relative;
  filter: grayscale(1.0);
}

.item-preview.selected {
  filter: grayscale(0.0);
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 75vh;
  align-self: center;
  overflow: hidden;
}

canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.video-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  min-width: 20rem;
}
</style>