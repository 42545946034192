import { ref } from 'vue'
import { getUploadUrl, upload } from '@/api/s3'
import { update } from '@/api/user'
import { useCurrentUser } from './user_data'
import { useUserStore } from '@/pinia/user'
import { useToast } from './toast'


export const useUploadAvatar = function () {
  const avatarInputRef = ref(null)
  const uploadProgress = ref(0)
  const { currentUser } = useCurrentUser()
  const userStore = useUserStore()
  const uploading = ref(false)
  const { Toast } = useToast()


  const pickAvatarImage = function () {
    if (avatarInputRef.value) {
      avatarInputRef.value.click()      
    } else {
      Toast({
        message: 'input not inited',
      })
    }
  }

  const onFilePicked = async function (event) {
    try {
      if (event.target.files.length <= 0) {
        return
      }
  
      uploading.value = true
      const file = event.target.files[0]
      const url = await getUploadUrl(file.name, file.type, file.size, file.lastModified)
      const avatarUrl = await upload(url, file, (percentage) => {
        console.log(percentage)
        uploadProgress.value = percentage * 100
      })
  
      await update(currentUser.value._id, {
        avatarURL: avatarUrl,
      })
  
      userStore.patchSelf('avatarURL', avatarUrl)
  
      event.target.value = '' 
    } catch (err) {
      Toast(err.message)
    } finally {
      uploading.value = false
    }
  }


  return {
    pickAvatarImage,
    avatarInputRef,
    onFilePicked,
    uploadProgress,
    uploading,
  }
}