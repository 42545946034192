<template>
  <div class="requesters">
    <input-text
      v-model="keyword"
      :placeholder="$t('searchAisatuToken')"></input-text>

    <div>
      <user-list-item
        v-for="user in users"
        :key="user._id"
        :user="user"
        :show-aisatu-code="true">
      </user-list-item>

      <load-more-button
        :busy="loading"
        :has-more="hasMore"
        @trigger="loadMore">
      </load-more-button>
    </div>
  </div>
</template>


<script>
//@ts-check
import { onMounted, ref } from 'vue'
import {
  searchChatWishingUsers,
  getChatWishingUsers
} from '@/api/chat'
import UserListItem from '@/components/UserListItem.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useCurrentUser } from '@/composables/user_data'
import InputText from 'primevue/inputtext'
import { watchDebounced } from '@vueuse/core'
import { useToast } from '@/composables/toast'


const limit = 5

export default {
  setup () {
    const users = ref([])
    const page = ref(1)
    const loading = ref(false)
    const hasMore = ref(true)
    const keyword = ref('')
    const { currentUser } = useCurrentUser()
    const { Toast } = useToast()

    watchDebounced(keyword, () => {
      users.value = []
      page.value = 1
      hasMore.value = true
      get()
    })

    const get = async function () {
      try {
        loading.value = true

        if (keyword.value) {
          // @ts-ignore
          users.value = await searchChatWishingUsers(currentUser.value._id, keyword.value, limit)
        } else {
          // @ts-ignore
          const gotUsers = await getChatWishingUsers(currentUser.value._id, page.value, limit)
          users.value = [...users.value, ...gotUsers]
        }
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }

    const loadMore = async function () {
      const beforeCount = users.value.length
      await get()
      const afterCount = users.value.length
      console.log(beforeCount, afterCount)
      if (beforeCount === afterCount) {
        hasMore.value = false
      } else {
        page.value += 1
        hasMore.value = true
      }
    }

    onMounted(() => {
      page.value = 1
      users.value = []
      hasMore.value = true
      loadMore()
    })

    return {
      users,
      loadMore,
      keyword,
      hasMore,
      loading,
    }
  },
  components: {
    UserListItem,
    LoadMoreButton,
    InputText,
  }
}
</script>


<style scoped>
.requesters {
  width: 100%;
}

.requesters > input {
  width: 100%;
  margin-bottom: 1rem;
}
</style>