//@ts-check


export const usePriceFormatter = function () {
  return {
    formatPrice (price, currency, handleDecimals = true) {
      if (!handleDecimals) {
        return `${currency} ${price}`
      }

      if (currency === 'JPY') {
        return `JPY ${price}`
      }

      return `${currency} ${Number((price / 100).toFixed(2))}`
    }
  }
}