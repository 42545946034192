import Vue from 'vue'
import { API_URL } from '../config'
import { api } from './base'
import { ACCESSTOKEN_HEADER_KEY } from '@/pinia/authenticator'
import { reusePendingPromise } from 'reuse-pending-promise'


const _getSelf = async function (token) {
  try {
    if (token) {
      Vue.http.headers.common[ACCESSTOKEN_HEADER_KEY] = token
    }
    const res = await api.get('/users/self')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getSelf = reusePendingPromise(_getSelf)


export const setAutoreply = async function (contactInfo, prices) {
  try {
    const res = await api.post('/users/vips/set', {
      contactInfo,
      prices,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const clearAutoreply = async function () {
  try {
    const res = await api.post('/users/vips/unset')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const intentChatPurchase = async function (userId, currency, {
  processor,
  returnUrl,
  subProcessor,
}) {
  try {
    const res = await api.post(`/users/${userId}/vipRequest`, {
      currency,
      processor,
      returnUrl,
      subProcessor,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const bindBankAccount = async function (countryCode, { account, holder, cnId, } = {}) {
  try {
    const res = await api.post('/users/bindbank', {
      countryCode,
      account,
      holder,
      cnId,
    })

    if (res.data.code === 200) {
      return res.data.result.url
    } else {
      throw new Error(res.data.message)
    }
  } catch (err) {
    console.error(err)
    if (err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err)
    }
  }
}

export const applyCreatorStripe = async function (countryCode, isR18 = false) {
  try {
    const res = await api.post('/users/authorize', {
      isR18,
      countryCode,
    })
    return res.data.result.tmpSecret
  } catch (err) {
    console.error(err)
    if (err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err)
    }
  }
}

export const applyCreator = async function (countryCode, firstName, lastName, isR18 = false) {
  try {
    const res = await api.post('/users/authorize', {
      isR18,
      countryCode,
      firstName,
      lastName
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    if (err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err)
    }
  }
}

export const checkVerification = async function (userId) {
  try {
    const res = await api.get(`/users/${userId}/verify_result`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const checkStripeVerification = async function (userId) {
  try {
    const res = await api.get(`/users/${userId}/stripe_verify_result`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const blockUser = async function (userId) {
  try {
    const res = await api.post(`/users/${userId}/block`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const unblockUser = async function (userId) {
  try {
    const res = await api.delete(`/users/${userId}/block`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getBlockedUsers = async function (page = 1, limit = 10) {
  try {
    const res = await api.get('/users/blacklist/all', {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getMyMemberTags = async function () {
  try {
    const res = await api.get('/users/tags/my')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateMyMemberTag = async function (authorId, visible) {
  try {
    const res = await api.post('/users/tags/select', {
      authorId,
      visible
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const setMemberTagForCircle = async function ({ title, style, color }) {
  try {
    const res = await api.post('/users/tags/update', {
      title,
      style,
      color,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const bindPaypal = async function (code) {
  try {
    const res = await api.post('/users/bindPaypal', {
      code
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getUserByUsername = async function (username) {
  try {
    const res = await api.get('/users', {
      params: {
        username
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const searchUsers = async function (keywords, page = 1, limit = 5) {
  try {
    const res = await api.get('/users/search', {
      params: {
        limit,
        page,
        keywords
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const requestEmailReplacementCaptcha = async function (email) {
  try {
    const res = await api.post('/users/bindEmailCode', {
      email
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateEmail = async function (email, captcha, merge = false) {
  try {
    const res = await api.post('/users/bindEmail', {
      email,
      captcha,
      merge,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const requestPhoneReplacementCaptcha = async function (phoneNumber) {
  try {
    const res = await api.post('/users/bindSms', {
      phoneNumber
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updatePhoneNumber = async function (phoneNumber, captcha, merge = false) {
  try {
    const res = await api.post('/users/bindPhoneNumber', {
      phoneNumber,
      captcha,
      merge,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const update = async function (userId, doc) {
  try {
    const res = await api.put(`/users/${userId}`, doc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  authorize (key) {
    const authorizePic = key

    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/users/authorize/pic`, {
          authorizePic
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  getOne (userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/${userId}`)
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  sync () {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/users/syncGuestPosts`)
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve()
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  getRelatives (userId, isFollowers, page) {
    const url = `${API_URL}/users/${userId}/${ isFollowers ? 'followers' : 'followings' }`

    return new Promise((resolve, reject) => {
      Vue.http
        .get(url, {
          params: {
            limit: 10,
            page
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  async withdraw (userId, withdraws) {
    try {
      const res = await api.post(`/users/${userId}/withdraw`, {
        withdraws
      })

      if (res.data.code === 200) {
        return res.data.result
      } else {
        throw new Error(res.data.message)
      }
    } catch (err) {
      console.error(err)
      if (err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error(err)
      }
    }
  },

  getOnsales () {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/onsales/list`, {
          page: 1,
          limit: 20
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  joinPROnly () {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/users/apply/exclusive`)
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  updateNotificationSettings ({ authorFollow, authorPost, comment, postContribute }) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/users/notifyFlags/update`, {
          authorFollow,
          authorPost,
          comment,
          postContribute
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  getUntrustRecords (page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/untrustRecord/list`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  follow (userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/users/follow/${userId}`)
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  unfollow (userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/users/unfollow/${userId}`)
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  bindalipay (realName,alipay,userId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .put(`${API_URL}/users/${userId}`, {
          realName,
          alipay
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve()
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  //获取订单状态
  getChargeStatus (chargeId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/charge/status?chargeNo=${chargeId}`)
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data)
          } else {
            return reject(err)
          }
        })
    })
  },

  async getStripeLoginUrl (userId) {
    try {
      const res = await api.get(`/users/${userId}/stripe_login_url`)

      if (res.data.code === 200) {
        return res.data.result.url
      } else {
        throw new Error(res.data.message)
      }
    } catch (err) {
      if (err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error(err)
      }
    }
  }
}
