<template>
  <component :is="UserList"></component>
</template>

<script>
import UserList from '@/components/UserList.vue';

export default {
  setup () {
    return {
      UserList
    }
  }
}
</script>