<template>
  <p
    v-if="shouldRender"
    :style="{ opacity }">
    <slot></slot>
  </p>
</template>


<script>
//@ts-check
import { onMounted, ref } from 'vue'

export default {
  props: {
    fade: {
      type: Boolean,
      required: false,
      default: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 10000,
    }
  },
  setup (props) {
    const opacity = ref(1)
    const shouldRender = ref(true)
    let start

    const fade = function (timestamp) {
      if (start === undefined) {
        start = timestamp
      }
      const elapsed = timestamp - start

      // Calculate the current opacity based on the elapsed time
      opacity.value = 1 - (elapsed / props.duration)

      if (opacity.value > 0) {
        requestAnimationFrame(fade)
      } else {
        opacity.value = 0
        shouldRender.value = false
      }
    }

    onMounted(() => {
      if (props.fade) {
        requestAnimationFrame(fade)        
      }
    })

    return {
      opacity,
      shouldRender,
    }
  }
}
</script>