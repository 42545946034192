import { render, staticRenderFns } from "./ReportCenterAccusation.vue?vue&type=template&id=54f03524&scoped=true"
import script from "./ReportCenterAccusation.vue?vue&type=script&lang=js"
export * from "./ReportCenterAccusation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f03524",
  null
  
)

export default component.exports