import { useCircleStore } from "@/pinia/circle"
import { computed, onMounted, ref, watch } from "vue"
import { useCurrentUser } from "./user_data"

const limit = 10

export const useMyCircles = function () {
  const store = useCircleStore()
  const myCirclesPage = ref(1)
  const myCirclesLoading = ref(false)
  const { authenticated } = useCurrentUser()
  const myCirclesHasmore = ref(true)

  const myCircles = computed(() => store.myCircles)

  watch(authenticated, () => {
    if (authenticated.value && myCircles.value.length === 0) {
      myCirclesPage.value = 1
      fetchMyCircles()
    }
  })

  const fetchMyCircles = async function () {
    myCirclesLoading.value = true
    const countBefore = myCircles.value.length
    await store.fetchMyCircles(myCirclesPage.value, limit)
    myCirclesLoading.value = false
    const countAfter = myCircles.value.length
    if (countAfter === countBefore) {
      myCirclesHasmore.value = false
    } else {
      myCirclesPage.value += 1
    }
  }

  onMounted(() => {
    if (authenticated.value) {
      fetchMyCircles()
    }
  })

  return {
    myCircles,
    myCirclesPage,
    fetchMyCircles,
    myCirclesLoading,
    myCirclesHasmore,
  }
}