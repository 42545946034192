<template>
  <footer>
    <div class="rows">
      <!-- <div class="row">
        <h5>{{ $t('products') }}</h5>
        <a
          href="https://driver.prshe.im"
          target="_blank">{{ $t('uploader') }}</a>
        <router-link to="/download">
          {{ $t('downloadApp') }}
        </router-link>
      </div> -->

      <div class="row">
        <h5>{{ $t('links') }}</h5>
        <a @click.prevent="$router.push('/create')">{{ $t('createOnPerohub') }}</a>
        <router-link to="/reportcenter">
          {{ $t('reportCenter') }}
        </router-link>
      </div>


      <div class="row">
        <h5>{{ $t('about') }}</h5>
        <!-- <router-link to="/rules">
          {{ $t('rules') }}
        </router-link> -->
        <router-link to="/privacy">
          {{ $t('privacyTerms') }}
        </router-link>
        <!-- <router-link to="/disclosure">
          {{ $t('disclosure') }}
        </router-link> -->
        <router-link to="/terms">{{ $t('tos') }}</router-link>
      </div>
    </div>

    <div class="contacts">
      <!-- <a
        title="sendmail"
        @click.prevent.stop="contactEmail"
        @mouseover.prevent="emailhover = true"
        @mouseleave.prevent="emailhover = false">
        <img
          v-show="!emailhover"
          src="@/assets/icon_page_footer_contact_email.png">
        <img
          v-show="emailhover"
          src="@/assets/icon_page_footer_contact_email_hover.png">
      </a> -->

      <a
        title="twitter"
        @click.prevent.stop="contactTwitter"
        @mouseover.prevent="twitterhover = true"
        @mouseleave.prevent="twitterhover = false">
        <i class="pi pi-twitter"></i>
      </a>

      <a
        title="telegram"
        @click.prevent.stop="joinTelegram"
        @mouseover.prevent="tgHover = true"
        @mouseleave.prevent="tgHover = false">
        <i class="pi pi-telegram"></i>
      </a>
    </div>

    <p>
      &copy;
      <img
        src="@/assets/sitelogo_grey.png"
        alt="prlogo">
      All Rights Reserved.
    </p>
  </footer>
</template>

<script>
import { useCurrentUser } from '@/composables/user_data'
import { useApplication } from '@/pinia/application'

export default {
  setup () {
    const application = useApplication()
    return {
      showLogin: application.showLogin,
      ...useCurrentUser(),
    }
  },
  data () {
    return {
      host: location.host,
      emailhover: false,
      twitterhover: false,
      tgHover: false,
    }
  },
  methods: {
    contactEmail () {
      const email = ['midaiki', '@', 'proton', '.me'].join().split(',').join('')
      window.open(`mailto:${email}`)
    },
    contactTwitter () {
      window.open('https://twitter.com/perohubcom')
    },
    joinDiscord () {
      window.open('https://discord.gg/ygm2FRvcuZ')
    },
    joinTelegram () {
      const newWindow = window.open('https://t.me/+65W0ofuBNxsxNzdh')
      if (newWindow === null) {
        location.href = 'https://t.me/+65W0ofuBNxsxNzdh'
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

footer {
  background: #fafafa;
  width: 100%;
  text-align: center;
  padding-top: 18px;
  padding-bottom: 18px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .rows {
    display: flex;
    gap: 4rem;

    .row {
      text-align: left;

      h5 {
        font-size: 14px;
        color: @black;
        font-weight: 500;
        margin-bottom: 11px;
      }

      a {
        display: block;
        font-size: 14px;
        color: #9b9b9b;
        margin-bottom: 5px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  p {
    font-size: 13px;
    color: @grey;
    font-weight: 500;
    margin-top: 23px;

    img {
      display: inline-block;
      margin: 0 9px;
      height: 11px;
    }
  }
}

.contacts {
  text-align: right;
  display: inline-flex;
  align-items: center;

  @media @desktop {
    position: absolute;
    right: 28px;
    top: 105px;
  }

  @media @phone {
    margin-top: 4rem;
  }

  a {
    display: inline-block;
    margin: 0 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: 1px solid @warm-grey;
    display: flex;
    align-items: center;
    justify-content: center;

    .pi {
      color: @warm-grey;
      font-size: 1rem;
    }

    &:hover {
      cursor: pointer;
      border-color: @main;

      .pi {
        color: @main;
      }
    }
  }
}
</style>
