<template>
  <div class="container">
    <router-link
      v-for="user in buyers"
      :key="user._id"
      :to="`/users/${user._id}`">
      <user-list-item 
        :user="user"
        class="hoverable touchable"></user-list-item>
    </router-link>

    <load-more-button
      :has-more="buyerHasmore"
      :busy="buyerBusy"
      :trigger="getArtworkBuyers"></load-more-button>
  </div>
</template>


<script>
import { useArtworkBuyer } from '@/composables/artwork_buyer'
import UserListItem from '@/components/UserListItem.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'


export default {
  setup () {
    return {
      ...useArtworkBuyer()
    }
  },
  components: {
    UserListItem,
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>
@media (max-width: 800px) { 
  .container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    box-sizing: border-box;
  }
}

@media (min-width: 800px) { 
  .container {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }
}
</style>