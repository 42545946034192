<template>
  <div class="promote-container">
    <div
      v-if="user.authed"
      class="author">
      <ins>{{ $t('creator') }}</ins>
      <mt-cell
        :title="$t('submitToChannels')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote/submit')"
      />
      <mt-cell
        :title="$t('discount')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote/discount')"
      />
      <mt-cell
        :title="$t('collectRequest')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote/submissions')"
      />
      <mt-cell
        :title="$t('coworkRequest')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote/cowork')"
      />
      <!-- <mt-cell
        title="预约发现推广位"
        is-link
        @click.native="$router.push('/i/publisher/promote/book/app')"
      />
      <mt-cell
        title="预约频道推广位"
        is-link
        @click.native="$router.push('/i/publisher/promote/book/channel')"
      /> -->
      <mt-cell
        :title="$t('submitToTopics')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote/topics')"
      />
      <!-- <mt-cell
        title="申请编辑推荐"
        is-link
        @click.native="$router.push('/i/publisher/promote/featured')"
      /> -->
    </div>
    <div
      v-else
      class="author">
      <ins>{{ $t('creator') }}</ins>
      <p>Only Open for Creators</p>
    </div>

    <div class="channel">
      <ins>{{ $t('channelOwner') }}</ins>
      <mt-cell
        :title="$t('shareChannel')"
        is-link
        class="hoverable"
        @click.native="$router.push('/i/publisher/promote/channel')"
      />
      <!-- <mt-cell
        title="开放频道推广位"
        is-link
        @click.native="$router.push('/i/publisher/promote/book/create')"
      /> -->
    </div>

    <div class="thirdparty">
      <ins>Third Parties</ins>
      <p>More to Come</p>
    </div>
  </div>
</template>

<script>
import { useCurrentUser } from '@/composables/user_data'


export default {
  metaInfo: {
    title: "Services",
  },
  setup () {
    const { currentUser } = useCurrentUser()
    return {
      user: currentUser
    }
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

.promote-container {
  padding-top: 2em;
  ins {
    font-size: 14px;
    display: block;
    background: @border;
    height: 29px;
    line-height: 29px;
    padding-left: 16px;
    color: @minor;
  }

  p {
    margin: 0 16px 0 16px;
    color: #888;
    border-bottom: 1px solid @border;
    min-height: 48px;
    line-height: 48px;
  }

  button {
    width: 8em;
    height: 4em;
    margin: 2.5px;
  }

  .mint-cell {
    margin: 0 16px;

    .mint-cell-wrapper {
      border-bottom: 0px;
      border-bottom: 1px solid @border;
      padding:0px;

      .mint-cell-title {
        color: #333333;
        font-weight: bold;
      }

      .mint-cell-allow-right::after {
        width: 8px;
        height: 8px;
        right: 6px;
      }
    }
  }
}


.promote-container > div {
  margin-bottom: 1em;
}
</style>
