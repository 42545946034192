<template>
  <artwork-photo></artwork-photo>
</template>

<script>
import ArtworkPhoto from '@/components/ArtworkPhoto.vue'

export default {
  components: {
    ArtworkPhoto
  }
}
</script>