<template>
  <div class="promote-book-channel-container">
    <div class="channel">
      <span
        v-for="(channel, index) in channels"
        :key="channel._id"
        class="touchable"
        :class="{active : channel.isSelected}"
        @click="selectChannel(channel,index)"
      >{{ channel.title }}</span>
    </div>
    <div class="placements">
      <placement
        v-for="(placement, index) in placements"
        :key="index"
        :placement="placement"
      />
      <div
        v-if="placements.length === 0 && loaded"
        class="noresult"
      >
        <img src="@/assets/Page_empty_guider.png">
        <p>暂无推广位开放</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/placement'
import channelAPI from '@/api/channel'
import Placement from '@/components/Placement.vue'
import { useCurrentUser } from '@/composables/user_data'

export default {
  metaInfo: {
    title: "预约频道推广位",
  },
  setup () {
    return {
      ...useCurrentUser()
    }
  },
  data () {
    return {
      placements: [],
      channels: [],
      loaded: false
    }
  },
  computed: {
    names () {
      return this.channels.map(channel => channel.title).join('、')
    }
  },
  mounted () {
    this
      .fetchChannels()
      .then(() => {
        this.selectChannel(this.channels[0],0)
      })
  },
  methods: {
    selectChannel (selectChannel,index) {
      this.loaded = false
      this.placements = []
      this.channels.forEach(channel => {
        channel.isSelected = (channel._id === selectChannel._id)
      })
      this.fetchPlacementsChannel(selectChannel._id)
      this.channelId = this.channels[index]._id
    },
    fetchPlacementsChannel (channelid) {
      api
        .fetch(channelid)
        .then(placements => {

          const total = []
          this.placements = []
          placements.forEach(placement => total.push(placement))

          this.placements = total
          this.loaded = true
        })
        .catch(err => {
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    },
    fetchChannels () {
      this.$indicator.open()

      return channelAPI
        .fetchallchannels()
        .then(channels => {
          channels.map(channel => {
            channel.isSelected = false
          })
          this.channels = channels
          this.$indicator.close()
        })
        .catch(err => {
          this.$indicator.close()
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    }
  },
  components: {
    Placement
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.promote-book-channel-container {
  padding-top: 0.6em;

  .channel {
    overflow-x: scroll;
    white-space: nowrap;
    background: white;
    padding: 6px 0 16px;
    margin-left: 10px;

    span {
      display: inline-block;
      width: 85px;
      height: 30px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      color: @minor;
      text-align: center;
      line-height: 29px;
      border-radius: 15px;
      margin-right: 10px;

      &.active {
        background: @border;
        border: 1px solid @border;
      }
    }
  }
  .placements {
    padding: 0 10px;

    .noresult {
      text-align: center;
      margin-top: 160px;

      img {
        width: 80px;
        height: 80px;
      }

    }
  }
}
</style>
