//@ts-check
import { computed, onActivated, onMounted } from "vue"
// @ts-ignore
import { Indicator } from 'mint-ui'
import channelapi from "@/api/channel"
import { useChannelStore } from "@/pinia/channel"
import { useRoute } from "vue-router/composables"
import { useToast } from "./toast"


export const useSubscriptionUpdating = function () {
  const channelStore = useChannelStore()
  const { Toast } = useToast()

  const draggableChannels = computed({
    set (channels) {
      channelStore.$patch({
        subscribedChannels: channels,
      })
    },
    get () {
      return channelStore.subscribedChannels
    }
  })

  const updateSubscriptions = async function () {
    Indicator.open()
    try {
      const channelIds = channelStore.subscribedChannels.map(channel => channel._id)
      await channelapi.updateSubscriptions(channelIds)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      Indicator.close()
    }
  }

  const unsubscribeChannel = async function (channelId) {
    try {
      const channels = channelStore.subscribedChannels.filter(channel => channel._id !== channelId)
      channelStore.$patch({
        subscribedChannels: channels
      })
      await updateSubscriptions()
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const subscribeChannel = async function (channelId, tmpChannelObj = undefined) {
    try {
      const channelIds = channelStore.subscribedChannels.map(channel => channel._id)
      channelIds.push(channelId)
      await channelapi.updateSubscriptions(channelIds)
      await channelStore.fetchSubscriptions()
      channelStore.setAdded(channelId, true)
      if (tmpChannelObj) {
        tmpChannelObj.isAdded = true
        tmpChannelObj.userCount += 1
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  if (!useRoute().params.channelId) {
    onActivated(() => {
      channelStore.fetchSubscriptions()
    }) 
  }

  onMounted(() => {
    channelStore.fetchSubscriptions()
  })

  return {
    updateSubscriptions,
    unsubscribeChannel,
    subscribeChannel,
    draggableChannels,
  }
}