//@ts-check
import { defineStore } from "pinia"
import { ref, watch } from "vue"
import localforage from 'localforage'
import { useToast } from "@/composables/toast"
import { useCurrentCountry } from "@/composables/country"

const key = 'ignoreR18Cover'

export const useR18CoverStore = defineStore('r18cover', () => {
  const r18coverVisible = ref(false)
  const { showToast } = useToast()
  const { countryCode } = useCurrentCountry()

  watch(r18coverVisible, async (value, oldValue) => {
    if (!value && oldValue) {
      try {
        await localforage.setItem(key, true)        
      } catch (err) {
        showToast(err.message)
      }
    }
  })

  const showR18Cover = async function () {
    try {
      if (r18coverVisible.value) {
        return
      }
      // do not show r18 cover for china as we've filtered the content without asking
      if (countryCode.value === 'CN') {
        return
      }
      const shouldIgnore = await localforage.getItem(key)
      if (!shouldIgnore) {
        r18coverVisible.value = true
      }
    } catch (err) {
      showToast(err.message)
    }
  }


  return {
    r18coverVisible,
    showR18Cover,
  }
})