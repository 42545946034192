<template>
  <div
    v-if="currentUser && !currentUser.authed"
    class="article">
    <p>Let us know your nickname on Perohub by DM offical twitter account</p>
    <p>You will get verified shortly</p>
    <pr-button
      size="small"
      @click.native="goToTwitter">
      Go To Twitter
    </pr-button>
  </div>
  <div v-else-if="currentUser && currentUser.authed">
    <p>Verification Completed</p>
    <pr-button
      type="primary"
      size="small"
      @click.native="$router.push('/i/publisher/publish')">
      Publish Artwork
    </pr-button>
  </div>
</template>

<script>
import PrButton from '@/components/PRButton.vue'
import { useCurrentUser } from '@/composables/user_data'


export default {
  setup () {
    const { currentUser } = useCurrentUser()

    const goToTwitter = () => {
      const newWindow = window.open('https://twitter.com/perohubcom', '_blank')
      if (!newWindow) {
        location.href = 'https://twitter.com/perohubcom'
      }
    }

    return {
      goToTwitter,
      currentUser,
    }
  },
  components: {
    PrButton
  }
}
</script>