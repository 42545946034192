import { computed, onMounted, onUnmounted, ref } from 'vue'
import UserAgent from 'express-useragent'

const mql = window.matchMedia('(max-width: 480px)')

export const useResponsive = function () {
  const isOnMobile = ref(!!mql.matches)
  const userAgent = UserAgent.parse(navigator.userAgent)

  const isIOS = computed(() => {
    return userAgent.isiPhone || userAgent.isiPad || userAgent.isiPod
  })

  const handler = e => {
    if (e.matches) {
      isOnMobile.value = true
    } else {
      isOnMobile.value = false
    }
  }

  onMounted(() => {
    mql.addEventListener('change', handler)
  })
  onUnmounted(() => {
    mql.removeEventListener('change', handler)
  })

  return {
    isOnMobile,
    isIOS,
  }
}