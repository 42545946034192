<template>
  <span>{{ text }}</span>
</template>

<script>
const date = new Date()
const tenMinsLater = new Date(date.setMinutes(date.getMinutes() + 10))

export default {
  props: {
    endTimestamp: {
      type: Number,
      default: tenMinsLater.getTime()
    }
  },
  data () {
    return {
      now: new Date().getTime(),
      timestamp: this.endTimestamp,
      lastId: 0
    }
  },
  computed: {
    text () {
      const seconds = parseInt((this.timestamp - this.now) / 1000)
      if (seconds > 0) {
        return this.secondsToHMS(seconds)
      } else {
        return '已结束'
      }      
    }
  },
  mounted () {
    this.countdown()
  },
  methods: {
    secondsToHMS (secs) {
      function z (n) {
        return (n < 10 ? '0' : '') + n
      }

      var sign = secs < 0 ? '-' : ''
      secs = Math.abs(secs)
      return sign + z(secs/3600 |0) + ':' + z((secs%3600) / 60 |0) + ':' + z(secs % 60)
    },
    countdown () {
      const vm = this

      if (this.lastId) {
        clearTimeout(this.lastId)
      }

      this.lastId = setTimeout(() => {
        this.now = new Date().getTime()
        vm.countdown()
      }, 1000)
    }
  }
}
</script>

<style scoped>
span {
  color: #cfcfcf;
}
</style>
