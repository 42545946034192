<template>
  <div class="container">
    <div class="title-wrap">
      <input
        v-model="title"
        type="text"
        :placeholder="$t('artworkTitle').toString()">
    </div>

    <div
      v-show="type === 'album' || type === '3dasset'"
      class="content-wrap">
      <textarea
        v-model="content"
        :placeholder="$t('artworkContent').toString()"
        cols="30"
        rows="10"></textarea>
    </div>
    <div
      v-show="type === 'album'"
      class="content-wrap">
      <text-block-editor
        v-for="(_, index) in textMedias"
        :key="index"
        v-model="textMedias[index]"
        :can-toggle-browse="priceInfo.price > 0 || requiresCircleRight"
        @close="textMedias.splice(index, 1)">
      </text-block-editor>
      <pr-button
        style="width: 100%; margin-bottom: 1.5rem;"
        @click.native="addTextMedia">
        {{ $t('addTextBlock') }}
      </pr-button>
    </div>

    <div v-show="type === 'article'">
      <div
        v-for="(media, index) in articleMedias"
        :key="index"
        ref="articleMediasDomRef">
        <text-block-editor
          v-if="media.type === MediaType.TEXT"
          v-model="articleMedias[index]"
          :can-toggle-browse="priceInfo.price > 0 || requiresCircleRight"
          @close="textMedias.splice(index, 1)">
        </text-block-editor>
        <preview-cell
          v-else
          class="dragger"
          :preview="media"
          :show-free-cover="media.canBrowse && (priceInfo.price > 0 || requiresCircleRight)"
          :show-backdrop="!media.canBrowse && (priceInfo.price > 0 || requiresCircleRight)"
          :progress="uploads[index] ? uploads[index] / media.file.size * 100 : 0"
          @click.native.prevent.stop="toggleCanBrowse(index)"
          @close="previews.splice(previews.indexOf(media), 1)">
        </preview-cell>
      </div>

      <pr-button
        style="width: 100%; margin-bottom: 0.5rem;"
        @click.native="addTextMedia">
        {{ $t('addTextBlock') }}
      </pr-button>
      <pr-button
        style="width: 100%; margin-bottom: 1.5rem;"
        @click.native="mediaInputRef.click()">
        {{ $t('addMedia') }}
      </pr-button>
    </div>

    <div class="slim-items">
      <dropdown
        v-model="originalOption"
        :options="originalOptions"
        :delighted="false"
        class="dropdown"></dropdown>
      <dropdown
        v-if="circleOptions.length > 1"
        v-model="circleOption"
        :options="circleOptions"
        class="dropdown"></dropdown>

      <prices-setter
        v-show="(original || allowPirate) && !requiresCircleRight && !issueId"
        v-model="priceInfo"
        class="price"
        @done="showClickTipIfNeeded"></prices-setter>
    </div>

    <div
      v-show="uploading"
      class="progressbar-wrap">
      <mt-progress
        :value="progress"></mt-progress>
    </div>

    <div
      v-show="previews.length === 0 && type !== 'article'"
      class="uploader-wrap touchable hoverable dashed-border"
      @click.stop="mediaInputRef.click()">
      <span>{{ indicatorText }}</span>
    </div>

    <draggable
      v-show="previews.length > 0 && type !== 'article'"
      v-model="previews"
      :move="dragging"
      draggable=".draggable"
      drag-class="dragging"
      class="medias">
      <preview-cell
        v-for="(preview, index) in previews"
        :key="index"
        class="draggable hoverable touchable"
        :preview="preview"
        :show-free-cover="preview.canBrowse && (priceInfo.price > 0 || requiresCircleRight)"
        :show-backdrop="!preview.canBrowse && (priceInfo.price > 0 || requiresCircleRight)"
        :progress="uploads[index] ? uploads[index] / preview.file.size * 100 : 0"
        @click.native.prevent.stop="toggleCanBrowse(index)"
        @close="previews.splice(index, 1)">
      </preview-cell>
      <div
        v-show="type === 'album' || type === 'comics'"
        class="hoverable touchable"
        style="display: flex; align-items: center; justify-content: center;"
        @click="mediaInputRef.click()">
        <i
          class="pi pi-plus icon-cover"
          style="font-size: 2rem;">
        </i>
      </div>
    </draggable>

    <mt-cell
      class="touchable hoverable"
      :title="$t('artworkType')">
      <select v-model="type">
        <option value="album">{{ $t('photo') }} {{ $t('illustration') }}</option>
        <option value="comics">{{ $t('manga') }}</option>
        <option value="article">{{ $t('article') }}</option>
        <option value="3dasset">{{ $t('threedasset') }}</option>
      </select>
    </mt-cell>

    <mt-cell
      title="R18">
      <input-switch v-model="isR18"></input-switch>
    </mt-cell>

    <mt-cell
      v-show="(priceInfo.price > 0 || requiresCircleRight) && type !== 'article'"
      :title="$t('useWatermark')">
      <input-switch v-model="useWatermark"></input-switch>
    </mt-cell>

    <mt-cell
      :title="$t('canBeCollected')">
      <input-switch v-model="collectible"></input-switch>
    </mt-cell>

    <mt-cell>
      <span
        ref="countdownTextRef"
        class="mint-cell-text"
        :class="{
          touchable: countdownable,
          hoverable: countdownable,
        }"
        @click.prevent="onTimerClicked">{{ countdownText }}</span>
      <input-switch v-model="countdownable"></input-switch>
    </mt-cell>

    <mt-cell
      v-show="circleIds.length > 0"
      :title="$t('requiresCircleRight')">
      <input-switch v-model="requiresCircleRight"></input-switch>
    </mt-cell>

    <mt-cell
      v-show="priceInfo.price === 0 && type === '3dasset'"
      :title="$t('canDownload')">
      <input-switch
        v-model="canDownload"
        :disabled="priceInfo.price > 0 || requiresCircleRight"></input-switch>
    </mt-cell>

    <mt-cell
      v-if="issueOptions.length > 1"
      :title="$t('deliverCommission')">
      <select v-model="issueId">
        <option
          v-for="option in issueOptions"
          :key="option.value"
          :value="option.value">
          {{ option.label.length > 50 ? `${option.label.slice(0, 50)}...` : option.label }}
        </option>
      </select>
    </mt-cell>

    <div
      v-show="tags.length > 0">
      <multi-select
        v-model="selfTagIds"
        :options="tags"
        :placeholder="$t('artworkCategories')"
        display="chip"
        option-label="title"
        option-value="_id"
        style="display: flex; justify-content: center; margin: 0.5rem 1rem;">
      </multi-select>
    </div>

    <div class="btns">
      <pr-button
        type="primary"
        @click.native="publish">
        {{ $t('publish') }}
      </pr-button>
    </div>

    <input
      v-show="false"
      ref="mediaInputRef"
      type="file"
      :accept="type === '3dasset' ? 'model/obj' : '*'"
      multiple
      @change="triggerFileInput">

    <mt-datetime-picker
      ref="timePickerRef"
      v-model="timeString"
      type="time"
      :cancel-text="$t('cancel')"
      :confirm-text="$t('confirm')"
      hour-format="{value} hours"
      minute-format="{value} minutes"
      @confirm="onTimePickerConfirmed"></mt-datetime-picker>

    <pr-popup
      v-model="showReferenceModal"
      class="reprint"
      @hide="closeReferenceModal">
      <template #header>
        <span>{{ $t('reference') }}</span>
      </template>
      <div class="reprint-content">
        <img src="@/assets/ic-page-creator-photo-source@2x.png">
        <input
          v-model="reference"
          :placeholder="$t('referencePlaceholder').toString()">
      </div>
      <p class="reprint-tip">
        {{ $t('referenceTip') }}
      </p>
      <pr-button
        type="primary"
        size="small"
        class="reprint-btn"
        @click.native="closeReferenceModal">
        {{ $t('confirm') }}
      </pr-button>
    </pr-popup>

    <watermark-positions-picker
      v-model="watermarkPositions"
      :visible="showWatermarkPositionsPicker"
      @close="showWatermarkPositionsPicker = false">
    </watermark-positions-picker>
  </div>
</template>

<script>
//@ts-check
import { useArtworkPublish } from '@/composables/artwork_publish'
import PricesSetter from '@/components/PricesSetter.vue'
import PrButton from '@/components/PRButton.vue'
import { useUploadMedia } from '@/composables/upload_media'
import Draggable from 'vuedraggable'
import Dropdown from '@/components/Dropdown.vue'
import { useResponsive } from '@/composables/responsive'
import { allowPirate } from '@/config'
import InputSwitch from 'primevue/inputswitch'
import WatermarkPositionsPicker from '@/components/WatermarkPositionsPicker.vue'
import MultiSelect from 'primevue/multiselect'
import { useSelfTagList } from '@/composables/selftag'
import { useCurrentUser } from '@/composables/user_data'
import { computed } from 'vue'
import PrPopup from '@/components/PRPopup.vue'
import TextBlockEditor from '@/components/TextBlockEditor.vue'
import PreviewCell from '@/components/PreviewCell.vue'
import { MediaType } from '@/utils/defines'

export default {
  setup () {
    const uploadMedia = useUploadMedia()
    const { currentUser, authenticated } = useCurrentUser()

    const userId = computed(() => {
      if (authenticated.value) {
        // @ts-ignore
        return currentUser.value._id
      }

      return null
    })

    const dragging = (event) => {
      if (!event.relatedContext.element) {
        return false
      }

      // 返 false 代表 cancel
      return event.draggedContext.element.deletable && event.relatedContext.element.deletable
    }

    return {
      ...useArtworkPublish({
        previews: uploadMedia.previews,
        uploadMedias: uploadMedia.uploadMedias,
        uploading: uploadMedia.uploading,
      }),
      ...uploadMedia,
      dragging,
      ...useResponsive(),
      allowPirate,
      ...useSelfTagList(userId),
      MediaType,
    }
  },
  components: {
    PricesSetter,
    PrButton,
    Draggable,
    Dropdown,
    InputSwitch,
    WatermarkPositionsPicker,
    MultiSelect,
    PrPopup,
    TextBlockEditor,
    PreviewCell,
  }
}
</script>

<style>
.reprint .p-dialog {
  width: 294px;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {

  .btns {
    margin-top: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 1em;

    button {
      width: 100%;
    }
  }

  .title-wrap, .content-wrap, .mint-cell, .slim-items {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .uploader-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 8rem;
    margin-bottom: 0.5rem;

    &.dashed-border {
      box-sizing: border-box;
      border-width: 0.2em;
      border-style: dashed;
      border-color: lighten(@main, 10%);
    }

    span {
      width: 100%;
      text-align: center;
    }
  }

  .progressbar-wrap {
    height: 2em;
    width: 100%;
  }
}


input, textarea {
  width: 100%;
  border: none;
  outline: none;
}

.title-wrap {
  margin-bottom: 2em;
  display: flex;
  align-items: baseline;

  span {
    margin-right: 0.5em;
    min-width: 2.3em;
  }

  input {
    flex-grow: 1;
  }
}

.slim-items {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  height: 2em;

  .price {
    margin-left: auto;
  }
}

.medias {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  gap: 1rem;

  @media @phone {
    grid-template-columns: repeat(3, 1fr);
  }

  @media @desktop {
    margin-bottom: 1em;
  }

  .scrollbar(@main, transparent);
}

.dragging {
  cursor: move;
}

.reprint-btn {
  margin-top: 1em;
  margin-left: 0.5em;
}

.reprint-top {
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  padding-left: 11px;
}
.reprint-top img {
  width: 22px;
  height: 22px;
  position: relative;
  top: 6px;

}

.reprint-top span {
  font-size: 14px;
  color: #333333;
  margin-left: 4px;
}

.reprint-content {
  margin-left: auto;
  margin-right: auto;
  width: 272px;
  border-bottom: 1px solid #f1f1f1;
  margin-top: 3px;
  padding-bottom: 4px;

}

.reprint-content img {
  width: 26px;
  height: 26px;
  position: relative;
  top: 4px;
} 

.reprint-content input {
  border: 0px;
  outline: none;
  position: relative;
  top: -2px;
  width: 230px;
  font-size: 17px;
}

.reprint-tip {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a5a5a5;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 9px;
}
/* 转载弹窗 */

.dropdown {
  margin-right: 0.5em;
}
</style>