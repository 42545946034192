<template>
  <div class="container" />
</template>

<script>
export default {
  metaInfo: {
    title: "选择要申请编辑推荐的作品",
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
</style>
