<template>
  <div class="reports">
    <div
      v-for="report in reports"
      :key="report._id"
      class="touchable hoverable report"
      @click="$router.push(`/reportcenter/${report._id}`)"
    >
      <div class="left">
        <span class="nickname">针对{{ report.suspect.nickname }}的举报</span>
        <span class="time">{{ new Date(report.createdAt).toLocaleString() }}</span>
      </div>

      <div class="right">
        <span
          v-if="report.status === 'reviewing'"
          :class="report.status"
        >待处理</span>
        <span
          v-if="report.status === 'settled' && report.verified"
          class="reportlist-verified"
        >
          属实
        </span>
        <span
          v-if="report.status === 'settled' && !report.verified"
          :class="report.status"
        >
          不属实
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reports: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.reports {
  margin-top: 0.5em;
}

.report {
  background: white;
  margin-bottom: 0.5em;
  margin: 10px;
  overflow: hidden;
  height: 53px;
  border-bottom: 1px solid @border;
  box-sizing: border-box;

  .title {
    font-weight: bold;
  }

  .left {
    width: 80%;
    display: inline-block;

    .nickname {
      font-weight: bold;
      display: block;
      font-size: 17px;
    }

    .time {
      color: rgba(0, 0, 0, 0.4);
      font-size: 12px;
      position: relative;
      top: -8px;
    }
  }

  .right {
    float: right;
    margin-top: 0.6em;
    text-align: right;

    span {
      font-size: 0.75em;
      height: 24px;
      width: 5em;
      line-height: 24px;
      display: block;
      text-align: center;
      border-radius: 12px;
      color: white;

      &.reviewing {
        background: rgb(49, 202, 252);
      }

      &.settled {
        background: rgb(204, 204, 204);
      }
    }
    .reportlist-verified {
      background: #ff5555;
    }
  }
}
</style>
