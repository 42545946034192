import { render, staticRenderFns } from "./CircleCollector.vue?vue&type=template&id=664c34aa&scoped=true"
import script from "./CircleCollector.vue?vue&type=script&lang=js"
export * from "./CircleCollector.vue?vue&type=script&lang=js"
import style0 from "./CircleCollector.vue?vue&type=style&index=0&id=664c34aa&prod&lang=css"
import style1 from "./CircleCollector.vue?vue&type=style&index=1&id=664c34aa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664c34aa",
  null
  
)

export default component.exports