//@ts-check
import { computed, onMounted, ref } from "vue"
import { exchange } from "@/api/exchange"
import { useCurrentUser } from "./user_data"
import { useToast } from "./toast"


export const useUserBalance = function () {
  const { currentUser: user } = useCurrentUser()
  const usdExchangeRates = ref({})
  const { Toast } = useToast()

  const balances = computed(() => {
    const balances = []
    // @ts-ignore
    const raw = user.value.balances
    for (const currency of Object.keys(raw)) {
      if (raw[currency] > 0) {
        balances.push({ currency, amount: raw[currency] })
      }
    }

    if (balances.length === 0) {
      balances.push({ currency: 'USD', amount: 0 })
    }

    return balances
  })

  const totalUSD = computed(() => {
    let total = 0

    // @ts-ignore
    for (const balance of balances.value.values()) {
      if (balance.currency === 'USD') {
        total += balance.amount
        continue
      }

      const rate = usdExchangeRates.value[balance.currency]
      if (isNaN(Number(rate))) {
        return 0
      }

      if (balance.currency === 'JPY') {
        total = total + Math.round(balance.amount / rate) * 100
      } else {
        total += Math.round(balance.amount / rate)
      }
    }

    return total
  })


  const getBalanceText = function ({ currency, amount }) {
    if (currency === 'JPY') {
      return `JPY ${amount}`
    }

    return `${currency} ${(amount / 100).toFixed(2)}`
  }

  onMounted(async () => {
    try {
      usdExchangeRates.value = await exchange('USD', false)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  })

  return {
    balances,
    getBalanceText,
    totalUSD,
  }
}