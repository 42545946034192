<template>
  <div class="container">
    <div class="form-container">
      <h1>{{ $t('openCircle') }}</h1>

      <mt-field
        v-model="title"
        :label="$t('circleTitle')"
        :placeholder="$t('inputCircleTitle')"></mt-field>

      <mt-field
        v-model="description"
        :label="$t('circleDescription')"
        :placeholder="$t('inputCircleDescription')"></mt-field>

      <mt-field
        v-model="introduction"
        type="textarea"
        rows="4"
        :label="$t('circleIntroduction')"
        :placeholder="$t('inputCircleIntroduction')"></mt-field>

      <mt-cell :label="$t('circleType')">
        <select-button
          v-model="displayType"
          :options="[
            { name: `${$t('photo')} ${$t('illustration')}`, value: 'album' },
            { name: $t('manga'), value: 'comics' },
            { name: $t('article'), value: 'article' },
          ]"
          option-label="name"
          option-value="value">
        </select-button>
      </mt-cell>

      <img
        v-if="coverUrl"
        loading="lazy"
        :src="coverUrl">
      <div
        v-else
        class="cover-placeholder">
        <span>{{ $t('clickBelowToUploadCircleCover') }}</span>
      </div>

      <pr-button
        @click.native="fileInput.click()">{{ $t('uploadCover') }}</pr-button>

      <progress-bar
        v-show="uploadProgress > 0"
        :value="uploadProgress"></progress-bar>

      <div class="presets-container">
        <select-button
          v-model="presetIndex"
          :options="[
            { name: 'Fantia Like', value: 0 },
            { name: 'YouTube Like', value: 1 },
            { name: 'Customize', value: 2 }
          ]"
          option-value="value"
          option-label="name">
        </select-button>

        <div class="preset">
          <mt-cell
            v-show="presetIndex === 2"
            :label="$t('keizaiTimeLength')">
            <select v-model="salesType">
              <option
                v-for="salesTypeOption in [
                  { label: $t('year'), value: 'year' },
                  { label: $t('halfYear'), value: 'half-year' },
                  { label: $t('quarter'), value: 'quarter' },
                  { label: $t('oneMonth'), value: 'month' },
                  { label: `${$t('forever')} (${$t('cannotComplete')})`, value: 'long-term' },
                ]"
                :key="salesTypeOption.value"
                :value="salesTypeOption.value">
                {{ salesTypeOption.label }}
              </option>
            </select>
          </mt-cell>

          <mt-cell
            :label="$t('recurringPrice')">
            <prices-setter
              v-model="pricesData"
              :default-currency="currency">
            </prices-setter>
          </mt-cell>

          <mt-cell
            v-show="presetIndex === 2"
            :label="$t('complementPrice')">
            <prices-setter
              v-model="complementPricesData"
              :default-currency="currency">
            </prices-setter>
          </mt-cell>

          <mt-cell
            v-show="presetIndex === 2 && salesType !== 'long-term'"
            :label="$t('upfrontPrice')">
            <prices-setter
              v-model="upfrontPricesData"
              :default-currency="currency">
            </prices-setter>
          </mt-cell>

          <mt-cell
            v-show="presetIndex === 2"
            :label="$t('forceComplement')">
            <input-switch v-model="forceComplement"></input-switch>
          </mt-cell>

          <Panel
            :header="$t('customerPricePreview')">
            <p>{{ $t('whenUserJoins') }}</p>
            <ul>
              <li>
                - {{ $t('circlePriceText', { priceText: formatPrice(pricesData.prices[currency], currency, false) }) }}
              </li>
              <li v-show="forceComplement && complementPricesData.prices[currency] > 0">
                - {{ $t('circleComplementPriceText', { priceText: formatPrice(complementPricesData.prices[currency], currency, false) }) }}
              </li>
              <li v-show="forceComplement && complementPricesData.prices[currency] === 0">
                - {{ $t('circleZeroComplementPriceText') }}
              </li>
              <li v-show="!forceComplement">
                - {{ $t('circleNonForceComplementPriceText', { priceText: formatPrice(complementPricesData.prices[currency], currency, false) }) }}
              </li>
              <li v-show="salesType !== 'long-term'">
                - {{ $t('circleUpfrontPriceText', { title, priceText: formatPrice(upfrontPricesData.prices[currency], currency, false) }) }}
              </li>
            </ul>
          </Panel>
        </div>
      </div>

      <pr-button
        class="submit"
        type="primary"
        @click.native="submit">{{ $t('submit') }}
      </pr-button>
    </div>

    <input
      ref="fileInput"
      hidden
      type="file"
      accept="image/*"
      @change="onFileInput">

    <Dialog
      :visible.sync="cropperShow"
      header="Image Resizer">
      <cropper
        v-if="cropperSrc"
        class="cropper"
        :src="cropperSrc"
        :stencil-props="{
          aspectRatio: 1/1,
          minAspectRatio: 1/1,
          maxAspectRatio: 1/1,
        }"
        @change="onCropperUpdated">
      </cropper>

      <template #footer>
        <div class="actions">
          <pr-button
            @click.native="closeCropper">{{ $t('cancel') }}</pr-button>
          <pr-button
            type="primary"
            @click.native="performCropping">Resize</pr-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import PricesSetter from '@/components/PricesSetter.vue'
import { useCircleApply } from '@/composables/circle_apply'
import { useResponsive } from '@/composables/responsive'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import Dialog from 'primevue/dialog'
import { useCurrentCurrency } from '@/composables/currency'
import { usePriceFormatter } from '@/composables/price_format'
import SelectButton from 'primevue/selectbutton'
import InputSwitch from 'primevue/inputswitch'
import Panel from 'primevue/panel/Panel'
import { useCoverUpload } from '@/composables/cover_upload'
import ProgressBar from 'primevue/progressbar'


export default {
  setup () {
    const coverComposition = useCoverUpload()
    return {
      ...coverComposition,
      ...useCircleApply({ coverUrl: coverComposition.coverUrl }),
      ...useResponsive(),
      ...useCurrentCurrency(),
      ...usePriceFormatter(),
    }
  },
  components: {
    PrButton,
    PricesSetter,
    Cropper,
    Dialog,
    SelectButton,
    InputSwitch,
    Panel,
    ProgressBar,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.p-slider {
  margin-bottom: 1rem;
}

.cropper {
  height: 40vh;
  max-width: 320px;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 10px;
  height: 6em;

  button {
    flex: 1;
  }
}

.form-container {
  .presets-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .preset {
    margin-top: 1rem;
    width: 100%;
  }

  h1 {
    margin-bottom: 1em;
  }

  img, .cover-placeholder {
    aspect-ratio: 1 / 1;
    width: 20em;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1rem;
  }

  .cover-placeholder {
    border-style: dashed;
    border-width: 2px;
    border-color: @main;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > button {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    width: 100%;

    &.submit {
      margin-top: 2em;
    }
  }
}

@media @phone {
  img, .cover-placeholder {
    text-align: center;
  }
}
</style>