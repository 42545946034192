<template>
  <artwork-manga></artwork-manga>
</template>


<script>
import ArtworkManga from '@/components/ArtworkManga.vue'

export default {
  components: {
    ArtworkManga,
  }
}
</script>