import Vue from 'vue'
import { API_URL } from '../config'

export default {
  send (postId, reason, contentIds) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/posts/${postId}/report`, {
          reason,
          contentIds
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve()
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getAll (page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/reports/all`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getMine (page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/reports/my`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getAccusation (page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/reports/target/my`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getOne (reportId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/reports/${reportId}/single`)
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  getComments (page, limit, reportId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/comments/report/comments`, {
          params: {
            page,
            limit,
            reportId
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  postComment (reportId, content) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/comments/reportComment`, {
          reportId,
          content
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
