<template>
  <div
    v-if="channel && channel._id"
    class="channel-container">
    <pr-empty
      v-if="channel.latestPosts.length === 0"
      :text="placeholderText"></pr-empty>

    <div v-else>
      <div
        v-if="channel.users.length > 0 && !$route.params.channelId"
        class="users">
        <header>
          <h5>{{ $t('recommendedSth', { item: $t('users') }) }}</h5>

          <router-link
            class="touchable hoverable"
            :to="`/channels/${channel._id}`">
            {{ $t('viewAll') }}
            <img src="@/assets/icon_page_nextpage_grey.png">
          </router-link>
        </header>

        <user-vertical-list
          :users="channel.users"></user-vertical-list>
      </div>

      <channel-info
        v-else-if="$route.params.channelId"
        :channel-id="$route.params.channelId"></channel-info>

      <mt-navbar
        v-model="selectedTabIndex"
        class="segmented-control"
        style="width: 100%;">
        <mt-tab-item
          v-if="channel.useAlgoTimeline"
          :id="0"
          class="touchable hoverable">
          {{ $t('forYou') }}
        </mt-tab-item>
        <mt-tab-item
          :id="1"
          class="touchable hoverable">
          {{ $t('latestPicks') }}
        </mt-tab-item>
        <mt-tab-item
          :id="2"
          class="touchable hoverable">
          {{ $t('hot') }}
        </mt-tab-item>
        <mt-tab-item
          :id="3"
          class="touchable hoverable">
          {{ $t('best') }}
        </mt-tab-item>
      </mt-navbar>

      <div
        class="contenthot">
        <mt-tab-container v-model="selectedTabIndex">
          <mt-tab-container-item
            v-if="channel.useAlgoTimeline"
            :id="0">
            <post-list-pc :posts="channel.algoPosts"></post-list-pc>
            <load-more-button
              :busy="algoLoading"
              :has-more="true"
              :trigger="getAlgoPosts"></load-more-button>
          </mt-tab-container-item>

          <mt-tab-container-item :id="1">
            <post-list-pc :posts="channel.latestPosts"></post-list-pc>
            <load-more-button
              :busy="latestLoading"
              :has-more="latestHasmore"
              :trigger="getLatestPosts"></load-more-button>
          </mt-tab-container-item>

          <mt-tab-container-item :id="2">
            <post-list-pc :posts="channel.hotPosts" />
            <load-more-button
              :busy="hotLoading"
              :has-more="hotHasmore"
              :trigger="getHotPosts"></load-more-button>
          </mt-tab-container-item>

          <mt-tab-container-item :id="3">
            <post-list-pc :posts="channel.bestPosts"></post-list-pc>
            <load-more-button
              :busy="bestLoading"
              :has-more="bestHasmore"
              :trigger="getBestPosts"></load-more-button>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
    </div>
  </div>
</template>

<script>
import UserVerticalList from '@/components/UserVerticalList.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue' 
import PostListPc from '@/components/PostListPc.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useChannelPresent } from '@/composables/channel_present'
import ChannelInfo from '@/components/ChannelInfo.vue'


export default {
  metaInfo () {
    return this.channelMetaInfo
  },
  props: {
    channelId: {
      type: String,
      required: false,
      default: null
    },
  },
  setup (props) {
    return {
      ...useChannelPresent(props),
    }
  },
  components: {
    LoadMoreButton,
    UserVerticalList,
    PostListPc,
    PrEmpty,
    ChannelInfo,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.channel-container {
  margin-left: auto;
  margin-right: auto;
  width: 810px;
}

.users {
  background: white;
  border-top: 1px solid @border;
  border-bottom: 1px solid @border;
  height: 203px;

  header {
    margin-top: 10px;
    margin-bottom: 5px;
    height: 21px;
    line-height: 21px;

    h5 {
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
    }

    a {
      font-size: 12px;
      color: @grey;
      display: block;
      float: right;

      img {
        width: 12px;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: -3px;
      }
    }
  }
}

.contenthot {
  margin-top: 20px;
  margin-bottom: 60px;
  margin-left: 14px;
}
</style>
