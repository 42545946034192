import { rtcapi } from "./base"

export const generateRandomClientId = () => {
  return Math.random().toString(36).substring(2, 15)
}

const clientId = generateRandomClientId()

rtcapi.interceptors.request.use(config => {
  const accessToken = config.headers['access-token']

  if (!accessToken) {
    config.params = config.params || {}
    config.params.clientId = clientId
  }

  return config
})

export const sendAnswer = async function (answer) {
  try {
    await rtcapi.post('/receiveAnswer', answer)
    console.log('Answer sent successfully to the server.')
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const sendCandidate = async function (candidate) {
  try {
    rtcapi.post('/addCandidate', candidate)
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchOffer = async function (mediaId) {
  try {
    const res = await rtcapi.get('/getOffer', {
      params: {
        mediaId
      }
    })
    return res.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getTurnCredentials = async function () {
  try {
    const res = await rtcapi.get('/turnCredentials')
    return res.data
  } catch (err) {
    console.error(err)
    throw err
  }
}