<template>
  <div
    ref="scrollDom"
    class="scroll"
    @mousewheel.prevent.stop="handleWheel">
    <router-link
      v-for="user in users"
      :key="user._id"
      class="user block-hoverable touchable"
      :to="`/users/${user._id}`">
      <user-avatar
        class="user-avatar"
        :user="user"
        :disabled="true"></user-avatar>
      <h6>{{ user.nickname }}</h6>
    </router-link>
  </div>
</template>

<script>
import UserAvatar from '@/components/UserAvatar.vue'
import { useResponsive } from '@/composables/responsive'
import { ref } from 'vue'
import { useScroll } from '@vueuse/core'


export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  setup () {
    const { isOnMobile } = useResponsive()
    const scrollDom = ref(null)
    const { x } = useScroll(scrollDom)
    let scrollingVertical = null
    let lastX = null
    let intervalId = null

    const observe = function () {
      intervalId = setInterval(() => {
        if (Math.abs(lastX - x.value) <= Number.EPSILON && scrollingVertical !== null) {
          scrollingVertical = null
          if (intervalId) {
            clearInterval(intervalId)
          }
        }
        lastX = x.value
      }, 100)
    }

    const handleWheel = function (event) {
      if (isOnMobile.value) {
        return true
      }

      if (scrollingVertical === null) {
        if (Math.abs(event.deltaY) > Number.EPSILON) {
          scrollingVertical = true
          observe()
        } else if (Math.abs(event.deltaX) > Number.EPSILON) {
          scrollingVertical = false
          observe()
        }
      }

      if (scrollingVertical === true) {
        x.value += event.deltaY * 0.8
      } else if (scrollingVertical === false) {
        x.value += event.deltaX * 1.5
      }
    }


    return {
      disabled: ref(false),
      scrollDom,
      handleWheel,
    }
  },
  components: {
    UserAvatar,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.scroll {
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  border: solid 1px #f1f1f1;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 17px;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 4px;


  .user {
    display: inline-block;
    width: 79px;
    border-radius: 4px;
    margin-right: 1em;
    padding-top: 10px;
    position: relative;

    h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      font-weight: 500;
      color: @black;
    }

    .user-avatar {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border-radius: 32px;
    }

    button {
      width: 88px;
      height: 25px;
      border-radius: 2px;
      font-size: 13px;
      margin-top: 10px;
    }
  }
}
</style>
