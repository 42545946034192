<template>
  <pr-popup
    v-model="visible"
    class="paypal-container">
    <div class="wrappay">
      <!-- <mt-field
        v-model="account"
        type="email"
        placeholder="PayPal Email"></mt-field>
      <pr-button @click.native="bind">
        Set PayPal Manually
      </pr-button>

      <hr> -->

      <div style="padding: 0.5em;">
        <div
          ref="paypalMountDom"
          class="login-with-paypal"></div>      
      </div>
    </div>
  </pr-popup>
</template>


<script>
//@ts-check
import { useResponsive } from '@/composables/responsive'
import { onMounted, ref, computed, watch } from 'vue'
import { bindPaypal, update } from '@/api/user'
import { paypalSandClientId, paypalProdClientId, paypalMerchantId } from '@/config'
import { useI18n } from 'vue-i18n-composable'
import { useCurrentUser } from '@/composables/user_data'
import { useAuthenticator } from '@/pinia/authenticator'
import { useToast } from '@/composables/toast'
import { useScriptTag, until } from '@vueuse/core'
import PrPopup from '@/components/PRPopup.vue'


const paypalAACJSUrl = 'https://www.paypalobjects.com/payouts/js/payouts_aac.js'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  emits: ['completed', 'input'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const account = ref('')
    const { currentUser, authenticated } = useCurrentUser()
    const authenticator = useAuthenticator()
    const { Toast } = useToast()
    const { load } = useScriptTag(paypalAACJSUrl, () => {}, { manual: true  })
    const paypalMountDom = ref(null)

    const visible = computed({
      set (value) {
        emit('input', value)
      },
      get() {
        return props.value
      }
    })


    watch(visible, () => {
      if (visible.value) {
        console.log('setupPaypalLogin')
        setupPaypalLogin()
      }
    })


    const setupPaypalLogin = async function () {
      try {
        await load()
        await until(paypalMountDom).toMatch(value => value !== null)

        //@ts-ignore
        paypal.PayoutsAAC.render({
          env: process.env.NODE_ENV === 'production' ? 'production' : 'sandbox',
          clientId: {
            sandbox: paypalSandClientId,
            production: paypalProdClientId,
          },
          merchantId: paypalMerchantId,
          pageType: 'login',
          async onLogin (response) {
            try {
              if (response.err) {
                console.error(response.err)
                throw new Error(response.err)
              }

              await bindPaypal(response.body.code)
              emit('completed')
              Toast({ message: t('success') }, 'ok')
            } catch (err) {
              Toast({
                message: err.message,
              })
            }
          }
        }, '.login-with-paypal')
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }


    const bind = async function () {
      try {
        await update(currentUser.value._id, {
          paypal: {
            email: account.value
          }
        })
        await authenticator.refreshUser()
        Toast({ message: t('success') }, 'ok')
        emit('completed')
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    onMounted(async () => {
      if (currentUser.value.paypal) {
        account.value = currentUser.value.paypal.email        
      }
    })


    return {
      ...useResponsive(),
      account,
      currentUser,
      authenticated,
      bind,
      visible,
      paypalMountDom,
    }
  },
  components: {
    PrPopup,
  }
}
</script>

<style>
.paypal-container .p-dialog {
  width: 30rem;
}

.paypal-container.p-sidebar {
  height: 16rem;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.hoverablebtn:hover {
  cursor: pointer;
  opacity: 0.65;
}

.wrappay {
  padding: 80px 16px 0;

  p {
    font-size: 12px;
    color: @black;
    margin-bottom: 6px;
    padding-left: 10px;
  }
  button {
    display: block;
    margin: auto;
    margin-top: 20px;
    width: 96%;
    background-color:@main;
    color: #ffffff;
  }
}

.mint-cell-wrapper {
  border-bottom: 1px solid @grey;
}
</style>
