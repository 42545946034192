//@ts-check
import { getCurrentInstance } from 'vue'
import { ToastSeverity } from 'primevue/api'


export const useToast = function () {
  const instance = getCurrentInstance()

  const Toast = function (messageOrMessageWrapper, level = 'error') {
    let message = ''
    let title = ''
    if (typeof messageOrMessageWrapper === 'string') {
      message = messageOrMessageWrapper
    } else {
      message = messageOrMessageWrapper.message
    }
    let severity = ToastSeverity.ERROR
    if (level === 'error') {
      severity = ToastSeverity.ERROR
      title = 'Error'
    } else if (level === 'info') {
      severity = ToastSeverity.INFO
      title = 'Info'
    } else if (level === 'warn') {
      severity = ToastSeverity.WARN
      title = 'Warning'
    } else if (level === 'ok') {
      severity = ToastSeverity.SUCCESS
      title = 'Success'
    }
    if (!instance) {
      return alert(message)
    }
    if (instance.proxy.$toast) {
      instance.proxy.$toast.add({ severity, summary: title, detail: message, life: 5000 })      
    }
  }

  const showToastWithButtons = function ({
    message,
    onConfirm,
    onReject,
    rejectLabel,
    confirmLabel,
    type = 'error'
  }) {
    let title = ''
    let severity = ToastSeverity.ERROR
    if (type === 'error') {
      severity = ToastSeverity.ERROR
      title = 'Error'
    } else if (type === 'info') {
      severity = ToastSeverity.INFO
      title = 'Info'
    } else if (type === 'warn') {
      severity = ToastSeverity.WARN
      title = 'Warning'
    } else if (type === 'ok') {
      severity = ToastSeverity.SUCCESS
      title = 'Success'
    }

    instance.proxy.$toast.add({
      severity,
      summary: title,
      detail: message,
      // life: 5000,
      onConfirm,
      onReject,
      rejectLabel,
      confirmLabel,
      group: 'withButtons'
    })

    return function () {
      instance.proxy.$toast.removeGroup('withButtons')
    }
  }

  return {
    Toast,
    showToast: Toast,
    showToastWithButtons,
  }
}