//@ts-check
import { getCurrentInstance } from "vue"


export const useConfirm = function () {
  const instance = getCurrentInstance()

  const confirm = function (primeVueConfirmOptions) {
    if (instance.proxy.$confirm) {
      instance.proxy.$confirm.require({
        ...primeVueConfirmOptions
      })
    }
  }

  return {
    confirm
  }
}