import { computed, ref } from "vue"
import { useCurrentUser } from "./user_data"
import { blockUser, unblockUser } from "@/api/user"
import { Indicator } from 'mint-ui'
import { useI18n } from "vue-i18n-composable"
import { APP_URL } from "@/config"
import { useToast } from "./toast"


export const useUserMoreMenu = function (plainUser) {
  const { currentUser, authenticated } = useCurrentUser()
  const showUserMoreMenu = ref(false)
  const { t } = useI18n()
  const { Toast } = useToast()

  const userMoreMenuItems = computed(() => {
    const items = []

    items.push({
      label: t('share'),
      async action () {
        try {
          await navigator.clipboard.writeText(`${APP_URL}/users/${plainUser._id}`)
          Toast('Link Copied', 'ok')
          showUserMoreMenu.value = false
        } catch (err) {
          Toast({
            message: err.message,
          })
        }
      }
    })

    if (authenticated.value) {
      if (currentUser.value._id !== plainUser._id) {
        if (plainUser.blocked) {
          items.push({
            label: t('unblock'),
            async action () {
              try {
                Indicator.open()
                showUserMoreMenu.value = false
                await unblockUser(plainUser._id)
                Toast(t('success'), 'ok')
              } catch (err) {
                Toast({
                  message: err.message,
                })
              } finally {
                Indicator.close()
              }
            },
          })
        } else {
          items.push({
            label: t('block'),
            async action () {
              try {
                Indicator.open()
                showUserMoreMenu.value = false
                await blockUser(plainUser._id)
                Toast(t('success'), 'ok')
              } catch (err) {
                Toast({
                  message: err.message,
                })
              } finally {
                Indicator.close()

              }
            },
            isDanger: true,
          })
        }
      }
    }

    return items
  })

  return {
    userMoreMenuItems,
    showUserMoreMenu,
  }
}