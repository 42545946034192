<template>
  <div class="content">
    <img src="@/assets/avatar_placeholder.png">

    <pr-button
      type="primary"
      size="small"
      @click.native="updateLoginVisible(true)">
      {{ $t('login') }}
    </pr-button>
  </div>
</template>

<script>
import { useResponsive } from '@/composables/responsive'
import { useApplication } from '@/pinia/application'
import PrButton from '@/components/PRButton.vue'


export default {
  setup () {
    const application = useApplication()
    return {
      ...useResponsive(),
      updateLoginVisible: application.updateLoginVisible
    }
  },
  components: {
    PrButton,
  }
}
</script>

<style scoped>
.content {
  background: white;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  display: inline-block;
  height: 100px;
}

button {
  display: block;
  margin-top: 3em;
  width: 6.6em;
  color: rgba(61, 64, 78, 0.77);
  border-color: rgba(61, 64, 78, 0.77);
}
</style>
