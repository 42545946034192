<template>
  <div class="notifications-container">
    <div class="actions">
      <pr-button
        size="small"
        :loading="notificationStore.clearing"
        @click.native="notificationStore.clearAll">
        <i class="pi pi-trash"></i>
      </pr-button>
    </div>

    <notification-cell
      v-for="notification in notificationStore.notifications"
      :key="notification._id"
      :notification="notification"
      class="touchable hoverable"></notification-cell>
  </div>
</template>

<script>
import NotificationCell from '@/components/NotificationCell.vue'
import { onMounted, ref } from 'vue'
import { useResponsive } from '@/composables/responsive'
import { useNotificationStore } from '@/pinia/notification'
import PrButton from '@/components/PRButton.vue'


export default {
  metaInfo: {
    title: 'Notifications'
  },
  setup () {
    const selected = ref(0)
    const notificationStore = useNotificationStore()

    onMounted(() => {
      notificationStore.fetch()
    })

    return {
      selected,
      ...useResponsive(),
      notificationStore,
    }
  },
  components: {
    NotificationCell,
    PrButton,
  }
}
</script>


<style lang="less" scoped>
.notifications-container {
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding: 1rem;
  }
}
</style>