import Vue from 'vue'
import { API_URL } from '../config'

export default {
  fetchIncome (timeNodes, nodeType) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/account/chart`, {
          params: {
            timeNodes,
            nodeType
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            const labels = []
            const datasets = [{
              label: 'USD',
              data: []
            }]

            data.result.forEach(income => {
              if (nodeType === 'month' || nodeType === '2month') {
                labels.push(income.startTimeNode.substring(0, 7))
              } else {
                labels.push(income.startTimeNode)
              }

              datasets[0].data.push(income.sales)
            })

            return resolve({
              labels: labels,
              datasets: datasets
            })
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchCoinsRecords (page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/account/detail`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchWithdraws (page, limit = 10) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/withdraws/list`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  fetchWithdrawTotal () {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/users/withdraws/total`)
        .then(({ ok, data }) => {
          if (ok) {
            return resolve(data.result.withdrawTotal)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
