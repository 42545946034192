<template>
  <div class="channels">
    <mt-spinner
      v-show="busy && channels.length === 0"
      type="snake"
      class="spinner"></mt-spinner>

    <router-link
      v-for="channel in channels"
      :key="channel._id"
      :to="`/channels/${channel._id}`">

      <div class="channel touchable hoverable">
        <h3>{{ channel.title }}</h3>
        <ins v-if="channel.type !== 'official'">{{ $t('adds', { count: channel.userCount }) }}</ins>

        <pr-button
          :type="channel.isAdded ? 'default' : 'primary'"
          :disabled="channel.isAdded"
          @click.prevent.stop.native="subscribe(channel._id)">
          {{ channel.isAdded ? $t('added') : $t('add') }}
        </pr-button>
      </div>
    </router-link>

    <load-more-button
      v-show="channels.length > 0"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search(keyword)">
    </load-more-button>

    <pr-empty
      v-if="channels.length === 0 && !hasMore"
      :text="$t('noChannelsFound')">
    </pr-empty>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PrButton from '@/components/PRButton.vue'
import { API_URL } from '@/config'
import PrEmpty from '@/components/PREmpty.vue'
import { useSubscriptionUpdating } from '@/composables/channel_subscribe'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    return {
      ...useSubscriptionUpdating(),
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      channels: [],
      hasMore: true,
      busy: false
    }
  },
  computed: {
    keyword () {
      return this.$route.query.q
    }
  },
  watch: {
    keyword (keyword, old) {
      this.page = 1
      this.hasMore = true
      this.channels = []

      this.search(keyword)
    }
  },
  methods: {
    search (keyword) {
      this.busy = true

      this.$http
        .get(`${API_URL}/channels/search/channels`, {
          params: {
            limit: 5,
            page: this.page,
            keywords: keyword
          }
        })
        .then(({ ok, data }) => {
          this.busy = false

          if (ok) {
            const channels = data.result

            if (this.page === 1) {
              this.channels = channels
            } else if (channels.length > 0) {
              channels.forEach(channel => this.channels.push(channel))
            }

            if (channels.length === 0) {
              this.hasMore = false
            }

            this.page = this.page + 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : (err.data ? err.data.message : err),
          })
        })
    },
    subscribe (channelId) {
      const channel = this.channels.filter(channel => channel._id === channelId)[0]
      this.subscribeChannel(channelId, channel)
    }
  },
  mounted () {
    if (this.keyword && this.channels.length === 0) {
      this.search(this.keyword)
    }
  },
  components: {
    LoadMoreButton,
    PrButton,
    PrEmpty,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}

.channels {
  overflow: hidden;
  text-align: left;
  width: 720px;
  margin: auto;

  @media @phone {
    width: 100%;
  }
}

.channel {
  width: 345px;
  height: 62px;
  line-height: 62px;
  border-radius: 4px;
  background-color: white;
  border: solid 1px #f1f1f1;
  margin-bottom: 8px;
  float: left;
  margin-right: 10px;

  @media @phone {
    width: 100%;
  }

  h3 {
    display: inline-block;
    font-size: 17px;
    font-weight: 500;
    color: #333333;
    margin-left: 11px;
  }

  ins {
    display: inline-block;
    font-size: 10px;
    color: #cfcfcf;
    margin-left: 6px;
  }

  button {
    float: right;
    margin-top: 14px;
    margin-right: 11px;
    height: 30px;
    border-radius: 4px;
    font-size: 13px;
  }
}

a {
  clear: both;
}
</style>
