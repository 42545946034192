<template>
  <div class="container">
    <header>
      <div
        class="score"
        :class="statusClasses"
      >
        <p>{{ statusString }}</p>
        <count-up
          v-if="typeof currentUser.untrust === 'number'"
          class="untrust"
          :start="0"
          :end-val="currentUser.untrust"
          :decimals="0"
          :duration="2.5"
        />
      </div>
    </header>

    <div class="group">
      <mt-cell
        title="什么是不良度"
        is-link
        @click.native="$router.push('untrust/about')"
      />
      <mt-cell
        title="不良度记录"
        is-link
        @click.native="$router.push('untrust/records')"
      />
    </div>
  </div>
</template>

<script>
import CountUp from 'vue-countup-v2'
import { useCurrentUser } from '@/composables/user_data'


export default {
  metaInfo: {
    title: "我的不良度",
  },
  setup () {
    return {
      ...useCurrentUser()
    }
  },
  computed: {
    status () {
      if (this.currentUser.untrust <= 150) {
        return 'low'
      } else if (this.currentUser.untrust > 150 && this.currentUser.untrust <= 300) {
        return 'intermediate'
      } else {
        return 'high'
      }
    },
    statusString () {
      if (this.currentUser.untrust <= 100) {
        return '优秀'
      } else if (this.currentUser.untrust > 100 && this.currentUser.untrust <= 300) {
        return '正常'
      } else if (this.currentUser.untrust > 300 && this.currentUser.untrust <= 400) {
        return '不良'
      } else if (this.currentUser.untrust > 400 && this.currentUser.untrust <= 500) {
        return '极度不良'
      } else {
        return '爆炸不良'
      }
    },
    statusClasses () {
      return {
        low: this.status === 'low',
        intermediate: this.status === 'intermediate',
        high: this.status === 'high'
      }
    }
  },
  components: {
    CountUp
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  width: 100%;
  background: #fafafa;
}

header {
  width: 100%;
  height: 15em;
  background: white;
  text-align: center;
  box-sizing: border-box;
  padding-top: 1em; //  15 - 13 = 1 * 2

  .low {
    color: #55ff6d;
  }

  .intermediate {
    color: #55caff;
  }

  .high {
    color: #ffda55;
  }

  .score {
    height: 13em;
    background: url('@/assets/meter.png') no-repeat center center;
    background-size: contain;
    padding-top: 4em;
    box-sizing: border-box;

    .untrust {
      font-size: 48px;
      font-weight: bold;
    }
  }
}
</style>
