import { onMounted, ref, computed, watch, onActivated } from 'vue'
import { useResponsive } from './responsive'
import { useCurrentUser } from './user_data'
import { useChannelStore } from '@/pinia/channel'
import { useAuthenticator } from '@/pinia/authenticator'
import { onBeforeRouteLeave } from 'vue-router/composables'


export const useSubscribedChannels = function () {
  const selected = ref(0)
  const channelStore = useChannelStore()
  const timelineShow = ref(false)
  const { isOnMobile } = useResponsive()
  const { authenticated } = useCurrentUser()
  const authenticator = useAuthenticator()

  const channels = computed(() => channelStore.subscribedChannels)

  const selectedChannel = computed(() => {
    return channels.value[selected.value]
  })


  watch(authenticated, async (value, oldValue) => {
    timelineShow.value = authenticated.value

    if (timelineShow.value) {
      selected.value = -1
    }

    if (authenticated.value && !oldValue.value) {
      await channelStore.fetchSubscriptions()
    }
  })


  watch(selectedChannel, () => {
    if (selectedChannel.value) {
      if (!timelineShow.value) {
        channelStore.$patch({ sourceChannelId: selectedChannel.value._id })        
      }
      channelStore.fetchChannel({ channelId: selectedChannel.value._id })
    }
  })


  watch(timelineShow, () => {
    if (timelineShow.value) {
      channelStore.$patch({ sourceChannelId: null })
    }
  })


  onMounted(async () => {
    channelStore.fetchSubscriptions()

    if (!isOnMobile.value) {
      await authenticator.checkAuth()
      timelineShow.value = authenticated.value

      if (timelineShow.value) {
        selected.value = -1
      }
    }

    if (selected.value >= 0 && selectedChannel.value) {
      channelStore.fetchChannel({ channelId: selectedChannel.value._id })
    } else if (selected.value === -1) {
      timelineShow.value = true
    }
  })


  onActivated(async () => {
    if (selectedChannel.value && !timelineShow.value) {
      channelStore.$patch({ sourceChannelId: selectedChannel.value._id })
    }
  })


  return {
    selected,
    channels,
    timelineShow,
    selectedChannel,
  }
}