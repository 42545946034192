<template>
  <div
    v-if="user"
    class="container">
    <out-of-service-cover
      v-if="user.isR18 && countryCode === 'CN'"></out-of-service-cover>
    <div v-if="(user.status === 0 || !user._id)">
      <user-info
        class="user-info"
        :user="user"></user-info>

      <div v-if="user.authed || allowPirate">
        <mt-navbar
          v-model="selected"
          class="segmented-control">
          <mt-tab-item
            :id="0"
            class="touchable"
            :class="{ hoverable: !isOnMobile }">
            {{ $t('latest') }}
          </mt-tab-item>
          <mt-tab-item
            :id="1"
            class="touchable"
            :class="{ hoverable: !isOnMobile }">
            {{ $t('hot') }}
          </mt-tab-item>
          <mt-tab-item
            v-if="circles.length > 0"
            :id="2"
            class="touchable"
            :class="{ hoverable: !isOnMobile }">
            {{ $t('circle') }}
          </mt-tab-item>
          <mt-tab-item
            v-if="user.authed && (user.issue.available || issues.length > 0)"
            :id="3"
            class="touchable"
            :class="{ hoverable: !isOnMobile }">
            {{ $t('assignment') }}
          </mt-tab-item>
        </mt-navbar>

        <mt-tab-container v-model="selected">
          <mt-tab-container-item :id="0">
            <div
              v-if="tags.length > 0"
              class="self-tags">
              <self-tag-selector
                :tag="{ title: 'Artworks' }"
                :selected="selectedTagId === ''"
                class="touchable"
                :class="{ hoverable: !isOnMobile }"
                @click.native="selectedTagId = ''"></self-tag-selector>

              <self-tag-selector
                v-for="tag in tags"
                :key="tag._id"
                :tag="tag"
                :selected="selectedTagId === tag._id"
                class="touchable"
                :class="{ hoverable: !isOnMobile }"
                @click.native="selectedTagId = tag._id">
              </self-tag-selector>
            </div>

            <div v-show="selectedTagId">
              <post-list-pc
                v-if="!isOnMobile"
                :posts="tagArtworks"></post-list-pc>
              <post-list-mobile
                v-if="isOnMobile"
                class="mobile-wrap"
                :posts="tagArtworks"></post-list-mobile>
            </div>

            <div v-show="!selectedTagId">
              <post-list-pc
                v-if="!isOnMobile"
                :posts="latestPosts"></post-list-pc>
              <post-list-mobile
                v-if="isOnMobile"
                class="mobile-wrap"
                :posts="latestPosts"></post-list-mobile>
              <load-more-button
                :busy="latestLoading"
                :has-more="latestHasmore"
                :trigger="getLatestPosts"></load-more-button>
            </div>
          </mt-tab-container-item>

          <mt-tab-container-item :id="1">
            <post-list-pc
              v-if="!isOnMobile"
              :posts="hotPosts"></post-list-pc>
            <post-list-mobile
              v-if="isOnMobile"
              class="mobile-wrap"
              :posts="hotPosts"></post-list-mobile>
            <load-more-button
              :busy="hotLoading"
              :has-more="hotHasmore"
              :trigger="getHotPosts"></load-more-button>
          </mt-tab-container-item>

          <mt-tab-container-item
            v-if="circles.length > 0"
            :id="2"
            :class="{
              'circles-container': !isOnMobile,
            }">
            <div
              v-for="circle in circles"
              :key="circle._id"
              :class="{
                'mobile-wrap': isOnMobile,
              }">
              <circle-cell-desktop
                v-if="!isOnMobile"
                :circle="circle"
                @click.native="$router.push(`/circles/${circle._id}`)">
              </circle-cell-desktop>
              <circle-cell
                v-if="isOnMobile"
                :circle="circle"
                @click.native="$router.push(`/circles/${circle._id}`)">
              </circle-cell>
            </div>
          </mt-tab-container-item>

          <mt-tab-container-item
            v-if="user.authed && (user.issue.available || issues.length > 0)"
            :id="3">
            <pr-button
              style="width: 100%;"
              type="primary"
              :disabled="!user.issue.available"
              @click.native="$router.push(`/users/${$route.params.userId}/commissions/create`)">
              {{ user.issue.available ? $t('createAssignment') : $t('assignmentUnavailable') }}
            </pr-button>
            <issue-list
              :issues="issues"
              style="margin-top: 1rem;">
            </issue-list>
            <load-more-button
              :busy="issueLoading"
              :has-more="issueHasMore"
              :trigger="fetchIssues">
            </load-more-button>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
      <div
        v-else
        class="unauthorized">
        This user is not a verified artist
      </div>
    </div>
    <banned
      v-else-if="user.status !== 0"
      :user="user"></banned>
  </div>
</template>

<script>
//@ts-check
import UserInfo from '@/components/UserInfo.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import Banned from '@/components/Banned.vue'
import PostListPc from '@/components/PostListPc.vue'
import PostListMobile from '@/components/PostListMobile.vue'
import { useResponsive } from '@/composables/responsive'
import { useUserPresent } from '@/composables/user_present'
import OutOfServiceCover from '@/components/OutOfServiceCover.vue'
import { useCurrentCountry } from '@/composables/country'
import { useUserSelfTagList } from '@/composables/user_selftag'
import SelfTagSelector from '@/components/SelfTagSelector.vue'
import { allowPirate } from '@/config'
import CircleCellDesktop from '@/components/CircleCellDesktop.vue'
import CircleCell from '@/components/CircleCell.vue'
import { useAssigneeIssues } from '@/composables/issue_assignee'
import { useRoute } from 'vue-router/composables'
import IssueList from '@/components/IssueList.vue'
import { useCurrentUser } from '@/composables/user_data'
import PrButton from '@/components/PRButton.vue'

export default {
  metaInfo () {
    return this.userMetaInfo
  },
  setup () {
    return {
      ...useAssigneeIssues(useRoute().params.userId),
      ...useResponsive(),
      ...useUserPresent(),
      ...useCurrentCountry(),
      ...useUserSelfTagList(),
      ...useCurrentUser(),
      allowPirate,
    }
  },
  data () {
    return {
      selected: 0,
    }
  },
  components: {
    UserInfo,
    LoadMoreButton,
    Banned,
    PostListPc,
    PostListMobile,
    OutOfServiceCover,
    SelfTagSelector,
    CircleCell,
    CircleCellDesktop,
    IssueList,
    PrButton,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.unauthorized {
  padding-top: 2em;
  text-align: center;
}

.user-info {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}


.segmented-control {
  margin-left: 10px;
  margin-right: 10px;
}

.mint-tab-container {
  overflow: visible;
  padding-top: 1em;
}

@media (min-width: 800px) {
  .container {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .user-info {
    padding-left: 0px;
    padding-right: 0px;
  }
  .segmented-control {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.mobile-wrap {
  margin-right: 10px;
  margin-left: 10px;
}

.self-tags {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.circles-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
}
</style>
