<template>
  <component :is="isOnMobile ? CirclePortal : CirclePortalDesktop"></component>
</template>

<script>
import CirclePortalDesktop from '@/components/CirclePortalDesktop.vue'
import CirclePortal from '@/components/CirclePortal.vue'
import { useResponsive } from '@/composables/responsive'


export default {
  setup () {
    return {
      ...useResponsive(),
      CirclePortal,
      CirclePortalDesktop,
    }    
  },
}
</script>

<style lang="less" scoped>

</style>