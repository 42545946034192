//@ts-check
import { computed, ref } from "vue"
import { useToast } from "./toast"
import { registerEmail } from "@/api/system"
import { useI18n } from "vue-i18n-composable"


export const useRegisterEmail = () => {
  const newEmail = ref('')
  const newPassword = ref('')
  const registerLoading = ref(false)
  const { Toast } = useToast()
  const showVerfication = ref(false)
  const time = ref(60)
  const { t } = useI18n()

  const countdown = () => {
    if (time.value === 0) {
      return
    }
    time.value -= 1
    setTimeout(countdown, 1000)
  }

  const captchaButtonText = computed(() => {
    if (time.value === 60) {
      return t('getCaptcha')
    } else {
      return t('timeleft', { time: time.value })
    }
  })

  const register = async function () {
    try {
      registerLoading.value = true
      await registerEmail(newEmail.value, newPassword.value)
      showVerfication.value = true
      time.value = 60
      countdown()
    } catch (err) {
      console.error(err)
      Toast(err)
    } finally {
      registerLoading.value = false
    }
  }

  return {
    newEmail,
    newPassword,
    registerLoading,
    register,
    showVerfication,
    time,
    captchaButtonText,
  }
}