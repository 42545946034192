<template>
  <div
    v-if="channel._id"
    class="container">
    <div class="left">
      <img
        loading="lazy"
        :src="channel.coverUrl">

      <div class="info">
        <div class="title">
          <h2>{{ channel.title }}</h2>
          <span>(ID:{{ channel.uniqueNum }})</span>
        </div>

        <div class="owner">
          <router-link
            :to="`/users/${channel.owner._id}`">{{ channel.owner.nickname }}</router-link>
          <span>{{ $t('channelOwner') }}</span>
        </div>

        <p>{{ channel.description }}</p>
      </div>
    </div>

    <div class="operations">
      <more-menu
        v-model="showChannelMoreMenu"
        :items="channelMoreMenuItems">
        <icon
          v-if="isOnMobile"
          name="more-horizontal"
          class="hoverable touchable"
          @click.native="showChannelMoreMenu = true"></icon>
        <icon
          v-else
          name="more-horizontal"
          class="hoverable touchable"
          @click.native="showChannelMoreMenu = true"
          @mouseover.native="showChannelMoreMenu = true"></icon>
      </more-menu>

      <pr-button
        v-if="!isOnMobile"
        size="small"
        :type="channel.isAdded ? 'default' : 'primary'"
        :disabled="channel.isAdded"
        @click.prevent.stop.native="subscribeChannel(channel._id)">
        {{ channel.isAdded ? $t('added') : $t('add') }}
      </pr-button>
    </div>
  </div>
</template>

<script>
import { useChannelPresent } from '@/composables/channel_present'
import PrButton from '@/components/PRButton.vue'
import Icon from 'vue-icon/lib/vue-feather.esm'
import { useChannelMoremenu } from '@/composables/channel_moremenu'
import MoreMenu from '@/components/MoreMenu.vue'
import { useSubscriptionUpdating } from '@/composables/channel_subscribe'
import { useResponsive } from '@/composables/responsive'


export default {
  props: {
    channelId: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const channelPresentComposition = useChannelPresent(props)

    return {
      ...channelPresentComposition,
      ...useChannelMoremenu(channelPresentComposition.channel),
      ...useSubscriptionUpdating(),
      ...useResponsive(),
    }
  },
  components: {
    PrButton,
    Icon,
    MoreMenu,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 2em;

  .operations {
    position: absolute;
    top: 0;
    right: 0;

    button {
      margin-left: 20px;
    }
  }

  @media @phone {
    .operations {
      right: 0.5em;
    }
  }
}


.left {
  display: flex;
  color: grey;
  font-size: 14px;

  img {
    width: 84px;
    height: 84px;
  }

  .info {
    margin-left: 9px;
  }

  h2 {
    color: @black;
    font-size: 17px;
    font-weight: bolder;
    display: inline-block;
    margin-right: 7px;
  }

  a {
    color: @blue;
    margin-right: 6px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.operations {
  display: flex;
}
</style>