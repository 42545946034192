<template>
  <div class="more-menu-container">
    <slot ref="moreButton"></slot>

    <Sidebar
      v-if="isOnMobile"
      :visible.sync="visible"
      position="bottom"
      :style="moreMenuStyle"
      :show-close-icon="false">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="more-menu-item touchable hoverable"
        :style="getMenuItemStyle(item)"
        @click.prevent.stop="item.action">
        {{ item.label }}
      </div>
    </Sidebar>
    <div
      v-else
      v-show="visible"
      class="more-menu card-1"
      :style="moreMenuStyle"
      @click.prevent.stop="visible = !visible"
      @mouseover="visible = true"
      @mouseleave="visible = false">
      <div
        v-for="item in items"
        :key="item.label"
        class="more-menu-item"
        :class="{ danger: item.isDanger }"
        @click.prevent.stop="item.action">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { useResponsive } from '@/composables/responsive'
import { watch, ref, computed } from 'vue'
import Sidebar from 'primevue/sidebar'


export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    negativeTop: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['input'],
  setup (props, { emit }) {
    const { isOnMobile } = useResponsive()
    const visible = ref(props.value)
    const moreButton = ref(null)

    const moreMenuStyle = computed(() => {
      if (isOnMobile.value) {
        return {
          minHeight: `${3 + props.items.length * 2.5}em`,
        }
      }

      const style = {
        top: `${40 - props.negativeTop}px`,
      }

      return style
    })


    watch(props, () => {
      visible.value = props.value
    })

  
    watch(visible, () => {
      emit('input', visible.value)
    })


    const getMenuItemStyle = function (item) {
      const style = {
        width: '100%',
        padding: '0.5em',
        boxSizing: 'border-box',
        height: '2.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #f1f1f1'
      }
      if (item.isDanger) {
        style.color = 'red'
      }
      return style
    }


    return {
      getMenuItemStyle,
      moreMenuStyle,
      isOnMobile,
      visible,
      moreButton,
    }
  },
  components: {
    Sidebar,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.more-menu-container {
  overflow: visible;
}

.more-menu {
  display: block;
  width: 184px;
  background: white;
  border-radius: 4px;
  border: solid 0.5px #dedede;
  position: absolute;
  top: 40px;
  right: 8px;
  z-index: 5;

  .more-menu-item {
    display: block;
    height: 32px;
    font-size: 14px;
    color: @black;
    padding: 5px 11px;
    box-sizing: border-box;
    border-bottom: solid 1px @border;
    text-align: left;

    &.danger {
      color: #ff5555;
    }

    &:hover, &:active {
      background: @border;
      cursor: pointer;
    }
  }
}
</style>