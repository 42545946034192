//@ts-check
import { until } from '@vueuse/core'
import { ref } from 'vue'
import { useToast } from './toast'
import { getTurnCredentials, sendAnswer, fetchOffer, sendCandidate } from '@/api/rtc'

export const useModelStreamRTC = (mediaId, canvasDomRef, logs) => {
  const connectionState = ref('closed')
  const iceConnectionState = ref('disconnected')
  const { showToast } = useToast()
  const channel = ref(null)

  const connectRTC = async function () {
    try {
      await until(canvasDomRef).toMatch(v => v !== null)
      const context = canvasDomRef.value.getContext('2d')

      const credentials = await getTurnCredentials()

      const peerConnection = new RTCPeerConnection({
        iceServers: [credentials]
      })

      peerConnection.onconnectionstatechange = () => {
        if (peerConnection.connectionState === 'connected') {
          logs.value.push('Peer connection established, communication can start.')
        }
        connectionState.value = peerConnection.connectionState
      }

      peerConnection.oniceconnectionstatechange = () => {
        logs.value.push(`ICE Connection State Change:${peerConnection.iceConnectionState}`);
        if (peerConnection.iceConnectionState === 'connected') {
          logs.value.push('ICE connection is successfully established.')
        }
        iceConnectionState.value = peerConnection.iceConnectionState
      }

      peerConnection.ondatachannel = event => {
        channel.value = event.channel
        channel.value.onmessage = async event => {
          try {
            const blob = new Blob([event.data], { type: 'image/jpeg' })
            const imageBitmap = await createImageBitmap(blob)
            context.clearRect(0, 0, canvasDomRef.value.width, canvasDomRef.value.height)
            context.drawImage(imageBitmap, 0, 0, canvasDomRef.value.width, canvasDomRef.value.height)
          } catch (err) {
            console.error(err)
          }
        }

        channel.value.onopen = () => {
          logs.value.push('Data channel is open.')
        }

        channel.value.onclose = () => {
          logs.value.push('Data channel is closed.')
        }

        channel.value.onerror = err => {
          logs.value.push(`Data channel error: ${err}`)
        }

        channel.value.onclosing = () => {
          logs.value.push('Data channel is closing.')
        }
      }

      peerConnection.onicecandidate = async event => {
        if (event.candidate) {
          logs.value.push(`Sending ICE candidate to server:${event.candidate}`)
          try {
            await sendCandidate(event.candidate)            
          } catch (err) {
            console.error(err)
            showToast(err.message)
          }
        } else {
          logs.value.push('ICE Gathering complete')
        }
      }

      const offer = await fetchOffer(mediaId)
      await peerConnection.setRemoteDescription(new RTCSessionDescription(offer))

      const answer = await peerConnection.createAnswer()
      await peerConnection.setLocalDescription(answer)

      // Ensure all ICE candidates are gathered before sending the answer
      await new Promise(resolve => {
        if (peerConnection.iceGatheringState === 'complete') {
          resolve()
        } else {
          peerConnection.onicecandidate = event => {
            if (event.candidate === null) {  // Null candidate indicates end of gathering
              resolve()
            }
          }
        }
      })

      await sendAnswer(answer)
    } catch (err) {
      console.error(err)
      showToast(err.message, 'error')
    }
  }

  return {
    channel,
    connectRTC,
    connectionState,
    iceConnectionState,
  }
}