import Vue from 'vue'
import { API_URL } from '../config'

export default {
  fetch (channelId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/bookings/bookableObjs`, {
          params: {
            channelId
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            const placements = data.result


            placements.forEach(placement => {
              placement.records = new Array(placement.maxBookingIndex + 1)

              placement.bookingRecords.forEach(record => {
                placement.records[record.bookingIndex] = record
              })
            })
            return resolve(placements)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },
  fetchMultiple (channelIds) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/bookings/channels/bookableObjs`, {
          params: {
            channelIds
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            const placements = data.result

            placements.forEach(placement => {
              placement.records = new Array(placement.maxBookingIndex + 1)

              placement.bookingRecords.forEach(record => {
                placement.records[record.bookingIndex] = record
              })
            })

            return resolve(placements)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },
  book (placementId, index, price,indexOffsetMax,minprice) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/bookings/booking`, {
          bookingObjId: placementId,
          indexExpect: index,
          maxPrice: price,
          minPrice: minprice,
          indexOffsetMax: indexOffsetMax
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve()
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },
  cancel (placementId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/bookings/unBooking`, {
          bookingObjId: placementId
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },
  create (channelId, { description, maxBookingIndex }, startTimestamp, endTimestamp) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/bookings/create`, {
          channelId,
          description,
          maxBookingIndex,
          status: 'on',
          startTimestamp,
          endTimestamp
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },
  fetchOngoing (channelId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/bookings/currentChannelObj`, {
          params: {
            channelId
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            if (!data.result) {
              return resolve(data.result)
            }

            const placement = data.result

            placement.records = new Array(placement.maxBookingIndex + 1)

            placement.bookingRecords.forEach(record => {
              placement.records[record.bookingIndex] = record
            })

            return resolve(placement)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
