import { onMounted, ref, watch } from 'vue'
import starapi from '@/api/media'
import { useCurrentUser } from './user_data'
import { useToast } from './toast'


export const useStars = function () {
  const starHasmore = ref(true)
  const starPage = ref(1)
  const albums = ref([])
  const { authenticated } = useCurrentUser()
  const starLoading = ref(false)
  const { Toast } = useToast()


  watch(authenticated, () => {
    if (authenticated.value) {
      getStarCategories()
    }
  })


  const getStarCategories = async function () {
    try {
      starLoading.value = true
      const newCategories = await starapi.albums(starPage.value)
      if (newCategories.length === 0) {
        starHasmore.value = false
      } else {
        starPage.value += 1
        albums.value.push(...newCategories)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      starLoading.value = false
    }
  }

  onMounted(() => {
    if (authenticated.value) {
      getStarCategories()
    }
  })

  return {
    albums,
    starHasmore,
    starPage,
    getStarCategories,
    starLoading,
  }
}