<template>
  <masonry
    :cols="3"
    :gutter="10">
    <div
      v-for="post in posts.filter(post => post)"
      :key="post._id"
      class="common">
      <post-list-item-over
        v-if="post.type === 'album' || post.type === '3dasset' || post.type === 'article'"
        :post="post"></post-list-item-over>
      <post-list-item-overcomic
        v-else-if="post.type === 'comics'"
        :post-id="post._id"></post-list-item-overcomic>
    </div>
  </masonry>
</template>

<script>
import PostListItemOver from '@/components/PostListItemOver.vue'
import PostListItemOvercomic from '@/components/PostListItemOvercomic.vue'

export default {
  components: {
    PostListItemOver,
    PostListItemOvercomic,
  },
  props: {
    posts: {
      type: Array,
      required: true
    },
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";


.common {
  width: 100%;
  margin-bottom: 1em;
}
</style>
