<template>
  <UserPage></UserPage>
</template>


<script>
//@ts-check
import UserPage from '@/pages/users/_id/index.vue'

export default {
  components: {
    UserPage
  }
}
</script>

<style scoped></style>