//@ts-check
import { getIPGuide } from "@/api/ip"
import { onMounted, ref } from "vue"
import { useToast } from "./toast"

let _countryCode = null
let _clientIp = null

export const useCurrentCountry = function () {
  const countryCode = ref('JP')
  const clientIp = ref(null)
  const { Toast } = useToast()

  onMounted(async () => {
    if (!_countryCode || !_clientIp) {
      try {
        const { countryCode: loc, ip } = await getIPGuide()
        _countryCode = loc
        _clientIp = ip
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    countryCode.value = _countryCode
    clientIp.value = _clientIp
  })

  return {
    clientIp,
    countryCode,
  }
}