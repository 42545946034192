<template>
  <div class="container">
    <router-link
      v-for="user in users"
      :key="user._id"
      :to="`/users/${user._id}`">
      <user-list-item 
        :user="user"
        class="hoverable touchable"></user-list-item>
    </router-link>

    <load-more-button
      :has-more="hasMore"
      :busy="busy"
      :trigger="getUsers"></load-more-button>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import UserListItem from '@/components/UserListItem.vue'
import api from '@/api/user'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    return {
      ...useToast(),
    }
  },
  components: {
    LoadMoreButton,
    UserListItem
  },
  beforeRouteUpdate (to) {
    this.init()
  },
  data () {
    return {
      page: 1,
      busy: false,
      hasMore: true,
      followers: false,
      users: [],
    }
  },
  mounted () {
    if (this.$route.path.indexOf('followers') !== -1) {
      document.title = '粉丝列表'
      this.followers = true
      this.getUsers()
    } else if (this.$route.path.indexOf('followings') !== -1) {
      document.title = '关注列表'
      this.followers = false
      this.getUsers()
    }
  },
  methods: {
    init () {
      this.page = 1
      this.busy = false
      this.hasMore = true
      this.followers = false
      this.users = []
    },
    getUsers () {
      this.busy = true

      api
        .getRelatives(this.$route.params.userId, this.followers, this.page)
        .then(users => {
          this.busy = false

          if (users.length === 0) {
            this.hasMore = false
          } else {
            this.page += 1
            users.forEach(user => this.users.push(user))
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>

@media (max-width: 800px) { 
  .container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    box-sizing: border-box;
  }
}

@media (min-width: 800px) { 
  .container {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }
}


</style>
