<template>
  <div class="users">
    <mt-spinner
      v-show="busy && users.length === 0"
      type="snake"
      class="spinner"></mt-spinner>
    <router-link
      v-for="user in users"
      :key="user._id"
      :to="`/users/${user._id}`">
      <user-list-item 
        :user="user"
        class="user touchable hoverable">
      </user-list-item>
    </router-link>
    <load-more-button
      v-show="users.length > 0"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search(keyword)">
    </load-more-button>

    <pr-empty
      v-if="users.length === 0 && !hasMore"
      :text="$t('noUsersFound')">
    </pr-empty>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import UserListItem from '@/components/UserListItem.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useToast } from '@/composables/toast'
import { searchUsers } from '@/api/user'


export default {
  setup () {
    return {
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      users: [],
      hasMore: true,
      busy: false
    }
  },
  computed: {
    keyword () {
      return this.$route.query.q
    }
  },
  watch: {
    keyword (keyword, old) {
      this.page = 1
      this.hasMore = true
      this.users = []

      this.search(keyword)
    }
  },
  methods: {
    async search (keyword) {
      this.busy = true

      try {
        const users = await searchUsers(keyword, this.page)

        if (this.page === 1) {
          this.users = users
        } else if (users.length > 0) {
          users.forEach(user => this.users.push(user))
        }

        if (users.length === 0) {
          this.hasMore = false
        }

        this.page = this.page + 1
      } catch (err) {
        console.error(err)

        this.showToast({
          message: err.message ? err.message : (err.data ? err.data.message : err),
        })
      } finally {
        this.busy = false
      }
    }
  },
  mounted () {
    if (this.keyword && this.users.length === 0) {
      this.search(this.keyword)
    }
  },
  components: {
    LoadMoreButton,
    UserListItem,
    PrEmpty,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}
.users {
  text-align: center;
  width: 720px;
  margin: auto;

  @media @phone {
    width: 100%;
  }
}

.user {
  width: 345px;
  float: left;
  margin-right: 10px;
  text-align: left;

  @media @phone {
    width: 100%;
  }
}

a {
  clear: both;
}
</style>
