<template>
  <div class="promote-book-create-container">
    <div class="channel">
      <span
        v-for="(channel, index) in channels"
        :key="index"
        class="touchable"
        :class="{active : channel.isSelected}"
        @click="selectChannel(channel,index)"
      >{{ channel.title }}</span>
    </div>

    <mt-navbar
      v-model="selected"
      class="segmented-control segmented-control-wrap"
    >
      <mt-tab-item
        :id="0"
        class="touchable"
      >
        预约中
      </mt-tab-item>
      <mt-tab-item
        :id="1"
        class="touchable"
      >
        生效中
      </mt-tab-item>
    </mt-navbar>
 
    <mt-tab-container v-model="selected">
      <mt-tab-container-item :id="0">
        <div class="placements">
          <placement
            v-for="(placement, index) in placements"
            :key="index"
            :placement="placement"
            :bookable="false"
            :anonymous="false"
          />
        </div>
      </mt-tab-container-item>
      <mt-tab-container-item :id="1">
        <div class="placements">
          <placement
            v-for="(placement, index) in runnings"
            :key="index"
            :placement="placement"
            :bookable="false"
            :anonymous="false"
          />
        </div>
      </mt-tab-container-item>
    </mt-tab-container>

    <pr-button @click.native="chooseshowModal">
      开放用户推广位
    </pr-button>
    <mt-popup
      id="modal"
      v-model="showModal"
      class="modal"
      position="bottom"
    >
      <div class="wrap">
        <p class="title">
          开放用户推广位
        </p>
        <mt-field
          v-model="description"
          label="推广信息"
          placeholder="本次预约的介绍"
        />
        <a class="mint-cell mint-field">
          <div class="mint-cell-wrapper">
            <div class="mint-cell-title">
              <span class="mint-cell-text">推广位数量</span>
            </div>
            <div class="mint-cell-value num">
              <img
                class="add"
                src="@/assets/ic-page-fans-payment-increase@2x.png"
                @click="add"
              >
              <input
                v-model="max"
                readonly="readonly"
                type="number"
              >
              <img
                class="reduce"
                src="@/assets/ic-page-fans-payment-reduce@2x.png"
                @click="reduce"
              >
            </div>
          </div>
        </a>
        <mt-field label="推广开始时间">
          <span
            class="starttime"
            @click="showPicker('start')"
          >{{ startText }}</span>
        </mt-field>
        <mt-field label="推广结束时间">
          <span
            class="starttime"
            @click="showPicker('end')"
          >{{ endText }}</span>
        </mt-field>
        <pr-button
          size="large"
          @click.native="submit"
        >
          提交
        </pr-button>
      </div>
    </mt-popup> 

    <mt-datetime-picker
      ref="startPicker"
      v-model="innerStartDate" 
      type="datetime"
      month-format="{value} 月"
      date-format="{value} 日"
      :start-date="pickerstartDate"
      @confirm="pickDate('start')"
    />

    <mt-datetime-picker
      ref="endPicker"
      v-model="innerEndDate"
      type="datetime"
      :start-date="pickerstartDate"
      month-format="{value} 月"
      date-format="{value} 日"
      @confirm="pickDate('end')"
    />
  </div>
</template>

<script>
import Placement from '@/components/Placement.vue'
import api from '@/api/placement'
import { fetchMyChannels } from '@/api/channel'
import PrButton from '@/components/PRButton.vue'

export default {
  metaInfo: {
    title: "开放频道推广位",
  },
  components: {
    Placement,
    PrButton,
  },
  data () {
    return {
      selected: 0,
      showModal: false,
      placements: [],
      runnings: [],
      channels: [],
      innerStartDate: new Date(),
      startDate: '',
      innerEndDate: new Date(),
      endDate: '',
      description: '',
      max: 3,
      channelId: '',
      pickerstartDate: new Date(),
      selectChannelid: 0
    }
  },
  computed: {
    startText () {
      if (this.startDate) {
        return this.startDate.toLocaleString()
      } else {
        return '选择时间'
      }
    },
    endText () {
      if (this.endDate) {
        return this.endDate.toLocaleString()
      } else {
        return '选择时间'
      }
    },
    showingChannels () {
      return this.channels.map(channel => {
        return {
          label: channel.title,
          value: channel._id,
          isSelected: false,
        }
      })
    },
  },
  mounted () {
    this
    .fetchChannels()
    .then(() => {
      this.selectChannel(this.channels[0],0)
    })
  },
  methods: {
    chooseshowModal () {
      this.showModal = true
      //this.startDate = ''
      //this.endDate = ''
      //this.description = ''
      //this.max = 3
    },
    add () {
      if (this.max < 10) {
        this.max++
      }
    },
    reduce () {
      if(this.max > 0) {
        this.max--
      }
    },
    selectChannel (selectChannel,index) {
      this.channels.forEach(channel => {
        channel.isSelected = (channel._id === selectChannel._id)
      })
      this.selected = 0
      this.fetchOngoingChannel(selectChannel._id)
      this.fetchPlacementsChannel(selectChannel._id)
      this.channelId = this.channels[index]._id
      this.selectChannelid = index
    },
    showPicker (type = 'start') {
      if (type === 'start') {
        this.$refs.startPicker.open()
      } else if (type === 'end') {
        this.$refs.endPicker.open()
      }
    },
    pickDate (type = 'start') {
      if (type === 'start') {
        this.startDate = this.innerStartDate
      } else if (type === 'end') {
        this.endDate = this.innerEndDate
      }
    },
    fetchChannels () {
      fetchMyChannels()
        .then(channels => {
          channels.map(channel => {
            channel.isSelected = false
          })
          this.channels = channels

          if (channels.length === 0) {
            this.$messagebox.alert('您还没有自己的频道，请先在「创作者」→「频道管理」中创建频道')
          }

          return
        })
        .catch(err => {
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    },
    fetchPlacementsChannel (channelid) {
      api
        .fetch(channelid)
        .then(placements => {

          const total = []
          this.placements = []
          placements.forEach(placement => total.push(placement))

          this.placements = total
        })
        .catch(err => {
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    },
    fetchOngoingChannel (channelid) {
      api
        .fetchOngoing(channelid) 
        .then(placements => {
          this.runnings = []
          if(placements) {
            this.runnings.push(placements)
          } 
        })
        .catch(err => {
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    },
    submit () {
      if (!this.startDate) {
        this.showModal = false

        return this.$toast({
          message: '没有选择起始时间',
          position: 'bottom'
        })
      }

      if (!this.endDate) {
        this.showModal = false

        return this.$toast({
          message: '没有选择结束时间',
          position: 'bottom'
        })
      }

      const channelId = this.channelId
      const description = this.description
      const maxBookingIndex = this.max - 1
      const startTimestamp = this.startDate.getTime()
      const endTimestamp = this.endDate.getTime()

      if (!channelId) {
        this.showModal = false

        return this.$toast({
          message: '没有选择频道',
          position: 'bottom'
        })
      }

      if (!description) {
        this.showModal = false

        return this.$toast({
          message: '没有输入描述',
          position: 'bottom'
        })
      }

      if (!maxBookingIndex) {
        this.showModal = false

        return this.$toast({
          message: '必须开放至少一个推广位',
          position: 'bottom'
        })
      }

      this.showModal = false
      this.$indicator.open()

      api
        .create(channelId, {
          description,
          maxBookingIndex
        }, startTimestamp, endTimestamp)
        .then(() => {
          this.$indicator.close()

          this.$toast({
            message: '创建成功',
            position: 'bottom'
          })

          this.selectChannel(this.channels[this.selectChannelid],this.selectChannelid)
        })
        .catch(err => {
          this.$indicator.close()

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    }
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

.promote-book-create-container{
  padding: 0 10px;

  .mint-navbar.segmented-control {
    margin-top: 0px;
  }

  .channel {
    overflow-x: scroll;
    white-space: nowrap;
    background: white;
    padding: 10px 0 12px;

    span {
      display: inline-block;
      height: 30px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      color: @minor;
      text-align: center;
      line-height: 29px;
      border-radius: 15px;
      margin-right: 10px;
      padding: 0 10px;
      min-width: 85px;

      &.active {
        background: @border;
        border: 1px solid @border;
      }
    }
  }
  .segmented-control-wrap {
    margin-bottom: 10px;
  }
  button {
    width: 100%;
    background: @main;
    color: @white;
    margin-top: 10px;
    font-size: 18px;
  }

  .modal {
    width: 100%;
    height: 306px;
    overflow-y: scroll;

    .mint-field .mint-cell-title {
      width: 130px !important;
    }
    button {
      margin-bottom: 0px;
      margin-top: 16px;
    }
    .wrap {
      margin: 0 10px;

      .mint-cell-wrapper {
        border-bottom: 1px solid @border;
      }
      .title {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid @border;
        font-size: 14px;
        color: @minor;
      }
      .mint-field-core {
        text-align: right;
      }
      .num {
        position: relative;
        height: 100%;

        img {
          position: absolute;
          width: 20px;
          height: 20px;
        }
        .reduce {
          left: 60px;
        }
        .add {
          right: 0px;
        }
        input {
          appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 0;
          border: 0;
          outline: 0;
          line-height: 1.6;
          font-size: inherit;
          width: 30px;
          position: absolute;
          right: 36px;
          text-align: right;
        }
      }
      .starttime {
        color: @grey;
        font-weight: normal;
      }
    }
  }
  button {
    margin-bottom: 20px;
  }
  .placements + button {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .mint-field-other > button {
    margin-right: 0.6em;
  }
}


</style>
