<template>
  <div class="container">
    <header
      v-if="!searchShow"
      class="searchbar-wrapper">
      <img
        class="back-icon touchable"
        src="@/assets/Navigation_Back@2x.png"
        @touchend="$router.back()">

      <search-entry
        class="searchbar-entry"
        :placeholder="$t('searchChannels')"
        @touchend.native="searchShow = true"></search-entry>
    </header>

    <div
      v-else
      class="searchbar-wrapper">
      <input
        ref="searchInputDom"
        v-model="keyword"
        type="text"
        @keyup.enter="searchChannels">

      <img
        src="@/assets/Navigation_Cancel@2x.png"
        class="close-icon touchable"
        @touchend="searchShow = false">
    </div>


    <div
      v-if="searchShow"
      class="channels-search-results">
      <div
        v-for="channel in channelResults"
        :key="channel._id"
        class="channel-result">
        <div>
          <span>{{ channel.title }}</span>
          <ins>{{ $t('adds', { count: channel.userCount }) }}</ins>
        </div>
        <pr-button
          :type="channel.isAdded ? 'default' : 'primary'"
          :disabled="channel.isAdded"
          @click.prevent.stop.native="subscribeChannel(channel._id, channel)">
          {{ channel.isAdded ? $t('added') : $t('add') }}
        </pr-button>
      </div>
    </div>


    <div v-else>
      <div class="channels-added">
        <header>
          <p>{{ $t('addedChannels') }}</p>

          <button class="touchable">
            <span @click="editing = !editing">{{ editing ? $t('confirm') : $t('edit') }}</span>
          </button>
        </header>

        <div class="channels">
          <draggable
            v-model="draggableChannels"
            :move="dragging"
            filter=".ignore"
            drag-class="dragging"
            @end="updateSubscriptions">
            <div
              v-for="(channel, index) in draggableChannels"
              :key="channel._id"
              class="channel">
              <span>{{ channel.title }}</span>
              <div
                v-show="editing"
                class="close"
                @touchstart="hoverIndex = index"
                @touchend="unsubscribeChannel(channel._id)">
                <img
                  v-show="hoverIndex !== index"
                  src="@/assets/ic_page_channel_manager_delete.png">
                <img
                  v-show="hoverIndex === index"
                  src="@/assets/ic_page_channel_manager_delete_press.png">
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSubscriptionUpdating } from '@/composables/channel_subscribe'
import Draggable from 'vuedraggable'
import SearchEntry from '@/components/SearchEntry.vue'
import { ref } from 'vue'
import { useChannelSearch } from '@/composables/channel_search'
import PrButton from '@/components/PRButton.vue'


export default {
  setup () {
    const editing = ref(false)
    const searchShow = ref(false)
    const hoverIndex = ref(-1)

    const dragging = () => {
      if (editing.value) {
        return true
      }

      return false
    }

    return {
      editing,
      dragging,
      searchShow,
      ...useSubscriptionUpdating(),
      ...useChannelSearch(),
      hoverIndex,
    }
  },
  components: {
    Draggable,
    SearchEntry,
    PrButton,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.container {

  .searchbar-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5em;

    .searchbar-entry {
      flex-grow: 1;
      margin-right: 0.5em;
    }

    input {
      flex-grow: 1;
      margin-left: 0.5em;
      border: 0;
      caret-color: @main;

      &:focus {
        outline: none;
      }
    }

    .back-icon {
      width: 2em;
    }

    .close-icon {
      width: 2em;
    }
  }

  .channels-added {
    margin-top: 0.5em;

    header {
      display: flex;
      align-items: center;
      height: 2em;
      padding: 0 0.5em;
      background-color: @border;

      p {
        flex-grow: 1;
        color: @warm-grey;
        font-size: 14px;
        font-weight: bolder;
      }

      button {
        width: 4rem;
        height: 1.4rem;
        border-radius: 0.7rem;
        border-color: @grey;
        border-width: 1px;
        border-style: solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @warm-grey;

        * {
          font-size: 12px;
        }
      }
    }
  }

  .channels-search-results {
    padding: 0.5em;

    .channel-result {
      display: flex;
      align-items: center;

      > div {
        flex-grow: 1;

        ins {
          display: inline-block;
          font-size: 10px;
          color: @grey;
          margin-left: 6px;
        }
      }

      button {
        height: 30px;
        border-radius: 4px;
        font-size: 13px;
      }
    }
  }

  .channels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding: 0.5em;
    margin-top: 1rem;

    .channel {
      background-color: @border;
      width: 7em;
      height: 1.8em;
      border-radius: 0.9em;
      color: @warm-grey;
      font-weight: bolder;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      position: relative;

      span {
        font-size: 14px;
      }

      .close {
        position: absolute;
        top: -0.5em;
        left: -0.2em;
        width: 1.4em;
        height: 1.4em;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>