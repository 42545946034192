import UserAgent from 'express-useragent'
import EventEmitter from 'eventemitter3'
import { streamingUrlRTC, API_URL, cdn } from '@/config'


const userAgent =  UserAgent.parse(navigator.userAgent)

export const isInWeixin = () => {
  if (typeof WeixinJSBridge !== 'undefined') {
    return true
  }

  const ua = navigator.userAgent.toLowerCase()
  const result = ua.match(/MicroMessenger/i)
	if (result && result[0] === 'micromessenger') {
		return true
 	} else {
		return false
	}
}


// 仅判断 iOS
export const isInPeroApp = () => {
  if (typeof PeroJSBridge !== 'undefined') {
    console.log('PeroJSBridge detected')
    return true
  }

  console.log('PeroJSBridge undetected')
  return false
}


// 仅判断 Baidu
export const isBaidu = () => {
  if (document.referrer.indexOf("baidu")>0) {
    return true
  } else {
    return false
  }
}

export const standalone = () => {
  if ('standalone' in navigator) {
    return navigator.standalone
  }

  return (window.matchMedia('(display-mode: standalone)').matches)
}

export const isOnMobile = () => {
  return userAgent.isMobile
}

export const isIOS = () => {
  return userAgent.isiPhone || userAgent.isiPad || userAgent.isiPod
}

export const diff = function (posts, localPosts) {
  const newPosts = []

  for (let i = 0; i < posts.length; i++) {
    const post = posts[i]
    let duplicate = false

    for (let j = 0; j < localPosts.length; j++) {
      const localPost = localPosts[j]

      if (post._id === localPost._id) {
        duplicate = true
        break
      }
    }

    if (!duplicate) {
      newPosts.push(post)
    }
  }

  return newPosts
}

export const placeholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

export class ScrollPosition {
  constructor (node) {
    this.node = node
    this.previousScrollHeightMinusTop = 0
    this.readyFor = 'up'
  }

  restore () {
    this.node.scrollTop = this.node.scrollHeight - this.previousScrollHeightMinusTop
  }

  prepareFor (direction) {
    this.readyFor = direction || 'up'
    this.previousScrollHeightMinusTop = this.node.scrollHeight - this.node.scrollTop
  }
}

export const disableInspector = function () {
  if (location.href.indexOf('/app/promote') !== -1) {
    return
  }

  const redirect = function () {
    window.close()
    window.location = 'about:blank'
  }

  document.addEventListener('contextmenu', e => {
    e.preventDefault()
    return false
  })

  document.addEventListener('keydown', e => {
    // F12
    if (e.key === 'F12') {
      redirect()
      return false
    }
  })

  window.addEventListener('resize', () => {
    if (isInspectorOpened()) {
      redirect()
    }
  })

  const isInspectorOpened = function () {
    console.profile()
    console.profileEnd()
    if (console.clear) {
      console.clear()
    }

    if (typeof console.profiles == 'object') {
      return console.profiles.length > 0
    }

    if ((window.outerHeight - window.innerHeight) > 200) {
      return true
    }

    if ((window.outerWidth - window.innerWidth) > 200) {
      return true
    }

    return false
  }

  if ((window.console && window.console.firebug) || (typeof opera == 'object' && typeof opera.postError == 'function')) {
    redirect()
  }

  if (isInspectorOpened()) {
    redirect()
  }
}

export const formatComponentName = function (vm) {
  if (vm.$root === vm) {
    return 'root'
  }

  const name = vm._isVue ? (vm.$options && vm.$options.name) || (vm.$options && vm.$options._componentTag) : vm.name
  return (name ? 'component <' + name + '>' : 'anonymous component') + (vm._isVue && vm.$options && vm.$options.__file ? ' at ' + (vm.$options && vm.$options.__file) : '')
}


export const addParamsToRoute = function (route, params) {
  history.replaceState(
    {},
    null,
    route.path +
      '?' +
      Object.keys(params)
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          )
        })
        .join('&')
  )
}


export const eventEmitter = new EventEmitter()


export const seoworkds = 'Perohub,pero,应援团,福利姬,二次元福利,原创作品社区'


export const countryCode2Locale = function (code) {
  let locale = 'en'

  if (['HK', 'CN', 'TW', 'SG', 'MO'].includes(code)) {
    locale = 'zh'
  } else if (code === 'KR') {
    locale = 'ko'
  } else if (code === 'JP') {
    locale = 'ja'
  }

  return locale
}


export const isValidDomain = function (domain) { 
  const re = /(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]/g
  const matches = domain.match(re)

  if (matches && matches[0] === domain) {
    return true
  }

  return false
}

export const getModelPreviewUrl = function (mediaId) {
  const url = new URL('preview', streamingUrlRTC)
  url.searchParams.append('mediaId', mediaId)
  return url.toString()
}


export const getCDNHost = function () {
  if (API_URL.includes('pero.app')) {
    return cdn.hk
  }

  return cdn.global
}


export const mayUseVPN = function (countryCode) {
  return ['CN', 'HK', 'SG'].includes(countryCode)
}