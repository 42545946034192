//@ts-check
import { defineStore } from "pinia"
import { ref } from "vue"


export const useProfileStore = defineStore('profile', () => {
  const myPostIds = ref([])
  const buyPostIds = ref([])

  return {
    myPostIds,
    buyPostIds,
  }
})