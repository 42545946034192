import { eventEmitter } from "@/utils"
import { useRoute } from "vue-router/composables"
import { ref } from "vue"
import { events } from "@/utils/events"


function getOffsetTop(element) {
  let offsetTop = 0
  while(element) {
    offsetTop += element.offsetTop
    element = element.offsetParent
  }
  return offsetTop
}


export const useMediaListScroll = function () {
  const route = useRoute()
  const enabled = route.params.postId && route.path.indexOf('/posts') !== -1
  const mediaListDom = ref(null)


  const scrollToIndex = function (index) {
    const dom = Array.from(mediaListDom.value.children)[index]
    if (!dom) {
      return
    }

    const absoluteElementTop = getOffsetTop(dom)
    const middle = absoluteElementTop - (window.innerHeight / 2) + (dom.clientHeight / 2)
    window.scrollTo({
      top: Math.max(0, middle),
    })
  }

  eventEmitter.on(events.ViewerEntered, (postId) => {
  })

  eventEmitter.on(events.ViewerQuitOnIndex, (postId, index) => {
    if (!mediaListDom.value) {
      return
    }
    if (!enabled) {
      return
    }
    if (postId !== route.params.postId) {
      return
    }
    scrollToIndex(index)
  })


  return {
    mediaListDom,
  }
}