<template>
  <div class="container">
    <div class="inputer">
      <img
        v-if="authenticated"
        :src="currentUser.avatarURL"
        :alt="currentUser.nickname"
      >
      <input
        v-model="commentContent"
        type="text"
        :placeholder="authenticated ? $t('enterComment') : $t('pleaseLogin')"
        :disabled="!authenticated"
      >
      <pr-button
        type="primary"
        :disabled="!authenticated"
        @click.native="postComment"
      >
        {{ $t('send') }}
      </pr-button>
    </div>

    <mt-spinner
      v-show="showSpinner"
      type="snake"
      class="spinner"
    />

    <comment-cell
      v-for="comment in comments"
      v-show="comments.length > 0"
      :key="comment._id"
      :comment="comment"
    />

    <load-more-button
      v-show="comments.length > 0"
      :busy="busy"
      :has-more="hasMore"
      :trigger="getComments($route.params.postId)"
    />

    <p v-show="comments.length === 0 && !showSpinner">
      {{ $t('nomoreComment') }}
    </p>

    <comment-replies
      v-show="showReplies"
      ref="repliesModal"
    />
  </div>
</template>

<script>
import { API_URL } from '../config'
import LoadMoreButton from './LoadMoreButton'
import PrButton from './PRButton'
import CommentCell from './CommentCell'
import CommentReplies from './CommentReplies'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'


const limit = 10

export default {
  setup () {
    return {
      ...useResponsive(),
      ...useToast(),
      ...useCurrentUser()
    }
  },
  data () {
    return {
      comments: [],
      showSpinner: false,
      page: 1,
      hasMore: true,
      busy: false,
      commentContent: ''
    }
  },
  mounted () {
    this.getComments(this.$route.params.postId)

    this.bodyEl = document.body
    this.top = 0
  },
  methods: {
    getComments (postId) {
      this.busy = true

      if (this.page === 1) {
        this.showSpinner = true
      }

      this.$http
        .get(`${API_URL}/comments`, {
          params: {
            postId,
            page: this.page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            const comments = data.result.comments

            if (comments.length > 0) {
              data.result.comments.forEach(comment => this.comments.push(comment))
            } else {
              this.hasMore = false
            }
          }

          this.page = this.page + 1
          this.busy = false
          this.showSpinner = false
        })
        .catch(err => {
          console.error(err)
          this.busy = false
          this.showSpinner = false
        })
    },
    postComment () {
      this.$indicator.open()

      const postId = this.$route.params.postId
      const content = this.commentContent

      this.$http
        .post(`${API_URL}/comments`, {
          postId,
          content
        })
        .then(({ ok, data }) => {
          this.$indicator.close()

          if (ok && data.code === 200) {
            this.commentContent = ''
            this.page = 1
            this.comments = []
            this.getComments(postId)
          }
        })
        .catch(err => {
          this.$indicator.close()

          console.error(err)

          this.showToast({
            message: err.message ? err.message : (err.data ? err.data.message : err),
          })
        })
    },
    showReplies (comment) {
      this.$refs.repliesModal.show(comment)
    },
    stopBodyScroll (isFixed) {
      if (isFixed) {
        this.top = window.scrollY

        this.bodyEl.style.position = 'fixed'
        this.bodyEl.style.top = -top + 'px'
        this.bodyEl.style.width = '100%'
      } else {
        this.bodyEl.style.position = 'static'
        this.bodyEl.style.top = ''

        window.scrollTo(0, this.top) 
      }
    },
  },
  components: {
    LoadMoreButton,
    PrButton,
    CommentCell,
    CommentReplies
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  padding-top: 20px;
  padding-bottom: 10px;

  > p {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 120px;
  }

  .inputer {
    text-align: center;
    margin-bottom: 20px;
    padding-left: 3px;
    padding-right: 6px;

    img {
      display: inline;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      vertical-align: middle;
    }

    input {
      height: 30px;  
      max-width: 440px;
      border-radius: 2px;
      border: solid 1px #cfcfcf;
      outline: 0;
      padding-left: 10px;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      width: 60%;
      left: 4px;

      &[disabled] {
        background: #eee;
        color: @light-grey;
      }
    }


    @media (min-width: 800px) {
      input {
        left: 10px;
        width: 70%;
      }
    }
    button {
      width: 64px;
      height: 30px;
      font-size: 13px;
      border-radius: 2px;
      vertical-align: middle;
      position: relative;
      right: -6px;
    }

    @media (min-width: 800px) {
      button {
        right: -10px;
      }
    }

  }
  @media (min-width: 800px) {
    .inputer {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}
</style>
