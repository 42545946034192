import { api } from "./base"



export const deleteComment = async function (commentId) {
  try {
    const res = await api.delete(`/comments/${commentId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}