import { reusePendingPromise } from 'reuse-pending-promise'
import { api } from "./base"


const _getIPGuide = async function () {
  try {
    const res = await api.get('/users/ipInfov2')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getIPGuide = reusePendingPromise(_getIPGuide)