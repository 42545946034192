import Vue from 'vue'
import { API_URL } from '../config'
import { api } from './base'


export const starMedias = async function (picIds) {
  try {
    const res = await api.post('/medias/starMulti', {
      picIds,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getResolutions = async function (mediaId) {
  try {
    const res = await api.get(`/medias/${mediaId}/resolutions`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export default {
  albums (page = 1, limit = 10) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/medias/favorite/owners`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  //批量取消收藏图片
  albumsDelete (pictureIds) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/medias/unstarMulti`, {
          pictureIds: pictureIds
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },



  //搜索收藏
  albumsSearch (keywords) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/medias/favorite/ownersSearch?keywords=${keywords}`)
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  oldImages (timestamp, ownerId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/medias/favorite/old`, {
          params: {
            timestamp,
            ownerId
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  newImages (timestamp, ownerId, limit = 10) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/medias/favorite/new`, {
          params: {
            timestamp,
            ownerId,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            if (ok && data.code === 200) {
              return resolve(data.result)
            } else {
              return reject(data.message)
            }
          }
        })
        .catch(err => {
          if (err.data) {
            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  }
}
