<template>
  <component :is="CircleList"></component>
</template>

<script>
import CircleList from '@/components/CircleList.vue'

export default {
  setup() {
    return {
      CircleList,
    }
  },
}
</script>