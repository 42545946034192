<template>
  <div class="container">
    <mt-field
      v-model="title"
      :label="$t('channelTitle')"
      :placeholder="$t('inputChannelTitle')"></mt-field>
    <mt-cell
      :title="$t('needVerify')">
      <input-switch v-model="needVerify"></input-switch>
    </mt-cell>
    <mt-cell
      :title="$t('setRoyaltyRate')">
    </mt-cell>
    <mt-range
      v-model="royaltyRate"
      :min="0"
      :max="100"
      :step="1"
      :bar-height="1.5">
      <div
        slot="end"
        style="width: 3em; display: flex; justify-content: end;">{{ royaltyRate }}%</div>
    </mt-range>
    <ul
      style="user-select: none;">
      <li>Only applied when artist submits</li>
      <li>Perohub takes 0% of your earn</li>
    </ul>
    <mt-cell
      :title="$t('limitArtistDisplay')">
      <input-switch
        v-model="limitArtistDisplay"></input-switch>
    </mt-cell>
    <mt-cell
      :title="$t('useAlgoTimeline')">
      <input-switch v-model="useAlgoTimeline"></input-switch>
    </mt-cell>
    <mt-cell
      v-show="limitArtistDisplay"
      :title="$t('oneArtistMaxPresentsInLatest')">
      <input-number v-model="oneArtistMaxPresentsInLatest"></input-number>
    </mt-cell>
    <mt-field
      v-model="description"
      :label="$t('channelDescription')"
      required
      type="textarea"
      rows="4"
      :placeholder="$t('pleaseInputChannelDescription')">
    </mt-field>
  
    <pr-button
      type="primary"
      @click.native="submit">{{ shouldCreate ? $t('submit') : $t('edit') }}</pr-button>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { createChannel, updateChannel } from '@/api/channel'
import { Indicator } from 'mint-ui'
import { useI18n } from 'vue-i18n-composable'
import { useRoute, useRouter } from 'vue-router/composables'
import PrButton from '@/components/PRButton.vue'
import { useChannelStore } from '@/pinia/channel'
import { useAuthenticator } from '@/pinia/authenticator'
import { useToast } from '@/composables/toast'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'


export default {
  setup () {
    const title = ref('')
    const description = ref('')
    const needVerify = ref(false)
    const royaltyRate = ref(0.0)
    const titleLocales = ref({})
    const descriptionLocales = ref({})
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const shouldCreate = ref(true)
    const channeStore = useChannelStore()
    const authenticator = useAuthenticator()
    const { Toast } = useToast()
    const limitArtistDisplay = ref(false)
    const oneArtistMaxPresentsInLatest = ref(0)
    const useAlgoTimeline = ref(false)


    watch(limitArtistDisplay, () => {
      if (!limitArtistDisplay.value) {
        oneArtistMaxPresentsInLatest.value = 0
      }
    })


    watch(oneArtistMaxPresentsInLatest, () => {
      oneArtistMaxPresentsInLatest.value = Math.round(oneArtistMaxPresentsInLatest.value)
    })


    const submit = async function () {
      try {
        if (!title.value) {
          throw new Error('please enter title')
        }

        if (!description.value) {
          throw new Error('please enter channel description')
        }

        const postData = {
          title: title.value,
          description: description.value,
          needVerify: needVerify.value,
          royaltyRate: Number((royaltyRate.value / 100).toFixed(2)),
          titleLocales: titleLocales.value,
          descriptionLocales: descriptionLocales.value,
          oneArtistMaxPresentsInLatest: oneArtistMaxPresentsInLatest.value, 
          useAlgoTimeline: useAlgoTimeline.value,
        }

        Indicator.open()
        if (shouldCreate.value) {
          await createChannel({
            ...postData
          })
        } else {
          await updateChannel(
            route.params.channelId,
            {
              ...postData
            }
          )
        }

        await channeStore.fetchManageableChannels()

        if (shouldCreate.value) {
          router.push({
            path: '/i/publisher/channels',
            replace: true,
          }) 
        } else {
          router.push({
            path: `/channels/${route.params.channelId}`,
            replace: true,
          })
        }
        Toast({ message: t('success') }, 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        Indicator.close()
      }
    }


    onMounted(async () => {
      const channelId = route.params.channelId
      if (!channelId) {
        return
      }

      shouldCreate.value = false
      if (channeStore.manageableChannels.length === 0) {
        await authenticator.checkAuth()
        await channeStore.fetchManageableChannels()
      }
      const channel = channeStore.manageableChannels.filter(channel => channel._id === channelId)[0]

      if (channel) {
        title.value = channel.title
        description.value = channel.description
        needVerify.value = channel.needVerify
        royaltyRate.value = Math.round(channel.royaltyRate * 100)
        titleLocales.value = channel.titleLocales
        descriptionLocales.value = channel.descriptionLocales
        oneArtistMaxPresentsInLatest.value = channel.oneArtistMaxPresentsInLatest
        limitArtistDisplay.value = !!oneArtistMaxPresentsInLatest.value
        useAlgoTimeline.value = channel.useAlgoTimeline
      }
    })


    return {
      title,
      needVerify,
      description,
      royaltyRate,
      titleLocales,
      descriptionLocales,
      submit,
      shouldCreate,
      oneArtistMaxPresentsInLatest,
      limitArtistDisplay,
      useAlgoTimeline,
    }
  },
  components: {
    PrButton,
    InputSwitch,
    InputNumber,
  }
}
</script>


<style lang="less">
@import "@/assets/base.less";

.mt-range-progress {
  background-color: @main;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {

  @media @desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }

  ul {
    font-size: 15px;
    color: @grey;
    font-weight: bolder;
    margin-bottom: 2em;
  }

  button {
    width: 100%;
    margin-top: 1em;
  }
}
</style>