<template>
  <div>
    <data-table :value="orders">
      <column
        header="item">
        <template #body="slotProps">
          {{ getOrderTitle(slotProps.data) }}
        </template>
      </column>
      <column
        field="createdAt"
        header="createdAt">
        <template #body="slotProps">
          {{ $d(slotProps.data.createdAt, 'dateonly') }}
        </template>
      </column>
      <column
        field="currency"
        header="currency">
      </column>
      <column
        field="amount"
        header="amount">
        <template #body="slotProps">
          {{ slotProps.data.currency === 'JPY' ? slotProps.data.amount : (slotProps.data.amount / 100).toFixed(2) }}
        </template>
      </column>
      <column
        field="status"
        header="status"></column>
      <column
        header="action">
        <template #body="slotProps">
          <pr-button
            v-show="slotProps.data.status === 'created'"
            size="small"
            type="primary"
            @click.native="pay(slotProps.data._id)">
            {{ $t('payment') }}
          </pr-button>
          <span
            v-show="slotProps.data.status === 'paid'"
            class="row-text">paid</span>
        </template>
      </column>
    </data-table>

    <load-more-button
      :busy="loading"
      :has-more="hasMore"
      @trigger="loadMore"></load-more-button>
  </div>
</template>


<script>
import { useOrders } from '@/composables/user_orders'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import PrButton from '@/components/PRButton.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'


export default {
  setup () {
    return {
      ...useOrders()
    }
  },
  components: {
    DataTable,
    Column,
    PrButton,
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.row-text {
  color: @minor;
}
</style>