<template>
  <div>
    <p v-if="ok">Your bank account binding is completed</p>
    <div v-else>
      <p>Your bank account binding failed</p>
      <pr-button
        type="primary"
        size="small"
        @click.native="$router.push('/i/wallet/bind')">Retry</pr-button>
    </div>
  </div>
</template>


<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import PrButton from '@/components/PRButton.vue'
import { useCurrentUser } from '@/composables/user_data'


export default {
  setup () {
    const ok = ref(false)
    const route = useRoute()
    const { currentUser, authenticated } = useCurrentUser()


    const onboarded = computed(() => {
      if (!authenticated.value) {
        return false
      }

      if (currentUser.value.payoutEnabled) {
        return true
      }

      return false
    })


    watch(authenticated, () => {
      if (authenticated.value) {
        ok.value = onboarded.value     
      }
    })


    onMounted(() => {
      if (route.query.ok) {
        if (Number(route.query.ok) === 0) {
          ok.value = false
        } else if (Number(route.query.ok) === 1) {
          ok.value = true
        }
      } else {
        ok.value = onboarded.value
      }
    })

  
    return {
      ok,
    }
  },
  components: {
    PrButton,
  }
}
</script>


<style lang="less" scoped>

</style>