<template>
  <div
    v-if="currentUser.authed || allowPirate"
    class="wrap card-1">
    <div class="header">
      <div class="balances">
        <div class="grid">
          <span
            v-for="balance in balances"
            :key="balance.currency">{{ getBalanceText(balance) }}</span>
        </div>
        <h2>
          <img src="@/assets/coin@2x.png">
          $ {{ (totalUSD / 100).toFixed(2) }}
        </h2>
      </div>
      <div
        style="min-height: 2.9em;"
        class="bindinfo">
        <div v-if="currentUser.payoutEnabled && currentUser.info">
          <div
            v-if="currentUser.info.payoutMethod === 'tron'">
            <p v-show="!currentUser.tronwallet">
              Payout with trc20 USDT
              <a
                class="outline touchable hoverable"
                @click="showTron = true">
                Set Tron Wallet
              </a>
            </p>
            <p v-show="currentUser.tronwallet">
              Tron Wallet：{{ currentUser.tronwallet }}
              <a
                class="outline touchable hoverable"
                @click="showTron = true">
                Update
              </a>
            </p>
          </div>
          <div
            v-else-if="currentUser.info.payoutMethod === 'stripe'">
            <p>
              Payout with Stripe Express
              <a
                class="outline touchable hoverable"
                @click="goToStripeDashboard">
                Go To Stripe Express
              </a>
            </p>
          </div>
          <div
            v-else-if="currentUser.info.payoutMethod === 'paypal'">
            <p v-if="currentUser.paypal">
              PayPal: {{ currentUser.paypal.email }}
              <a
                class="outline touchable hoverable"
                @click="showPaypal = true">
                Update
              </a>
            </p>
            <p v-else>
              <a
                class="outline touchable hoverable"
                @click="showPaypal = true">
                Set PayPal
              </a>
            </p>
          </div>
        </div>
        <div v-else>
          <p>
            <a
              v-if="!currentUser.payoutEnabled"
              class="outline touchable hoverable"
              @click="$router.push('/i/wallet/bind')">
              Set Payout Method
            </a>
          </p>
        </div>
        <pr-button
          class="btn"
          :loading="withdrawing"
          @click.native="withdraw">
          {{ $t('payout') }}
        </pr-button>
      </div>
    </div>
    <div
      v-if="currentUser.canWithdraw"
      class="coins">
      <bill></bill>
    </div>
    <withdraw
      v-model="showWithdraw"
      currency="USD">
    </withdraw>
    <user-tron
      v-if="currentUser.info && currentUser.info.payoutMethod === 'tron'"
      v-model="showTron">
    </user-tron>
    <user-paypal
      v-if="currentUser.info && currentUser.info.payoutMethod === 'paypal'"
      v-model="showPaypal">
    </user-paypal>
  </div>
  <div v-else>
    <p>please verify first before you can publish any artworks or make earns</p>
    <pr-button
      type="primary"
      size="small"
      @click.native="$router.push('/i/publisher/verify')">
      Go to Verify
    </pr-button>
  </div>
</template>

<script>
import Bill from '@/components/Bill.vue'
import PrButton from '@/components/PRButton.vue'
import Withdraw from '@/components/Withdraw.vue'
import { useUserBalance } from '@/composables/user_balance'
import { useUserPayout } from '@/composables/user_payout'
import { useCurrentUser } from '@/composables/user_data'
import { t } from '@/translations'
import { ref } from 'vue'
import UserTron from '@/components/UserTron.vue'
import UserPaypal from '@/components/UserPaypal.vue'
import { allowPirate } from '@/config'


export default {
  beforeRouteEnter (to, from, next) {
    document.title = t('mywallet')
    next()
  },
  setup () {
    const showTron = ref(false)
    const showPaypal = ref(false)
    return {
      ...useUserBalance(),
      ...useUserPayout(),
      ...useCurrentUser(),
      showTron,
      allowPirate,
      showPaypal,
    }
  },
  methods: {
    apply () {
      this.$router.push({
        name: 'verifyapply',
      })
    },
  },
  components: {
    Bill,
    PrButton,
    Withdraw,
    UserTron,
    UserPaypal,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.wrap {
  border: 1px solid @border;
  padding: 10px 12px;
  width: 700px;
  margin: 0 auto;
  margin-top:10px;
  min-height: 0px;

  .header {
    vertical-align: justfy;
    margin-bottom: 20px;

    img {
      width: 18px;
    }

    .balances {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .grid {
        display: grid;
        grid-template-columns: 20% 2fr;
      }

      @media @phone {
        .grid {
          grid-template-columns: 50% 2fr;
        }
      }

      span, h2 {
        color: @main;
        font-weight: bold;
      }

      span {
        font-size: 14px;
      }

      h2 {
        display: flex;
        align-items: baseline;
        gap: 10px;
      }
    }

    p {
      display: inline-block;
      margin-left: 10px;
      color: #a1a1a1;
      font-size: 14px;

      span {
        color: @blue;
        font-size: 14px;
        padding-left:5px;
      }
    }

    button {
      float: right;
      width: 54px;
      height: 26px;
      background: @border;
      color: @minor;
      font-size: 13px;
      margin-left:8px;
      margin-top:6px;
    }

    .btn {
      background: @main;
      color: @white;
      margin-left: auto;

      @media @phone {
        width: 80px;
      }
    }
  }
}

@media @phone {
  .wrap {
    box-sizing: border-box;
    width: 100%;
  }
}

.bindinfo {
  display: flex;
  align-items: middle;

  @media @phone {
    margin-top: 20px;
  }
}
</style>
