<template>
  <div class="container">
    <router-link
      v-for="topic in topics"
      :key="topic._id"
      class="topic touchable hoverable"
      :to="`/promote/topics/${topic._id}/contribute?title=${topic.title}`"
    >
      <img
        :src="topic.coverUrl"
        :alt="topic.title"
      >
    </router-link>

    <load-more-button
      :busy="busy"
      :has-more="hasMore"
      :trigger="getTopics"
    />
  </div>
</template>

<script>
import contribution from '@/api/contribute'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useToast } from '@/composables/toast'

const limit = 10

export default {
  metaInfo: {
    title: "选择要投稿的专题",
  },
  setup () {
    return {
      ...useToast(),
    }
  },
  components: {
    LoadMoreButton
  },
  data () {
    return {
      topics: [],
      page: 1,
      hasMore: true,
      busy: true
    }
  },
  mounted () {
    this.getTopics()
  },
  methods: {
    getTopics () {
      this.busy = true

      contribution
        .getAvailableTopics(this.page, limit)
        .then(topics => {
          this.busy = false

          if (topics.length === 0) {
            this.hasMore = false
          } else {
            topics.forEach(topic => this.topics.push(topic))
            this.page += 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.topic {
  width: 100%;
  max-width: 512px;
  display: block;
  margin: 0.5em auto;

  img {
    width: 100%;
  }
}
</style>
