//@ts-check
import { useUserStore } from "@/pinia/user"
import { useToast } from "./toast"
import { computed, onMounted } from "vue"


export const useUserInfo = function (userId) {
  const userStore = useUserStore()
  const { Toast } = useToast()

  const user = computed(() => {
    return userStore.users[userId]
  })

  const getUser = async function () {
    try {
      if (!userStore.users[userId]) {
        await userStore.getUserById(userId)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  onMounted(async () => {
    await getUser()
  })

  return {
    user,
  }
}