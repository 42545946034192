<template>
  <div
    v-if="channel && channel._id"
    class="channel">

    <channel-info
      :channel-id="channel._id"></channel-info>

    <div class="container">
      <mt-navbar
        v-model="selectedTabIndex"
        class="segmented-control">
        <mt-tab-item
          v-if="channel.useAlgoTimeline"
          :id="0"
          class="touchable hoverable">
          {{ $t('forYou') }}
        </mt-tab-item>
        <mt-tab-item
          :id="1"
          class="touchable hoverable">
          {{ $t('latestPicks') }}
        </mt-tab-item>
        <mt-tab-item
          :id="2"
          class="touchable hoverable">
          {{ $t('hot') }}
        </mt-tab-item>
        <mt-tab-item
          :id="3"
          class="touchable hoverable">
          {{ $t('best') }}
        </mt-tab-item>
      </mt-navbar>

      <mt-tab-container v-model="selectedTabIndex">
        <mt-tab-container-item
          v-if="channel.useAlgoTimeline"
          :id="0">
          <post-list-mobile
            class="item"
            :posts="channel.algoPosts"></post-list-mobile>
          <load-more-button
            :busy="algoLoading"
            :has-more="true"
            :trigger="getAlgoPosts"></load-more-button>
        </mt-tab-container-item>

        <mt-tab-container-item :id="1">
          <post-list-mobile
            class="item"
            :posts="channel.latestPosts"></post-list-mobile>
          <load-more-button
            :busy="latestLoading"
            :has-more="latestHasmore"
            :trigger="getLatestPosts"></load-more-button>
        </mt-tab-container-item>

        <mt-tab-container-item :id="2">
          <post-list-mobile
            class="item"
            :posts="channel.hotPosts"></post-list-mobile>
          <load-more-button
            :busy="hotLoading"
            :has-more="hotHasmore"
            :trigger="getHotPosts"></load-more-button>
        </mt-tab-container-item>

        <mt-tab-container-item :id="3">
          <post-list-mobile
            class="item"
            :posts="channel.bestPosts"></post-list-mobile>

          <load-more-button
            :busy="bestLoading"
            :has-more="bestHasmore"
            :trigger="getBestPosts"></load-more-button>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostListMobile from '@/components/PostListMobile.vue'
import { useChannelPresent } from '@/composables/channel_present'
import ChannelInfo from '@/components/ChannelInfo.vue'


export default {
  metaInfo () {
    return this.channelMetaInfo
  },
  setup () {
    return {
      ...useChannelPresent()
    }
  },
  components: {
    LoadMoreButton,
    PostListMobile,
    ChannelInfo,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
}

.item {
  margin-left: 4px;
  margin-right: 4px;
}
</style>
