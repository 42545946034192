//@ts-check
import { APP_URL, onesignal as onesignalConfig } from "@/config"
import { onBeforeMount } from "vue"
import { useToast } from "./toast"
// @ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'
import { useI18n } from 'vue-i18n-composable'
import { useScriptTag } from '@vueuse/core'


let onesignal = null

const _getOneSignal = async function () {
  if (onesignal) {
    return onesignal
  }

  return new Promise(async (resolve, reject) => {
    try {
      useScriptTag(onesignalConfig.pageJSUrl, () => {
        try {
          // @ts-ignore
          window.OneSignalDeferred = window.OneSignalDeferred || []
          // @ts-ignore
          window.OneSignalDeferred.push(function(OneSignal) {
            OneSignal.init({
              appId: onesignalConfig.domains[location.hostname].appId,
              safari_web_id: onesignalConfig.domains[location.hostname].safariWebId,
              autoResubscribe: true,
            })
            onesignal = OneSignal
            const url = new URL(APP_URL)
            url.pathname = '/i/notifications'
            onesignal.Notifications.setDefaultUrl(url.toString())
            resolve(onesignal)
          })
        } catch (err) {
          reject(err)
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

const getOneSignal = reusePendingPromise(_getOneSignal)


const oneSignalTaskWrapper = function (callback) {
  return new Promise(async (resolve, reject) => {
    try {
      // @ts-ignore
      if (!window.OneSignalDeffered) {
        await getOneSignal()
      }
      // @ts-ignore
      window.OneSignalDeferred.push(async function(OneSignal) {
        try {
          resolve(await callback(OneSignal))
        } catch (err) {
          reject(err)
        }
      }) 
    } catch (err) {
      reject(err)
    }
  })
}


export const useOneSignal = function () {
  const { Toast } = useToast()
  const { t } = useI18n()

  const optIn = async function () {
    return oneSignalTaskWrapper(async function(onesignal) {
      try {
        if (!onesignal.Notifications.permission) {
          if (Notification.permission === 'denied') {
            Toast(t('notifcationDeniedWarning'), 'info')
          } else {
            await onesignal.Notifications.requestPermission()
          }
        }
        return await onesignal.User.PushSubscription.optIn()
      } catch (err) {
        console.error(err)
        Toast(err.message)
      }
    })
  }

  const optOut = async function () {
    return oneSignalTaskWrapper(async function(onesignal) {
      try {
        return await onesignal.User.PushSubscription.optOut()
      } catch (err) {
        console.error(err)
        Toast(err.message)
      }
    })
  }

  const attachExternalId = async function (userId, nickname) {
    return oneSignalTaskWrapper(async function(onesignal) {
      try {
        onesignal.User.addAlias('nickname', nickname)
        return await onesignal.login(userId)
      } catch (err) {
        console.error(err)
        Toast(err.message)
      }
    })
  }

  const isOptedIn = async function () {
    return oneSignalTaskWrapper(async function(onesignal) {
      try {
        return onesignal.User.PushSubscription.optedIn && onesignal.Notifications.permission
      } catch (err) {
        console.error(err)
        Toast(err.message)
        return false
      }
    })
  }

  onBeforeMount(getOneSignal)

  return {
    optIn,
    optOut,
    attachExternalId,
    isOptedIn,
  }
}