var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mt-button',{class:{
    default: _vm.type === 'default',
    positive: _vm.type === 'positive',
    primary: _vm.type === 'primary',
    blue: _vm.type === 'blue',
    flat: _vm.flat,
    plain: _vm.plain
  },attrs:{"size":_vm.size,"disabled":_vm.disabled || _vm.loading,"plain":_vm.plain}},[(_vm.loading)?_c('icon',{staticClass:"animation-spin icon",attrs:{"name":"refresh-cw"}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }