<template>
  <a
    class="outline"
    :class="{
      'no-more': !hasMore,
      'touchable': hasMore && !busy,
      'hoverable': hasMore && !busy,
      'busy': busy
    }"
    @click.prevent="loadMore"
  >{{ loadingText }}</a>
</template>

<script>
export default {
  props: {
    busy: {
      type: Boolean,
      default: false,
      required: true
    },
    hasMore: {
      type: Boolean,
      default: true
    },
    idleText: {
      type: String,
      default: '',
    },
    trigger: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  computed: {
    loadingText () {
      if (this.busy) {
        return this.$t('loading')
      } else if (this.hasMore) {
        if (this.idleText) {
          return this.idleText
        } else {
          return this.$t('loadmore')
        }
      } else {
        return this.$t('nomore')
      }
    }
  },
  methods: {
    async loadMore () {
      if (this.busy) {
        return
      }

      if (!this.hasMore) {
        return
      }

      if (typeof this.trigger === 'function') {
        await this.trigger()
      } else {
        this.$emit('trigger')
      }
    }
  }
}
</script>

<style scoped>
a {
  display: block;
  text-align: center;
  width: 100%;
  margin: 10px auto;
  padding: 10px 0;
  
}

a.no-more {
  color: #aaa;
}

a.busy {
  color: #aaa;
}
</style>
