/**
 * Created by harbon on 16/10/15.
 */
import Vue from 'vue'
import { API_URL, APP_URL } from '../config'
import { reusePendingPromise } from 'reuse-pending-promise'
import { api } from './base'


const _getOne = async function (postId) {
  try {
    const res = await api.get(`/posts/${postId}`)
    return res.data.result
  } catch (err) {
    if (err.response) {
      console.error(err.response.data)
    } else {
      console.error(err)
    }
    throw err
  }
}

export const release = async (post) => {
  try {
    const res = await api.post('/posts', post)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const removeArtwork = async function (postId) {
  try {
    const res = await api.delete(`/posts/${postId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getBuyers = async function (postId, page, limit = 10) {
  try {
    const res = await api.get(`/posts/${postId}/buyers`, {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getUserPostsTill = async function (userId, timestamp, limit = 5) {
  try {
    const res = await api.get(`${API_URL}/posts/old`, {
      params: {
        userId,
        timestamp,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const promotePost = async function (postId) {
  try {
    const res = await api.post(`/posts/${postId}/promote`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const unpromotePost = async function (postId) {
  try {
    const res = await api.post(`/posts/${postId}/unpromote`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


const _getSocialImageUrl = async function (postId) {
  try {
    const res = await api.get(`/posts/${postId}/socialImage`)
    return res.data.result.url
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getSocialImageUrl = reusePendingPromise(_getSocialImageUrl)


export const updatePost = async function (postId, doc) {
  try {
    const res = await api.put(`/posts/${postId}`, {
      ...doc
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


const _getPostTranslation = async function (postId) {
  try {
    const res = await api.get(`/posts/${postId}/translations`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getPostTranslation = reusePendingPromise(_getPostTranslation)


export const searchSelfPosts = async function (keyword, limit, page) {
  try {
    const res = await api.get('/posts/search/self', {
      params: {
        keyword,
        limit,
        page
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getUserPromotedPosts = async function (userId) {
  try {
    const res = await api.get('/posts/promote', {
      params: {
        userId
      }
    })
    const posts = res.data.result

    posts.forEach(post => post.pinningUserId = userId)

    return posts
  } catch (err) {
    console.error(err)
    throw err
  }
}


export default {
  async getPosts (query, type = 'recommended') {
    let url = ''

    if (type === 'recommended') {
      url = `${API_URL}/posts`
    }

    if (type === 'old') {
      url = `${API_URL}/posts/old`
    }

    if (type === 'new') {
      url = `${API_URL}/posts/new`
    }

    if (type === 'top') {
      url = `${API_URL}/posts/promote`
    }

    if (type === 'dog') {
      url = `${API_URL}/posts/fakePosts`
    }

    try {
      const res = await api.get(url, {
        params: query
      })

      if (res.data.code === 200) {
        return res.data.result
      }

      throw new Error(res.data.message)
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  
  getOne: reusePendingPromise(_getOne),

  async buy (postId, currency = 'JPY', {
    processor,
    channelId = null,
    returnUrl,
    subProcessor,
    discountCode,
  }) {
    const url = `${API_URL}/posts/${postId}/buy`

    return new Promise((resolve, reject) => {
      Vue.http
        .post(url, {
          currency,
          channelId,
          processor,
          returnUrl,
          subProcessor,
          discountCode,
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            return resolve(data.result)
          } else {
            return reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            if (err.data.code === 117) {
              return resolve(err.data.result)
            }

            return reject(err.data.message)
          } else {
            return reject(err)
          }
        })
    })
  },

  buyAsAnonymous (postId, channel, openId, channelId = null) {
    const data = {
      subject: 'Perohub网页端购买作品',
      body: postId,
      channel,
      success_url: `${APP_URL}/posts/${postId}`,
      cancel_url: `${APP_URL}/posts/${postId}`
    }

    if (channel === 'wx_pub') {
      data.openId = openId
    }

    const url = `${API_URL}/posts/${postId}/guestBuy`
    const finalURL = channelId ? `${url}?channelId=${channelId}` : url

    return new Promise((resolve, reject) => {
      Vue.http
        .post(finalURL, data)
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            resolve(data.result.charge)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  like (postId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/posts/${postId}/like`)
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  discount (postId, discount) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/posts/${postId}/discount`, { discount })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            resolve(true)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  setTitle (postId, title) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/posts/${postId}/updateTitle`, { title })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  getTimelinePostsSince (timestamp, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/followings/new`, {
          params: {
            timestamp,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  getTimelinePostsTill (timestamp, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/followings/old`, {
          params: {
            timestamp,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  getUserHotPosts (userId, page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/heat`, {
          params: {
            userId,
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  getUserPurchased (userId, page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/paid`, {
          params: {
            limit,
            page
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  getAnonymousPurchased (uuid, page, limit) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/uuidPaid`, {
          params: {
            page,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  //漫画上一话
  getComicPostsNew (timestamp, limit, circleId, sort) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/groups/${circleId}/postsNew`, {
          params: {
            timestamp,
            sort,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },
  //漫画下一话

  getComicPostsOld (timestamp, limit, circleId, sort) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/groups/${circleId}/postsOld`, {
          params: {
            timestamp,
            sort,
            limit
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  //获取作者违规作品
  getVolitePosts (limit, page) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/illegalPosts`, {
          params: {
            limit,
            page
          }
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },
  //违规作品提交
  legalUpdate (legalTitle, legalContent, legalMedias, postId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/posts/${postId}/legalUpdate`, {
            legalTitle,
            legalContent,
            legalMedias

        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },
  //获取单个违规作品
  getonelegal(postId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/${postId}/illegalPost`)
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  //获取联合发布请求
  jointRequestPosts (limit, page) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${API_URL}/posts/jointRequestPosts?limit=${limit}&page=${page}`)
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },

  //审核联合发布请求
  jointUpdate (postId,status) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${API_URL}/posts/${postId}/jointUpdate`, {
            status: status,
        })
        .then(({ ok, data }) => {
          if (ok) {
            resolve(data.result)
          } else {
            reject(data.message)
          }
        })
        .catch(err => {
          if (err.data) {
            reject(err.data.message)
          } else {
            reject(err)
          }
        })
    })
  },



}
