<template>
  <div class="container">
    <mt-cell
      v-for="channel in channels"
      :key="channel._id"
      :title="channel.title"
      is-link
      @click.native="$router.push(`/promote/featured/contribute?channelId=${channel._id}`)"
    />
  </div>
</template>

<script>
import api from '@/api/channel'

export default {
  metaInfo: {
    title: "选择要申请置顶的频道",
  },
  data () {
    return {
      channels: []
    }
  },
  mounted () {
    this.$indicator.open()

    api
      .fetchApplicableChannels()
      .then(channels => {
        //console.info(channels)
        this.$indicator.close()
        this.channels = channels
      })
      .catch(err => {
        this.$indicator.close()

        console.error(err)

        this.$toast({
          message: err,
          position: 'bottom'
        })
      })
  }
}
</script>

<style lang="less" scoped>
</style>
