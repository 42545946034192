//@ts-check
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { addParamsToRoute } from '@/utils'
import postapi from '@/api/post'
import { getCirclePosts } from '@/api/circle'
import { useToast } from './toast'
import { useI18n } from 'vue-i18n-composable'
import { asyncComputed, onKeyStroke } from '@vueuse/core'
import { MediaType } from '@/utils/defines'


const cacheCount = 18
let timeoutId = null
const limit = 5

export const useArtworkMangaOperations = function (refArtwork, refCircle) {
  const toolbarShow = ref(false)
  const displayingIndex = ref(0)
  const nextArtworkId = ref(null)
  const prevArtworkId = ref(null)
  const chapterMenuShow = ref(false)
  const route = useRoute()
  const mediaRef = ref(null)
  const mediaLoadingProgress = ref(0)
  const router = useRouter()
  const circlePosts = ref([])
  const sort = ref('des')
  const { Toast } = useToast()
  const { t } = useI18n()


  const page = computed(() => displayingIndex.value + 1)

  const browserableMedias = computed(() => {
    if (!refArtwork.value) {
      return []
    }

    return refArtwork.value.pictures.filter(media => media.type === MediaType.IMAGE || media.type === MediaType.VIDEO)
  })

  
  const mediaStyle = asyncComputed(() => {
    return new Promise(resolve => {
      const tryGet = function () {
        if (mediaRef.value === null) {
          return setTimeout(tryGet, 20)
        }

        if (mediaRef.value.width === 0 || mediaRef.value.height === 0) {
          return setTimeout(tryGet, 20)
        }

        if (mediaRef.value.width > mediaRef.value.height) {
          resolve({
            width: '100vw',
            height: '100%',
          })
        } else {
          resolve({
            width: '100%',
            height: '100vh',
          })
        }
      }

      setTimeout(tryGet, 20)
    })
  })


  const displayingMedia = computed(() => {
    if (!refArtwork.value) {
      return null
    }
    return browserableMedias.value[displayingIndex.value]
  })

  
  const chapterTurnable = computed(() => {
    return !!refCircle.value
  })


  const preloadMedias = computed(() => {
    if (!refArtwork.value) {
      return []
    }

    const medias = []

    for (let index = 0; index < displayingIndex.value + 1 + cacheCount; index++) {
      if (browserableMedias.value.length - 1 >= index) {
        medias.push(browserableMedias.value[index])
      } else {
        continue
      }
    }

    return medias
  })


  const chapterNavigatable = computed(() => {
    return true
  })


  watch(page, () => {
    addParamsToRoute(route, {
      page: page.value,
    })

    startFakeLoading()
  })


  watch(mediaRef, () => {
    if (!mediaRef.value) {
      return
    }

    if (mediaRef.value.onload) {
      return
    }

    mediaRef.value.onload = function () {
      mediaLoadingProgress.value = 100
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        mediaLoadingProgress.value = 0
      }, 500)
    }
  })


  watch(refCircle, () => {
    if (!refCircle.value) {
      return
    }
    if (!refArtwork.value) {
      return
    }
    if (!nextArtworkId.value && !prevArtworkId.value) {
      preloadChapters()
    }
  })


  watch(sort, () => {
    getMenu()
  })


  const startFakeLoading = function () {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null 
    }

    const max = 2 * 1000
    const min = 0.3 * 1000

    const step = function () {
      timeoutId = setTimeout(() => {
        if (mediaLoadingProgress.value >= 90) {
          return
        }
        mediaLoadingProgress.value += 5
        step()
      }, Math.floor(Math.random() * (max - min + 1) + min))  // random number between max and min
    }

    mediaLoadingProgress.value += 2
    step()
  }


  const onMediaClicked = function (event) {
    const dom = event.target
    const x = event.clientX - dom.getBoundingClientRect().left
    if (x < dom.offsetWidth / 2) {
      prevPage()
    } else {
      nextPage()
    }
  }


  const onMediaDragged = function ({ swipe }) {
    const [x, y] = swipe
    if (x === 1 || y === 1) {
      prevPage()
    } else if (x === -1 || y === -1) {
      nextPage()
    }
  }


  const nextPage = function () {
    if (displayingIndex.value === browserableMedias.value.length - 1) {
      return Toast({ message: t('nomore') }, 'info')
    }

    displayingIndex.value += 1
  }

  const prevPage = function () {
    if (displayingIndex.value === 0) {
      return Toast({ message: t('alreadyFirstPage') }, 'info')
    }

    displayingIndex.value -= 1
  }


  const nextChapter = async function () {
    if (!nextArtworkId.value) {
      return
    }

    router.push(`/comics/${nextArtworkId.value}`)
  }


  const prevChapter = function () {
    if (!prevArtworkId.value) {
      return
    }

    router.push(`/comics/${prevArtworkId.value}`)
  }


  const preloadChapters = async function () {
    try {
      const [ newerPosts, olderPosts ] = await Promise.all([
        postapi.getComicPostsNew(refArtwork.value.createdAt, 1, refCircle.value._id, 'des'),
        postapi.getComicPostsOld(refArtwork.value.createdAt, 1, refCircle.value._id, 'des'),
      ])

      if (newerPosts.length > 0) {
        nextArtworkId.value = newerPosts[0]._id
      }

      if (olderPosts.length > 0) {
        prevArtworkId.value = olderPosts[0]._id
      }

      await getMenu()
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const getMenu = async function () {
    try {
      let page = 1
      let newPosts = await getCirclePosts(refCircle.value._id, page, limit, sort.value)

      while (newPosts.length !== 0) {
        circlePosts.value.push(...newPosts)
        page += 1
        newPosts = await getCirclePosts(refCircle.value._id, page, limit, sort.value)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  onKeyStroke(['ArrowLeft', 'a', 'ArrowUp', 'w'], prevPage)
  onKeyStroke(['ArrowRight', 'd', 'ArrowDown', 's'], nextPage)

  onMounted(() => {
    const page = Number(route.query.page)
    if (!isNaN(page) && page >= 1) {
      displayingIndex.value = page - 1
    }

    if (refArtwork.value) {
      startFakeLoading()
      if (refCircle.value) {
        preloadChapters()
      }      
    }
  })


  return {
    chapterNavigatable,
    nextChapter,
    prevChapter,
    toolbarShow,
    displayingIndex,
    displayingMedia,
    nextArtworkId,
    prevArtworkId,
    chapterMenuShow,
    onMediaClicked,
    preloadMedias,
    mediaRef,
    mediaStyle,
    mediaLoadingProgress,
    onMediaDragged,
    chapterTurnable,
    circlePosts,
    sort,
  }
}