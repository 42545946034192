import { useCircleStore } from "@/pinia/circle"
import { computed, onMounted, ref, watch } from "vue"
import { useCurrentUser } from "./user_data"
import { reusePendingPromise } from 'reuse-pending-promise'

const limit = 10


export const useAllCircles = function () {
  const store = useCircleStore()
  const circleAllPage = ref(1)
  const circleAllLoading = ref(false)
  const circleAllHasmore = ref(true)
  const { authenticated } = useCurrentUser()

  const allCircles = computed(() => store.allCircles)

  watch(authenticated, () => {
    if (authenticated.value && allCircles.value.length === 0) {
      circleAllPage.value = 1
      fetchAllCircles()
    }
  })

  const _fetchAllCircles = async function () {
    circleAllLoading.value = true
    const countBefore = allCircles.value.length
    await store.fetchAllCircles(circleAllPage.value, limit)
    circleAllLoading.value = false
    const countAfter = allCircles.value.length
    if (countAfter === countBefore) {
      circleAllHasmore.value = false
    } else {
      circleAllPage.value += 1
    }
  }

  const fetchAllCircles = reusePendingPromise(_fetchAllCircles)


  onMounted(() => {
    fetchAllCircles()
  })

  return {
    allCircles,
    circleAllPage,
    fetchAllCircles,
    circleAllLoading,
    circleAllHasmore,
  }
}