<template>
  <Dialog
    header="Watermark Positions"
    :visible.sync="show">

    <div class="positions">
      <div
        v-for="index in [0, 1, 2, 3, 4, 5, 6, 7, 8]"
        :key="index"
        class="hoverable"
        @click="togglePosition(index)">
        <img
          v-show="positions.includes(index)"
          style="width: 1.4rem;"
          src="@/assets/ic-page-switch-selected@2x.png">
      </div>
    </div>
  </Dialog>
</template>


<script>
//@ts-check
import { watch, ref } from 'vue'
import Dialog from 'primevue/dialog'


export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    }
  },
  setup (props, { emit }) {
    const positions = ref([])

    const show = ref(props.visible)

    watch(props, () => {
      show.value = props.visible
    })

    watch(show, () => {
      if (!show.value) {
        emit('close')        
      }
    })

    const togglePosition = function (index) {
      if (positions.value.includes(index)) {
        positions.value.splice(positions.value.indexOf(index), 1)
      } else {
        positions.value.push(index)
      }
      emit('input', positions.value)
    }

    return {
      togglePosition,
      positions,
      show
    }
  },
  components: {
    Dialog,
  }
}
</script>


<style scoped>
.positions {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-image: url("@/assets/suli-headimage@2x.png");
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.positions > div {
  border: 0.1rem dashed white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
}
</style>