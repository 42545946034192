// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Mint from 'mint-ui'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueSocialSharing from 'vue-social-sharing'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { GesturePlugin } from '@vueuse/gesture'
import { APP_URL, mixpanelToken } from './config'
import VueMasonry from 'vue-masonry-css'
import PrimeVue from 'primevue/config'

import App from './App.vue'
import { disableInspector } from './utils'
import routes from './routes'
import linkify  from  'vue-linkify'
import MetaInfo from 'vue-meta-info'
import { i18n } from './translations'
import VuePlyr from 'vue-plyr'
import './registerServiceWorker'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import mixpanel from 'mixpanel-browser'
import { useAuthenticator } from './pinia/authenticator'
import { createHead } from '@unhead/vue'
import { UnheadPlugin } from '@unhead/vue/vue2'


Vue.directive('linkified', linkify)
if (process.env.NODE_ENV === 'production') {
  // disableInspector()
}
Vue.use(PiniaVuePlugin)
Vue.use(Mint)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(MetaInfo)
Vue.use(GesturePlugin)
Vue.use(VueSocialSharing)
Vue.use(VuePlyr)
Vue.use(ToastService)
Vue.use(ConfirmationService)
Vue.use(VueMasonry)
Vue.use(PrimeVue)
Vue.use(UnheadPlugin)
Vue.http.headers.common['platform'] = 'web'
Vue.config.silent = true


const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {  
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  window.prerenderReady = false
  next()
})

router.beforeEach(async (to, from, next) => {
  if(to.query.token) {
    const authenticator = useAuthenticator()
    await authenticator.setAccessToken(to.query.token)
    let query = to.query
    delete query.token

    next({ path: to.path, query: query, replace: true })
  }
  next()
})

router.afterEach(function (transition) {
  window.prerenderReady = true
})

router.afterEach(function (transition) {
  let path = transition.path
  if (path.includes('/posts/') || path.includes('/users/') || path.includes('/comics/')) {
    if (!document.referrer.includes(APP_URL) && history.length <= 2) {
      history.replaceState({}, '', '/channels')
      const keys = Object.keys(transition.query)
      for (const [index, key] of keys.entries()) {
        if (path[path.length - 1] === '?') {
          path += '?'
        }
        path += `${key}=${transition.query[key]}`
        if (keys[index + 1]) {
          path += '&'
        }
      }
      history.pushState({}, '', path) 
    }
  }
})

window.router = router // make router accessible from native app

mixpanel.init(
  mixpanelToken,
  {
    debug: process.env.NODE_ENV !== 'production',
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
  }
)

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
  pinia: createPinia(),
  unhead: createHead(),
})