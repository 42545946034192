<template>
  <div class="container">
    <div class="records">
      <div
        v-for="record in records"
        :key="record._id"
        class="record">
        <div class="description-wrapper">
          <span class="description">{{ record.description }}</span>
          <span class="time">{{ new Date(record.createdAt).toLocaleString() }}</span>
        </div>
        <span
          class="coins"
          :class="{
            income: record.type.indexOf('income') !== -1,
            expense: record.type.indexOf('expense') !== -1
          }">
          {{ record.type.indexOf('income') !== -1 ? '+' : '-' }}
          {{ getPriceText(record.currency, record.coins) }}
        </span>
      </div>

      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="fetch"></load-more-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/bill'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    const getPriceText = function (currency, amount) {
      if (currency === 'JPY') {
        return `JPY ${amount}`
      }

      return `${currency} ${(amount / 100).toFixed(2)}`
    }

    return {
      getPriceText,
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      limit: 10,
      busy: false,
      hasMore: true,
      records: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.busy = true

      api
        .fetchCoinsRecords(this.page, this.limit)
        .then(records => {
          this.busy = false
          this.page += 1

          records.forEach(record => this.records.push(record))

          if (records.length < this.limit) {
            this.hasMore = false
          }
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    }
  },
  components: {
    LoadMoreButton
  },
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.records {
  margin-top: 1em;
}

.record {
  background: white;
  margin-bottom: 0.5em;
  padding: 0.5em;
  height: 3.6em;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-bottom: solid @border 2px;

  @media @phone {
    height: 5em;
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
  }

  span {
    display: inline-block;
  }

  .time {
    color: #aaa;
  }

  .coins {
    text-align: right;
    margin-left: auto;
    font-weight: bold;
    font-size: 1.3em;
    white-space: nowrap;
  }

  .income {
    color: rgb(76, 217, 100);
  }

  .expense {
    color: rgb(255, 45, 85);
  }
}
</style>
