<template>
  <div class="user-picker">
    <div class="user-picker-title">
      <div v-if="selectedUser">
        <p>
          You are granting to {{ selectedUser.nickname }}
        </p>
        <img :src="selectedUser.avatarURL">
      </div>
      <p v-else>You don't pick anyone</p>
    </div>

    <div>
      <input-text
        ref="inputDomRef"
        v-model="keyword"
        @focus="onFocus">
      </input-text>
    </div>

    <div
      v-show="users.length > 0"
      ref="usersDomRef"
      :style="floatingStyles"
      class="users-overlay card-2">
      <user-list-item
        v-for="user in users"
        :key="user._id"
        :user="user"
        :show-follow-button="false"
        class="touchable hoverable"
        @click.native="selectedUser = user">
      </user-list-item>
    </div>
  </div>
</template>

<script>
//@ts-check
import { computed, ref, watch } from 'vue'
import { searchUsers } from '@/api/user'
import { watchThrottled } from '@vueuse/core'
import { useToast } from '@/composables/toast'
import UserListItem from './UserListItem.vue'
import InputText from 'primevue/inputtext'
import { offset, useFloating } from '@floating-ui/vue'
import { useResponsive } from '@/composables/responsive'

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const userId = ref(props.value)
    const users = ref([])
    const keyword = ref('')
    const selectedUser = ref(null)
    const { Toast } = useToast()
    const usersDomRef = ref(null)
    const inputDomRef = ref(null)
    const { isOnMobile } = useResponsive()

    const middleware = computed(() => {
      if (isOnMobile.value) {
        return [offset(-50)]
      }

      return []
    })

    const { floatingStyles } = useFloating(inputDomRef, usersDomRef, {
      placement: 'bottom-start',
      middleware
    })

    watch(userId, () => {
      emit('input', userId.value)
    })

    watch(selectedUser, () => {
      if (selectedUser.value) {
        users.value = []
        userId.value = selectedUser.value._id
      }
    })

    watchThrottled(keyword, async () => {
      try {
        if (keyword.value) {
          users.value = await searchUsers(keyword.value)          
        } else {
          users.value = []
        }
      } catch (err) {
        Toast(err.message)
      }
    }, { throttle: 800 })


    const onFocus = async function () {
      if (users.value.length === 0 && keyword.value) {
        users.value = await searchUsers(keyword.value)
      }
    }

    return {
      userId,
      users,
      keyword,
      selectedUser,
      usersDomRef,
      inputDomRef,
      floatingStyles,
      onFocus
    }
  },
  components: {
    UserListItem,
    InputText,
  }
}
</script>

<style scoped>
.user-picker {
  width: 100%;
}

.user-picker-title {
  margin-bottom: 1rem;
}

.user-picker-title > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.user-picker-title img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 1rem
}

.users-overlay {
  width: calc(100% - 3rem);
  z-index: 1003;
  background-color: white;
}
</style>