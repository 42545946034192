//@ts-check
import { useToast } from '@/composables/toast'
import mediaapi from '../api/media'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { useRoute } from 'vue-router/composables'
import { useApplication } from './application'

export const useCollectionStore = defineStore('collection', () => {
  const medias = ref([])
  const groups = ref([])
  const hasMore = ref(true)
  const loading = ref(false)
  const { showToast } = useToast()
  const { t } = useI18n()
  const route = useRoute()
  const editing = ref(false)
  const application = useApplication()

  const selectedMediasCount = computed(() => {
    return medias.value.filter(media => media.ischeck).length
  })

  const more = async function () {
    const timestamp = medias.value[medias.value.length - 1].starredAt
    getMedias(route.params.ownerId, timestamp)
  }

  const remove = async function () {
    try {
      if (selectedMediasCount.value === 0) {
        showToast({
          message: t('chooseDeletion')
        }, 'info')
        return
      }

      const mediaIds = []
      medias.value.forEach(media => {
        if (media.ischeck) {
          mediaIds.push(media._id)
        }
      })

      await mediaapi.albumsDelete(mediaIds)

      showToast({
        message: t('unstarOK')
      }, 'ok')

      medias.value = []
      await getMedias(route.params.ownerId)
      edit(false)
    } catch (err) {
      showToast(err)
    }
  }

  const edit = async function (flag) {
    editing.value = flag
    medias.value = medias.value.map(media => {
      media.showcheck = flag
      media.ischeck = false
      return media
    })
  }

  const view = function (index) {
    if (editing.value) {
      medias.value = medias.value.map((media, i) => {
        if (i === index) {
          media.ischeck = !media.ischeck
        }
        return media
      })
    } else {
      application.openBrowser(medias.value, index)
    }
  }

  const getStarGroups = async function (page = 1) {
    try {
      loading.value = true
      const newGroups = await mediaapi.albums(page)
      groups.value = groups.value.concat(newGroups)

      hasMore.value = newGroups.length !== 0
    } catch (err) {
      showToast(err)
    } finally {
      loading.value = false
    }
  }

  const getMedias = async function (ownerId, timestamp = (new Date().getTime())) {
    try {
      loading.value = true
      const rawMedias = await mediaapi.oldImages(timestamp, ownerId)

      rawMedias.forEach(media => {
        media.showcheck = false
        media.ischeck = false
      })
      medias.value = rawMedias

      if (medias.value.length === 0) {
        showToast(t('nomore'), 'info')
      }
    } catch (err) {
      showToast(err)
    } finally {
      loading.value = false
    }
  }

  return {
    getStarGroups,
    getMedias,
    medias,
    loading,
    groups,
    selectedMediasCount,
    remove,
    edit,
    editing,
    more,
    view,
    hasMore,
  }
})