const day = 86400

export default {
    timestampToTime (timestamp) {
    //timestampToTime(1403058804);
    //console.log(timestampToTime(1403058804));//2014年06月18日 10:33
    //大于一周的情况
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '年';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
        var D = date.getDate() + '日 ';
        var h = date.getHours() + ':';
        var m = date.getMinutes();
        return Y+M+D+h+m;
    },

    timestamptohour (timestamp) {
        var date = new Date(timestamp);
        var h = date.getHours() + ':';
        var m = date.getMinutes();
        return h+m;
    },

    timestamptomonth (timestamp) {
        var date = new Date(timestamp);
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
        var D = date.getDate() + '日';
        return M+D
    },

    timestamptoyear (timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '年';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
        var D = date.getDate() + '日';
        return Y+M+D
    },
    getWeek (timestamp) { 
        var date = new Date(timestamp)
        var week; 
        if(date.getDay() == 0) week = "星期日 "
        if(date.getDay() == 1) week = "星期一 " 
        if(date.getDay() == 2) week = "星期二 " 
        if(date.getDay() == 3) week = "星期三 " 
        if(date.getDay() == 4) week = "星期四 " 
        if(date.getDay() == 5) week = "星期五 " 
        if(date.getDay() == 6) week = "星期六 " 
        return week; 
    },

    //shangwu xiawu
    morning (str) {
        //一天的情况
        now = new Date(str),
        hour = now.getHours() 
        if(hour < 6){
            document.write("凌晨好！")
        } 
        else if (hour < 9){
            document.write("早上好！")
        } 
        else if (hour < 12){
            document.write("上午好！")} 
        else if (hour < 14){document.write("中午好！")
        } 
        else if (hour < 17){
            document.write("下午好！")
        } 
        else if (hour < 19){
            document.write("傍晚好！")
        } 
        else if (hour < 22){
            document.write("晚上好！")
        } 
        else {
            document.write("夜里好！")
        } 
    },

    chatList (str) {
        //var str = 1474172800000;
        //isToday(str);
        let daynum = this.getDayTimeBefor(str)

        if (daynum < 0 || daynum === 0) {
            return this.timestamptohour(str)
        } else if (daynum ==1 ) {
            return '昨天  ' + this.timestamptohour(str)
        } else {
            return this.timestampToTime(str)
        }
    },


    //今天显示10: 05 昨天显示昨天 今年显示2月四日 最后显示年份2017年12月3日
    converListTime (publishtime) {
        let data = this.converTimeBefor(publishtime)
        if (data.days < 0 || data.days === 0) {
            //今天
            return this.timestamptohour(publishtime)
        } else if (data.days === 1) {
            return '昨天'

        } else if ( data.days > 1 &&  data.years < 0 || data.years ===0) {
            return this.timestamptomonth(publishtime)
        } else {
            return this.timestamptoyear(publishtime)
        }
        //console.info(data)
    },
    converTimeBefor(publishtime) { 
        var currTime = Date.parse(new Date());
        var l = parseInt(currTime) - parseInt(publishtime); 
        var time = l / 1000; 
        // 秒转分钟 
        var minuies = Math.floor(time / 60); 
        // 秒转小时 
        var hours = Math.floor(time / 3600); 
        //秒转天数 
        var days = Math.floor(time / 3600 / 24); 
        var months = Math.floor(time / 3600 / 24 / 30); 
        var years = Math.floor(time / 3600 / 24 / 30 / 12); 
        var arr = { minuies, hours, days, years, time, months };
        return arr

    },
    //7天以前时间有变化 1天是10: 05。2天是昨天 10:25 7天是 周四 10: 25 大于7天是2014年06月18日 10:33
    //3分钟显示一次时间
    getDateTimeBefor(publishtime) { 
        var currTime = Date.parse(new Date());
        var l = parseInt(currTime) - parseInt(publishtime); 
        // 少于一分钟 
        var time = l / 1000; 
        if (time < 60) { 
            return "刚刚"; 
        } 
  
        // 秒转分钟 
        var minuies = time / 60; 
        if (minuies < 60) { 
            return Math.floor(minuies) + "分钟前"; 
        } 
  
        // 秒转小时 
        var hours = time / 3600; 
        if (hours < 24) { 
            return Math.floor(hours) + "小时前"; 
        } 
        //秒转天数 
        var days = time / 3600 / 24; 
        if (days < 30) { 
            return Math.floor(days) + "天前"; 
        } 
        //秒转月 
        var months = time / 3600 / 24 / 30; 
        if (months < 12) { 
            return Math.floor(months) + "月前"; 
        } 
        //秒转年 
        var years = time / 3600 / 24 / 30 / 12; 
        return Math.floor(years) + "年前"; 
  
    },
    //在几天前
    getDayTimeBefor(publishtime) { 
        var currTime = Date.parse(new Date());
        var l = parseInt(currTime) - parseInt(publishtime); 
        // 少于一分钟 
        var time = l / 1000; 
        //秒转天数
        var days = time / 3600 / 24; 
        if (days < 30) { 
            return Math.floor(days); 
        }

    },

    //在3分钟以前
    getThreeTimeBefor(beforetime, aftertime) { 
        var l = parseInt(beforetime) - parseInt(aftertime); 
        // 少于一分钟 
        var time = l / 1000; 
        // 秒转分钟 
        var minuies = time / 60; 
        if (minuies < 3) { 
            return false
        } else {
            return true
        }

    }
    
}