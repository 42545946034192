<template lang="html">
  <div
    v-if="authenticated"
    class="container">
    <div class="posts">
      <post-simple-item
        v-for="(post, index) in posts"
        :key="post._id"
        :post="post"
        class="touchable post"
        @click.native="handleClick(index)"></post-simple-item>
    </div>
    
    <load-more-button
      :busy="busy"
      :has-more="hasMore"
      :trigger="getPosts"></load-more-button>
  </div>
</template>

<script>
import api, { getUserPromotedPosts } from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostSimpleItem from '@/components/PostSimpleItem.vue'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'


export default {
  metaInfo: {
    title: "打折促销",
  },
  setup () {
    return {
      ...useToast(),
      ...useCurrentUser()
    }
  },
  data () {
    return {
      posts: [],
      timestamp: new Date().getTime(),
      busy: false,
      hasMore: true,
    }
  },
  watch: {
    authenticated (value) {
      if (value) {
        this.getTopPosts()
        this.getPosts()
      }
    }
  },
  mounted () {
    if (this.authenticated) {
      this.getTopPosts()
      this.getPosts()
    }
  },
  methods: {
    getTopPosts () {
      getUserPromotedPosts(this.currentUser._id)
        .then(posts => posts.reverse().forEach(post => this.posts.unshift(post)))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err,
          })
        })
    },
    getPosts () {
      this.busy = true

      const userId = this.currentUser._id
      const timestamp = this.timestamp

      api
        .getPosts({ timestamp, limit: 10, userId }, 'old')
        .then(posts => {
          posts.forEach(post => this.posts.push(post))
          if (posts.length > 0) {
            this.timestamp = posts[posts.length - 1].createdAt            
          } else {
            this.hasMore = false
          }
          this.busy = false
        })
        .catch(err => {
          console.error(err)

          this.busy = false

          this.showToast({
            message: err,
          })
        })
    },
    handleClick (index) {
      const post = this.posts[index]

      if (post.discount === 0.0) {
        this.setDiscount(index)
      } else {
        this.unsetDiscount(index)
      }
    },
    unsetDiscount (index) {
      const post = this.posts[index]

      this
        .$messagebox({
          confirmButtonClass: 'dialog-positive-button',
          cancelButtonClass: 'dialog-negative-button',
          showCancelButton: true,
          title: '确定取消折扣吗？',
        })
        .then(action => {
          if (action === 'confirm') {
            return api.discount(post._id, 0)
          } else {
            throw action
          }
        })
        .then(() => {
          post.discount = 0

          this.showToast({
            message: '折扣已取消',
          }, 'ok')
        })
        .catch(err => {
          if (err !== 'cancel') {
            console.error(err)

            const message = err.message ? err.message : err

            this.showToast({
              message,
            })
          }
        })
    },
    setDiscount (index) {
      const post = this.posts[index]

      if (post.price === 0) {
        return this.showToast({
          message: '免费帖无法打折',
        }, 'info')
      }

      let discount = 0

      this
        .$messagebox({
          confirmButtonClass: 'dialog-positive-button',
          cancelButtonClass: 'dialog-negative-button',
          showCancelButton: true,
          title: '请输入折扣率',
          message: '比如，如果您想打八折，请输入 0.2',
          showInput: true
        }, (value, action) => {
          if (action === 'cancel') {
            return
          }

          if (value > 0.0 && value < 1.0) {
            discount = value
            api
              .discount(post._id, discount)
              .then(ok => {
                if (ok) {
                  post.discount = discount

                  this.showToast({
                    message: '打折成功',
                  }, 'ok')
                }
              })
              .catch(err => {
                console.error(err)

                if (err !== 'cancel') {
                  const message = err.message ? err.message : err

                  this.showToast({
                    message,
                  })
                }
              })
          } else {
            this.showToast({
              message: '请输入 0 - 1 之间的折扣率',
            }, 'info')
          }
        })
    }
  },
  components: {
    LoadMoreButton,
    PostSimpleItem
  }
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.container {
  background-color: @listview-background;
  padding-top: 1px;
  padding-bottom: 1px;
}

.posts {
  .post {
    margin: 1em 0;
  }
}
</style>
