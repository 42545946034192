<template>
  <div class="post-list-item-overview-container">
    <div
      class="item-pics-container item-pics-container-common"
      :class="{ gray: $parent.post.fake}">
      <div
        v-if="filterdMedias.length > 0"
        class="item-pics-row item-pics-row-common">
        <post-list-item-picture
          v-for="index in [0, 1, 2]"
          :key="index"
          :index="index"
          :pictures="filterdMedias"
          :post="$parent.post"
          @click.stop.prevent.native="$parent.view(index)"></post-list-item-picture>
      </div>

      <div
        v-if="filterdMedias.length > 3"
        class="item-pics-row item-pics-row-common">
        <post-list-item-picture
          v-for="index in [3, 4, 5]"
          :key="index"
          :index="index"
          :pictures="filterdMedias"
          :post="$parent.post"
          @click.stop.prevent.native="$parent.view(index)"></post-list-item-picture>
      </div>

      <div
        v-if="filterdMedias.length > 6"
        class="item-pics-row item-pics-row-common">
        <post-list-item-picture
          v-for="index in [6, 7, 8]"
          :key="index"
          :index="index"
          :pictures="filterdMedias"
          :post="$parent.post"
          :show-indicator="index === 8"
          @click.stop.prevent.native="$parent.view(index)"></post-list-item-picture>
      </div>
    </div>
  </div>
</template>

<script>
import PostListItemPicture from '@/components/PostListItemPicture.vue'
import { MediaType } from '@/utils/defines'

export default {
  computed: {
    filterdMedias () {
      return this.$parent.post.pictures.filter(media => media.type === MediaType.IMAGE || media.type === MediaType.VIDEO || media.type === MediaType.OBJ)
    }
  },
  components: {
    PostListItemPicture,
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

.post-list-item-overview-container {
  .postlistitemoverview-linkified {
    color: #55caff;
    cursor: pointer;
    opacity: 0.65;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
    float: none;
  }
  .item-pics-container {
    margin-top: 15px;
    text-align: center;
    position: relative;

    .item-pics-row {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    .item-pics-row-common {
      width: 236px;

      @media @phone {
        width: 100%;
      }
      margin: 0 auto;
    }
  }

  .item-content {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: inline-block;
    margin-bottom: 10px;

    .item-content-text {
      margin-left: 1em;
      margin-right: 1em;
      font-size: 0.88em;
    }
  }
  .item-pics-container-common {
    margin-top: 8px;
  }
}

</style>
