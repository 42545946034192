<template>
  <div class="empty-container">
    <img src="@/assets/Page_empty_guider.png">
    <p>{{ text }}</p>
    <pr-button
      v-if="buttonText"
      type="primary"
      @click.native="clicked"
    >
      {{ buttonText }}
    </pr-button>
  </div>
</template>

<script>
import PrButton from '@/components/PRButton.vue'
import { t } from '@/translations'

export default {
  components: {
    PrButton
  },
  props: {
    text: {
      type: String,
      default: t('nothingHere')
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  methods: {
    clicked () {
      this.$emit('trigger')
    }
  }
}
</script>

<style lang="less" scoped>
.empty-container {
  text-align: center;
  padding: 40px 0;

  > * {
    display: block;
  }

  img {
    width: 80px;
    margin: auto;
  }

  p {
    margin-top: 10px;
    font-size: 17px;
    color: #7b7b7b;
    font-weight: 500;
  }

  button {
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    margin: auto;
    margin-top: 18px;
  }
}
</style>
