//@ts-check
import { onUnmounted, ref } from "vue"
import { streamingUrlWS } from "@/config"
import { until } from "@vueuse/core"
import localforage from "localforage"
import { ACCESSTOKEN_KEY } from "@/pinia/authenticator"
import { generateRandomClientId } from "@/api/rtc"
import { useToast } from "./toast"
import pica from "pica"

const serverRenderingSize = {
  height: 800,
  width: 800,
}

const dpr = window.devicePixelRatio || 1

const offscreenCanvas = document.createElement('canvas')
offscreenCanvas.width = serverRenderingSize.width * dpr
offscreenCanvas.height = serverRenderingSize.height * dpr
const offscreenCtx = offscreenCanvas.getContext('2d')

const bufferCanvas = document.createElement('canvas')

export const useModelStreamWS = function (mediaId, canvasDomRef, logs) {
  const connectionState = ref('closed')
  const ws = ref(null)
  const preferedState = ref('closed')
  const { showToast } = useToast()

  let retryCount = 1
  const maxRetries = 1

  const connectWS = async function () {
    try {
      preferedState.value = 'connected'

      await until(canvasDomRef).toMatch(v => v !== null)
  
      const url = new URL(streamingUrlWS)
      url.searchParams.append('mediaId', mediaId)
      const token = await localforage.getItem(ACCESSTOKEN_KEY)
      if (token) {
        url.searchParams.append('token', token)
      } else {
        url.searchParams.append('clientId', generateRandomClientId())
      }
  
      ws.value = new WebSocket(url.toString())
      const context = canvasDomRef.value.getContext('2d')
  
      ws.value.onopen = () => {
        connectionState.value = 'connected'
        logs.value.push('WebSocket connection established.')
        const rect = canvasDomRef.value.getBoundingClientRect()
        canvasDomRef.value.width = rect.width
        canvasDomRef.value.height = rect.height
        retryCount = 1
      }
  
      ws.value.onmessage = async event => {
        try {
          const blob = new Blob([event.data], { type: 'image/webp' })
          const imageBitmap = await createImageBitmap(blob)

          // offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height)
          offscreenCtx.drawImage(imageBitmap, 0, 0, offscreenCanvas.width, offscreenCanvas.height)

          const rect = canvasDomRef.value.getBoundingClientRect()
          bufferCanvas.width = rect.width
          bufferCanvas.height = rect.height

          await pica().resize(offscreenCanvas, bufferCanvas, {
            filter: 'mks2013',
          })

          context.clearRect(0, 0, rect.width, rect.height)
          context.drawImage(bufferCanvas, 0, 0, rect.width, rect.height)
        } catch (err) {
          console.error(err)
        }
      }
  
      ws.value.onerror = err => {
        logs.value.push(`WebSocket error: ${err}`)
        console.error(err)
      }
  
      ws.value.onclose = async () => {
        connectionState.value = 'closed'
        logs.value.push('WebSocket connection closed.')
        if (preferedState.value === 'connected' && retryCount <= maxRetries) {
          ws.value = null
          retryCount++
          await connectWS()
        }
      }
    } catch (err) {
      showToast(err.message)
    }
  }

  onUnmounted(() => {
    preferedState.value = 'closed'
    ws.value.close()
  })

  return {
    ws,
    connectWS,
    connectionState,
  }
}