//@ts-check
import { ref } from "vue"
import { useAuthenticator } from "@/pinia/authenticator"


export const useLoginEmail = (verifiedEmailRef) => {
  const authenticator = useAuthenticator()
  const verifiedEmail = verifiedEmailRef ? verifiedEmailRef : ref('')
  const emailCode = ref('')
  const verfiying = ref(false)

  const loginEmail = async () => {
    try {
      verfiying.value = true
      await authenticator.loginEmail({ email: verifiedEmail.value, captcha: emailCode.value })
    } catch (error) {
      console.error(error)
    } finally {
      verfiying.value = false
    }
  }

  return {
    loginEmail,
    verifiedEmail,
    emailCode,
    verfiying,
  }
}