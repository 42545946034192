//@ts-check
import { onMounted, computed, ref, watch } from "vue"
import { useI18n } from 'vue-i18n-composable'
import { format } from 'timeago.js'
import { useRoute } from 'vue-router/composables'
import { useArtworkStore } from '@/pinia/artwork'
import { useCurrentUser } from '@/composables/user_data'
import { countryCode2Locale, eventEmitter, getCDNHost, seoworkds } from "@/utils"
import { APP_URL, untrustLimit } from "@/config"
import { useCurrentCountry } from "./country"
import { events } from "@/utils/events"
import { getSocialImageUrl } from "@/api/post"
import { sanitize } from 'dompurify'
import { useR18CoverStore } from "@/pinia/r18cover"
import { MediaType } from "@/utils/defines"

// postId -> boolean
const socialImageUrlTasks = new Map()

export const useArtworkPresent = function (plainPostId, refCurrency, propsArtwork = null) {
  const { t, d } = useI18n()
  const artworkStore = useArtworkStore()
  const postNotFound = ref(false)
  const { authenticated, currentUserAuthed } = useCurrentUser()
  const { countryCode } = useCurrentCountry()
  const route = useRoute()
  const socialImageUrl = ref('')
  const r18coverStore = useR18CoverStore()
  const isVisitorMode = Number(route.query.visitor) === 1


  if (!plainPostId) {
    plainPostId = route.params.postId
  }

  if (propsArtwork) {
    artworkStore.cacheArtwork(propsArtwork)
  }

  const post = computed(() => {
    return artworkStore.artworks[plainPostId]
  })

  const createdAt = computed(() => {
    if (!post.value) {
      return ''
    }

    return format(post.value.createdAt, navigator.language.replace('-', '_'))
  })

  const price = computed(() => {
    if (!post.value) {
      return -1
    }

    const price = post.value.prices[refCurrency.value]

    if (price === 0) {
      return t('free')
    }

    if (price > 1000) {
      const shouldBeInt = (price / 100) % 10 === 0

      if (shouldBeInt) {
        return `${Math.round(price / 1000)}k`
      }

      return `${(price / 1000).toFixed(1)}k`
    }

    if (refCurrency.value === 'JPY') {
      return price
    } else {
      return price / 100
    }
  })

  const priceText = computed(() => {
    if (!post.value) {
      return ''
    }

    if (post.value.requiresCircleRight) {
      return t('onlyForMembers')
    }

    if (onSale.value) {
      return `${t('discountQuote', { price: discountedPriceText.value })}`
    } else {
      return localPriceText.value
    }
  })

  const localPriceText = computed(() => {
    if (!post.value) {
      return ''
    }
    
    if (!refCurrency.value) {
      return ''
    }

    let localPrice = post.value.prices[refCurrency.value]

    if (refCurrency.value !== 'JPY') {
      localPrice = localPrice / 100
    }

    return `${refCurrency.value}${localPrice}`
  })

  const discountedPriceText = computed(() => {
    if (!post.value) {
      return ''
    }
    
    if (!refCurrency.value) {
      return ''
    }

    let localPrice = post.value.prices[refCurrency.value]

    if (refCurrency.value !== 'JPY') {
      localPrice = localPrice / 100
    }

    let discountedPrice = localPrice * (1 - post.value.discount)
    if (refCurrency.value === 'JPY') {
      discountedPrice = Math.round(discountedPrice)
    } else {
      discountedPrice = Number(discountedPrice.toFixed(2))
    }
    return `${refCurrency.value}${discountedPrice}`
  })

  const coverUrl = computed(() => {
    if (firstPreviewMedia.value) {
      return firstPreviewMedia.value.thum
    }

    if (post.value.coverUrl) {
      return post.value.coverUrl
    }

    if (post.value.pictures.length > 0) {
      return post.value.pictures[0].thum
    }

    return 'https://placehold.co/100x100?text=No+Preview'
  })

  const coverImageStyle = computed(() => {
    if (post.value.pictures.length > 0) {
      if (coverUrl.value === post.value.pictures[0].thum) {
        if (post.value.pictures[0].width && post.value.pictures[0].height) {
          return {
            aspectRatio: `${post.value.pictures[0].width} / ${post.value.pictures[0].height}`,
          } 
        }
      }
    }

    if (firstPreviewMedia.value && coverUrl.value === firstPreviewMedia.value.thum) {
      if (firstPreviewMedia.value.width && firstPreviewMedia.value.height) {
        return {
          aspectRatio: `${firstPreviewMedia.value.width} / ${firstPreviewMedia.value.height}`,
        }
      }
    }

    return {
      aspectRatio: '1 / 1',
    }
  })

  const avatarURL = computed(() => {
    if (!post.value) {
      return ''
    }

    const user = post.value.user
    if (!user) {
      return ''
    }

    return user.avatarURL ? user.avatarURL : ''
  })

  const circleId = computed(() => {
    if (!post.value) {
      return ''
    }

    if (post.value.groupIds) {
      return post.value.groupIds[0]
    } else {
      return ''
    }
  })

  const nickname = computed(() => {
    if (!post.value) {
      return ''
    }

    const user = post.value.user

    if (!user) {
      return ''
    }

    return user.nickname ? user.nickname : ''
  })

  const videoIncluded = computed(() => {
    if (!post.value) {
      return false
    }

    return post.value.pictures.filter(picture => picture.type === MediaType.VIDEO).length > 0
  })

  const onSale = computed(() => {
    if (!post.value) {
      return false
    }

    if (post.value.discount === 0 || post.value.discount === undefined) {
      return false
    }

    return true
  })

  const starText = computed(() => {
    if (!post.value) {
      return ''
    }


    if (post.value.starCount !== 0) {
      return t('starWithCount', { starCount: post.value.starCount })
    } else {
      return t('star')
    }
  })

  const artworkMetaInfo = computed(() => {
    const info = {
      meta: []
    }

    if (outofservice.value) {
      info.title = `${t('outofservice', { countryCode: countryCode.value })} | Perohub`
      return info
    }

    let content = ''

    if (!post.value) {
      info.title = `${t('artworkDetail')} | Perohub`
    } else {
      if (post.value.title) {
        info.title = sanitize(`${post.value.title} by ${post.value.user.nickname} | Perohub`)
      } else {
        info.title = sanitize(`Artwork by ${post.value.user.nickname} | Perohub`)
      }
      content = sanitize(`${post.value.title} ${post.value.content}`)
      info.meta.push(
        {
          name: 'keywords',
          content: `${sanitize(post.value.title)},${sanitize(post.value.user.nickname)},${seoworkds}` },
        {
          name: 'og:url',
          content: `${APP_URL}/posts/${post.value._id}`
        }
      )
    }

    info.meta.push({ name: 'description', content: sanitize(content) })

    const metas = [{
      name: 'twitter:site',
      content: '@perohubcom'
    }, {
      name: 'twitter:title',
      content: sanitize(info.title),
    }, {
      name: 'twitter:description',
      content: sanitize(content),
    }, {
      name: 'og:title',
      content: sanitize(info.title)
    }]

    if (firstPreviewMedia.value || socialImageUrl.value) {
      let content = null

      if (firstPreviewMedia.value) {
        const url = firstPreviewMedia.value.type === MediaType.IMAGE ? new URL(firstPreviewMedia.value.url) : new URL(firstPreviewMedia.value.thum)
        const key = url.searchParams.get('path') 
        const cdnUrl = new URL(getCDNHost())

        cdnUrl.hostname = getCDNHost()
        cdnUrl.pathname = 'files'
        cdnUrl.searchParams.append('path', key)
        content = cdnUrl.toString()
        if (firstPreviewMedia.value.type === MediaType.IMAGE) {
          content = decodeURIComponent(content).replace('/pjpeg', '/thumbnail/300')
        }
      }

      if (socialImageUrl.value) {
        content = socialImageUrl.value
      }

      if (content) {
        const finalUrl = new URL(content)
        // refresh web crawler
        finalUrl.searchParams.append('t', String((Math.round(Date.now() / 1000))))
  
        metas.push({
          name: 'twitter:card',
          content: 'summary_large_image',
        }, {
          name: 'twitter:image',
          content: finalUrl.toString(),
        }, {
          name: 'og:image',
          content: finalUrl.toString(),
        })
      } else {
        metas.push({
          name: 'twitter:card',
          content: 'summary',
        })
      }
    } else {
      metas.push({
        name: 'twitter:card',
        content: 'summary',
      })
    }

    info.meta.push(...metas)

    return info
  })

  const firstPreviewMedia = computed(() => {
    if (!post.value) {
      return null
    }

    const freePictures = post.value.pictures.filter(media => {
      if (post.value.price > 0) {
        return media.canBrowse && media.type === MediaType.IMAGE
      }

      return media.type === MediaType.IMAGE
    })

    if (freePictures.length > 0) {
      return freePictures[0]
    }

    if (videoIncluded.value) {
      const media = post.value.pictures.filter(media => media.type === MediaType.VIDEO)[0]

      if (media) {
        return media
      }
    }

    return null
  })

  const firstPreviewTextMedia = computed(() => {
    const freeTextMedias = post.value.pictures.filter(media => media.type === MediaType.TEXT && (media.canBrowse || post.value.price === 0))
    if (freeTextMedias.length === 0) {
      return null
    }

    return freeTextMedias[0]
  })

  const nsfw = computed(() => {
    if (post.value) {
      if (post.value.isR18) {
        return true
      }

      return post.value.pictures.filter(media => media.untrust >= untrustLimit).length > 0      
    }

    return false
  })

  const outofservice = computed(() => {
    if ((post.value && post.value.user.isR18) || nsfw.value) {
      if (countryCode.value === 'CN' && !currentUserAuthed.value) {
        return true
      }
    }

    return false
  })

  const localeTitle = computed(() => {
    if (!post.value) {
      return ''
    }

    let title = ''

    if (!post.value.titleLocales) {
      title = post.value.title
    } else {
      const locale = countryCode2Locale(countryCode.value)
      if (post.value.titleLocales[locale]) {
        title = post.value.titleLocales[locale]
      } else {
        title = post.value.title
      }
    }

    if (!title) {
      return t('artworkOnDate', { date: d(post.value.createdAt) })
    }

    return title
  })

  const deleted = computed(() => {
    if (!post.value) {
      return false
    }

    if (post.value.paid) {
      return false
    }

    if (post.value.status === 'deleted') {
      return true 
    }

    return false
  })

  watch(authenticated, (value, oldValue) => {
    if (value && !oldValue) {
      artworkStore.getArtworkById(plainPostId, true)
    }
  })

  watch(post, () => {
    initSocialImageUrl()
  })

  watch(nsfw, () => {
    if (nsfw.value) {
      r18coverStore.showR18Cover()      
    }
  })

  const getPost = async function () {
    await artworkStore.getArtworkById(plainPostId)
    if (!post.value) {
      postNotFound.value = true
    }
  }

  const initSocialImageUrl = async function () {
    try {
      if (!post.value) {
        return
      }
      if (!route.path.includes('/posts/') && !route.path.includes('/comics/')) {
        return
      }
      if (socialImageUrlTasks.has(post.value._id)) {
        return
      }
      socialImageUrlTasks.set(post.value._id, true)
      socialImageUrl.value = await getSocialImageUrl(post.value._id)
      socialImageUrlTasks.delete(post.value._id)
    } catch (err) {
      console.error(err)
    }
  }

  onMounted(() => {
    if (typeof plainPostId === 'string') {
      getPost()

      eventEmitter.on(events.LoginCompleted, getPost)
      eventEmitter.on(events.LogoutCompleted, getPost)
    }
    initSocialImageUrl()
  })

  return {
    post,
    createdAt,
    priceText,
    avatarURL,
    circleId,
    nickname,
    videoIncluded,
    onSale,
    starText,
    price,
    postNotFound,
    artworkMetaInfo,
    nsfw,
    outofservice,
    discountedPriceText,
    localPriceText,
    localeTitle,
    firstPreviewMedia,
    deleted,
    firstPreviewTextMedia,
    isVisitorMode,
    coverUrl,
    coverImageStyle,
  }
}