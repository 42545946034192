<template>
  <Sidebar
    v-if="isOnMobile"
    :visible.sync="visible"
    :position="fullscreenOnMobile ? 'full' : 'bottom'"
    class="popup-sidebar"
    @hide="emit('hide')">
    <template #header>
      <slot name="header"></slot>
    </template>
    <slot></slot>
    <slot name="footer"></slot>
  </Sidebar>
  <Dialog
    v-else
    :visible.sync="visible"
    :modal="true"
    @hide="emit('hide')">
    <template #header>
      <slot name="header">&nbsp;</slot>
    </template>
    <slot></slot>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </Dialog>
</template>


<script>
import { useResponsive } from '@/composables/responsive'
import Sidebar from 'primevue/sidebar'
import Dialog from 'primevue/dialog'
import { ref, watch } from 'vue'


export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    fullscreenOnMobile: {
      type: Boolean,
      default: false,
    }
  },
  setup (props, { emit }) {
    const visible = ref(props.value)

    watch(visible, () => {
      emit('input', visible.value)
    })

    watch(props, () => {
      visible.value = props.value
    })

    return {
      visible,
      emit,
      ...useResponsive(),
    }
  },
  components: {
    Sidebar,
    Dialog,
  }
}
</script>

<style>
.popup-sidebar .p-sidebar-header-content {
  margin-right: auto;
}

.popup-sidebar .p-sidebar-content {
  flex: 1;
}
</style>