<template>
  <div
    v-show="circle"
    class="tags-container">
    <member-tag-designer
      v-if="isCircleOwner"></member-tag-designer>
    <member-tag-updater
      v-else></member-tag-updater>
  </div>
</template>


<script>
import { useRoute } from 'vue-router/composables'
import { onMounted, ref, computed } from 'vue'
import { useCurrentUser } from '@/composables/user_data'
import { getCircle } from '@/api/circle'
import MemberTagDesigner from '@/components/MemberTagDesigner.vue'
import MemberTagUpdater from '@/components/MemberTagUpdater.vue'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    const { currentUser } = useCurrentUser()
    const circleId = useRoute().params.circleId
    const circle = ref(null)
    const { Toast } = useToast()

    const isCircleOwner = computed(() => {
      if (!circle.value) {
        return false
      }

      if (!currentUser.value._id) {
        return false
      }

      if (circle.value.author._id === currentUser.value._id) {
        return true
      }

      return false
    })


    onMounted(async () => {
      try {
        circle.value = await getCircle(circleId)
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      }
    })

    return {
      isCircleOwner,
      circle,
    }
  },
  components: {
    MemberTagDesigner,
    MemberTagUpdater,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.tags-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>