import { api } from "./base"


export const getDiscountCodes = async () => {
  try {
    const res = await api.get('/discountCodes')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createDiscountCode = async (data) => {
  try {
    const res = await api.post('/discountCodes', data)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateDiscountCode = async (discountCodeId, data) => {
  try {
    const res = await api.put(`/discountCodes/${discountCodeId}`, {
      ...data,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const deleteDiscountCode = async (discountCodeId) => {
  try {
    const res = await api.delete(`/discountCodes/${discountCodeId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}