<template>
  <div class="container">
    <p>在每个频道的「频道详情」中即可给该频道投稿。</p>
    <p>您不会才知道吧！</p>
  </div>
</template>

<script>

export default {
  metaInfo: {
    title: "投稿",
  },
}
</script>

<style scoped>
.container {
  padding: 0.6em;
}
</style>
