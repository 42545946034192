<template>
  <div>
    <div
      v-for="post in posts.filter(post => post !== undefined)"
      :key="post._id"
      class="item">
      <post-list-item
        v-if="post.type === 'album' || post.type === '3dasset' || post.type === 'article'"
        :post="post">
      </post-list-item>
      <post-list-item-overcomic-phone
        v-if="post.type === 'comics'"
        :post-id="post._id">
      </post-list-item-overcomic-phone>
    </div>
  </div>
</template>

<script>
import PostListItemOvercomicPhone from '@/components/PostListItemOvercomicPhone.vue'
import PostListItem from '@/components/PostListItem.vue'


export default {
  props: {
    posts: {
      type: Array,
      required: true
    },
  },
  components: {
    PostListItemOvercomicPhone,
    PostListItem,
  },
}
</script>

<style scoped lang="less">



  
</style>
