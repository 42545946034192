<template>
  <div class="downloads-container">
    <header>
      <p>{{ $t('zipTaskAutoDeleteTip') }}</p>
      <pr-button
        :loading="loading"
        style="margin-left: auto;"
        @click.native="refresh">
        {{ $t('refresh') }}
      </pr-button>
    </header>

    <data-table :value="tasks">
      <column
        header="Artwork"
        field="title">
      </column>
      <column
        header="status">
        <template #body="slotProps">
          {{ getStatusText(slotProps.data.status) }}
        </template>
      </column>
      <column
        header="action">
        <template #body="slotProps">
          <pr-button
            :disabled="slotProps.data.status !== 'ok'"
            size="small"
            type="primary"
            @click.native="goToDownload(slotProps.data.artworkId)">
            {{ $t('downloads') }}
          </pr-button>
        </template>
      </column>
    </data-table>

    <load-more-button
      :busy="loading"
      :has-more="hasMore"
      @trigger="loadMore">
    </load-more-button>
  </div>
</template>

<script>
//@ts-check
import { useToast } from '@/composables/toast'
import { onMounted, ref } from 'vue'
import { getZipTasks } from '@/api/ziptask'
import { useCurrentUser } from '@/composables/user_data'
import { ACCESSTOKEN_KEY } from '@/pinia/authenticator'
import localforage from 'localforage'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import PrButton from '@/components/PRButton.vue'
import { getCDNHost } from '@/utils'


export default {
  setup () {
    const loading = ref(false)
    const { showToast } = useToast()
    const { authenticated } = useCurrentUser()
    const tasks = ref([])
    const page = ref(1)
    const hasMore = ref(true)

    const refresh = async function () {
      try {
        tasks.value = []
        page.value = 1
        await get() 
      } catch (err) {
        showToast(err.message)
      }
    }

    const get = async function () {
      try {
        loading.value = true
        tasks.value.push(...await getZipTasks(page.value))
        page.value += 1
      } catch (err) {
        showToast(err.message)
      } finally {
        loading.value = false
      }
    }

    const loadMore = async function () {
      const beforeCount = tasks.value.length
      await get()
      const afterCount = tasks.value.length
      if (beforeCount === afterCount) {
        hasMore.value = false
      } else {
        hasMore.value = true
      }
    }

    const getStatusText = function (status) {
      return status
    }

    const goToDownload = async function (artworkId) {
      try {
        if (!authenticated.value) {
          throw new Error('Please login first')
        }
        const token = await localforage.getItem(ACCESSTOKEN_KEY)
        const url = new URL(getCDNHost())
        url.pathname = 'zip'
        url.searchParams.append('artwork_id', artworkId)
        url.searchParams.append('token', token)
        const newWindow = window.open(url.toString())
        if (!newWindow) {
          location.href = url.toString()
        }
      } catch (err) {
        showToast(err.message)
      }
    }


    onMounted(() => {
      get()
    })

    return {
      goToDownload,
      loading,
      tasks,
      page,
      hasMore,
      loadMore,
      getStatusText,
      refresh,
    }
  },
  components: {
    DataTable,
    Column,
    LoadMoreButton,
    PrButton,
  }
}
</script>

<style scoped>
.downloads-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

header {
  display: flex;
  gap: 1rem;
  align-items: center;
}
</style>