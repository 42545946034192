import { loadStripe } from '@stripe/stripe-js'
import { StripePublishableKey } from '@/config'
import { reusePendingPromise } from 'reuse-pending-promise'

let stripe = null

const _getStripe = async function () {
  if (!stripe) {
    stripe = await loadStripe(StripePublishableKey)
  }

  return stripe
}

export const getStripe = reusePendingPromise(_getStripe)