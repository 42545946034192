//@ts-check
import { useArtworkStore } from '@/pinia/artwork'
import { ref, watch, computed, onMounted } from 'vue'
import { getCircle } from '@/api/circle'
import { useCurrentCurrency } from '@/composables/currency'
import { useI18n } from 'vue-i18n-composable'
import { useToast } from './toast'
import { usePriceFormatter } from '@/composables/price_format'


export const useArtworkCircle = function (artworkId) {
  const artworkStore = useArtworkStore()
  const { currency } = useCurrentCurrency()
  const { t } = useI18n()
  const circle = ref(null)
  const { Toast } = useToast()
  const { formatPrice } = usePriceFormatter()


  const artwork = computed(() => {
    return artworkStore.artworks[artworkId]
  })


  const circleAdText = computed(() => {
    if (!circle.value) {
      return ''
    }

    let durationI18Key = ''
    if (circle.value.salesType === 'half-year') {
      durationI18Key = 'halfYear'
    } else if (circle.value.salesType === 'month') {
      durationI18Key = 'oneMonth'
    } else if (circle.value.salesType === 'long-term'){
      durationI18Key = 'forever'
    } else {
      durationI18Key = circle.value.salesType
    }

    const duration = t(durationI18Key)

    if (circle.value.status === 'completed') {
      return t('circleAdInPostUpfront', {
        price: `${currency.value}${circle.value.upfrontPrices[currency.value]}`,
        duration,
      })
    }

    const price = circle.value.prices[currency.value]

    if (circle.value.complementPrices[currency.value] === 0) {
      return t('circleAdInPostFreeComplement', {
        price: formatPrice(price, currency.value, true),
        duration,
      }) 
    }

    return t('circleAdInPost', {
      price: formatPrice(price, currency.value, true),
    })
  })


  watch(artwork, () => {
    if (!artwork.value) {
      return
    }

    if (circleAdText.value) {
      return
    }

    get()
  })


  const get = async function () {
    try {
      if (!artwork.value) {
        return
      }
      
      const circleId = artwork.value.groupIds[0]
      if (!circleId) {
        return
      }

      circle.value = await getCircle(circleId)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  onMounted(get)

  return {
    circleAdText,
    circle
  }
}