<template>
  <mt-tabbar
    v-show="show"
    v-model="path"
    fixed>
    <mt-tab-item id="/circles/all">
      <img
        v-show="!isCirclesall"
        slot="icon"
        src="@/assets/ic_tabs_fans@2x.png">
      <img
        v-show="isCirclesall"
        slot="icon"
        src="@/assets/ic_tabs_fan_switch_on@2x.png">
    </mt-tab-item>
    <mt-tab-item id="/channels">
      <img
        v-show="!isChannels"
        slot="icon"
        src="@/assets/ic_tabs_post@2x.png">
      <img
        v-show="isChannels"
        slot="icon"
        src="@/assets/ic_tabs_post_switch_on@2x.png">
    </mt-tab-item>
    <mt-tab-item id="/explore">
      <img
        v-show="!isExplore"
        slot="icon"
        src="@/assets/Discovery_disable.png">
      <img
        v-show="isExplore"
        slot="icon"
        src="@/assets/Discovery_SwitchOn.png">
    </mt-tab-item>
    <mt-tab-item id="/stars">
      <img
        v-show="!isStars"
        slot="icon"
        src="@/assets/ic_tabs_collect@2x.png">
      <img
        v-show="isStars"
        slot="icon"
        src="@/assets/ic_tabs_collect_swich_on@2x.png">
    </mt-tab-item>
    <mt-tab-item id="/i">
      <badge
        v-if="unreadCount > 0 && path !== '/i'"
        :value="unreadCount > 99 ? '99+' : unreadCount"></badge>
      <img
        v-show="!isMe"
        slot="icon"
        src="@/assets/ic_tabs_user_center@2x.png">
      <img
        v-show="isMe"
        slot="icon"
        src="@/assets/ic_tabs_user_center_switch_on@2x.png">
    </mt-tab-item>
  </mt-tabbar>
</template>

<script>
import { useNotificationStore } from '@/pinia/notification'
import Badge from 'primevue/badge'
import { computed, onMounted } from 'vue'


export default {
  setup () {
    const notificationStore = useNotificationStore()

    onMounted(() => {
      notificationStore.fetchUnreadCount()
    })

    return {
      unreadCount: computed(() => notificationStore.unreadCount),
    }
  },
  computed: {
    path: {
      get () {
        const path = this.$route.path

        if (path.length > 1 && path.substr(-1) === '/') {  // remove "/" at the tail
          return path.substring(0, path.length - 1)
        } else {
          return path
        }
      },

      set (path) {
        this.$router.replace(path)
      }
    },
    isMe () {
      return this.path === '/i'
    },
    isCircles () {
      return this.path === '/'
    },
    isChannels () {
      return this.path === '/channels'
    },
    isStars () {
      return this.path === '/stars'
    },
    isExplore () {
      return this.path === '/explore'
    },
    isCirclesall () {
      return this.path === '/circles/all'
    },
    show () {
      return this.isCircles || this.isChannels || this.isMe || this.isStars || this.isExplore || this.isCirclesall
    }
  },
  components: {
    Badge
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.mint-tabbar {
  background-color: rgba(255, 255, 255, 0.95);
  background-image: none;
  border: 1px solid @border;
  min-height: 50px;
}

.mint-tabbar.is-fixed {
  z-index: 3;
}

.mint-tabbar > .mint-tab-item.is-selected {
  background: none;
}

.mint-tab-item {
  position: relative;
}

.p-badge {
  position: absolute;
  top: -0.25rem;
  left: 0;
}
</style>
