import { api } from "./base"


export const createZipTask = async (artworkId) => {
  try {
    const res = await api.post('/zipTasks', {
      artworkId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getZipTasks = async (page = 1, limit = 10) => {
  try {
    const res = await api.get('/zipTasks', {
      params: {
        page,
        limit
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}