<template>
  <div
    v-if="post"
    class="item-container touchable hoverable">
    <div
      @click.prevent.stop="$router.push(`/comics/${post._id}`)">
      <div class="item-header">
        <user-avatar
          class="item-avatar hoverable item-avatar-common"
          :class="{'item-avatar-pc-postlistitem': !isOnMobile}" 
          :user="post.user"
          :disabled="true"
          @click.stop.native="$router.push(`/users/${post.user._id}`)"></user-avatar>
        <h5
          class="item-nickname">
          {{ nickname }}
          <circle-badge
            v-if="post.user && post.user.tag && post.user.tag.visible"
            :member-tag="post.user.tag.memberTag"
            @click.native.stop="$router.push(`/user/${post.user.tag.authorId}/circles`)"></circle-badge>
        </h5>
        <span
          class="user-avatar-span">
          {{ createdAt }}&nbsp;&nbsp;
          {{ price === $t('free') ? $t('free') : priceText }}
        </span>
      </div>

      <img
        v-if="post.fake"
        class="fake-badge"
        src="@/assets/fake_original.png"
        alt="fake original">

      <div
        class="title-common">
        <h2>
          {{ outofservice ? $t('outofservice', { countryCode }) : localeTitle }}
          {{ localeTitle }}{{ localeTitle }}
        </h2>
        <a
          v-if="post.likes > post.starCount || post.starCount === 0"
          class="indicator hoverable"
          @click.prevent.stop="like">
          <img
            v-if="!post.liked"
            src="@/assets/like.png">
          <img
            v-else
            src="@/assets/like_click.png">
          <span>{{ post.likes }}</span>
        </a>
        <a
          v-else
          class="indicator hoverable"
          @click.prevent.stop="intentStar">
          <img
            v-if="!post.starred"
            src="@/assets/star.png">
          <img
            v-else
            src="@/assets/star_click.png">
          <span>{{ post.hideStars ? '' : post.starCount }}</span>
        </a>
      </div>
      
      <more-menu
        v-model="moreMenuVisible"
        :items="menuItems">
        <span
          v-if="isOnMobile"
          class="more touchable hoverable more-common"
          @click.prevent.stop="moreMenuVisible = !moreMenuVisible">
          <img src="@/assets/More_icon.png">
        </span>
        <span
          v-else
          class="more touchable hoverable more-common"
          @click.prevent.stop="moreMenuVisible = !moreMenuVisible"
          @mouseover="moreMenuVisible = true"
          @mouseleave="moreMenuVisible = false">
          <img src="@/assets/More_icon.png">
        </span>
      </more-menu>

      <img
        v-if="coverUrl && !outofservice"
        class="circleimg"
        :style="coverImageStyle"
        :src="coverUrl">
      <span
        v-if="post.title && !outofservice"
        class="comic-title">{{ post.title }}</span>
      <span
        v-else-if="!outofservice"
        class="comic-title">{{ post.coverTitle }}</span>
      <tags
        class="tag-over"
        :tags="post.tags"></tags>
    </div>

    <!--report-->
    <mt-popup
      v-if="reportShow"
      v-show="reportShow"
      :position="isOnMobile ? 'right' : 'middle'">
      <report
        :pics-component="post"
        :post-id-component="post._id"
        :nickname-component="nickname"
        :user-id-component="post.user._id"
        :url="true"
        @close="reportShow = false"></report>
    </mt-popup>
    <!--report-->

    <media-saver
      v-model="collectorShow"
      :post="post"
      @completed="onStarCompleted"></media-saver>
  </div>
</template>


<script>
//@ts-check
import Tags from '@/components/Tags.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import Report from '@/pages/Report.vue'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { usePostMoreMenu } from '@/composables/artwork_moremenu'
import { useResponsive } from '@/composables/responsive'
import { useArtworkActions } from '@/composables/artwork_action'
import MediaSaver from '@/components/MediaSaver.vue'
import MoreMenu from '@/components/MoreMenu.vue'
import { useCurrentCountry } from '@/composables/country'


export default {
  props: {
    postId: {
      type: String,
      required: true
    },
  },
  setup (props) {
    const { currency } = useCurrentCurrency()
    const artworkPresentComposition = useArtworkPresent(props.postId, currency)

    return {
      ...useCurrentCountry(),
      ...artworkPresentComposition,
      currency,
      ...usePostMoreMenu(artworkPresentComposition.post),
      ...useResponsive(),
      ...useArtworkActions(props.postId),
    }
  },
  components: {
    Tags,
    UserAvatar,
    CircleBadge,
    Report,
    MediaSaver,
    MoreMenu,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.item-container {
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 0.1rem @border;

  .more {
    position: absolute;
    right: 8px;
    top: 45px;

    img {
      width: 20px;
    }
  } 
  .more-common {
    top: 30px;
  }
}

.title-common {
  width:236px;
  display: flex;
  align-items: flex-start;

  h2 {
    font-size: 17px;
    color: @minor;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  a {
    margin-left: auto;
    font-size: 0.6rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 0.8rem;
    }
  }
}

.item-header {
  text-align: left;

  span {
    position: absolute;
    left: 6.1em;
    top: 3.4em;
    font-size: 12px;
    color: @grey;
  }
  .user-avatar-span {
    left: 5.9em;
  }

  .item-avatar {
    width: 50px;
    height: 50px;
    margin-left: 0.85em;
    margin-top: 0.85em;
    border-radius: 25px;
  }

  .item-avatar-pc-postlistitem {
    margin-left: 0em;
  }

  .item-avatar-common {
    margin-left: 0.4em;
  }

  .item-nickname {
    position: absolute;
    left: 4.6em;
    top: 1.3em;
    font-size: 15px;
    color: @black;
    font-weight: 500;
  }
}

.indicator {
  img {
    width: 20px;
  }

  span {
    font-size: 12px;
    color: @grey;
    margin-left: 3px;
  }
}

.fake-badge {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 120px;
  z-index: 10;
}

a {
  span {
    color: #B4B4B4;
    vertical-align: middle;
    margin-left: -3px;
  }

  img {
    width: 32px;
    vertical-align: middle;
  }
}


.tag-tuchong {
  margin-bottom: 0em !important;
  margin-left: -0.7em !important;
 
}

.circleimg {
  width: 236px;
  margin-left: 10px;
  margin-top: 6px;
}

.comic-title {
  position: absolute;
  display: inline-block;
  bottom: 54px;
  width: calc(100% - 28px);
  left: 14px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.34);
}

</style>
