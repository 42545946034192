<template>
  <div class="r18-cover">
    <p>{{ $t('r18tip') }}</p>
    <div class="buttons">
      <pr-button
        @click.native="$router.back()">{{ $t('no') }}</pr-button>
      <pr-button
        type="primary"
        @click.native="$emit('dismiss')">{{ $t('yesiam18') }}</pr-button>
    </div>
  </div>
</template>


<script>
import PrButton from '@/components/PRButton.vue'

export default {
  components: {
    PrButton
  }
}
</script>


<style lang="less">
.r18-cover {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 1em;
  box-sizing: border-box;

  p {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }

  .buttons {
    width: 20em;
    text-align: center;
    
    > button {
      margin: 0.5em;
    }
  }
}
</style>