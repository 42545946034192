//@ts-check
import { api } from "./base"


export const sendIssue = async function ({ assigneeId, content, price, currency, publicIssue }, {
  returnUrl,
  subProcessor,
  processor,
}) {
  try {
    const res = await api.post('/issues', {
      assigneeId,
      content,
      price,
      currency,
      publicIssue,
      returnUrl,
      subProcessor,
      processor,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const addRecord = async function (issueId, { content = '', status = null, artworkId = null, returnUrl = null, subProcessor = null, processor = null }) {
  try {
    const res = await api.post(`/issues/${issueId}/records`, {
      content,
      status,
      artworkId,
      returnUrl,
      subProcessor,
      processor,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getIssues = async function (query, { page, limit }) {
  try {
    const res = await api.get('/issues', {
      params: {
        ...query,
        page,
        limit
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getOneIssue = async function (issueId) {
  try {
    const res = await api.get(`/issues/${issueId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateIssue = async function (issueId, doc) {
  try {
    const res = await api.put(`/issues/${issueId}`, {
      ...doc
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}