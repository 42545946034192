<template>
  <div class="container">
    <div
      v-for="(user, index) in users"
      :key="user._id"
      class="user">
      <img
        loading="lazy"
        :src="user.avatarURL">
      <h2>{{ user.nickname }}</h2>
      <pr-button
        size="small"
        @click.native="user.blocked ? unblock(index) : block(index)">
        {{ getButtonTextFor(index) }}
      </pr-button>
    </div>

    <load-more-button
      :has-more="hasMore"
      :busy="loading"
      :trigger="get"></load-more-button>
  </div>
</template>


<script>
import { blockUser, unblockUser, getBlockedUsers } from '@/api/user'
import { onMounted, ref } from 'vue'
import { Indicator } from 'mint-ui'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PrButton from '@/components/PRButton.vue'
import { useToast } from '@/composables/toast'


const limit = 10

export default {
  setup () {
    const page = ref(1)
    const users = ref([])
    const hasMore = ref(true)
    const loading = ref(false)
    const { Toast } = useToast()

    const get = async function () {
      try {
        loading.value = true
        const gotUsers = await getBlockedUsers(page.value, limit)
        if (gotUsers.length === 0) {
          hasMore.value = false
        } else {
          users.value.push(...gotUsers)
          page.value += 1
        }
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }


    const block = async function (index) {
      try {
        Indicator.open()
        const user = users.value[index]
        await blockUser(user._id)
        user.blocked = true
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        Indicator.close()
      }
    }


    const unblock = async function (index) {
      try {
        Indicator.open()
        const user = users.value[index]
        await unblockUser(user._id)
        user.blocked = false
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        Indicator.close()
      }
    }


    const getButtonTextFor = function (index) {
      const user = users.value[index]
      if (user.blocked) {
        return '移除'
      }

      return '拉黑'
    }


    onMounted(() => {
      get()
    })


    return {
      users,
      hasMore,
      get,
      page,
      loading,
      block,
      unblock,
      getButtonTextFor,
    }
  },
  components: {
    LoadMoreButton,
    PrButton,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.user {
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-bottom-style: solid;
  border-bottom-color: @border;
  border-bottom-width: 1px;

  img {
    width: 3em;
    margin-right: 0.5em;
  }

  h2 {
    color: @black;
    font-size: 14px;
  }

  button {
    margin-left: auto;
  }
}
</style>