<template>
  <div class="container">
    <article
      v-if="currentUser.authed"
      class="article"
    >
      <h1>不良度简介</h1>

      <ul>
        <li>只有认证用户才有不良度</li>
        <li>用户的不良度为作品不良度的平均分</li>
        <li>每当发布新作品时，不良度会被重新计算</li>
      </ul>
    </article>
    <article
      v-else
      class="article"
    >
      <h1>只有原创认证用户才有不良度</h1>
    </article>
  </div>
</template>

<script>
import '@/assets/doc.css'
import { useCurrentUser } from '@/composables/user_data'


export default {
  metaInfo: {
    title: "关于不良度",
  },
  setup () {
    return {
      ...useCurrentUser()
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.container {
  height: 100%;
  background: white;
}

.good {
  color: @positive;
}

.bad {
  color: red;
}
</style>
