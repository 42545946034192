<template>
  <component :is="ChannelEditForm"></component>
</template>


<script>
import ChannelEditForm from '@/components/ChannelEditForm.vue'

export default {
  setup () {
    return {
      ChannelEditForm,
    }
  }
}
</script>