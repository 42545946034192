import { onMounted, ref } from 'vue'
import { getBuyers } from '@/api/post'
import { useRoute } from 'vue-router/composables'
import { useToast } from './toast'


const limit = 10

export const useArtworkBuyer = function () {
  const buyers = ref([])
  const buyerPage = ref(1)
  const buyerHasmore = ref(false)
  const route = useRoute()
  const buyerBusy = ref(false)
  const { Toast } = useToast()


  const getArtworkBuyers = async function () {
    try {
      const { postBuyers } = await getBuyers(route.params.postId, buyerPage.value, limit)
      if (postBuyers.length === 0) {
        buyerHasmore.value = false
      } else {
        buyers.value.push(...postBuyers.map(postBuyer => postBuyer.user))
        buyerPage.value += 1
        buyerHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  onMounted(() => {
    getArtworkBuyers()
  })


  return {
    buyers,
    buyerHasmore,
    buyerPage,
    getArtworkBuyers,
    buyerBusy,
  }
}