import { api } from "./base"


export const getSelfTags = async function (userId) {
  try {
    const res = await api.get('/selfTags', {
      params: {
        userId
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const addSelfTag = async function (title) {
  try {
    const res = await api.post('/selfTags', {
      title
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const removeSelfTag = async function (tagId) {
  try {
    const res = await api.delete(`/selfTags/${tagId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateSelfTag = async function (tagId, doc) {
  try {
    const res = await api.put(`/selfTags/${tagId}`, doc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getSelfTag = async function (tagId) {
  try {
    const res = await api.get(`/selfTags/${tagId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}