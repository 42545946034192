<template>
  <div
    v-if="circle"
    class="child hoverable touchable">
    <div class="top">
      <transition-image
        :styles="{
          height: '256px',
          width: '256px',
        }"
        :src="circle.coverUrl">
      </transition-image>
      <span>{{ circle.title }}</span>
    </div>
    <div class="middle">
      <tags
        class="tags"
        :update="circle.status === 'updating' ? true : false"
        size="small"
        :tags="tags"
        :lighter="true"></tags>

      <p>{{ getPostTitle(circle.lastUpdatedPost) }}</p>
      <div class="bottom">
        <img
          loading="lazy"
          :src="circle.author.avatarURL">
        <span class="bold">{{ circle.author.nickname }}
          <circle-badge
            v-if="circle.author.tag && circle.author.tag.memberTag != null"
            :member-tag="circle.author.tag.memberTag"
            @click.native.stop="$router.push(`/user/${circle.author.tag.authorId}/circles`)"></circle-badge>
        </span>
        <span
          class="join"
          :class="{ 'yellow-mobile-circle-cell': !circle.isMember }">
          {{ circle.isMember ? $t('joined') : priceText }} 
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from '@/components/Tags.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import { useCirclePresent } from '@/composables/circle_present'
import { useCurrentCurrency } from '@/composables/currency'
import { useFormattedString } from '@/composables/formatted_string'
import TransitionImage from '@/components/TransitionImage.vue'


export default {
  props: {
    circle: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { currency } = useCurrentCurrency()

    return {
      ...useCirclePresent(currency, {
        circle: props.circle,
      }),
      ...useFormattedString(),
    }
  },
  components: {
    Tags,
    CircleBadge,
    TransitionImage,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.child:hover {
  background-color: rgba(0, 0, 0, 0.01);
  opacity: 1;
}

.child {
  box-sizing: border-box;
  background-color: white;
  border: 1.5px solid @border;
  margin: 10px 10px 0 0;
  box-sizing: border-box;
  width: 256px;
  height: 398px;
  border-radius: 4px;
  overflow: hidden;

  .top {
    position: relative;
    height: 256px;

    span {
      position: absolute;
      bottom: 2px;
      left: 6px;
      font-size: 17px;
      font-weight: bold;
      color: @white;
    }
  }
  .middle {
    padding: 4px 10px;

    .tags {
      margin-bottom: 0;
      height: 57.58px;
    }

    p {
      line-height: 30px;
      font-size: 14px;
      color: #9b9b9b;
      margin-top: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      height: 30px;
      background-color: #f1f1f1;
      margin-bottom: 5px;
      padding-left: 5px;
      border-radius: 4px;
      margin-top: 6px;
      box-sizing: border-box;
      font-weight: 300;
    }
  }
  .bottom {
    position: relative;

    img {
      width: 21px;
      height: 21px;
      border-radius: 21px;
      vertical-align: text-bottom;
    }
    span {
      font-size: 14px;
      color: @black;
      padding-left: 2px;

    }
    .bold {
      font-weight: bold;
    }
    .join {
      position: absolute;
      right: 0px;
      top: 2px;
      border: 1px solid @border;
      color: @border;
      padding: 0px 8px;
      border-radius: 11.5px;
      font-size: 10px;
    }
    .yellow-mobile-circle-cell {
      color: @yellow;
      border: 1px solid @yellow;
      font-weight: bold;
    }
  }
}
</style>
