//@ts-check
import { APP_URL } from "@/config"
import { useChannelStore } from "@/pinia/channel"
import { eventEmitter, seoworkds } from "@/utils"
import { events } from "@/utils/events"
import { computed, onActivated, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n-composable"
import { useRoute } from "vue-router/composables"
import { useToast } from "./toast"
import { sanitize } from "dompurify"


// must pass props because props is reactive, props must contains channelId or channel obj
export const useChannelPresent = function (props) {
  const channelStore = useChannelStore()
  const paramsChannelId = useRoute().params.channelId
  const hotHasmore = ref(true)
  const hotLoading = ref(false)
  const bestHasmore = ref(true)
  const bestLoading = ref(false)
  const latestHasmore = ref(true)
  const latestLoading = ref(false)
  const algoLoading = ref(false)
  const { t } = useI18n()
  const { Toast } = useToast()
  const placeholderText = ref(t('loading'))
  const selectedTabIndex = ref(1)


  const channelId = computed(() => {
    if (paramsChannelId) {
      return paramsChannelId
    }

    return props.channelId
  })


  const channelMetaInfo = computed(() => {
    const info = {
      meta: []
    }
    let content = ''

    if (!channel.value) {
      info.title = sanitize(`${t('channels')} | Perohub`)
      info.meta.push({ name: 'keywords', content: seoworkds })
    } else {
      info.title = `${channel.value.title} | Perohub`
      content = `${sanitize(channel.value.title)} ${sanitize(channel.value.description)}`
      info.meta.push({
        name: 'keywords',
        content: `${sanitize(channel.value.title)},${seoworkds}`
      }, {
        name: 'twitter:image',
        content: channel.value.coverUrl,
      }, {
        name: 'og:image',
        content: channel.value.coverUrl,
      }, {
        name: 'og:url',
        content: `${APP_URL}/channels/${channel.value._id}`
      })
    }

    info.meta.push({ name: 'description', content: sanitize(content) })
    info.meta.push({
      name: 'twitter:card',
      content: 'summary',
    }, {
      name: 'twitter:site',
      content: '@perohubcom'
    }, {
      name: 'twitter:title',
      content: sanitize(info.title),
    }, {
      name: 'twitter:description',
      content,
    }, {
      name: 'og:title',
      content: sanitize(info.title)
    })

    return info
  })


  const channel = computed(() => {
    return channelStore.channels[channelId.value]
  })

  watch(channelId, () => {
    selectedTabIndex.value = 1
  })

  const getHotPosts = async function () {
    try {
      hotLoading.value = true
      const beforeCount = channel.value.hotPosts.length
      await channelStore.fetchChannelHotPosts(channelId.value)
      const afterCount = channel.value.hotPosts.length
      if (beforeCount === afterCount) {
        hotHasmore.value = false
      } else {
        hotHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      hotLoading.value = false
    }
  }


  const getBestPosts = async function () {
    try {
      bestLoading.value = true
      const beforeCount = channel.value.bestPosts.length
      await channelStore.fetchChannelBestPosts(channelId.value)
      const afterCount = channel.value.bestPosts.length
      if (beforeCount === afterCount) {
        bestHasmore.value = false
      } else {
        bestHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      bestLoading.value = false
    }
  }


  const getLatestPosts = async function () {
    try {
      latestLoading.value = true
      const beforeCount = channel.value.latestPosts.length
      await channelStore.fetchChannelLatestPosts(channelId.value)
      const afterCount = channel.value.latestPosts.length
      if (beforeCount === afterCount) {
        latestHasmore.value = false
        if (afterCount === 0) {
          placeholderText.value = t('channelNoPosts')
        }
      } else {
        latestHasmore.value = true
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      latestLoading.value = false
    }
  }

  const getAlgoPosts = async function () {
    try {
      algoLoading.value = true
      await channelStore.fetchChannelAlgoPosts(channelId.value)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      algoLoading.value = false
    }
  }

  const init = async function () {
    hotLoading.value = true
    bestLoading.value = true
    latestLoading.value = true
    algoLoading.value = true
    await channelStore.fetchChannel({ channelId: channelId.value })
    hotLoading.value = false
    bestLoading.value = false
    latestLoading.value = false
    algoLoading.value = false
  }


  onMounted(() => {
    init()

    eventEmitter.on(events.LoginCompleted, () => {
      init()
    })
    eventEmitter.on(events.LogoutCompleted, () => {
      init()
    })
  })


  onActivated(() => {
    channelStore.$patch({ sourceChannelId: null })
  })


  return {
    channel,
    hotHasmore,
    hotLoading,
    bestHasmore,
    bestLoading,
    latestHasmore,
    latestLoading,
    algoLoading,
    getHotPosts,
    getBestPosts,
    getAlgoPosts,
    getLatestPosts,
    channelMetaInfo,
    placeholderText,
    selectedTabIndex,
  }
}