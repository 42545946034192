<template>
  <div class="members-container">
    <user-list-item
      v-for="user in users"
      :key="user._id"
      :user="user"
      class="touchable hoverable"
      @click.native="$router.push(`/users/${user._id}`)"></user-list-item>

    <load-more-button
      :has-more="hasMore"
      :busy="loading"
      :trigger="get"></load-more-button>
  </div>
</template>


<script>
import UserListItem from '@/components/UserListItem.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { onMounted, ref } from 'vue'
import { getMembers } from '@/api/circle'
import { useRoute } from 'vue-router/composables'
import { useToast } from '@/composables/toast'


export default {
  metaInfo () {
    return {
      title: 'Circle Members'
    }
  },
  setup () {
    const loading = ref(false)
    const hasMore = ref(true)
    const users = ref([])
    const circleId = useRoute().params.circleId
    const page = ref(1)
    const { Toast } = useToast()

    const get = async function () {
      try {
        const newUsers = await getMembers(circleId, page.value)
        if (newUsers.length === 0) {
          hasMore.value = false
        } else {
          users.value.push(...newUsers)
          page.value += 1
        }
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    onMounted(get)

    return {
      loading,
      hasMore,
      users,
      get,
    }
  },
  components: {
    UserListItem,
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>

</style>