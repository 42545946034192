//@ts-check
import { fetchConfig } from "@/api/system"
import { computed, onMounted, ref } from "vue"
import { useToast } from "./toast"


let _config = null

export const useServerConfig = function () {
  const config = ref({})
  const { Toast } = useToast()
  const tax = computed(() => {
    // @ts-ignore
    return config.value.withdrawRoyaltyRate
  })
  const taxString = computed(() => {
    return `${Math.round(tax.value * 100)}%`
  })

  onMounted(async () => {
    if (!_config) {
      try {
        _config = await fetchConfig()        
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    config.value = _config
  })

  return {
    tax,
    taxString,
  }
}