<template>
  <div
    class="self-tag-selector"
    :class="{ selected }">
    {{ tag.title }}
  </div>
</template>


<script>
export default {
  props: {
    tag: {
      required: true,
      type: Object,
    },
    selected: {
      required: false,
      type: Boolean,
      default: false,
    }
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.self-tag-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.15rem @border solid;
  border-radius: 0.4rem;
  color: @minor;
  font-size: 14px;
  font-weight: bolder;
  padding: 0.25rem 1rem;
  height: 2.4rem;
  white-space: nowrap;
}

.self-tag-selector.selected {
  background-color: @border;
}
</style>