import { api } from './base'

export const exchange = async function (base, accurate = true) {
  try {
    const params = {
      base,
    }
    if (accurate) {
      params.accurate = accurate
    }
    const res = await api.get('/configs/exchange', {
      params,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}