//@ts-check
import { MediaType } from "@/utils/defines"
import { ref, computed, watch } from "vue"

export const makeTextMedia = function () {
  return {
    type: MediaType.TEXT,
    canBrowse: true,
    textContent: '',
    path: 'placeholder.txt',
  }
}

export const useArtworkWrite = function ({ mediasRef }) {
  const textMedias = ref([])
  const articleMedias = ref([])

  const textMediaPreviews = computed(() => {
    return [...textMedias.value, ...mediasRef.value]
  })

  watch(textMediaPreviews, (newVal) => {
    const currentOrder = articleMedias.value.filter(item => newVal.includes(item))
    const newItems = newVal.filter(item => !currentOrder.includes(item))
    articleMedias.value = [...currentOrder, ...newItems]
  }, { immediate: true })

  const addTextMedia = function () {
    textMedias.value.push(makeTextMedia())
  }

  const concludeTextMedias = function () {
    return textMedias.value.filter(media => media.textContent.trim() !== '')
  }

  return {
    articleMedias,
    addTextMedia,
    textMedias,
    concludeTextMedias,
  }
}