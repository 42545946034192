<template>
  <div class="container">
    <mt-navbar
      v-model="selected"
      class="card-1"
      :fixed="true">
      <mt-tab-item
        v-for="index in indexes"
        :id="index"
        :key="index"
        class="touchable hoverable">
        {{ titles[index] }}
      </mt-tab-item>
    </mt-navbar>

    <mt-tab-container v-model="selected">
      <mt-tab-container-item
        v-for="(chapter, index) in chapters"
        :id="index"
        :key="index">
        <article
          class="article"
          v-html="sanitize(chapter)"></article>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import { parse } from 'marked'
import { sanitize } from 'dompurify'
import '@/assets/doc.css'

import slogan from '@/docs/slogan.md'
import piracy from '@/docs/piracy.md'
import censorship from '@/docs/censorship.md'
import compliants from '@/docs/compliants.md'


export default {
  metaInfo: {
    title: "Community Guidelines",
  },
  data () {
    return {
      scripts: [ slogan, piracy, compliants, censorship ],
      selected: 0
    }
  },
  computed: {
    chapters () {
      return this.scripts.map(script => sanitize(parse(script)))
    },
    titles () {
      return ['Vision', 'Termination', 'Complaints', 'Censorship']
    },
    indexes () {
      return this.chapters.map((_, index) => index)
    }
  },
  watch: {
    selected (selected) {
      window.scrollTo(0, 0)

      this.$router.replace({
        path: '/rules',
        query: {
          chapter: this.titles[selected]
        }
      }).catch(err => {

      })
    }
  },
  methods: {
    sanitize,
  },
  mounted () {
    const chapterTitle = this.$route.query.chapter
    if (chapterTitle) {
      this.titles.forEach((title, index) => {
        if (title === chapterTitle) {
          this.selected = index
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  height: 100%;
  background: #fcfcfc;
}

.mint-navbar {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

a.mint-tab-item {
  color: black;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.mint-navbar .mint-tab-item.is-selected {
  border-bottom: 0px solid @main;
  color: @main;
  margin-bottom: 0px;
  background: #efefef;
}
</style>
