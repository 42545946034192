<template>
  <pr-popup
    v-model="showReplies"
    class="replies-modal card-3"
    @hide="dismiss">
    <template #header>
      {{ comment.user ? $t('commentOf', { username: comment.user.nickname }) : '' }}
    </template>

    <div class="replies-container">
      <div class="content">
        <comment-cell
          v-if="comment.user"
          :comment="comment"
          :is-reply="true">
        </comment-cell>
        <hr>
        
        <div
          v-if="comment.replies && comment.replies.length > 0"
          class="replies">
          <comment-cell
            v-for="reply in comment.replies"
            :key="reply._id"
            :comment="reply"
            :is-reply="true">
          </comment-cell>
        </div>

        <load-more-button
          v-if="comment.replies"
          v-show="comment.replies.length > 0"
          :busy="busy"
          :has-more="hasMore"
          @trigger="getCommentReplies(comment._id)">
        </load-more-button>
      </div>

      <div class="inputer">
        <input
          v-model="replyContent"
          type="text"
          :placeholder="authenticated ? $t('enterComment') : $t('pleaseLogin')"
          :disabled="!authenticated">

        <pr-button
          type="primary"
          :disabled="!authenticated"
          @click.native="send">
          {{ $t('send') }}
        </pr-button>
      </div>
    </div>
  </pr-popup>
</template>

<script>
import { API_URL } from '@/config'
import CommentCell from '@/components/CommentCell.vue'
import PrButton from '@/components/PRButton.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useToast } from '@/composables/toast'
import { ref, onMounted } from 'vue'
import { events } from '@/utils/events'
import { eventEmitter } from '@/utils'
import PrPopup from '@/components/PRPopup.vue'


export default {
  setup () {
    const comment = ref({})

    onMounted(() => {
      eventEmitter.on(events.CommentDeleted, commentId => {
        // @ts-ignore
        if (comment.value.replies) {
          // @ts-ignore
          comment.value.replies = comment.value.replies.filter(comment => {
            if (comment._id === commentId) {
              return false
            }

            return true
          }) 
        }
      })
    })

    return {
      comment,
      ...useResponsive(),
      ...useCurrentUser(),
      ...useToast(),
    }
  },
  data () {
    return {
      showReplies: false,
      replyContent: '',
      hasMore: true,
      busy: false,
      page: 1
    }
  },
  methods: {
    getCommentReplies(commentId) {
      this.busy = true

      this.$http
        .get(`${API_URL}/comments/reply`, {
          params: {
            commentId,
            page: this.page,
            limit: 10
          }
        })
        .then(({ ok, data }) => {
          if (ok && data.code === 200) {
            const replies = data.result.comments

            if (this.page === 1) {
              this.comment.replies = data.result.comments
            } else if (replies.length > 0) {
              data.result.comments.forEach(reply => this.comment.replies.push(reply))
            } else {
              this.hasMore = false
            }
          }

          this.page = this.page + 1
          this.busy = false
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : (err.data ? err.data.message : err),
          })
        })
    },
    show (comment) {
      this.comment = comment
      this.showReplies = true
      this.$parent.stopBodyScroll(true)
      this.getCommentReplies(comment._id)
    },
    dismiss () {
      this.showReplies = false
      this.comment = {} 
      this.$parent.stopBodyScroll(false)
      this.page = 1
    },
    send () {
      this.$http
        .post(`${API_URL}/comments/reply`, {
          commentId: this.comment._id,
          content: this.replyContent
        })
        .then(({ ok, data }) => {

          if (ok) {
            this.comment.replies.push(data.result)
            this.replyContent = ''
          }
        })
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : (err.data ? err.data.message : err),
          })
        })
    }
  },
  components: {
    CommentCell,
    PrButton,
    LoadMoreButton,
    PrPopup,
  }
}
</script>

<style>
.replies-modal .p-dialog {
  width: 30rem;
  max-height: 460px;
}

.replies-modal.p-sidebar {
  height: 40rem;
}

.replies-modal .p-sidebar-content {
  flex: 1;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";

.replies-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
}

.content {
  flex: 1;
}

hr {
  margin-top: -16px;
}

.inputer {
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  background: white;
  backdrop-filter: blur(1px);
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: inset 0 0.5px 0 0 #f1f1f1;
  display: flex;
  gap: 1rem;

  button {
    width: 30%;
    max-width: 64px;
    height: 30px;
    border-radius: 2px;
    font-size: 13px;
    vertical-align: middle;
  }

  input {
    height: 30px;
    border-radius: 2px;
    border: 0px;
    padding-left: 5px;
    outline: 0;
    vertical-align: middle;
    flex: 1;
    font-size: 14px;
    border-radius: 4px;
    background-color: #f1f1f1;
    font-size: 14px;
    font-weight: bold;

    &[disabled] {
      background: #eee;
      color: @light-grey;
    }
  }
}
</style>
